@import "../../../../Assets/_var.scss";

.followers {
  // .searchbar {
  //   margin-bottom: 5rem;

  //   :global {
  //     .search_input {
  //       width: 100%;

  //       .search_input_inner {
  //         height: 5.8rem;
  //         border-radius: 2.9rem;
  //         background: #f6f6f6;
  //         padding: 0 2.8rem;

  //         @media (max-width: 991px) {
  //           height: 5rem;
  //         }

  //         @media (max-width: 767px) {
  //           height: 4.5rem;
  //         }

  //         .search_input_icon {
  //           border: none;
  //           left: 2rem;

  //           svg {
  //             path {
  //               fill: #8e8c8c;
  //             }
  //           }
  //         }

  //         .form-control {
  //           border: none;
  //           background: transparent;
  //           font-size: 2rem;

  //           @media (max-width: 991px) {
  //             font-size: 1.8rem;
  //           }

  //           @media (max-width: 767px) {
  //             font-size: 1.6rem;
  //           }

  //           &::placeholder {
  //             color: #959494;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .rowFollow {
    margin: -3.1rem -1.5rem;

    @media (max-width:1679px) {
      margin: -2.8rem -1.5rem;
    }

    @media (max-width:1399px) {
      margin: -2.2rem -1.5rem;
    }

    @media (max-width:991px) {
      margin: -1.8rem -1.5rem;
    }

    @media (max-width:767px) {
      margin: -1.2rem -1.5rem;
    }

    .colFollow {
      padding: 3.1rem 1.5rem;

      @media (max-width:1679px) {
        padding: 2.8rem 1.5rem;
      }

      @media (max-width:1399px) {
        padding: 2.2rem 1.5rem;
      }

      @media (max-width:991px) {
        padding: 1.8rem 1.5rem;
      }

      @media (max-width:767px) {
        padding: 1.2rem 1.5rem;
      }

    }

    @media (min-width:1400px) {
      :global {
        .col-xxl-2 {
          width: calc(100% / 5);
        }
      }
    }
  }

  .noData {
    margin: 2.5rem 0;
  }
}