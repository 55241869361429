@import '../../../../Assets/_var.scss';

.NextRelease {
    // padding-top: 10rem;
    position: relative;
    margin-top: 3rem;

    &Head {
        border-top: 2px solid $black;
        border-bottom: 2px solid $black;
        text-align: center;
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width:575px) {
            flex-direction: column;
        }

        &Gradient {
            background: #2B6747;
            background: linear-gradient(to top, #2B6747 0%, #4AAD7D 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        span {
            font-family: $baseFont;
        }
    }

    >div>div {
        position: relative;

        >div {
            &:last-child {
                @media (min-width:768px) {
                    border-left: 2px solid $black;
                }
            }
        }
    }

    &Left {
        h3 {
            padding-bottom: 1rem;
            padding-top: 2rem;
        }

        @media (max-width:767px) {
            text-align: center;
        }

        form {
            margin-top: 2rem;

            @media (min-width:1280px) {
                max-width: 507px;
            }
        }
    }

    .ApplyToCreate {
        padding-top: 3rem;

        h2 {
            color: $yellow;
        }

        p {
            margin: 1.5rem 0 3rem;
        }

        &Btn {
            color: $yellow;

            &:hover {
                color: $white;
            }
        }

        @media (max-width: 767px) {
            text-align: center;
            padding-top: 2rem;

            p {
                margin: 1.4rem 0;
            }
        }

        .form_fields {
            max-width: 50.6rem;
            width: 100%;

            @media (max-width:767px) {
                margin: 0 auto 2rem;
            }

            .btn_sizing {
                max-width: 18rem;
                width: 100%;
            }
        }
    }

    &Right {
        text-align: center;

        @media (min-width:768px) {
            text-align: right;
        }

        &heading {
            // border-bottom: 2px solid $black;
            padding: 2.6rem 0;
        }

        h2 {
            position: relative;
            padding-bottom: 1rem;
            padding-top: 2rem;
            display: block;
        }

        ul {
            padding-top: 2rem;
            margin-left: auto;

            @media (max-width:767px) {
                margin: 0 auto;
            }
        }

        &Box {
            width: 100%;
            border-bottom: 2px solid $black;
            padding: 2.6rem 0;

            @media (max-width: 1439px) {
                padding: 2rem 0;
            }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }

    @media (max-width:1679px) {
        padding-top: 8rem;
    }

    @media (max-width:1439px) {
        padding-top: 7rem;
    }

    @media (max-width:991px) {
        padding-top: 5rem;
    }
}