@import "../../../../Assets/_var.scss";

:global {
  .fade.modal-backdrop.show.fade_clrbg {
    background-color: #edc367;
    opacity: 0.85;
  }
}

.offerModal {
  &Left {
    &Inner {
      padding-bottom: 4rem !important;
      height: 57rem;

      img {
        height: 100%;
      }
    }
  }

  &Right {
    .editionDetails {
      margin-bottom: 0rem;
      height: 100%;

      :global {
        form {
          height: 100%;
        }
      }

      .buy_btn_box {
        margin-top: 5rem;
        padding-bottom: 0 !important;
      }
    }
  }
}

.sellsModal,
.offerModal {
  :global {
    .modal-dialog {
      max-width: 131rem !important;

      @media (max-width: 1439px) {
        max-width: 100rem !important;
      }

      @media (max-width: 1199px) {
        max-width: 90rem !important;
      }

      .modal-content {
        background-color: transparent;
        border: none;

        .modal-header,
        .modal-body {
          padding: 0;
        }
      }
    }
  }

  &Left,
  &Right {
    flex: 0 0 50%;
    padding: 5.8rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1679px) {
      padding: 4rem;
    }

    @media (max-width: 1439px) {
      padding: 3rem;
    }

    @media (max-width: 991px) {
      padding: 2.4rem;
    }

    .bottomLine {
      margin-top: auto;
      width: 100%;
      height: 2px;
      background-color: $white;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &Left {
    background-color: $white;

    .bottomLine {
      background-color: $black;
    }

    &Inner {
      padding-bottom: 7rem;

      @media (max-width: 1439px) {
        padding-bottom: 2.4rem;
      }

      @media (max-width: 767px) {
        padding-bottom: 0;
      }

      img {
        box-shadow: 0 0 6.2rem rgb(163, 163, 163, 0.3);
        width: 100%;
      }
    }
  }

  &Right {
    color: $white;
    background-color: $black;

    h3 {
      font-size: 4.6rem;
      margin-bottom: 5rem;
      line-height: 1;

      @media (max-width: 1679px) {
        font-size: 3.6rem;
        margin-bottom: 3rem;
      }

      @media (max-width: 1439px) {
        font-size: 3rem;
        margin-bottom: 2rem;
      }

      @media (max-width: 991px) {
        font-size: 2.4rem;
      }
    }

    .artistDetails {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 4rem;

      h4 {
        font-size: 2.4rem;
        font-weight: 700;

        @media (max-width: 1679px) {
          font-size: 2.2rem;
        }

        @media (max-width: 1439px) {
          font-size: 2rem;
        }

        @media (max-width: 1199px) {
          font-size: 1.8rem;
        }
      }

      &Edition {
        text-align: right;

        p {
          color: #a3a3a3;

          @media (max-width: 991px) {
            font-size: 1.4rem;
          }
        }

        span {
          font-weight: 700;
          font-size: 1.6rem;

          @media (max-width: 991px) {
            font-size: 1.4rem;
          }
        }
      }
    }

    .editionDetails {
      margin: 2.9rem 0 6.2rem;

      @media (max-width: 1679px) {
        margin: 2rem 0 2.6rem;
      }

      @media (max-width: 767px) {
        margin: 2rem 0;
      }

      .select_changes {
        margin-top: 1.5rem;

        :global {
          .common_select_inner {
            .react-select {
              &__control {
                border-radius: 1rem;
              }

              &__menu {
                background: #fff;

                &-list {
                  padding: 0;

                  .react-select__option {
                    color: #000;
                  }
                }
              }
            }
          }
        }
      }

      .addfileinput {
        :global {
          .common_input_inner {
            width: 100%;
            max-width: 25rem;

            .leftIcon {
              left: 1.2rem;
              width: 2rem;
            }

            .rightIcon {
              right: 1.2rem;
              width: 2rem;
            }

            .form-control {
              text-align: center;
              padding: 1.1rem 3rem;
            }
          }
        }
      }
    }

    .amountDetails {
      h5 {
        font-size: 2rem;
        margin-bottom: 4.4rem;

        @media (max-width: 1679px) {
          margin-bottom: 2rem;
        }

        @media (max-width: 1199px) {
          font-size: 1.8rem;
        }

        @media (max-width: 991px) {
          font-size: 1.6rem;
          margin-bottom: 1.6rem;
        }
      }

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;

        @media (max-width: 991px) {
          margin-bottom: 1.6rem;
        }

        &:last-child {
          margin: 2.9rem 0 0 0;

          h6 {
            font-size: 2rem;
            font-weight: 700;

            @media (max-width: 1199px) {
              font-size: 1.8rem;
            }

            @media (max-width: 991px) {
              font-size: 1.6rem;
            }
          }

          @media (max-width: 991px) {
            margin: 2rem 0 0 0;
          }
        }

        p {
          font-size: 1.6rem;
          font-weight: 400;
          color: $white;

          &:last-child {
            text-align: right;
          }

          @media (max-width: 991px) {
            font-size: 1.4rem;
          }
        }
      }
    }

    .buy_btn_box {
      margin-top: auto;
      padding-bottom: 2.2rem !important;

      .buy_btn {
        border-color: $white;
        width: 100%;
        color: $black;

        &:hover {
          color: $white;
        }
      }

      // @media (max-width: 1679px) {
      //     padding: 5rem 0;
      // }

      // @media (max-width: 1439px) {
      //     padding: 7.6rem 0 2.4rem;
      // }

      // @media (max-width: 1023px) {
      //     padding: 4rem 0;
      // }

      // @media (max-width: 991px) {
      //     padding: 2rem 0;
      // }

      // @media (max-width: 767px) {
      //     padding-bottom: 0;
      // }
    }

    .pricelist {
      padding: 2rem 2.6rem;
      border-radius: 1rem;
      background: #1a1a1a;

      h6 {
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3rem;
        font-weight: bold;
        font-family: "Roboto", sans-serif;
        color: #fff;
      }

      .highvalue {
        margin-bottom: 0;
        color: #ebbc57;

        span {
          color: #ebbc57;
        }
      }
    }

    .bottomLine {
      margin-top: 0;
    }
  }
}

.offerModal {
  &Right {
    .buy_btn_box {
      margin-top: auto;
      padding-bottom: 2.2rem !important;

      .buy_btn {
        &:hover {
          color: $white;
        }
      }
    }

    .bottomLine {
      margin-top: 0;
    }
  }
}

.uploadModal {
  :global {
    .modal-dialog {
      max-width: 58rem !important;

      .modal-content {
        .modal-header {
          padding: 1rem 2rem;
        }

        .modal-body {
          padding: 1rem 2rem 3rem;
        }

        .inputArt {
          label {
            color: $white;
            font-size: 1.6rem !important;
            font-family: "Roboto";
            margin-bottom: 1.2rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .uploadArtInner {
    .uploadIput {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 2.5rem;

      label {
        color: $white;
        font-size: 1.6rem !important;
        font-family: "Roboto";
        margin-bottom: 1.2rem;
        font-weight: 400;
      }

      &_inner {
        position: relative;

        input {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 4.7rem;
        }

        p {
          height: 4.7rem;
          width: 100%;
          background-color: $white;
          border-radius: 0.53rem;
          border: 0;
          padding: 1.1rem 1.5rem;
          font-size: 1.6rem;
          border: 2px solid $black;
          color: $black;
          display: flex;
          align-items: center;
          white-space: nowrap;
          svg {
            margin-right: 0.4rem;
          }
          span {
            width: 100%;
            color: $black;
            margin-left: 0.4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      :global {
        .error_newmsg {
          margin-top: 1rem;
          color: $red;
          font-size: 1.2rem;
          font-weight: 400;
        }
      }
    }
  }
}
