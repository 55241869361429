@import "../../../Assets/_var.scss";

@keyframes marquee {
  0% {
    background-position: 0;
  }

  100% {
    background-position: 300rem;
  }
}

.SignUp {
  width: 100%;
  background-color: $black4;
  // background-image: url(../../../Assets/images/Signup-bg.jpg);
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: top center;
  // background-attachment: fixed;
  padding: 12rem 2rem 17rem;
  position: relative;
  min-height: calc(100vh - 36.6rem);
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: absolute;
    background-image: url(../../../Assets/images/SignUpNewBg.svg);
    background-size: auto;
    background-position: center;
    background-repeat: repeat-x;
    animation: marquee 110s infinite linear;
    z-index: 0;
  }

  @media (min-height: 1079px) {
    min-height: calc(100vh - 30.6rem);
  }

  @media (max-width: 1439px) {
    padding: 9rem 2rem 13rem;
  }

  @media (max-width: 1199px) {
    min-height: calc(100vh - 17.6rem);
  }

  @media (max-width: 991px) {
    padding: 6rem 0;
  }

  &__Section {
    background-color: $white;
    padding: 4.5rem 4.7rem 5rem;
    border: 4px solid $black;
    width: 100%;
    max-width: 59rem;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    @media (max-width: 1439px) {
      padding: 4rem;
    }

    @media (max-width: 991px) {
      padding: 3rem 2rem;
    }

    @media (max-width: 767px) {
      padding: 2rem 1.5rem;
    }

    .Heading {
      h3 {
        color: $black;
        font-weight: bold;
        font-family: "Raleway", sans-serif;
        line-height: 1;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: bold;
        margin-bottom: 3rem;
        color: $black;

        @media (max-width: 1679px) {
          margin-bottom: 2.8rem;
        }

        @media (max-width: 1439px) {
          font-size: 1.6rem;
          margin-bottom: 2.4rem;
        }

        @media (max-width: 991px) {
          font-size: 1.4rem;
          margin-bottom: 2rem;
        }

        @media (max-width: 767px) {
          font-size: 1.2rem;
          margin-bottom: 1.5rem;
        }
      }

      ul {
        padding-left: 1.3rem;
        margin-bottom: 3.4rem;

        li {
          list-style: disc;
          font-size: 1.4rem;
          line-height: 2rem;
          color: #c12222;
          font-weight: 500;
        }
      }
    }

    .kyc_radiobtn {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      h6 {
        font-size: 1.6rem;
        color: $black3;
      }

      .kcy_btninner {
        display: flex;
        align-items: center;
        margin-left: 1.9rem;
      }
    }

    .SignUp__Form {
      width: 100%;
      max-width: 45rem;

      @media (max-width: 767px) {
        max-width: 100%;
      }

      form {
        :global {
          .common_input {
            @media (max-width: 767px) {
              margin-bottom: 1.5rem;
            }

            &.password_input {
              .rightIconInput {
                .form-control {
                  padding-right: 4.5rem;
                }

                .rightIcon {
                  width: 3rem;
                  margin-right: 0.6rem;
                }
              }
            }

            .error_message {
              font-size: 1.2rem;
              font-weight: 400;
            }
          }
        }

        .userFields {
          h6 {
            font-size: 1.4rem;
            line-height: 1.8rem;
            font-weight: 500;
            color: $black;
            margin-bottom: 1rem;
          }
        }

        p {
          font-size: 1.2rem;
          line-height: 1.4rem;
          font-weight: 400;
          color: #a8a8a8;
        }

        .subInformat {
          margin-bottom: 2.6rem;

          @media (max-width: 767px) {
            margin-bottom: 1.5rem;
          }
        }

        .spaceField {
          margin-bottom: 0.6rem;

          @media (max-width: 767px) {
            margin-bottom: 1.5rem;
          }
        }

        // .fieldborder {
        //     border: 2px solid $light_gray4;
        // }

        .bdrField {
          border-top: 2px solid $light_gray4;
          padding: 1.6rem 0 4.4rem;
          margin-top: 1rem;

          @media (max-width: 767px) {
            padding: 1.5rem 0;
          }

          p {
            font-size: 1.2rem;
            color: $gray626262;
            font-family: $baseFont;

            span,
            a {
              font-weight: 600;
              color: $gray626262;
            }
          }
        }


        .bottomLogin {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 479px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .leftSec {
            width: 100%;
            max-width: calc(100% - 19rem);

            @media (max-width: 479px) {
              max-width: 100%;

            }

            .LoginFormSignUp {
              font-size: 1.6rem;
              color: $gray626262;
              font-weight: bold;

              @media (max-width: 767px) {
                font-size: 1.4rem;
              }

              a {
                color: $green;
                font-weight: bold;
              }
            }
          }

          .signUpBtn {
            width: 100%;
            height: 5rem;
            max-width: 19rem;

            @media (max-width: 575px) {
              height: 4rem;
              margin-top: 0;
            }

            @media (max-width: 479px) {
              margin-top: 1rem;
            }
          }
        }

        .signUpBtn {
          width: 100%;
          height: 5rem;

          @media (max-width: 575px) {
            margin-top: 1.5rem;
            height: 4rem;
          }
        }

        .socialSignUp {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 3.8rem;

          .signSocial {
            margin-left: 1rem;
          }
        }

        .iconblack {
          svg {
            path {
              fill: $black;
            }
          }
        }
      }

      .confirmForm {
        margin-top: 4.4rem;
        position: relative;
        z-index: 3;

        @media (max-width: 1679px) {
          margin-bottom: 4rem;
        }

        @media (max-width: 1439px) {
          margin-bottom: 3rem;
        }

        @media (max-width: 991px) {
          margin-bottom: 2rem;
        }

        @media (max-width: 767px) {
          margin-bottom: 1.5rem;
        }

        .verifyCodesend {
          margin-bottom: 3rem;

          @media (max-width: 767px) {
            margin-bottom: 1rem;
          }

          .spaceField {
            margin-bottom: 1rem;
          }

          .BtnsSend {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .btnSec {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              max-width: max-content;

              .btnSize {
                background: transparent;
                border: none;
                padding: 0.2rem 0.6rem;
                font-size: 1.4rem;
                position: relative;
                z-index: 9;

                &:first-child {
                  padding-left: 0px;
                }
              }
            }

            p {
              color: red;
            }
          }
        }

        .BtnSignUp {
          border-top: 2px solid $light_gray4;
          padding: 4.4rem 0;

          @media (max-width: 767px) {
            padding: 1.4rem 0;
          }

          @media (max-width: 575px) {
            padding-top: 0;
          }
        }
      }
    }
  }
}

:global {
  .form-label {
    font-size: 1.4rem;
    color: #d6d6d6;
    display: block;
  }
}