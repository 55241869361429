@import '../../../../Assets/_var.scss';

@keyframes marquee {
    0% {
        background-position: 0;
    }

    100% {
        background-position: 300rem;
    }
}

.ourTeam {
    position: relative;
    z-index: 1;
    margin-bottom: 10.7rem;
    padding-top: 16rem;

    @media (max-width:1679px) {
        margin-bottom: 8rem;
        padding-top: 12rem;
    }

    @media (max-width:1399px) {
        margin-bottom: 6rem;
        padding-top: 9rem;
    }

    @media (max-width:991px) {
        margin-bottom: 4rem;
        padding-top: 7rem;
    }

    &:before {
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: 39.6rem;
        position: absolute;
        background-image: url(../../../../Assets/images/naxBgPattern.png);
        background-size: cover;
        background-position: center;
        background-repeat: repeat-x;
        animation: marquee 110s infinite linear;
        z-index: -1;
    }

    &_lisitng {
        border: 0.2rem solid #071A2F;
        background: $white;
        padding: 7.9rem 7.5rem 4.2rem;
        position: relative;

        @media (max-width:1679px) {
            padding: 6rem 6rem 4rem;
        }

        @media (max-width:1399px) {
            padding: 4.5rem 4.5rem 3rem;
        }

        @media (max-width:1199px) {
            padding-inline: 3rem;
        }

        @media (max-width:991px) {
            padding-inline: 2rem;
        }

        h2 {
            position: absolute;
            top: -2.3rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 37rem;
            height: 4.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            background: $white;
            padding: 0.2rem 1rem;

            @media (max-width:1679px) {
                max-width: 30rem;
            }

            @media (max-width:1399px) {
                max-width: 26rem;
            }

            @media (max-width:991px) {
                max-width: 20rem;
            }
        }

        .sliderTeam {
            padding-bottom: 0;


            @media (max-width:991px) {
                padding-bottom: 4rem;
            }

            :global {
                .slick-slide>div {

                    @media (max-width:991px) {
                        margin: 0 1rem;
                    }
                }

                .slick-arrow {
                    left: 50%;
                    bottom: -1rem !important;
                }
            }
        }

        .teamCard {
            text-align: center;
            max-width: 40rem;
            margin: auto;
            display: flex !important;
            flex-direction: column;

            .teamImg {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25.4rem;
                height: 25.4rem;
                margin: 0 auto 1.7rem;

                @media (max-width:1679px) {
                    width: 22.4rem;
                    height: 22.4rem;
                }

                @media (max-width:1399px) {
                    width: 20.4rem;
                    height: 20.4rem;
                    margin-bottom: 1.2rem;
                }

                @media (max-width:1199px) {
                    width: 18.4rem;
                    height: 18.4rem;
                }

                @media (max-width:991px) {
                    width: 16.4rem;
                    height: 16.4rem;
                }

                @media (max-width:767px) {
                    width: 12.4rem;
                    height: 12.4rem;
                }
            }

            .teamText {
                .topHeadings {
                    margin-bottom: 2rem;

                    h4 {
                        color: $black;
                    }

                    .socialLink {
                        margin-top: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .btnLink {
                            background: transparent;
                            border: none;
                            width: 3.5rem;
                            height: 3.5rem;
                            span {
                                margin-left: 0;
                                width: 100%;
                                height: 100%;
                                svg {
                                    width: 100%;
                                    height: 100%;         
                                }
                            }
                        }
                    }
                }

                h4,
                h6,
                p {
                    font-size: 2rem;
                    line-height: 2.6rem;

                    @media (max-width:1679px) {
                        font-size: 1.8rem;
                        line-height: 2.2rem;
                    }

                    @media (max-width:1399px) {
                        font-size: 1.6rem;
                        line-height: 2rem;
                    }

                    @media (max-width:991px) {
                        font-size: 1.4rem;
                        line-height: 1.8rem;
                    }
                }

                h6,
                p {
                    color: #707070;
                    font-weight: 400;
                }
            }
        }
    }
}