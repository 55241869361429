@import "../../../../Assets/_var.scss";

.common_profileup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  position: relative;
  cursor: pointer;

  img {
    width: 16.3rem;
    height: 16.3rem;
    border-radius: 50%;
    box-shadow: 0px 0px 13px #636363;
    object-fit: cover;
    object-position: center;
  }

  .file_upload {
    position: absolute;
    top: 0;
    left: auto;
    opacity: 0;
    width: 100%;
    max-width: 16.3rem;
    height: 100%;
    cursor: pointer;
  }

  h6 {
    font-size: 1.6rem;
    font-weight: 400;
    font-family: "Roboto";
    margin-left: 3.3rem;
    color: #000;
  }
}