@import "../../../../../../../Assets/_var.scss";

.filterTable {
  .topHead {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1.3rem;

    :global {
      .filterDrop {
        margin-right: 2rem;
        width: 100%;
        max-width: 25rem;
      }

      .filterSort {
        max-width: 25rem;
      }

      .dropdown-menu {
        background: $white;

        .dropdown-item {
          color: $black2;
        }
      }
    }
  }

  .tableSec {
    .bidder_table {
      :global {
        .table-responsive {
          border: 0.2rem solid $borderClr4;
          border-radius: 0.6rem;
        }

        table {
          border-spacing: 0;
          border-collapse: collapse;

          thead {
            tr {
              th {
                background-color: $black;
                color: $white;
                padding: 1rem 2rem;
                font-weight: bold;
                font-size: 1.2rem;
                font-family: $baseFont;
                text-align: left;

                &:first-child {
                  border-radius: 5px 0px 0px 0px;
                }

                &:last-child {
                  border-radius: 0px 5px 0px 0px;
                }

                &.minSpace {
                  width: 11rem;
                  min-width: 11rem;
                  max-width: 11rem;
                }

                .btnGlobal {
                  height: 3rem;
                  font-size: 1.2rem;
                  padding: 0.3rem 2rem;
                  width: 100%;
                  max-width: 19rem;
                }
              }
            }
          }

          tbody {
            tr {
              border-bottom: 2px solid $borderClr5;

              td {
                border: none;
                padding: 1rem 2rem;
                font-size: 1.2rem;
                font-weight: bold;
                font-family: $baseFont;
                text-align: left;
                color: $black;
                background: $white;

                &:first-child {
                  text-align: center;
                }

                &:nth-child(2) {
                  text-align: center;
                  width: 20rem;
                  max-width: 20rem;
                  min-width: 20rem;
                }

                &.currentPrice {
                  color: $black;
                }

                &.custom_hiegt {
                  padding: 1.65rem 2rem;
                  text-align: left;
                }

                .ownedBy {
                  display: flex;
                  align-items: center;

                  img {
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                  }

                  h6 {
                    font-size: 1.2rem;
                    margin-left: 0.6rem;
                  }
                }

                .btnSection {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-inline: -1rem;

                  .btnMake,
                  .btnBuy {
                    height: 3rem;
                    width: 100%;
                    max-width: 9rem;
                    font-size: 1.2rem;
                    padding: 0.7rem 1.4rem;
                    margin-inline: 1rem;
                  }

                  p {
                    font-size: 1.2rem;
                    margin-inline: 1rem;
                    font-weight: 400;
                    color: #aeaeae;
                  }
                }
              }

              &:first-child {
                td {
                  color: $black;
                  background: $white;

                  // &.lastSale {
                  //   color: #ababab;
                  // }

                  .ownedBy {
                    h6 {
                      color: $black;
                    }
                  }
                }
              }

              &:last-child {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
