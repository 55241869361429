@import "../../../../../Assets/_var.scss";

.account_information {
  padding: 2.2rem 0;

  .userImage {
    margin-bottom: 5rem;
  }

  .displayfield {
    margin-bottom: 4rem;

    h6 {
      font-weight: bold;
      color: $black;
      margin-bottom: 1rem;
    }

    :global {
      .border_input {
        margin-bottom: 2rem;
        border: 1px solid $black;
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;
        height: 3.5rem;

        .form-label {
          color: $gray969696;
          margin-bottom: 0;
          line-height: 1;
          font-size: 0.8rem;
          font-weight: 400;
        }

        .common_input_inner {
          .form-control {
            height: auto;
            line-height: 1.2;
            background: transparent;
            font-size: 1.2rem;
          }
        }

        .error_message {
          margin-left: -1rem;
        }
      }
    }
  }


  .info_available {
    margin: 0.4rem 0;
    font-size: 1.6rem;
    font-weight: 600;

    span {
      a {
        color: #0d6dfd;
      }
    }
  }

  .profile_url {
    font-size: 1.2rem;
    font-weight: 400;
    color: $gray969696;
    margin-bottom: 4rem;
    cursor: pointer;
  }

  .input_check {
    position: relative;

    .form-label {
      color: $black3;
      font-size: 1.4rem;
      margin-bottom: 1rem;
      font-weight: bold;
      line-height: 1.2;
    }

    .social_media {
      position: relative;

      .btnremove {
        background: transparent;
        border: none;
        font-size: 1.4rem;
        font-weight: 400;
        position: absolute;
        top: 46px;
        right: -70px;
        z-index: 9;
      }
    }

    .bio_text {
      margin-bottom: 2.5rem;

      span {
        margin-top: 0.5rem;
        text-align: end;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        font-size: 1.4rem;
        font-weight: 400;
        color: $gray;
      }
    }

  }

  :global {
    .spaceCheck {
      .checkbox_input {
        top: -2rem;
      }
    }

    .infoBio {
      margin-top: 0.5rem;
      text-align: end;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 1.1rem;
      font-weight: 400;
      color: $gray969696;
    }

    .text_valida {
      font-size: 1rem !important;
      margin-top: 0.9rem !important;
      position: relative;
      left: -1rem;

      svg {
        width: 1rem !important;
        height: 1rem !important;
      }
    }

    .quill {
      border-radius: 0.5rem;
      border: 0.1rem solid $black;

      .ql-toolbar.ql-snow {
        border-bottom: 0.1rem solid $black;
      }

      .ql-container.ql-snow {
        .ql-editor {
          padding: 1rem;
        }
      }
    }

    .error_message {
      font-size: 1.1rem;
      font-weight: 400;
      margin-top: 0.4rem;
    }

    textarea {
      resize: none;
    }

    form {
      width: 100%;
      max-width: 32rem;

      .checkboxInner {
        position: absolute;
        top: -3rem;
        right: 0;
        margin-bottom: 0;
        z-index: 9;

        .form-check {
          display: flex;
          align-items: center;
          padding-left: 0.5rem;

          input {
            border: 1px solid;
            border-radius: 0.2rem;
            height: 1rem;
            width: 1rem;
            margin: 0;
            top: 0;

            &:focus {
              box-shadow: none;
            }
          }

          .form-check-label {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1;
            color: $black;
          }
        }
      }

      .checkbox_input {
        position: absolute;
        top: 0;
        right: 0;
        margin-bottom: 0;
        z-index: 9;

        .form-check {
          display: flex;
          align-items: center;
          padding-left: 0.5rem;

          input {
            border: 1px solid;
            border-radius: 0.2rem;
            height: 1rem;
            width: 1rem;
            margin: 0;
            top: 0;

            &:focus {
              box-shadow: none;
            }
          }

          .form-check-label {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1;
            color: $black;
          }
        }
      }

      .input_spaces {
        .form-label {
          color: $black3;
          font-size: 1.4rem !important;
          margin-bottom: 1rem;
          font-weight: bold;
          line-height: 1.2;
        }

        .form-control {
          padding: 1rem 2.4rem;
        }

        .leftIconInput {
          .form-control {
            padding-left: 5.5rem;
          }

          span.leftIcon {
            svg {

              circle,
              rect {
                fill: $green;
              }

              path {
                fill: $white;
              }
            }
          }
        }
      }
    }

    .accountUpload {
      .file_upload {
        top: 50%;
        left: 19.5rem;
        transform: translateY(-50%);
        height: 3rem;
        width: 100%;
        max-width: 11.13rem;
      }

      h6 {
        border: 0.1rem solid $black;
        border-radius: 2rem;
        padding: 0.6rem 1.8rem;
        height: 3rem;
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    .darkLabel {
      .form-label {
        font-weight: bold;
        color: $black;
        margin-bottom: 1rem;
      }

      .common_input_inner {
        .form-control {
          border: 1px solid $black;
          border-radius: 0.5rem;
          padding: 0.5rem 1rem;
          height: 3.5rem;
          background: transparent;
          font-size: 1.2rem;
        }

        &.leftIconInput {
          .leftIcon {
            left: 1rem;
            width: 1.6rem;
            height: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .form-control {
            padding-left: 3.5rem;
          }
        }
      }
    }

    .input_number {
      .form-label {
        color: $black3;
        font-size: 1.4rem !important;
        margin-bottom: 1rem;
        font-weight: bold;
        line-height: 1.2;
      }

      .phone_border {
        border: 1px solid $black  !important;
        border-radius: 0.5rem !important;
        padding: 0.5rem 1rem !important;
        height: 3.5rem !important;
        background: transparent;
        font-size: 1.2rem !important;

        .PhoneInputInput {
          font-size: 1.2rem !important;
        }
      }
    }
  }

  .btnsave {
    margin-top: 5rem;

    .btnsize {
      height: 4rem;
      font-size: 1.6rem;
      width: 100%;
      max-width: 14.8rem;
      padding: 1rem;
      font-weight: bold;
      font-family: $baseFont2;
    }
  }
}