@import "../../../Assets/_var.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black;
  transition: 0.3s all ease-in-out;
  height: auto;
  width: 100%;
  z-index: 9991;

  &_navbar {
    padding: 1.5rem 0;

    @media (max-width: 1679px) {
      padding: 1rem 0;
    }
  }

  &_left {
    position: relative;
    display: flex;
    align-items: center;

    .infoArt {
      color: $white;
      margin-left: 3.9rem;
      font-weight: 700;

      @media (max-width: 1399px) {
        margin-left: 2rem;
      }

      @media (max-width: 1199px) {
        display: none;
      }
    }
  }

  &_logo {
    height: 100%;
    display: block;
    position: relative;
    z-index: 9;

    img {
      width: 25.5rem;
      height: 5rem;

      @media (max-width: 1679px) {
        width: 20rem;
        height: 4.5rem;
      }

      @media (max-width: 991px) {
        width: 16rem;
        height: 3.5rem;
      }

      @media (max-width: 425px) {
        max-width: 14rem;
        height: 3rem;
      }
    }
  }

  &_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .header_nav {
      align-items: center;

      .nav_link {
        width: 100%;
        max-width: max-content;
        white-space: nowrap;
        font-size: 1.6rem;
        font-weight: 700;
        padding: 0.6rem 1.3rem;
        color: $white;
        font-family: $baseFont2;

        @media (max-width: 1399px) {
          font-size: 1.4rem;
          padding: 0.6rem 1rem;
        }

        @media (max-width: 991px) {
          max-width: 100%;
          padding: 1.5rem 1rem;
        }

        &:hover,
        &:focus {
          color: $green  !important;
        }
      }
    }
  }

  &_search {
    padding-right: 1.6rem;

    @media (max-width: 1399px) {
      padding-right: 1rem;
    }

    a {
      display: flex;
      width: 1.7rem;
      height: 1.7rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &_dropDown {
    padding: 0.6rem 1.3rem;

    @media (max-width: 1399px) {
      padding: 0.6rem 1rem;
    }

    @media (max-width: 991px) {
      padding: 0;
    }

    :global {
      .dropdown-menu {
        padding: 1.8rem 1.4rem;
        min-width: 24rem;
        border: 1px solid #707070;
        border-radius: 0.5rem;
      }

      .dropdown-toggle {
        color: $white;
        border: none !important;

        &.show {
          color: $white  !important;
        }
      }
    }

    @media (max-width: 991px) {
      margin-left: 0;
      width: 100%;

      >div {
        min-width: inherit;
        max-width: calc(100% - 4rem);
        margin-left: 2rem;
      }
    }

    @media (min-width: 1280px) {
      >div {
        display: block !important;
        transition: 0.3s all ease-in-out;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
        transform: translateY(1rem);
      }

      &:hover {
        >div {
          visibility: visible;
          pointer-events: all;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &_dropDown>a,
  &_navlink {
    font-size: 1.6rem;
    font-family: $baseFont2;
    color: $white;
    padding: 0.6rem 1.3rem;
    font-weight: 700;
    transition: 0.3s all ease-in-out;

    &:after {
      vertical-align: inherit;
      border: 0;
      border-right: 0.2rem solid $white;
      border-bottom: 0.2rem solid $white;
      height: 0.9rem;
      width: 0.9rem;
      transform: rotate(45deg);
      margin-left: 1rem;
      margin-bottom: 0.2rem;
      transition: 0.3s all ease-in-out;

      @media (max-width: 1679px) {
        margin-bottom: 0.5rem;
      }

      @media (min-width: 992px) and (max-width: 1679px) {
        transform: rotate(45deg) translateY(3px);
      }
    }

    &:hover,
    &:focus {
      color: $green  !important;

      &:after {
        border-right-color: $green;
        border-bottom-color: $green;
      }
    }

    @media (max-width: 1399px) {
      font-size: 1.4rem;
      padding: 0.6rem 1rem;
    }

    @media (max-width: 991px) {
      padding: 1.5rem 1rem;
      position: relative;

      &:after {
        position: absolute;
        top: 1.7rem;
        right: 2rem;
      }
    }

    :global {
      .nav-link {
        color: $white;

        &.show {
          color: $white  !important;
        }
      }
    }
  }

  &_navlink {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1399px) {
      height: 3.5rem;
    }
  }

  &_dropDown {
    &Item {
      font-size: 1.2rem;
      font-weight: 400;
      font-family: $baseFont;
      color: $black;
      padding: 0.5rem 0;
      border-bottom: 1px solid #d5d5d5 !important;

      &:first-child {
        padding-top: 0;
        margin-bottom: 0 !important;
      }

      &:last-child {
        padding-bottom: 0;
        margin-top: 0 !important;
        border: none !important;
      }

      @media (max-width: 1679px) {
        font-size: 1.4rem;
      }

      @media (max-width: 991px) {
        white-space: break-spaces;
      }

      &:focus {
        background-color: transparent;
        color: $black;
      }

      &:hover {
        background-color: transparent;
        color: $yellow;
      }
    }
  }

  &_action {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 991px) {
      >a {
        max-width: max-content;
        width: 100%;
        min-width: 6rem;
      }
    }

    @media (max-width: 575px) {
      >a {
        max-width: max-content;
        width: 100%;
        min-width: auto;
      }
    }

    >a {
      margin: 0;

      span {
        @media (max-width: 575px) {
          display: none;
        }
      }

      svg {
        width: 2.5rem;

        @media (min-width: 576px) {
          display: none;
        }
      }

      @media (max-width: 991px) {
        padding: 1rem 0 !important;
      }

      @media (max-width: 575px) {
        padding: 0 0.5rem !important;
      }
    }
  }

  .login_action {
    margin-left: 6rem;
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 1679px) {
      margin-left: 4rem;
    }

    @media (max-width: 991px) {
      margin-left: 1.5rem;

      >a {
        max-width: max-content;
        width: 100%;
        min-width: 6rem;
      }
    }

    @media (max-width: 767px) {
      margin-left: 1rem;
    }

    @media (max-width: 575px) {
      margin-left: 0.4rem;

      >a {
        max-width: max-content;
        width: 100%;
        min-width: auto;
      }
    }

    >a {
      margin: 0;

      &:last-child {
        color: $yellow  !important;
      }

      span {
        @media (max-width: 575px) {
          display: none;
        }
      }

      svg {
        width: 2.5rem;

        @media (min-width: 576px) {
          display: none;
        }
      }

      @media (max-width: 991px) {
        padding: 1rem 0 !important;
      }
    }

    &_divider {
      background-color: $white;
      width: 1px;
      height: 5rem;
      margin: 0 3.5rem;

      @media (max-width: 1679px) {
        margin: 0 2.5rem;
      }

      @media (max-width: 991px) {
        margin: 0 1.5rem;
        height: 3rem;
      }

      @media (max-width: 767px) {
        margin: 0 1rem;
      }

      @media (max-width: 479px) {
        margin: 0 0.7rem;
      }
    }
  }

  &_userDropdown,
  &_notification_dropDown {
    padding: 0;

    :global {
      .dropdown-toggle {
        border: none !important;
      }
    }

    button {
      background-color: transparent !important;
      margin: 0;
      padding: 0;
      border: 0;
      border-radius: 0;

      &:focus,
      &:hover {
        outline: none;
        background-color: transparent;
      }

      &:after {
        content: none;
      }
    }

    a {
      font-size: 1.6rem;
      font-family: $baseFont2;
      font-weight: bold;
    }

    >div {
      left: auto !important;
      right: 0;
    }

    &__userIcon {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btnProfileone,
      .user_changes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 5.2rem;
        height: 5.2rem;

        img {
          width: 4rem;
          height: 4rem;
        }
      }

      .user_changes {
        position: relative;
        background-image: url("../../../Assets/icons/New Project.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 5.2rem;
        height: 5.2rem;
        cursor: pointer;

        img {
          width: 3.6rem;
          height: 3.6rem;
        }
      }

      h6 {
        font-size: 1.6rem;
        font-family: "Raleway", sans-serif;
        font-weight: bold;
        margin-left: 1rem;
        width: 100%;
      }
    }

    .drop_links {
      display: flex;
      flex-direction: column;

      :global {
        .dropdown-item {
          margin: 0;
          border: none;
          padding: 0;

          &:first-child {
            margin-top: 1rem;
          }

          a {
            width: 100%;
            display: block;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &_notification_dropDown {
    >div {
      min-width: 36rem;
      right: -13rem;

      @media (max-width: 1365px) {
        right: -10.5rem;
      }

      @media (max-width: 991px) {
        min-width: 30rem;
      }
    }

    :global {
      .dropdown-toggle {
        height: 4.4rem;
        width: 4.4rem;
        border-radius: 50%;
        border: 2px solid $yellow  !important;
        position: relative;

        @media (max-width: 1679px) {
          height: 3.4rem;
          width: 3.4rem;

          img {
            width: 1.7rem;
          }
        }

        &.btnNotification {
          // &::after {
          //   background-color: transparent;
          //   content: "";
          //   height: 1rem;
          //   width: 1rem;
          //   border-radius: 50%;
          //   background-color: $yellow;
          //   position: absolute;
          //   right: 0.7rem;
          //   border: 1px solid $black;
          //   top: 0.7rem;
          // }

          &::after {
            content: unset !important;
          }

          .numberMail {
            color: $black;
            font-size: 1.2rem;
            font-weight: 600;
            position: absolute;
            right: -0.6rem;
            top: -0.6rem;
            height: 2.8rem;
            width: 2.8rem;
            border-radius: 50%;
            background-color: $yellow;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 1679px) {
              height: 2rem;
              width: 2rem;
              font-size: 1rem;
            }
          }

          @media (max-width: 1679px) {
            height: 3.4rem;
            width: 3.4rem;

            img {
              width: 1.4rem;
            }

            &::after {
              content: "";
              height: 0.7rem;
              width: 0.7rem;
            }
          }
        }
      }
    }
  }

  &_userDropdown {
    >div {
      background-color: $white;
      border-color: $white;
      box-shadow: 0px 0px 7px rgba($gray707070, 0.5);
    }

    button {
      img {
        height: 4.4rem;
        width: 4.4rem;
        border-radius: 50%;
        object-fit: cover;

        @media (max-width: 1679px) {
          height: 3.4rem;
          width: 3.4rem;
        }
      }
    }

    a {
      color: $black;
      font-weight: bold;

      &:first-of-type {
        margin-top: 1rem;
        margin-bottom: 0;
        border-top: 2px solid $borderGray;
      }

      &:last-child {
        margin-top: 0;
      }

      &:hover,
      &:focus {
        color: $yellow;
        background-color: transparent;
      }
    }
  }

  &_toggleBtn {
    position: relative;
    padding: 0;
    margin-left: 1rem;
    width: 100%;
    max-width: 2.7rem;

    span {
      display: block;
      width: 2.5rem;
      height: 2px;
      margin: 0.6rem 0;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      background-color: $yellow;
      transform-origin: right center;
      transition: 0.3s all ease-in-out;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 991px) {
    &_navbarCollapse {
      // background-image: url(../../../Assets/images/logo.png);
      // background-repeat: no-repeat;
      // background-position: center 1.6rem;
      background-size: 16rem;
      padding-top: 6.4rem;
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh !important;
      min-height: 100vh !important;
      overflow-y: auto;
      width: 25rem;
      background-color: $black;
      display: block !important;
      transform: translateX(-25rem);
      transition: 0.3s all ease-in-out;
      z-index: 1;

      >div {
        &:first-child {
          border-top: 1px solid rgba(255, 255, 255, 0.3);
          padding-top: 2rem;
        }
      }
    }
  }

  :global {
    .basBgClr {
      font-family: $baseFont2;
      border: 2px solid $green;
      border-radius: 3rem;
      background-color: $green;
      padding: 0.6rem 3rem !important;

      @media (max-width: 1399px) {
        padding: 0.6rem 1.8rem !important;
      }

      @media (max-width: 991px) {
        min-width: 9.3rem;
      }

      @media (max-width: 575px) {
        background: transparent;
        border: none;
        padding: 0 0.5rem !important;
        min-width: 3.5rem;
      }

      &:hover {
        background-color: $yellow;
        color: $black  !important;
        border-color: $yellow;
        box-shadow: 4px 5px 14px rgba(0, 0, 0, 0.3);

        >span {
          svg {

            path,
            polygon {
              fill: $black;
            }
          }
        }
      }
    }

    @media (max-width: 991px) {
      .navbar-collapse {
        &.show {
          transform: translateX(0);
          box-shadow: 0 0 24px rgba(0, 0, 0, 90%);
        }
      }
    }

    .navbar-toggler {
      &:not(.collapsed) {
        span {
          width: 2.35rem;

          &:first-child {
            transform: rotate(-45deg);
          }

          &:nth-child(2) {
            transform: scale(0);
          }

          &:last-child {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

.headerBackdrop {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: $black, $alpha: 0.4);
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(11px);
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.backdrp_bg {
  background-color: #000 !important;
  opacity: 0.85;
}