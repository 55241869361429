@import '../../../../../Assets/_var.scss';

.common_textarea {
    &_inner {
        min-height: 10rem;
        height: 100%;

        @media (max-width: 991px) {
            min-height: 7rem;
        }

        textarea {
            min-height: 10rem;
            height: 100%;
            width: 100%;
            background-color: $white;
            border-radius: 10px;
            font-size: 16px;
            &:focus {
                box-shadow: none;
            }

            @media (max-width: 991px) {
                min-height: 7rem;
                font-size: 14px;
            }
        }
    }
}