@import '../../../../Assets/_var.scss';

.CollectionDetails {
    &Tabs {
        display: flex;
        align-items: center;
        padding-top: 5rem;
        border-bottom: 3px solid $borderClr;
        margin-bottom: 6rem;

        @media (max-width: 991px) {
            padding-top: 3rem;
        }

        .CollectionDetailsTab {

            padding: 0 2rem 2rem;
            border-bottom: 3px solid transparent;
            cursor: pointer;
            transform: translateY(2px);

            p {
                font-size: 1.6rem;
                color: rgb(217, 217, 217);
                font-weight: 700;
                font-family: $baseFont;

                @media (max-width: 991px) {
                    font-size: 1.4rem;
                }
            }

            @media (max-width: 1439px) {
                padding: 0 1.6rem 1.6rem;
            }

            @media (max-width: 1199px) {
                padding: 0 1.4rem 1rem;
            }


            &.active {
                border-bottom-color: rgb(60, 61, 60);

                p {
                    color: $black;
                }
            }
        }
    }

    &Content {
        padding-bottom: 6rem;
        @media (max-width: 1199px) {
            padding-bottom: 4rem;
        }
        @media (max-width: 991px) {
            padding-bottom: 3rem;
        }
    }
}