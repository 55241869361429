@import '../../../../Assets/_var.scss';

.dfaCollections {
    padding: 0 0 10.6rem;

    @media (max-width:1679px) {
        padding: 0 0 9rem;
    }

    @media (max-width:1399px) {
        padding: 0 0 7rem;
    }

    @media (max-width:1199px) {
        padding: 0 0 6.5rem;
    }

    @media (max-width:991px) {
        padding: 0 0 5rem;
    }

    &_innerPart {
        .firstDfa {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 111.4rem;
            margin: auto;
            padding-bottom: 23rem;
            padding-top: 13.7rem;

            @media (max-width:1679px) {
                padding-bottom: 19rem;
                padding-top: 11rem;
            }

            @media (max-width:1399px) {
                padding-bottom: 15rem;
                padding-top: 8rem;
            }

            @media (max-width:1199px) {
                padding-bottom: 12rem;
                padding-top: 6.5rem;
            }

            @media (max-width:991px) {
                padding-bottom: 8rem;
                padding-top: 5rem;
            }

            @media (max-width:767px) {
                flex-direction: column;
                padding-bottom: 5rem;
            }

            .leftImgSec {
                width: 100%;
                max-width: 46.4rem;
                flex: 0 0 46.4rem;

                @media (max-width:1679px) {
                    max-width: 40rem;
                    flex: 0 0 40rem;
                }

                @media (max-width:1399px) {
                    max-width: 35rem;
                    flex: 0 0 35rem;
                }

                @media (max-width:1199px) {
                    max-width: 30rem;
                    flex: 0 0 30rem;
                }

                @media (max-width:991px) {
                    max-width: 26rem;
                    flex: 0 0 26rem;
                }

                @media (max-width:767px) {
                    max-width: 28rem;
                    flex: 0 0 100%;
                }
            }

            .rightTextSec {
                width: 100%;
                max-width: calc(100% - 46.4rem);
                flex: 0 0 calc(100% - 46.4rem);
                padding-left: 6.7rem;

                @media (max-width:1679px) {
                    max-width: calc(100% - 40rem);
                    flex: 0 0 calc(100% - 40rem);
                    padding-right: 4rem;
                }

                @media (max-width:1399px) {
                    max-width: calc(100% - 35rem);
                    flex: 0 0 calc(100% - 35rem);
                    padding-right: 2.6rem;
                }

                @media (max-width:1199px) {
                    max-width: calc(100% - 30rem);
                    flex: 0 0 calc(100% - 30rem);
                    padding-right: 2.2rem;
                }

                @media (max-width:991px) {
                    max-width: calc(100% - 26rem);
                    flex: 0 0 calc(100% - 26rem);
                }

                @media (max-width:767px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding: 0;
                    text-align: center;
                }

                .infoPera {
                    margin-top: 3.4rem;
                    width: 100%;
                    max-width: 47rem;

                    @media (max-width:1679px) {
                        margin-top: 2.8rem;
                    }

                    @media (max-width:1399px) {
                        margin-top: 2.4rem;
                    }

                    @media (max-width:1199px) {
                        margin-top: 2rem;
                    }

                    @media (max-width:767px) {
                        margin-top: 1.6rem;
                        width: 100%;
                        max-width: 100%;
                    }

                    p {
                        font-size: 1.8rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: $black;
                        margin-bottom: 3.4rem;

                        @media (max-width:1679px) {
                            font-size: 1.6rem;
                            margin-bottom: 2.8rem;
                        }

                        @media (max-width:1399px) {
                            margin-bottom: 2.4rem;
                        }

                        @media (max-width:1199px) {
                            font-size: 1.4rem;
                            margin-bottom: 2rem;
                        }

                        @media (max-width:767px) {
                            margin-bottom: 1.6rem;
                        }
                    }
                }

                .btnSec {
                    display: flex;
                    align-items: flex-start;

                    @media (max-width:767px) {
                        justify-content: center;
                    }

                    .btnExplore {
                        height: 4rem;
                        font-size: 1.6rem;
                        padding: 1rem 1.8rem;
                        margin-right: 3.7rem;
                    }

                    .btnLearn {
                        background: transparent;
                        border: none;
                        padding: 0 0 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        color: $green;
                        height: 100%;
                        font-size: 1.4rem;
                        font-weight: bold;
                        line-height: 1.2;

                        span {
                            margin-bottom: 0.4rem;
                        }

                        :global {
                            .scroll-arrow {
                                border-right: 0.2rem solid $green;
                                border-bottom: 0.2rem solid $green;
                            }
                        }
                    }
                }
            }
        }

        .secondDfa {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 102.9rem;
            margin: auto;
            padding-bottom: 10rem;

            @media (max-width:767px) {
                flex-direction: column-reverse;
                padding-bottom: 5rem;
            }

            .rightImgSec {
                width: 100%;
                max-width: 36.4rem;
                flex: 0 0 36.4rem;

                @media (max-width:1679px) {
                    max-width: 32.4rem;
                    flex: 0 0 32.4rem;
                }

                @media (max-width:1399px) {
                    max-width: 30.4rem;
                    flex: 0 0 30.4rem;
                }

                @media (max-width:1199px) {
                    max-width: 29rem;
                    flex: 0 0 29rem;
                }

                @media (max-width:767px) {
                    max-width: 28rem;
                    flex: 0 0 100%;
                }
            }

            .leftTextSec {
                width: 100%;
                max-width: calc(100% - 36.4rem);
                flex: 0 0 calc(100% - 36.4rem);
                padding-right: 6.7rem;

                @media (max-width:1679px) {
                    max-width: calc(100% - 32.4rem);
                    flex: 0 0 calc(100% - 32.4rem);
                    padding-right: 4rem;
                }

                @media (max-width:1399px) {
                    max-width: calc(100% - 30.4rem);
                    flex: 0 0 calc(100% - 30.4rem);
                    padding-right: 2.6rem;
                }

                @media (max-width:1199px) {
                    max-width: calc(100% - 29rem);
                    flex: 0 0 calc(100% - 29rem);
                    padding-right: 2.2rem;
                }

                @media (max-width:767px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding: 0;
                    text-align: center;
                }

                h3 {
                    width: 100%;
                    max-width: 40rem;

                    @media (max-width:767px) {
                        max-width: 100%;
                        text-align: center;
                    }
                }

                .infoPera {
                    margin-top: 3.4rem;
                    width: 100%;
                    max-width: 49rem;

                    @media (max-width:1679px) {
                        margin-top: 2.8rem;
                    }

                    @media (max-width:1399px) {
                        margin-top: 2.4rem;
                    }

                    @media (max-width:1199px) {
                        margin-top: 2rem;
                    }

                    @media (max-width:767px) {
                        margin-top: 1.6rem;
                        width: 100%;
                        max-width: 100%;
                    }

                    p {
                        font-size: 1.8rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: $black;
                        margin-bottom: 3.4rem;

                        @media (max-width:1679px) {
                            font-size: 1.6rem;
                            margin-bottom: 2.8rem;
                        }

                        @media (max-width:1399px) {
                            margin-bottom: 2.4rem;
                        }

                        @media (max-width:1199px) {
                            font-size: 1.4rem;
                            margin-bottom: 2rem;
                        }

                        @media (max-width:767px) {
                            margin-bottom: 1.6rem;
                        }
                    }
                }
            }
        }

        .thirdDfa {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 116.4rem;
            margin: auto;
            padding-bottom: 23rem;

            @media (max-width:1679px) {
                padding-bottom: 19rem;
            }

            @media (max-width:1399px) {
                padding-bottom: 15rem;
            }

            @media (max-width:1199px) {
                padding-bottom: 12rem;
            }

            @media (max-width:991px) {
                padding-bottom: 8rem;
            }

            @media (max-width:767px) {
                flex-direction: column;
                padding-bottom: 5rem;
            }

            .leftImgSec {
                width: 100%;
                max-width: calc(100% - 40rem);
                flex: 0 0 calc(100% - 40rem);
                padding-right: 13rem;
                display: flex;
                align-items: center;
                position: relative;

                @media (max-width:1679px) {
                    max-width: calc(100% - 37rem);
                    flex: 0 0 calc(100% - 37rem);
                }

                @media (max-width:1399px) {
                    max-width: calc(100% - 34rem);
                    flex: 0 0 calc(100% - 34rem);
                }

                @media (max-width:1199px) {
                    max-width: calc(100% - 30rem);
                    flex: 0 0 calc(100% - 30rem);
                }

                @media (max-width:991px) {
                    max-width: calc(100% - 24rem);
                    flex: 0 0 calc(100% - 24rem);
                }

                @media (max-width:767px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding-right: 10rem;
                }

                .dfaImg {
                    display: flex;
                    width: 100%;
                    // max-width: calc(100% - 26.5rem);
                    // flex: 0 0 calc(100% - 26.5rem);
                }

                .weathermulti {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    position: absolute;
                    right: 5rem;
                    top: -2rem;
                    max-width: 22rem;
                    // max-width: 26.5rem;
                    // flex: 0 0 26.5rem;

                    @media (max-width:1199px) {
                        max-width: 18rem;
                    }

                    @media (max-width:767px) {
                        right: 0rem;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .rightTextSec {
                width: 100%;
                max-width: 40rem;
                flex: 0 0 40rem;

                @media (max-width:1679px) {
                    max-width: 37rem;
                    flex: 0 0 37rem;
                }

                @media (max-width:1399px) {
                    max-width: 34rem;
                    flex: 0 0 34rem;
                }

                @media (max-width:1199px) {
                    max-width: 30rem;
                    flex: 0 0 30rem;
                }

                @media (max-width:991px) {
                    max-width: 24rem;
                    flex: 0 0 24rem;
                }

                @media (max-width:767px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding: 0;
                    text-align: center;
                }

                h3 {
                    width: 100%;
                    max-width: 38rem;

                    @media (max-width:767px) {
                        max-width: 100%;
                        text-align: center;
                    }
                }

                .infoPera {
                    margin-top: 3.4rem;
                    width: 100%;
                    max-width: 40rem;

                    @media (max-width:1679px) {
                        margin-top: 2.8rem;
                    }

                    @media (max-width:1399px) {
                        margin-top: 2.4rem;
                    }

                    @media (max-width:1199px) {
                        margin-top: 2rem;
                    }

                    @media (max-width:767px) {
                        margin-top: 1.6rem;
                        width: 100%;
                        max-width: 100%;
                    }

                    p {
                        font-size: 1.8rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: $black;
                        margin-bottom: 3.4rem;

                        @media (max-width:1679px) {
                            font-size: 1.6rem;
                            margin-bottom: 2.8rem;
                        }

                        @media (max-width:1399px) {
                            margin-bottom: 2.4rem;
                        }

                        @media (max-width:1199px) {
                            font-size: 1.4rem;
                            margin-bottom: 2rem;
                        }

                        @media (max-width:767px) {
                            margin-bottom: 1.6rem;
                        }
                    }
                }
            }
        }

        .forthDfa {
            display: flex;
            align-items: flex-start;
            width: 100%;
            max-width: 132.9rem;
            margin: auto;

            @media (max-width:767px) {
                flex-direction: column-reverse;
            }

            .rightImgSec {
                width: 100%;
                max-width: calc(100% - 45rem);
                flex: 0 0 calc(100% - 45rem);
                padding-left: 6.7rem;

                @media (max-width:1679px) {
                    max-width: calc(100% - 40rem);
                    flex: 0 0 calc(100% - 40rem);
                    padding-right: 4rem;
                }

                @media (max-width:1399px) {
                    max-width: calc(100% - 35rem);
                    flex: 0 0 calc(100% - 35rem);
                    padding-right: 2.6rem;
                }

                @media (max-width:1199px) {
                    max-width: calc(100% - 30rem);
                    flex: 0 0 calc(100% - 30rem);
                    padding-right: 2.2rem;
                }

                @media (max-width:991px) {
                    max-width: calc(100% - 24rem);
                    flex: 0 0 calc(100% - 24rem);
                }

                @media (max-width:767px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }

            .leftTextSec {
                width: 100%;
                max-width: 45rem;
                flex: 0 0 45rem;

                @media (max-width:1679px) {
                    max-width: 40rem;
                    flex: 0 0 40rem;
                }

                @media (max-width:1399px) {
                    max-width: 35rem;
                    flex: 0 0 35rem;
                }

                @media (max-width:1199px) {
                    max-width: 30rem;
                    flex: 0 0 30rem;
                }

                @media (max-width:991px) {
                    max-width: 24rem;
                    flex: 0 0 24rem;
                }

                @media (max-width:767px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding: 0;
                    text-align: center;
                }

                .infoPera {
                    margin-top: 3.4rem;
                    width: 100%;
                    max-width: 43.8rem;

                    @media (max-width:1679px) {
                        margin-top: 2.8rem;
                    }

                    @media (max-width:1399px) {
                        margin-top: 2.4rem;
                    }

                    @media (max-width:1199px) {
                        margin-top: 2rem;
                    }

                    @media (max-width:767px) {
                        margin-top: 1.6rem;
                        width: 100%;
                        max-width: 100%;
                    }

                    p {
                        font-size: 1.8rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: $black;
                        margin-bottom: 3.4rem;

                        @media (max-width:1679px) {
                            font-size: 1.6rem;
                            margin-bottom: 2.8rem;
                        }

                        @media (max-width:1399px) {
                            margin-bottom: 2.4rem;
                        }

                        @media (max-width:1199px) {
                            font-size: 1.4rem;
                            margin-bottom: 2rem;
                        }

                        @media (max-width:767px) {
                            margin-bottom: 1.6rem;
                        }
                    }
                }
            }
        }
    }
}