@import '../../../../../Assets/_var.scss';

.expendmodal {
    .modal-dialog {
        max-width: 100%;
        padding: 0;

        .modal-content {
            width: 100%;
            height: 100%;
            position: fixed;

            .modal-header {
                padding: 0;
            }

            .modal-body {
                padding: 0;

                .modalInner {
                    .videoData {
                        video {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}