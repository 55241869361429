@import '../../../../../Assets/_var.scss';

.dfaTable {
    margin-top: 7rem;

    @media (max-width:1679px) {
        margin-top: 5rem;
    }

    @media (max-width:1199px) {
        margin-top: 3.8rem;
    }

    @media (max-width:991px) {
        margin-top: 2.8rem;
    }
}