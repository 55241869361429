@import "../../../../../../../Assets/_var.scss";

.cardTable {
  display: flex;
  align-items: flex-start;

  // &leftSide {
  //     flex: 0 0 30rem;
  //     width: 100%;
  //     max-width: 30rem;
  //     height: 100%;

  //     .cardSec {
  //         border: 0.2rem solid $borderClr4;
  //         border-radius: 0.5rem;
  //         height: 100%;
  //         min-height: 25.1rem;
  //         background: $white;
  //         padding: 1.8rem 1rem 1rem;
  //         position: relative;
  //         z-index: 1;

  //         &::before {
  //             content: '';
  //             position: absolute;
  //             top: 0;
  //             left: 0;
  //             width: 100%;
  //             height: 11.6rem;
  //             background: $black;
  //             z-index: -1;
  //         }

  //         .topHead {
  //             display: flex;
  //             align-items: center;
  //             flex-direction: column;

  //             p {
  //                 font-size: 1.2rem;
  //                 color: $white;
  //                 font-weight: bold;
  //                 margin-bottom: 0.5rem;
  //             }

  //             h5 {
  //                 color: $white;
  //                 font-weight: bold;
  //                 margin-bottom: 1.5rem;
  //             }

  //             img {
  //                 width: 9rem;
  //                 height: 9rem;
  //             }
  //         }

  //         .bottomHead {
  //             display: flex;
  //             align-items: center;
  //             flex-direction: column;
  //             margin-top: 1rem;

  //             p {
  //                 font-size: 1.2rem;
  //                 color: $black;
  //                 font-weight: 400;
  //             }

  //             h5 {
  //                 color: $black;
  //                 font-weight: bold;
  //                 margin: 1.5rem 0 0.35rem;
  //             }
  //         }
  //     }
  // }

  &rightSide {
    flex: 0 0 calc(100% - 30rem);
    max-width: calc(100% - 30rem);
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    // padding-left: 3rem;
  }

  .tableSec {
    .bidder_table {
      :global {
        .table-responsive {
          border: 2px solid $borderClr4;
          border-radius: 0.6rem;
        }

        table {
          border-spacing: 0;
          border-collapse: collapse;

          thead {
            tr {
              th {
                background-color: $black;
                color: $white;
                padding: 1.5rem 2rem;
                font-weight: bold;
                font-size: 1.2rem;
                font-family: $baseFont;
                text-align: left;

                &:first-child {
                  border-radius: 5px 0px 0px 0px;
                  min-width: 7rem;
                  width: 7rem;
                  max-width: 7rem;
                }

                &:last-child {
                  border-radius: 0px 5px 0px 0px;
                }

                .btnGlobal {
                  height: 3rem;
                  font-size: 1.2rem;
                  padding: 0.3rem 2rem;
                  width: 100%;
                  max-width: 19rem;
                }
              }
            }
          }

          tbody {
            tr {
              border-bottom: 2px solid $borderClr5;

              td {
                border: none;
                padding: 1rem 2rem;
                font-size: 1.2rem;
                font-weight: bold;
                font-family: $baseFont;
                color: $black;
                text-align: left;
                background: #fafafa;

                &:first-child {
                  text-align: center;
                }

                &:nth-child(2) {
                  text-align: center;
                  width: 20rem;
                  max-width: 20rem;
                  min-width: 20rem;
                }
                &.currentPrice {
                  color: $black;
                }

                .ownedBy {
                  display: flex;
                  align-items: center;

                  img {
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                  }

                  h6 {
                    font-size: 1.2rem;
                    margin-left: 0.6rem;
                  }
                }

                .btnSection {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  margin-inline: -1rem;

                  .btnMake,
                  .btnBuy {
                    height: 3rem;
                    width: 100%;
                    max-width: 9rem;
                    font-size: 1.2rem;
                    padding: 0.7rem 1.4rem;
                    margin-inline: 1rem;
                  }

                  p {
                    font-size: 1.2rem;
                    margin-inline: 1rem;
                    font-weight: 400;
                    color: #aeaeae;
                  }
                }
              }

              // &:first-child {
              //     td {
              //         color: $black;
              //         background: $white;

              //         &.lastSale {
              //             color: #ABABAB;
              //         }

              //         .ownedBy {
              //             h6 {
              //                 color: $black;
              //             }
              //         }
              //     }
              // }

              &:last-child {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}