@import "../../../../Assets/_var.scss";

.card_details {
  position: relative;

  &_inner {
    display: flex;
    align-items: flex-end;
    border-bottom: 2px solid $white_light4;
    padding-bottom: 2rem;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &Thumb {
      border-radius: 50%;
      height: 25rem;
      width: 25rem;
      border: 1.7rem solid $yellow100;
      overflow: hidden;
      box-shadow: 0 0 2.2rem rgba(0, 0, 0, 0.7);
      margin: -12.5rem 0 0;
      position: relative;
      z-index: 1;

      @media (max-width: 1679px) {
        margin: -10rem 0 1.6rem;
        width: 23rem;
        height: 23rem;
      }

      @media (max-width: 1399px) {
        border-width: 1.5rem;
        width: 20rem;
        height: 20rem;
      }

      @media (max-width: 991px) {
        width: 16rem;
        height: 16rem;
        border-width: 1rem;
      }

      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }

    &_headings {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      max-width: calc(100% - 25rem);
      padding-left: 4rem;

      @media (max-width: 1679px) {
        padding-left: 3rem;
        max-width: calc(100% - 23rem);
      }

      @media (max-width: 1399px) {
        padding-left: 2rem;
        max-width: calc(100% - 20rem);
      }

      @media (max-width: 991px) {
        padding-left: 0;
        max-width: 100%;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .texthead {
        h2 {
          font-weight: bold;
          line-height: 1.2;
          font-family: $baseFont2;
          color: $black;
        }

        h6 {
          margin: 1rem 0;
          color: $black;
          line-height: 1.2;
        }

        .userprofileShare {
          display: flex;
          align-items: center;

        }

        p {
          font-size: 1rem;
          color: $gray747474;
          font-weight: 400;
        }
      }

      .head_btns {
        display: flex;
        align-items: center;

        .btneditsize {
          font-size: 1.6rem;
          font-weight: bold;
          font-family: "Raleway";
          margin-right: 2rem;
          padding: 0.8rem 2rem;
          white-space: nowrap;
          height: 4rem;

          @media (max-width: 1399px) {
            margin-right: 1.5rem;
            height: 3.5rem;
          }

          @media (max-width: 991px) {
            font-size: 1.4rem;
            margin-right: 1rem;
            height: 3rem;
            padding: 0.6rem 1.5rem;
          }

          @media (max-width: 767px) {
            padding: 0.4rem 1rem;
          }

          @media (max-width: 439px) {
            margin-top: 1rem;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .shareeditBtn {
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
          margin-top: 1.5rem;
        }

        @media (max-width: 439px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .shareProfile {
          display: flex;
          align-items: center;
          margin-right: 5rem;

          @media (max-width: 1679px) {
            margin-right: 4rem;
          }

          @media (max-width: 1399px) {
            margin-right: 3rem;
          }

          @media (max-width: 991px) {
            margin-right: 2rem;
          }

          @media (max-width: 575px) {
            margin-right: 1rem;
          }

          .socialist {
            display: flex;
            align-items: center;

            li {
              &:first-child {
                margin-left: 0;
              }

              a {
                width: 3rem;
                height: 3rem;
                border: 1px solid $black;
                background: $white;
                padding: 0.6rem;
                transition: ease-in-out all 0.3s;

                svg {
                  width: 1.5rem;
                  height: 1.5rem;
                  transition: ease-in-out all 0.3s;
                }

                &:hover {
                  background: $green;
                  border-color: $green;
                  transition: ease-in-out all 0.3s;

                  svg {
                    transition: ease-in-out all 0.3s;

                    path {
                      fill: $white;
                    }
                  }
                }
              }
            }
          }

          .userShareIcon {
            width: 3rem;
            height: 3rem;
            margin-right: 0;
            margin-left: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            :global {
              .dropdown-toggle {
                border-radius: 50%;
                background: $white;
                border: 1px solid $black;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                svg {
                  width: 100% !important;
                  height: 100% !important;

                  .bg_path {
                    fill: transparent;
                  }
                }

                &.show {
                  background: $green;
                  border-color: $green;
                  transition: ease-in-out all 0.3s;

                  svg {
                    transition: ease-in-out all 0.3s;

                    .bg_path {
                      fill: $green;
                    }

                    path {
                      fill: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .card_head {
      border-bottom: 2px solid $white_light4;
      padding-bottom: 1.3rem;

      h1 {
        font-size: 13.4rem;
        font-weight: bold;
        color: $black3;
        font-family: "Raleway";
        margin-bottom: 4rem;

        @media (max-width: 1679px) {
          font-size: 10.4rem;
        }

        @media (max-width: 1439px) {
          font-size: 8rem;
          margin-bottom: 2.5rem;
        }

        @media (max-width: 1199px) {
          font-size: 7rem;
        }

        @media (max-width: 991px) {
          font-size: 5.5rem;
        }

        @media (max-width: 767px) {
          font-size: 4rem;
        }
      }

      .owner_list {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 479px) {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 0.8rem;
        }

        li {
          flex: 0 0 18%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          @media (max-width: 1679px) {
            flex: 0 0 25%;
          }

          @media (max-width: 1199px) {
            flex: 0 0 50%;

            &:first-child {
              flex: 0 0 20%;
            }
          }

          @media (max-width: 575px) {
            flex: 0 0 80%;

            &:first-child {
              flex: 0 0 20%;
              margin-right: 1rem;
            }
          }

          @media (max-width: 479px) {
            max-width: 100%;
          }

          label {
            font-size: 1.6rem;
            font-weight: bold;
            color: $black333333;
            font-family: "Roboto";
          }

          p {
            font-size: 1.6rem;
            font-weight: bold;
            color: $black333333;
            font-family: "Roboto";
            margin-left: 0.5rem;
          }
        }
      }
    }

  }

  &_containt {
    position: relative;

    h4 {
      font-family: $baseFont;
      font-size: 1.6rem;
      margin-bottom: 4.5rem;
      font-weight: 500;

      @media (max-width: 1199px) {
        margin-bottom: 3rem;
      }

      @media (max-width: 991px) {
        margin-bottom: 2.5rem;
      }

      @media (max-width: 767px) {
        margin-bottom: 1.8rem;
      }
    }

    :global {
      .list_posi {
        position: absolute;
        top: 2.4rem;
        right: 0;

        @media (max-width: 767px) {
          position: unset;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 1rem;

          li {
            padding-right: 1rem;

            &:last-child {
              padding: 0;
            }
          }
        }
      }
    }

    .text_infor {
      width: 100%;
      max-width: 82.5rem;
      margin-top: 3rem;

      @media (max-width: 767px) {
        max-width: 100%;
        margin-top: 1.5rem;
      }

      h6 {
        font-size: 2rem;
        font-weight: bold;
        color: $black3;
        font-family: "Roboto";
        margin-bottom: 2rem;
      }

      .textInfo {
        margin-bottom: 3rem;
        display: inline;

        p {
          max-height: 7rem;
          overflow: hidden;
          display: inline;
        }

        a {
          color: $blue007eff;
          font-weight: 700;
          font-size: 1.8rem;
          margin-left: 0.6rem;
        }

        :global {
          .textSection {
            display: inline;
          }

          .showData {
            max-height: 100%;
            overflow: unset;
          }
        }
      }
    }

    .list_info {
      .view_list {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 575px) {
          flex-wrap: wrap;
        }

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          min-width: 14rem;
          min-height: 9rem;
          border-right: 2px solid $white_light4;
          padding: 1.5rem 0;

          @media (max-width: 575px) {
            border-bottom: 2px solid $white_light4;
            border-right: none;
            flex: 0 0 50%;

            &:first-child {
              align-items: center !important;
              border-right: 2px solid $white_light4;
            }

            &:last-child {
              flex: 0 0 100%;
              padding: 0 !important;
            }
          }

          &:last-child {
            flex-direction: row;
            border: none;
            padding-left: 5rem;
          }

          &:first-child {
            align-items: flex-start;

            h6 {
              min-width: 7rem;
            }
          }

          lable {
            font-size: 1.6rem;
            font-weight: bold;
            color: $black3;
            font-family: "Roboto";
          }

          h6 {
            font-size: 1.6rem;
            font-weight: bold;
            color: $black3;
            font-family: "Roboto";
          }

          .btnlikeshare {
            background-color: transparent;
            border: none;
            margin-right: 1.2rem;
            padding: 0;

            svg {
              width: 5.5rem;
              height: 5.5rem;

              @media (max-width: 1679px) {
                width: 4.5rem;
                height: 4.5rem;
              }

              @media (max-width: 1439px) {
                width: 4rem;
                height: 4rem;
              }

              :global {
                path.bg_path {
                  fill: $black;
                  transition: 0.3s all ease-in-out;
                }

                path.Icon_path {
                  fill: $yellow;
                  transition: 0.3s all ease-in-out;
                }
              }
            }

            &:hover {
              svg {
                width: 5.5rem;
                height: 5.5rem;

                :global {
                  .bg_path {
                    fill: $yellow;
                    transition: 0.3s all ease-in-out;
                  }

                  .Icon_path {
                    fill: $black;
                    transition: 0.3s all ease-in-out;
                  }
                }
              }
            }
          }
        }
      }
    }

    .biotext {
      margin-top: 2rem;
      width: 100%;
      max-width: 82.5rem;

      @media (max-width: 1199px) {
        margin-top: 1.5rem;
      }

      @media (max-width: 991px) {
        margin-top: 1.2rem;
      }

      // h6 {
      //   font-size: 2rem;
      //   font-weight: bold;
      //   font-family: "Roboto", sans-serif;
      //   color: $black3;
      //   margin-bottom: 1rem;
      // }

      p {
        font-size: 1.6rem;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        color: $black3;
      }

      .textInfo {
        margin-bottom: 3rem;
        display: inline;

        p {
          max-height: 7rem;
          overflow: hidden;
          display: inline;
        }

        a {
          color: $blue007eff;
          font-weight: 700;
          font-size: 1.8rem;
          margin-left: 0.6rem;
        }

        :global {
          .textSection {
            display: inline;
          }

          .showData {
            max-height: 100%;
            overflow: unset;

            p {
              max-height: 100%;
            }
          }
        }
      }
    }

    .tagList {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-inline: -0.5rem;
      margin-top: 2.5rem;
      overflow-y: auto;
      height: 100%;
      max-height: 8rem;

      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        background-color: $grayC1C1C1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $green;
      }

      .tags {
        padding: 0.5rem;

        p {
          font-size: 1.2rem;
          font-weight: 500;
          border: 0.1rem solid $borderClr2;
          border-radius: 1rem;
          padding: 0.8rem 1.4rem;
        }
      }
    }
  }
}