@import '../../../../Assets/_var.scss';

.offerModal {
    :global {
        .modal-dialog {
            @media (max-width: 767px) {
                padding-top: 5rem;
            }

            .modal-content {
                background-color: transparent;
                border: none;

                .modal-header,
                .modal-body {
                    padding: 0;
                }
            }
        }
    }

    &Left,
    &Right {
        flex: 0 0 50%;
        padding: 2rem;
        border-radius: 0;
        display: flex;
        flex-direction: column;

        @media (max-width: 1399px) {
            padding: 1.5rem;
        }

        @media (max-width: 991px) {
            padding: 1rem;
        }

        .bottomLine {
            margin-top: auto;
            width: 100%;
            height: 2px;
            background-color: $white;

            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    &Left {
        background-color: $white;

        .bottomLine {
            background-color: $black;
        }

        &Inner {
            width: 100%;
            height: 63.6rem;

            @media (max-width:1679px) {
                height: 60rem;
            }

            @media (max-width:991px) {
                height: 50rem;
            }

            @media (max-width:767px) {
                height: 40rem;
            }

            @media (max-width:575px) {
                height: 35rem;
            }

            img,
            video {
                box-shadow: 0 0 6.2rem #a3a3a34d;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &Right {
        color: $white;
        background-color: $black;

        h3 {
            line-height: 1.2;
            margin: 0.5rem 0;
        }

        .artistDetails {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            margin-bottom: 4.5rem;

            @media (max-width:1679px) {
                margin-bottom: 4rem;
            }

            @media (max-width:1399px) {
                margin-bottom: 3rem;
            }

            @media (max-width:1199px) {
                margin-bottom: 2.2rem;
            }

            @media (max-width:991px) {
                margin-bottom: 1.8rem;
            }

            h6 {
                font-size: 1.4rem;
                font-weight: 500;
                color: $grayC7C7C7;

                @media (max-width: 767px) {
                    font-size: 1.2rem;
                }
            }

            &Edition {
                display: flex;
                align-items: center;

                p {
                    color: $white;
                    text-transform: capitalize;
                    font-weight: bold;
                    font-size: 1.2rem;
                }

                span {
                    font-weight: 400;
                    font-size: 1.2rem;
                    margin-left: 0.8rem;
                }
            }
        }

        .editionDetails {
            padding: 2.5rem 0 3.5rem;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            border-top: 0.2rem solid $gray707070;

            @media (max-width:1679px) {
                padding: 2rem 0 3rem;
            }

            @media (max-width:1399px) {
                padding: 1.5rem 0 2.5rem;
            }

            @media (max-width:1199px) {
                padding: 1.2rem 0 2rem;
            }

            @media (max-width:991px) {
                padding: 1rem 0 1.5rem;
            }

            @media (max-width:767px) {
                justify-content: space-between;
            }

            .enter_text {
                width: 100%;
                max-width: 14rem;
                padding-top: 0.4rem;

                @media (max-width:767px) {
                    max-width: 20rem;
                }

                label {
                    font-size: 1.2rem;
                    font-weight: bold;
                    line-height: 1;
                    margin: 0;
                }

                p {
                    font-size: 1rem;
                    font-weight: 400;
                    color: $light_gray3;
                    font-family: $baseFont;
                    line-height: 1;
                }
            }

            :global {
                .common_input {
                    margin: 0;

                    &_inner {
                        width: 100%;
                        max-width: 14.4rem;

                        @media (max-width:767px) {
                            max-width: 100%;
                        }

                        .leftIcon {
                            font-size: 1.8rem;
                            font-weight: 600;
                            left: 1.3rem;

                            @media (max-width: 1399px) {
                                font-size: 1.6rem;
                            }

                            @media (max-width: 991px) {
                                font-size: 1.4rem;
                            }
                        }

                        .form-control {
                            padding: 1rem 1.3rem;
                            padding-left: 2.8rem;
                            background: $black;
                            border: 1px solid #5C5C5C;
                            color: $white;
                            font-size: 1.6rem;
                            font-weight: bold;
                            height: 4rem;

                            @media (max-width: 1399px) {
                                font-size: 1.4rem;
                            }

                            @media (max-width: 991px) {
                                font-size: 1.2rem;
                                padding: 0.7rem 1.5rem;
                                padding-left: 2.6rem;
                            }

                            &::placeholder {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        .buy_btn_box {
            padding-top: 0 !important;

            .buy_btn {
                height: 4rem;
                width: 100%;
            }
        }

        .receive_payment {
            width: 100%;
            max-width: 28rem;
            margin: 2.8rem 0 4.5rem;

            @media (max-width:1679px) {
                margin: 2.2rem 0 4rem;
            }

            @media (max-width:1399px) {
                margin: 1.6rem 0 3rem;
            }

            @media (max-width:1199px) {
                margin: 1.2rem 0 2.2rem;
            }

            @media (max-width:991px) {
                margin: 1rem 0 1.8rem;
            }

            @media (max-width:767px) {
                max-width: 100%;
            }

            h6 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: $baseFont;
                color: $white;
                width: 100%;
                font-weight: bold;

                label {
                    width: 100%;
                    max-width: max-content;
                    min-width: 15rem;
                    color: $white;
                }

                span {
                    word-break: break-word;
                    color: $white;
                }
            }
        }

        .paragraph_text {
            font-size: 1.2rem;
            font-weight: 400;
        }

        .pricelist {
            width: 100%;
            max-width: 28rem;
            padding-bottom: 2rem;

            @media (max-width:767px) {
                max-width: 100%;
            }

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0.8rem;

                &:last-child {
                    margin-bottom: 0;
                }

                &.highvalue {
                    h6 {
                        color: $white;
                        font-weight: bold;
                    }

                    span {
                        color: $white;
                    }
                }

                h6,
                label {
                    font-size: 1.2rem;
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-family: $baseFont;
                    color: $grayB5B5B5;
                    width: 100%;
                    max-width: max-content;
                    min-width: 10rem;
                }

                span {
                    word-break: break-word;
                    font-size: 1.2rem;
                    font-weight: 400;
                    color: $grayB5B5B5;
                }
            }


            p {
                font-size: 1.2rem;
                font-weight: 500;
                color: $light_gray3;
                font-family: $baseFont;
                line-height: 1;
                font-style: italic;
            }
        }
    }
}