@import '../../../Assets/_var.scss';

.loader_style {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($white, 0.0);
  z-index: 9999992;
  backdrop-filter: blur(10px);

  .spinner-border {
    width: 5rem;
    height: 5rem;
    border: 0.6rem solid $green;
    border-right-color: transparent;
  }
}