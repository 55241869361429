@import "../../../../../Assets/_var.scss";

.offers_received {
  padding: 3.2rem 0;

  .searchbar {
    .filter_btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 32rem;
      margin-left: auto;

      :global {
        .common_dropdown {
          width: 100%;
          margin-right: 1.5rem;

          &:last-child {
            margin-left: 0;
          }
        }
      }

      .list_check {
        padding: 1rem;

        li {
          .setlimitcheck {
            margin: 0 0 1rem;

            :global {
              .form-check {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                input {
                  height: 2.1rem;
                  width: 2.1rem;
                  top: 0;
                }

                .form-check-label {
                  color: $black3;
                  font-size: 2rem;
                  font-weight: 400;
                  line-height: 2.4rem;
                  font-family: "Roboto";
                }
              }
            }
          }

          &:last-child {
            .setlimitcheck {
              margin: 0;
            }
          }
        }
      }
    }
  }

  :global {
    form {
      .search_input {
        width: 100%;
        margin-bottom: 3rem;

        .search_input_inner {
          height: 5.8rem;
          border-radius: 2.9rem;
          background: $white_light3;
          padding: 0 2.8rem;

          @media (max-width: 991px) {
            height: 5rem;
          }

          @media (max-width: 767px) {
            height: 4.5rem;
          }

          .search_input_icon {
            border: none;
            left: 2rem;

            svg {
              path {
                fill: $gray5;
              }
            }
          }

          .form-control {
            border: none;
            background: transparent;
            font-size: 2rem;

            @media (max-width: 991px) {
              font-size: 1.8rem;
            }

            @media (max-width: 767px) {
              font-size: 1.6rem;
            }

            &::placeholder {
              color: $gray6;
            }
          }
        }
      }
    }
  }

  .table_sectionoffier {
    border: 2px solid $borderClr2;

    // height: 53rem;
    // overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black;
    }
  }

  .main_containt {
    .offier_recive_row {
      margin-bottom: 2.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      .colSpaceLeft {
        @media (min-width:1200px) {
          width: 100%;
          max-width: 28rem;
        }
      }

      .colSpaceRight {
        @media (min-width:1200px) {
          width: 100%;
          max-width: calc(100% - 28rem);
        }
      }

      .edition_head {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 1rem 0;

        @media (max-width: 475px) {
          flex-wrap: wrap;
        }

        :global {
          .activeBtn {
            background: $black;
            color: $white;
          }
        }

        .btneditsize {
          height: 4rem;
          padding: 1rem 1.5rem;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.2;
          font-family: $baseFont2;

          @media (max-width: 1439px) {
            font-size: 1.4rem;
          }

          @media (max-width: 991px) {
            &:first-child {
              margin-right: 1rem;
            }
          }

          @media (max-width: 767px) {
            font-size: 1.2rem;
          }

          @media (max-width: 475px) {
            &:first-child {
              margin-right: 0.8rem;
            }
          }
        }

        .edition_select {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 2rem;

          @media (max-width: 1439px) {
            margin-right: 1.5rem;
          }

          @media (max-width: 991px) {
            margin-right: 1rem;
          }

          @media (max-width: 439px) {
            flex: 0 0 100%;
            margin-bottom: 1rem;
            margin-right: 0;
          }

          label {
            font-size: 1.6rem;
            font-weight: bold;
            color: $black;
            line-height: 1.2;
            font-family: $baseFont2;

            @media (max-width: 1439px) {
              font-size: 1.4rem;
            }

            @media (max-width: 767px) {
              font-size: 1.2rem;
            }
          }

          .edition_number {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $white;
            border: 0.1rem solid $black;
            border-radius: 3rem;
            padding: 0.8rem 1rem;
            margin-left: 1.3rem;
            height: 4rem;

            @media (max-width: 475px) {
              margin-left: 0.8rem;
            }

            button {
              background: transparent;
              border: none;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                path {
                  fill: $black;
                  stroke-width: 1px;
                  stroke: $black;
                }
              }
            }

            p {
              font-size: 1.6rem;
              color: $black;
              font-weight: bold;
              font-family: $baseFont2;
              padding: 0 0.8rem;

              @media (max-width: 1439px) {
                font-size: 1.4rem;
              }

              @media (max-width: 767px) {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }

    .artimgselect {
      @media (min-width: 480px) and (max-width: 991px) {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2rem;
      }

      img {
        height: 29.7rem;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .text_fields {
        margin-top: 2rem;

        @media (min-width: 480px) and (max-width: 991px) {
          margin-left: 2rem;
          width: 100%;
          max-width: 25rem;
          margin-top: 0;
        }

        @media (max-width: 479px) {
          margin-bottom: 2rem;
        }

        h5 {
          font-size: 1.4rem;
          font-weight: 400;
          color: $black3;
          margin-bottom: 0.5rem;
          line-height: 1.2;
        }

        p {
          font-size: 1.2rem;
          font-weight: 400;
          color: $black3;
          margin-bottom: 1rem;
          line-height: 1.2;
        }

        .listart {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          border-top: 1px solid $gray707070;
          border-bottom: 1px solid $gray707070;
          padding: 1rem 0;

          li {
            display: flex;
            align-items: center;
            width: 100%;
            padding-bottom: 0.8rem;

            &:last-child {
              padding-bottom: 0;
            }

            label {
              color: $black3;
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 1;
              margin-right: 1rem;
            }

            h6 {
              color: $black3;
              font-size: 1.2rem;
              font-weight: 400;
              line-height: 1;
            }
          }
        }

        .price_list {
          border-bottom: 1px solid $gray707070;
          padding: 1rem 0;
          margin-bottom: 2rem;

          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 0.8rem;

            &:last-child {
              margin: 0;
            }

            label {
              color: $black3;
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 1;
              margin-right: 1rem;
            }

            h6 {
              color: $black3;
              font-size: 1.2rem;
              font-weight: 400;
              line-height: 1;
            }
          }
        }

        .btneditsize {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.2;
          height: 4rem;
          padding: 0.8rem;
          width: 100%;
          max-width: 25rem;
          font-family: $baseFont2;

          @media (max-width: 1439px) {
            font-size: 1.4rem;
          }

          @media (max-width: 767px) {
            font-size: 1.2rem;
          }
        }
      }
    }

    :global {
      .table-responsive {
        height: 47.4rem;
      }

      table {
        border-spacing: 0;
        border-collapse: collapse;
        // border: 2px solid $borderClr2;

        thead {
          tr {
            th {
              background-color: $black;
              border-bottom: 1px solid $black;
              color: $white;
              padding: 2rem 2.4rem;
              font-weight: bold;
              font-size: 1.6rem;

              @media (max-width: 1439px) {
                font-size: 1.4rem;
                padding: 1.6rem 2rem;
              }

              @media (max-width: 991px) {
                font-size: 1.2rem;
                padding: 1rem 1.6rem;
              }


              .dropBtn {
                margin-left: auto;
                font-size: 1.6rem;
                font-weight: bold;
                font-family: $baseFont2;
                padding: 0 1rem;
                background: transparent;
                border: none;
                color: $white;
                height: 2.3rem;
                width: 100%;
                max-width: 10rem;

                &:focus,
                &.active {
                  color: $white  !important;
                  border: none !important;
                }

                &.show {
                  border: none;
                  color: $white;

                  &:focus,
                  &.active {
                    color: $white  !important;
                    border: none !important;
                  }
                }

                @media (max-width: 1439px) {
                  font-size: 1.4rem;
                }

                @media (max-width: 991x) {
                  font-size: 1.2rem;
                }

                svg {
                  height: 1.5rem;
                  width: 1.5rem;

                  path {
                    fill: $white;
                  }
                }
              }

              .dropdown-menu {
                min-width: 10rem;
              }

              .accept_check {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0;

                .black_check {
                  margin: 0;
                  margin-right: 2rem;

                  .form-check {
                    margin: 0;
                    display: flex;
                    align-items: center;

                    .form-check-input {
                      border: 1px solid $gray707070;
                      height: 1rem;
                      width: 1rem;

                      &:checked {
                        background-color: $black2;
                      }

                      &:focus {
                        box-shadow: none;
                      }
                    }

                    .form-check-label {
                      color: $white;
                      font-size: 1.6rem;
                      font-weight: bold;
                      padding-left: 1.2rem;

                      @media (max-width: 1439px) {
                        font-size: 1.4rem;
                      }

                      @media (max-width: 767px) {
                        font-size: 1.2rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        tbody {
          tr {
            td {
              border-top: 1px solid $black;
              border-bottom: 1px solid $black;
              padding: 2rem 2.4rem;
              font-weight: bold;
              border-left: none;
              border-right: none;
              font-size: 1.6rem;

              @media (max-width: 1439px) {
                font-size: 1.4rem;
                padding: 1.6rem 2rem;
              }

              @media (max-width: 991px) {
                font-size: 1.2rem;
                padding: 1rem 1.6rem;
              }

              .offer_price {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;

                h6 {
                  font-weight: bold;
                  border-left: none;
                  margin-bottom: 0.6rem;
                }

                button {
                  background: transparent;
                  border: none;
                  text-decoration: underline;
                  font-size: 1.2rem;
                  font-weight: 300;
                  color: $black2;
                  line-height: 1;
                  padding: 0;
                }
              }

              .status_tab {
                text-transform: capitalize;
              }

              &:last-child {
                text-align: right;
              }

              .btn_section {
                .btneditsize {
                  padding: 0.8rem 1.6rem;
                  height: 3rem;
                  font-size: 1.2rem;
                  min-width: 8rem;
                  margin-left: 2rem;

                  &:first-child {
                    margin-left: 0;
                  }
                }
              }
            }

            &:first-child {
              td {
                border-top: none;
              }
            }
          }
        }
      }
    }
  }
}