@import "../../../../Assets/_var.scss";

.CommonCard {
    position: relative;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    height: 39rem;
    cursor: pointer;
    // transition: .3s all ease-in-out;
    // transition: all 0.3s linear;
    // padding: 2rem 3rem;

    >img {
        height: 100%;
        // object-fit: fill;
        object-fit: cover;
        width: 100%;
        max-width: 100%;
        position: relative;
        z-index: -1;
    }

    .image_section_hover {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .thumnail_img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            display: block;
            opacity: 1;
            visibility: visible;
        }

        .featureimg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
        }

        .thumnail_img,
        .featureimg {
            transition: .5s all ease-in-out;
        }

        &:hover {
            .thumnail_img {
                opacity: 0;
                visibility: hidden;
            }

            .featureimg {
                opacity: 1;
                visibility: visible;
            }
        }

        &::after {
            content: "";
            height: 100%;
            z-index: 0;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: -moz-linear-gradient(top,
                    rgba(0, 0, 0, 0.1) 30%,
                    rgba(0, 0, 0, 0.8) 100%);
            background: -webkit-linear-gradient(top,
                    rgba(0, 0, 0, 0.1) 30%,
                    rgba(0, 0, 0, 0.8) 100%);
            background: linear-gradient(to bottom,
                    rgba(0, 0, 0, 0.1) 30%,
                    rgba(0, 0, 0, 0.8) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
            // z-index: -1;
            // transition: .5s all ease-in-out;
        }
    }

    // &::before {
    //     content: "";
    //     display: block;
    //     padding-top: 100%;
    //     width: 100%;
    //     max-width: 100%;
    // }
    &.card_withouthover {
        .image_section_hover {
            &:hover {
                .thumnail_img {
                    opacity: 1 !important;
                    visibility: visible !important;
                }

                .featureimg {
                    opacity: 0 !important;
                    visibility: hidden !important;
                }
            }
        }
    }

    &Date {
        height: 5rem;
        width: 5rem;
        border-radius: 0.5rem;
        background-color: $white;
        position: absolute;
        left: 3rem;
        top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        p {
            margin: 0;
            text-transform: uppercase;
            font-size: 1.6rem;
            text-align: center;
            line-height: 1.1;

            span {
                color: $gray;
                display: block;
            }

            strong {
                color: $black;
                font-size: 2.2rem;
            }
        }

        @media (max-width: 1679px) {
            height: 4rem;
            width: 4rem;

            p {
                font-size: 1.2rem;

                strong {
                    font-size: 1.6rem;
                }
            }
        }

        @media (max-width: 767px) {
            left: 1rem;
            top: 1rem;
        }
    }

    &ContentHandle {
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2rem 3rem;
        width: 100%;
        max-width: 100%;

        &Top {
            display: flex;
            justify-content: space-between;
            padding-bottom: 1.2rem;
            border-bottom: 2px solid $white;
            margin-bottom: 1.2rem;

            @media (max-width: 767px) {
                padding-bottom: 1rem;
                margin-bottom: 0.8rem;
            }

            &Left {
                flex: 1 auto;
                max-width: 100%;
                padding-right: 1rem;
            }

            &Right {
                flex: 1 auto;
                max-width: 100%;
                padding-left: 1rem;
                text-align: right;

                &Live {
                    span {
                        height: 2.2rem;
                        width: 2.2rem;
                        border-radius: 50%;
                        background-color: $yellow;
                        display: inline-block;
                        margin-left: 1rem;
                        position: relative;
                        top: 0.2rem;

                        @media (max-width: 991px) {
                            height: 1.6rem;
                            width: 1.6rem;
                        }
                    }
                }
            }

            &Left {
                * {
                    color: $white;
                }

                h3 {
                    font-family: $baseFont;
                }
            }

            &Right {
                * {
                    color: $white;
                }

                h3 {
                    font-family: $baseFont;
                }
            }
        }

        &Bottom {
            display: flex;
            justify-content: space-between;
            min-height: 3rem;

            &Left {
                padding-right: 0.8rem;
            }

            &Right {
                padding-left: 0.8rem;
                text-align: right;

                &Bell {
                    height: 4.4rem;
                    width: 4.4rem;
                    border: 2px solid $yellow;
                    border-radius: 50%;
                    background-color: $black;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    @media (max-width: 1679px) {
                        height: 3.4rem;
                        width: 3.4rem;

                        svg,
                        img {
                            width: 1.5rem;
                        }
                    }

                    @media (max-width: 1439px) {
                        height: 3rem;
                        width: 3rem;

                        svg,
                        img {
                            width: 1.2rem;
                        }
                    }
                }
            }

            &Left,
            &Right {
                * {
                    color: $white;
                }

                p {
                    font-size: 1.6rem;

                    strong {
                        font-family: $baseFont;
                    }

                    @media (max-width: 1679px) {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        &.Second {
            display: none;
        }

        @media (max-width: 767px) {
            padding: 1rem;
        }
    }

    // &:hover {
    //     // box-shadow: 0 0 14px rgba(0, 0, 0, .5);
    //     &:after {
    //         backdrop-filter: blur(1rem);
    //     }
    // }
    &.content-wrap {
        @media (max-width: 575px) {
            .CommonCard {
                &ContentHandle {

                    &Top,
                    &Bottom {
                        flex-direction: column;

                        &Left,
                        &Right {
                            text-align: center;
                            margin-bottom: 1rem;
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}

.timetext {
    height: 6rem;
    width: 7rem;
    border-radius: 0.5rem;
    position: absolute;
    left: 2rem;
    top: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    color: $white;
    font-size: 1.5rem;
    font-weight: 600;
}

.startin {
    z-index: 2;
    position: absolute;
    top: 1.9rem;
    left: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
        font-family: "Roboto";
        font-size: 1.6rem;
        font-weight: bold;
        color: $white;
        white-space: nowrap;
        margin-right: 0.5rem;
    }

    p {
        font-family: "Roboto";
        font-size: 1.6rem;
        font-weight: bold;
        color: $white;
    }
}

.actionBtn {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 13.1rem;
    height: 3.7rem;
    border-radius: 3rem;
    font-size: 1.6rem;
    font-weight: bold;
    color: $white;
    background: $green;

    @media (max-width: 1679px) {
        max-width: 12rem;
        font-size: 1.4rem;
    }

    &:hover {
        background-color: $yellow;
        color: $black;
        border-color: $yellow;
        box-shadow: 4px 5px 14px rgba($black, 0.3);
    }
}

.quickbuyBtn {
    position: absolute;
    top: 1rem;
    right: 2rem;
    background: $black;
    color: $white;
    border-radius: 2.4rem;
    padding: 0.7rem 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    opacity: 0;
    transition: 0.3s all ease-in-out;
    z-index: 99;
    border: none;

    &:hover {
        background-color: $yellow;
        color: $black;
        border-color: $yellow;
        box-shadow: 4px 5px 14px rgba($black, 0.3);
    }
}

.video_hover_sec {
    width: 100%;
    height: 45rem;
    display: flex !important;
    flex-direction: column;

    >div {
        &:first-child {
            order: 2;
            z-index: 3 !important;
            opacity: 1 !important;
            visibility: visible !important;
        }
    }

    >video {
        display: block;
        width: 100%;
        object-fit: cover !important;
        height: 100%;
        background: $black;
        order: 1;
    }
}

.video_hover_sec_second {
    width: 100%;
    height: 45rem;

    >video {
        display: block;
        width: 100%;
        object-fit: cover !important;
        height: 100%;
        background: $black;
    }
}