@import '../../../../Assets/_var.scss';

.like {
    // .searchbar {
    //     margin-bottom: 4rem;

    //     :global {
    //         form {
    //             display: flex;
    //             align-items: center;
    //             justify-content: space-between;
    //             width: 100%;

    //             @media (max-width:575px) {
    //                 flex-direction: column;
    //                 align-items: flex-start;
    //             }

    //             .search_input {
    //                 width: 100%;

    //                 .search_input_inner {
    //                     height: 5.8rem;
    //                     border-radius: 2.9rem;
    //                     background: #f6f6f6;
    //                     padding: 0 2.8rem;

    //                     @media (max-width:991px) {
    //                         height: 5rem;
    //                     }

    //                     @media (max-width:767px) {
    //                         height: 4.5rem;
    //                     }


    //                     .search_input_icon {
    //                         border: none;
    //                         left: 2rem;

    //                         svg {
    //                             path {
    //                                 fill: #8e8c8c;
    //                             }
    //                         }
    //                     }

    //                     .form-control {
    //                         border: none;
    //                         background: transparent;
    //                         font-size: 2rem;

    //                         @media (max-width:991px) {
    //                             font-size: 1.8rem;
    //                         }

    //                         @media (max-width:767px) {
    //                             font-size: 1.6rem;
    //                         }

    //                         &::placeholder {
    //                             color: #959494;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    &_section {
        &_arttable {
            .like_table {
                .info_user {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-end;
                    flex-direction: column;
                    cursor: pointer;
                    height: 100%;

                    &_innerSec {
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-start;
                        margin-bottom: 1.4rem;

                        @media (max-width: 991px) {
                            margin-bottom: 1rem;
                        }

                        @media (max-width: 575px) {
                            justify-content: center;
                        }

                        @media (max-width: 479px) {
                            flex-direction: column;
                            align-items: flex-start;
                            text-align: left;
                        }

                        img {
                            width: 10rem;
                            height: 10rem;
                            flex-shrink: 0;
                            object-fit: cover;
                            object-position: center;

                            @media (max-width: 1679px) {
                                width: 9rem;
                                height: 9rem;
                            }

                            @media (max-width: 1399px) {
                                width: 8rem;
                                height: 8rem;
                            }

                            @media (max-width: 1199px) {
                                width: 7rem;
                                height: 7rem;
                            }

                            @media (max-width: 991px) {
                                width: 6rem;
                                height: 6rem;
                            }

                            @media (max-width: 767px) {
                                width: 5rem;
                                height: 5rem;
                            }
                        }

                        .text_info {
                            padding-left: 2.4rem;
                            cursor: pointer;
                            height: 100%;
                            display: flex;
                            flex-direction: column;

                            @media (max-width: 1679px) {
                                padding-left: 1.5rem;
                            }

                            @media (max-width: 575px) {
                                padding-left: 1rem;
                            }

                            @media (max-width: 479px) {
                                padding-left: 0;
                                padding-top: 1rem;
                            }

                            h6 {
                                font-weight: bold;
                                color: $black;
                                font-family: $baseFont;
                                line-height: 1;
                                word-break: break-word;
                                margin-bottom: 1rem;

                                @media (max-width: 991px) {
                                    margin-bottom: 0.5rem;
                                }
                            }

                            p {
                                color: $black;
                                font-size: 1.6rem;
                                font-weight: 400;
                                font-family: $baseFont;
                                line-height: 1;
                                margin-bottom: 1rem;
                                // text-overflow: ellipsis;
                                // white-space: nowrap;
                                // overflow: hidden;
                                // width: 100%;
                                // max-width: 14rem;

                                @media (max-width: 991px) {
                                    font-size: 1.4rem;
                                    margin-bottom: 0.5rem;
                                }

                                @media (max-width: 767px) {
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }

                    .btnfollow {
                        width: 100%;
                        height: 3rem;
                        max-width: 9rem;
                        padding: 0.8rem 2rem;
                        font-size: 1.2rem;
                        font-weight: bold;
                        font-family: $baseFont;
                        margin-top: auto;

                        @media (max-width: 1399px) {
                            padding: 0.5rem 1.6rem;
                        }

                        @media (max-width: 991px) {
                            padding: 0.5rem 1rem;
                            height: 2.6rem;
                        }
                    }
                }
            }
        }
    }

    .rowFollow {
        margin: -3.1rem -1.5rem;

        @media (max-width:1679px) {
            margin: -2.8rem -1.5rem;
        }

        @media (max-width:1399px) {
            margin: -2.2rem -1.5rem;
        }

        @media (max-width:991px) {
            margin: -1.8rem -1.5rem;
        }

        @media (max-width:767px) {
            margin: -1.2rem -1.5rem;
        }

        .colFollow {
            padding: 3.1rem 1.5rem;

            @media (max-width:1679px) {
                padding: 2.8rem 1.5rem;
            }

            @media (max-width:1399px) {
                padding: 2.2rem 1.5rem;
            }

            @media (max-width:991px) {
                padding: 1.8rem 1.5rem;
            }

            @media (max-width:767px) {
                padding: 1.2rem 1.5rem;
            }
        }

        :global {
            @media (min-width: 1400px) {
                .col-xxl-2 {
                    width: calc(100% / 5);
                }
            }
        }
    }
}