@import "../../../../../Assets/_var.scss";

.offers_made {
  padding: 3.2rem 0;

  .searchbar {
    margin-bottom: 1.4rem;

    :global {
      .activeBtn {
        background: $black;
        color: $white;
      }

      form {
        .search_input {
          width: 100%;
          margin-bottom: 3rem;

          .search_input_inner {
            height: 5.8rem;
            border-radius: 2.9rem;
            background: $white_light3;
            padding: 0 2.8rem;

            @media (max-width: 991px) {
              height: 5rem;
            }

            @media (max-width: 767px) {
              height: 4.5rem;
            }

            .search_input_icon {
              border: none;
              left: 2rem;

              svg {
                path {
                  fill: $gray5;
                }
              }
            }

            .form-control {
              border: none;
              background: transparent;
              font-size: 2rem;

              @media (max-width: 991px) {
                font-size: 1.8rem;
              }

              @media (max-width: 767px) {
                font-size: 1.6rem;
              }

              &::placeholder {
                color: $gray6;
              }
            }
          }
        }
      }
    }

    .tab_fields {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width:575px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .btn_tabs {
        width: 100%;
        max-width: 30rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .btneditsize {
          height: 4rem;
          padding: 1rem 1.5rem;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.2;
          font-family: $baseFont2;

          @media (max-width: 1439px) {
            font-size: 1.4rem;
          }

          @media (max-width: 767px) {
            font-size: 1.2rem;
          }
        }
      }
    }

    .filter_btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 24rem;
      margin-left: auto;

      @media (max-width:767px) {
        max-width: 28rem;
        margin-left: 0;
      }

      @media (max-width:575px) {
        max-width: 30rem;
        margin-top: 1rem;
      }

      :global {
        .common_dropdown {
          width: 100%;
          margin-right: 1.5rem;
          max-width: 12rem;
          min-width: 8rem;

          &:last-child {
            margin-right: 0;
          }

          &.filterDrop {

            .dropdown-menu {
              &.show {
                background: white;
                min-width: 15rem;
              }
            }
          }

          .dropBtn {
            border: none;
            padding: 0;
            height: 3rem;
            line-height: 1.2;

            svg {
              margin-left: 0.5rem;
            }

            @media (max-width:991px) {
              font-size: 1.4rem;
              padding: 0.6rem 1.5rem;
              height: 3.6rem;
              width: 100%;
              max-width: 100%;

              svg {
                width: 1.4rem;
                height: 1.4rem;
              }
            }

            &.show {

              &:focus,
              &.active {
                border: none;
              }
            }
          }

        }
      }

      .list_check {
        padding: 1rem;

        li {
          .setlimitcheck {
            margin: 0 0 1rem;

            :global {
              .form-check {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                input {
                  height: 1.2rem;
                  width: 1.2rem;
                  top: -0.2rem;
                }

                .form-check-label {
                  color: $black;
                  font-size: 1.2rem;
                  font-weight: 400;
                  line-height: 1.2;
                }
              }
            }
          }

          &:last-child {
            .setlimitcheck {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .table_section {
    .artowned_table {
      :global {
        table {
          tbody {
            tr {
              td {
                padding: 2.5rem 2rem;
                border-top: 1px solid $gray707070;
                border-bottom: 1px solid $gray707070;
                min-width: 16rem;

                @media (max-width: 991px) {
                  padding: 2rem 1.5rem;
                }

                @media (max-width: 767px) {
                  padding: 1.5rem 1rem;
                }

                &:nth-child(2) {
                  min-width: 24rem;

                  @media (max-width: 991px) {
                    min-width: 20rem;
                  }
                }

                &:first-child {
                  border-left: 1px solid $gray707070;
                  min-width: 24rem;

                  @media (max-width: 991px) {
                    min-width: 20rem;
                  }
                }

                &:last-child {
                  border-right: 1px solid $gray707070;
                }

                .field_table {
                  display: flex;
                  align-items: center;

                  img {
                    margin-right: 2rem;
                    width: 7rem;
                    height: 7rem;
                    object-fit: cover;

                    @media (max-width: 991px) {
                      margin-right: 1.5rem;
                      width: 4rem;
                      height: 4rem;
                    }
                  }

                  .text_field {
                    h5 {
                      font-size: 1.4rem;
                      line-height: 1.2;
                      font-weight: bold;
                      font-family: $baseFont;
                      color: $black;
                      margin-bottom: 0.7rem;
                      white-space: normal;
                      word-break: break-word;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -moz-box-orient: vertical;
                      -ms-box-orient: vertical;
                      box-orient: vertical;
                      -webkit-line-clamp: 2;
                      -moz-line-clamp: 2;
                      -ms-line-clamp: 2;
                      line-clamp: 2;
                      overflow: hidden;

                      @media (max-width: 991px) {
                        font-size: 1.2rem;
                        margin-bottom: 0;
                      }
                    }

                    p {
                      font-size: 1.6rem;
                      font-weight: 400;
                      font-family: $baseFont;
                      color: $black4;
                    }
                  }
                }

                .text_field_all {
                  h6 {
                    font-size: 1.4rem;
                    line-height: 1.2;
                    font-weight: bold;
                    color: $black;
                    margin-bottom: 0.4rem;
                  }

                  p {
                    font-size: 1.4rem;
                    font-weight: 400;
                    color: $black;

                    &.smallText {
                      font-size: 1.2rem;
                    }
                  }
                }

                &.border_none {
                  border: none !important;
                }

                &.art_details {
                  min-width: 30.4rem;

                  @media (max-width: 991px) {
                    min-width: 25rem;
                  }
                }

                &.last_sale {
                  min-width: 24rem;
                }

                &.edition_info,
                &.offer_my {
                  min-width: 10rem;
                }

                .btn_section {
                  .btneditsize {
                    height: 3rem;
                    font-size: 1.2rem;
                    padding: 0.5rem 2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}