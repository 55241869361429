@import "../../../../Assets/_var.scss";

.kyc_section {
  &_tabvalid {
    padding: 3rem 0;

    .text_sec {
      margin-bottom: 4rem;

      p {
        font-size: 1.6rem;
        font-weight: 400;
        font-family: "Roboto";
        color: $black;
        line-height: 2.1rem;
        margin-bottom: 2rem;
      }
    }

    .form_code {
      width: 100%;
      max-width: 50.5rem;

      h6 {
        font-size: 1.6rem;
        font-weight: bold;
        font-family: "Roboto";
        color: $black;
        line-height: 2.1rem;
        margin-bottom: 3rem;
      }

      .fields_value {
        margin-bottom: 6rem;

        .label_form {
          font-size: 1.6rem;
          font-weight: 400;
          font-family: "Roboto";
          color: $black;
          line-height: 2rem;
          margin-bottom: 1.5rem;
        }

        .btnnewandre {
          display: flex;
          align-items: center;

          span {
            margin: 0 0.5rem;
            font-size: 1.6rem;
            font-weight: 400;
            font-family: "Roboto";
            color: $black;
            line-height: 2.1rem;
          }
        }
      }
    }

    .form_kyc {
      width: 100%;
      max-width: 53rem;

      @media (max-width:991px) {
        max-width: 100%;
      }

      .upload_image_sec {
        margin-top: 2rem;
      }

      .file_up {
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 15rem;
        border: 0.1rem dashed $black;
        border-radius: 1rem;
        position: relative;
        margin: 1rem 0;

        input {
          display: block !important;
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          top: 0;
          left: 0;
          cursor: pointer;
        }

        h6 {
          font-size: 1.8rem;
          font-weight: 400;
          color: $black;
          font-family: "Roboto";
          line-height: 2.4rem;
          margin: 0;

          span {
            font-weight: bold;
            text-decoration: underline;
          }
        }

        p {
          padding-inline: 2rem;
          font-size: 1.2rem;
          color: $black3;
          font-weight: 400;
          line-height: 1.2;

          span {
            text-decoration: underline;
            font-weight: bold;
          }
        }
      }
    }
  }

  .kyc_radiobtn {
    display: flex;
    align-items: center;

    h6 {
      font-size: 1.6rem;
      color: $black3;
    }

    .kcy_btninner {
      display: flex;
      align-items: center;
      margin-left: 1.9rem;
    }
  }

  .form_business {
    width: 100%;
    max-width: 25rem;
    padding: 3rem 0;

    @media (max-width:575px) {
      max-width: 32rem;
    }

  }

  .btnspace {
    width: 10rem;
    height: 4rem;
    margin-top: 3.8rem;
    font-size: 1.6rem;
    padding: 0.4rem;
  }
}

.kyctexting {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

:global {
  .SelectKyc {
    border: 1px solid $black;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    height: 3.5rem;
    margin-bottom: 2rem;

    @media (max-width:1399px) {
      margin-bottom: 1.5rem;
    }

    @media (max-width:991px) {
      margin-bottom: 1rem;
    }

    .form-label {
      display: flex;
      width: 100%;
      color: $gray969696;
      margin-bottom: 0;
      line-height: 1;
      font-size: 0.8rem;
      font-weight: 400;
    }

    .common_select_inner {
      height: 2rem;

      .react-select {
        .react-select__control {
          border: none !important;
          border-radius: 0.8rem;
          background: transparent;
          min-height: 1.6rem;
          padding: 0;
          height: 1.6rem;

          .react-select__value-container {
            padding: 0;

            .react-select__single-value,
            .react-select__input-container,
            .react-select__placeholder {
              font-size: 12px;
              margin: 0;
              padding: 0;
              color: $black3;
            }
          }

          .react-select__indicators {
            min-width: 1rem;
            height: 1.6rem;

            .react-select__indicator {
              svg {
                width: 1.5rem;
                height: 1.5rem;
              }
            }
          }
        }
      }
    }

    .error_message {
      margin-left: -1rem;
      font-size: 1rem;
    }
  }

  .common_input {
    &.border_input {
      margin-bottom: 2rem;
      border: 1px solid $black;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      height: 3.5rem;

      @media (max-width:1399px) {
        margin-bottom: 1.5rem;
      }

      @media (max-width:991px) {
        margin-bottom: 1rem;
      }

      .form-label {
        color: $gray969696;
        margin-bottom: 0;
        line-height: 1;
        font-size: 0.8rem;
        font-weight: 400;
      }

      .common_input_inner {
        .form-control {
          height: auto;
          line-height: 1.2;
          background: transparent;
          font-size: 1.2rem;
        }
      }

      .error_message {
        margin-left: -1rem;
        font-size: 1rem;
      }
    }

    &.radio_btn {
      margin-bottom: 0;
      margin-right: 2.1rem;

      &:last-child {
        margin-right: 0;
      }

      .form-check {
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 0;
        cursor: pointer;

        &-input {
          border: 1px solid $gray707070;
          width: 0.8rem;
          height: 0.8rem;
          line-height: 1;
          margin: 0;

          &:checked {
            background: $black;
            border-color: $black;
            box-shadow: none;
          }
        }

        &-label {
          font-size: 1.2rem;
          font-weight: 400;
          color: $blacklight;
          padding-left: 1rem;
          line-height: 1;
          cursor: pointer;
        }
      }
    }
  }

  .dropzone {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      object-fit: fill;
      height: 100%;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .errorUpfile {
    margin-bottom: 1.8rem;

    @media (max-width:1399px) {
      margin-bottom: 1.3rem;
    }

    @media (max-width:991px) {
      margin-bottom: 0.8rem;
    }

    .error_message {
      font-size: 1rem;
    }

    &.error_size {
      .letterBtn {
        bottom: -3.5rem;
      }
    }
  }

  .inputFilelabel {
    border: 0.1rem dashed $black;
    border-radius: 0.54rem;
    padding: 0.8rem 1rem;
    margin-bottom: 0.2rem;
    position: relative;

    .form-label {
      display: block;
      color: $black3;
      font-size: 1.2rem;
      line-height: 1;
      margin-bottom: 0.2rem;
    }

    &_innerPart {
      position: relative;
      padding-top: 0.8rem;

      input {
        display: inline-block !important;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 1;
      }

      h6 {
        font-size: 1.4rem;
        font-weight: 500;
        color: $black3;
      }

      p {
        font-size: 0.9rem;
        font-weight: 400;
        color: $gray969696;

        span {
          font-weight: bold;
          color: $black3;
          text-decoration: underline;
        }
      }
    }

    .btnInfo {
      background: transparent;
      border: none;
      padding: 0;
      position: absolute;
      top: 0.4rem;
      right: 1rem;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .letterBtn {
      background: transparent;
      border: none;
      font-size: 1rem;
      font-weight: bold;
      text-decoration: underline;
      position: absolute;
      right: 0;
      bottom: -2.2rem;
      padding: 0;
    }
  }
}

// @import '../../../../Assets/_var.scss';

// .kyc_section {
//     &_tabvalid {
//         padding: 3rem 0;

//         .text_sec {
//             margin-bottom: 4rem;

//             p {
//                 font-size: 1.6rem;
//                 font-weight: 400;
//                 font-family: 'Roboto';
//                 color: $black;
//                 line-height: 2.1rem;
//                 margin-bottom: 2rem;
//             }
//         }

//         .form_code {
//             width: 100%;
//             max-width: 50.5rem;

//             h6 {
//                 font-size: 1.6rem;
//                 font-weight: bold;
//                 font-family: 'Roboto';
//                 color: $black;
//                 line-height: 2.1rem;
//                 margin-bottom: 3rem;
//             }

//             .fields_value {
//                 margin-bottom: 6rem;

//                 .label_form {
//                     font-size: 1.6rem;
//                     font-weight: 400;
//                     font-family: 'Roboto';
//                     color: $black;
//                     line-height: 2rem;
//                     margin-bottom: 1.5rem;
//                 }

//                 .btnnewandre {
//                     display: flex;
//                     align-items: center;

//                     span {
//                         margin: 0 0.5rem;
//                         font-size: 1.6rem;
//                         font-weight: 400;
//                         font-family: 'Roboto';
//                         color: $black;
//                         line-height: 2.1rem;
//                     }
//                 }
//             }
//         }

//         .form_kyc {
//             width: 100%;
//             max-width: 61.3rem;
//             .btnspace {
//                 min-width: 16rem;
//                 min-height: 4.5rem;
//             }

//             .file_up {
//                 background: #fafafa;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 width: 100%;
//                 height: 21.5rem;
//                 border: 2px dashed #d7d7d7;
//                 border-radius: 1rem;
//                 position: relative;
//                 margin: 4rem 0;

//                 input {
//                     position: absolute;
//                     width: 100%;
//                     height: 100%;
//                     opacity: 0;
//                 }

//                 h6 {
//                     font-size: 1.8rem;
//                     font-weight: 400;
//                     color: $black;
//                     font-family: 'Roboto';
//                     line-height: 2.4rem;
//                     margin: 0;

//                     span {
//                         font-weight: bold;
//                         text-decoration: underline;
//                     }
//                 }
//             }
//         }
//     }
// }
.decline_msg {
  h6 {
    font-size: 1.6rem;
    font-weight: 600;
    color: $black;
  }

  .image_attach {
    width: 100%;
    max-width: 40rem;
    height: 30rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
}