@import '../../../Assets/_var.scss';

.landingPage {
    display: flex;
    align-items: center;
    position: relative;
    height: 100vh;
    overflow: hidden;

    @media (max-width:991px) {
        overflow: auto;
        flex-direction: column;
        overflow-x: hidden;
    }

    .leftCard {
        height: 100%;
        flex: 0 0 50%;
        background-size: 100% 100%;
        clip-path: polygon(0 0, 68% 0%, 0 189%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: ease-in-out all 0.3s;

        @media (max-width:991px) {
            flex: 0 0 100%;
            // position: unset;
            // clip-path: unset;
            // display: flex;
            // flex: 0 0 50%;
            clip-path: polygon(0 0, 198% 0%, 0 65%);
        }

        @media (max-width:575px) {
            clip-path: polygon(0 0, 212% 0%, 0 53%);
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: ease-in-out all 0.3s;

            .innerSec {
                position: relative;
                height: 100%;

                @media (max-width:991px) {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: column;
                    height: 63vh;
                }

                .videoImgsec {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    transition: ease-in-out all 0.3s;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        display: block;
                        object-position: top center;
                        opacity: 1;
                        visibility: visible;
                        transition: ease-in-out all 0.3s;
                    }

                    .videoData {
                        transition: ease-in-out all 0.3s;
                        opacity: 0;
                        visibility: hidden;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;

                        video {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            display: block;
                            object-position: top center;
                        }
                    }
                }

                .textSec {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    max-width: 60rem;
                    margin-left: 20rem;
                    padding-top: 22.6rem;

                    @media (max-width:1679px) {
                        max-width: 36rem;
                        margin-left: 10rem;
                        padding-top: 10rem;
                    }

                    @media (max-width:1199px) {
                        max-width: 26rem;
                        margin-left: 8rem;
                        padding-top: 8rem;
                    }

                    @media (max-width:991px) {
                        margin: 0 auto 0;
                        padding: 0;
                        height: 40vh;
                    }

                    .btnSize {
                        margin-top: 3.6rem;

                        @media (max-width:1679px) {
                            margin-top: 2.6rem;
                        }

                        @media (max-width:1199px) {
                            margin-top: 1.6rem;
                        }
                    }
                }
            }
        }

        &:hover {
            .innerSec {
                .videoImgsec {

                    img {
                        opacity: 0;
                        visibility: hidden;
                        // display: none;
                    }

                    .videoData {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }

    .rightCard {
        flex: 0 0 50%;
        // background: url(../../../Assets/images/LandingnaxcanvasBg.png);
        // background-position: top center;
        background-size: 100% 100%;
        background: #000;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        clip-path: polygon(100% -99%, 100% 100%, 32% 100%);
        transition: ease-in-out all 0.3s;
        overflow: hidden;

        @media (max-width:991px) {
            flex: 0 0 100%;
            // position: unset;
            // clip-path: unset;
            // align-items: center;
            // justify-content: center;
            clip-path: polygon(100% 32%, 100% 100%, -119% 100%);
        }

        @media (max-width:575px) {
            clip-path: polygon(100% 28%, 100% 100%, -189% 100%);
        }

        a {
            display: flex;
            height: 100%;
            width: 100%;
            align-items: flex-end;
            justify-content: flex-end;
            cursor: pointer;
            position: relative;
            z-index: 1;

            @media (min-width:1921px) {
                align-items: center;
            }

            &::before {
                content: '';
                position: absolute;
                top: -5rem;
                right: -4%;
                width: 100%;
                max-width: 50%;
                height: 100%;
                background: url(../../../Assets/images/SpotLight.png);
                background-repeat: no-repeat;
                background-size: contain;
                opacity: 0.6;
                transform: scale(1.7);
                z-index: -1;

                @media (min-width:1921px) {
                    max-width: 40%;
                    transform: scale(1.8);
                    right: 0;
                }

                @media (max-width:991px) {
                    top: unset;
                    bottom: -50%;
                    right: 0%;
                    width: 100%;
                    max-width: 100%;
                    transform: translateY(-10%) scale(1.6);
                }
            }

            .tableLampImg {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                z-index: -2;

                img {
                    width: 100%;
                    max-width: 60%;

                    @media (max-width:1679px) {
                        max-width: 63%;
                    }

                    @media (max-width:1399px) {
                        max-width: 65%;
                    }

                    @media (max-width:991px) {
                        max-width: 100%;
                    }
                }
            }

            @media (max-width:991px) {
                // align-items: center;
                justify-content: center;
            }

            .innerSec {
                position: relative;
                // height: 100%;
                padding-right: 25rem;

                @media (min-width:1921px) {
                    padding-right: 10%;
                    padding-top: 5%;
                }

                @media (min-width:2999px) {
                    padding-right: 13%;
                }

                @media (max-width:1679px) {
                    padding-right: 10rem;
                }

                @media (max-width:1199px) {
                    padding-right: 8rem;
                }

                @media (max-width:991px) {
                    padding-right: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 52vh;
                }

                .videoPart {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .portrait {
                        width: 12.5rem;
                        height: 21.1rem;
                        margin-right: 1rem;
                        background: url(../../../Assets/images/PortraitBg.png);
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media (max-width:1679px) {
                            width: 10.5rem;
                            height: 18.1rem;
                            margin-right: 0.7rem;
                        }

                        @media (max-width:1199px) {
                            width: 9.5rem;
                            height: 16.1rem;
                        }

                        img {
                            height: 92%;
                            width: 87%;
                            object-fit: cover;
                            display: block;
                        }

                        .videoData {
                            opacity: 0;
                            visibility: hidden;
                            height: 92%;
                            width: 87%;
                            margin: auto;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            video {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                                display: block;
                            }
                        }
                    }

                    .landscape {
                        width: 21.1rem;
                        height: 12.5rem;
                        margin-left: 1rem;
                        background: url(../../../Assets/images/LandscapeBg.png);
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media (max-width:1679px) {
                            width: 18.1rem;
                            height: 10.5rem;
                            margin-left: 0.7rem;
                        }

                        @media (max-width:1199px) {
                            width: 16.1rem;
                            height: 9.5rem;
                        }

                        img {
                            width: 92%;
                            height: 88%;
                            object-fit: cover;
                            display: block;
                        }

                        .videoData {
                            opacity: 0;
                            visibility: hidden;
                            width: 92%;
                            height: 88%;
                            margin: auto;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transition: ease-in-out all 0.3s;

                            video {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                                display: block;
                            }
                        }
                    }

                }

                .textSec {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    max-width: 60rem;
                    margin-top: 4.5rem;
                    padding-bottom: 15.8rem;

                    @media (max-width:1679px) {
                        max-width: 36rem;
                        margin-top: 3.5rem;
                        padding-bottom: 10rem;
                    }

                    @media (max-width:1199px) {
                        max-width: 26rem;
                        margin-top: 2.5rem;
                        padding-bottom: 8rem;
                    }

                    @media (max-width:991px) {
                        padding-bottom: 0;
                    }

                    .btnSize {
                        margin-top: 3.6rem;

                        @media (max-width:1679px) {
                            margin-top: 2.6rem;
                        }

                        @media (max-width:1199px) {
                            margin-top: 1.6rem;
                            padding-inline: 1.5rem;
                        }
                    }
                }
            }
        }

        &:hover {
            .innerSec {
                .videoPart {

                    // img {
                    //     display: none;
                    // }
                    .portrait,
                    .landscape {
                        .videoData {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}