@import "../../../../../Assets/_var.scss";

.userInfo {
  .userTophead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    @media (min-width: 992px) and (max-width: 1279px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1.2rem;
    }

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1.2rem;
    }

    h3 {
      font-family: $baseFont2;
    }

    .rightInfo {
      display: flex;
      align-items: center;

      @media (min-width: 992px) and (max-width: 1279px) {
        margin-top: 1rem;
      }

      @media (max-width: 575px) {
        margin-top: 1rem;
      }

      h5 {
        position: relative;
        margin-right: 2rem;
        min-width: 6.4rem;
        width: 100%;
        max-width: max-content;

        @media (max-width: 1439px) {
          margin-right: 1.5rem;
          min-width: 6rem;
        }

        span {
          position: relative;
          width: 100%;
          display: inline-block;
        }

        :global {
          .liveTag {
            &::before {
              content: "";
              position: absolute;
              right: 0;
              top: 50%;
              background: $green2;
              width: 1.4rem;
              height: 1.4rem;
              border-radius: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }

      p {
        border: 0.2rem solid $black;
        padding: 0.6rem 2rem;
        border-radius: 0.5rem;
        font-size: 1.2rem;
        font-weight: bold;

        @media (max-width: 1439px) {
          padding: 0.6rem 1rem;
        }
      }
    }
  }

  .artDetails {
    display: flex;
    align-items: center;
    border-top: 0.2rem solid $borderClr4;
    width: 100%;

    @media (min-width: 992px) and (max-width: 1199px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .artistInfo {
      flex: 0 0 25.5rem;
      width: 100%;
      max-width: 25.5rem;
      border-right: 0.2rem solid $borderClr4;
      padding: 2rem 1rem 1rem 0;

      @media (max-width: 1279px) {
        flex: 0 0 20rem;
        max-width: 20rem;
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        flex: 0 0 100%;
        max-width: 100%;
        border-right: 0;
        border-bottom: 0.2rem solid $borderClr4;
        padding: 1rem 0;
      }

      @media (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
        border-right: 0;
        border-bottom: 0.2rem solid $borderClr4;
        padding: 1rem 0;
      }

      .singlePorfile {
        display: flex;
        align-items: center;
        width: 100%;

        .imgSec {
          width: 100%;
          flex: 0 0 4rem;
          max-width: 4rem;
          height: 4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }

        .textSec {
          padding-left: 2rem;
          display: flex;
          flex-direction: column;
          width: 100%;
          flex: 0 0 calc(100% - 4rem);
          max-width: calc(100% - 4rem);

          label {
            font-size: 1.4rem;
            line-height: 1.2;
            color: #6e6e6e;
            font-weight: 400;
          }

          h6 {
            margin-top: 0.4rem;
          }
        }
      }

      .multiPorfile {
        display: flex;
        align-items: flex-start;
        width: 100%;

        label {
          font-size: 1.4rem;
          line-height: 1.2;
          color: #6e6e6e;
          font-weight: 400;
          flex: 0 0 6rem;
          width: 100%;
          max-width: 6rem;
          margin-top: 1.6rem;
        }

        .textSec {
          flex: 0 0 calc(100% - 6rem);
          width: 100%;
          max-width: calc(100% - 6rem);

          :global {
            .slick-slider {
              display: flex;
              align-items: center;
              justify-content: center;

              .slick-arrow {
                height: 1.4rem;
                display: flex !important;
                align-items: center;
                justify-content: center;
                position: relative !important;
                top: 0;
                transform: unset;

                &::before {
                  position: absolute;
                  width: 0.9rem;
                  height: 1.4rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                }

                &.slick-prev {
                  left: 0;

                  &::before {
                    content: "";
                    opacity: 1;
                    background: url(../../../../../Assets/icons/arrowLeftgreen.svg);
                    background-size: cover;
                    background-repeat: no-repeat;
                  }
                }

                &.slick-next {
                  right: 0;

                  &::before {
                    content: "";
                    opacity: 1;
                    background: url(../../../../../Assets/icons/arrowRightgreen.svg);
                    background-size: cover;
                    background-repeat: no-repeat;
                  }
                }
              }

              .slick-list {
                width: 100%;
                margin: 0 0.6rem 0 0.2rem;

                .slick-slide {
                  padding-inline: 0.7rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 5rem;
                  opacity: 0.7;

                  >div {
                    width: 100%;
                    height: 100%;
                    min-width: 3.5rem;

                    >div {
                      width: 100%;
                      height: 100%;
                      min-width: 3.5rem;
                      display: flex !important;
                      align-items: center;
                    }
                  }

                  &.slick-center {
                    opacity: 1;
                    height: 5rem;
                    width: 5rem;

                    >div {
                      width: 100%;
                      height: 100%;
                      min-width: 5rem;
                    }
                  }
                }
              }
            }
          }

          h6 {
            margin-top: 1rem;
            text-align: center;
          }
        }
      }
    }

    .seriesInfo {
      flex: 0 0 calc(100% - 36.5rem);
      width: 100%;
      max-width: calc(100% - 36.5rem);
      border-right: 0.2rem solid $borderClr4;
      padding: 2rem 1rem 1rem;
      display: flex;
      flex-direction: column;
      min-height: 7rem;

      @media (max-width: 1279px) {
        flex: 0 0 calc(100% - 31rem);
        max-width: calc(100% - 31rem);
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        flex: 0 0 100%;
        max-width: 100%;
        border-right: 0;
        border-bottom: 0.2rem solid $borderClr4;
        padding: 1rem 0;
      }

      @media (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
        border-right: 0;
        border-bottom: 0.2rem solid $borderClr4;
        padding: 1rem 0;
      }

      label {
        font-size: 1.4rem;
        line-height: 1.2;
        color: #6e6e6e;
        font-weight: 400;
      }

      h6 {
        margin-top: 0.4rem;
      }
    }

    .shareInfo {
      flex: 0 0 11rem;
      width: 100%;
      max-width: 11rem;
      padding: 2rem 0 1rem 1rem;

      @media (min-width: 992px) and (max-width: 1199px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 1rem 0;
      }

      @media (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 1rem 0;
      }

      &_list {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: -0.6rem;

        @media (max-width: 1199px) {
          justify-content: flex-start;
        }

        li {
          padding-inline: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .btnlikeshare,
          .sharebtn {
            background: transparent;
            border: none;
            width: 3.5rem;
            height: 3.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          .sharebtn {
            margin: 0;
          }
        }
      }
    }
  }
}