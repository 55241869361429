@import '../../../../Assets/_var.scss';

.offerModal {
    :global {
        .modal-dialog {
            @media (max-width: 767px) {
                padding-top: 5rem;
            }

            .modal-content {
                background-color: transparent;
                border: none;

                .modal-header,
                .modal-body {
                    padding: 0;
                }
            }
        }
    }

    &Left,
    &Right {
        flex: 0 0 50%;
        padding: 2rem;
        border-radius: 0;
        display: flex;
        flex-direction: column;

        @media (max-width: 1399px) {
            padding: 1.5rem;
        }

        @media (max-width: 991px) {
            padding: 1rem;
        }

        .bottomLine {
            margin-top: auto;
            width: 100%;
            height: 2px;
            background-color: $white;

            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    &Left {
        background-color: $white;

        .bottomLine {
            background-color: $black;
        }

        &Inner {
            width: 100%;
            height: 63.6rem;

            @media (max-width:1679px) {
                height: 60rem;
            }

            @media (max-width:991px) {
                height: 50rem;
            }

            @media (max-width:767px) {
                height: 40rem;
            }

            @media (max-width:575px) {
                height: 35rem;
            }

            img,
            video {
                box-shadow: 0 0 6.2rem #a3a3a34d;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &Right {
        color: $white;
        background-color: $black;

        h3 {
            line-height: 1.2;
            margin: 0.5rem 0;
        }

        .artistDetails {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            margin-bottom: 4.5rem;

            @media (max-width:1679px) {
                margin-bottom: 4rem;
            }

            @media (max-width:1399px) {
                margin-bottom: 3rem;
            }

            @media (max-width:1199px) {
                margin-bottom: 2.2rem;
            }

            @media (max-width:991px) {
                margin-bottom: 1.8rem;
            }

            h6 {
                font-size: 1.4rem;
                font-weight: 500;
                color: $grayC7C7C7;

                @media (max-width: 767px) {
                    font-size: 1.2rem;
                }
            }

            &Edition {
                display: flex;
                align-items: center;

                p {
                    color: $white;
                    text-transform: capitalize;
                    font-weight: bold;
                    font-size: 1.2rem;
                }

                span {
                    font-weight: 400;
                    font-size: 1.2rem;
                    margin-left: 0.8rem;
                }
            }
        }

        .buy_btn_box {
            display: flex;
            align-items: center;
            justify-content: center;

            :global {
                .select_decline {
                    border-radius: 3rem;
                    margin: 0;
                    height: 4rem;
                    margin-right: 1rem;
                    width: 100%;
                    border: 1px solid #5C5C5C;
                    padding: 0.4rem 1rem;
                    display: flex;
                    align-items: center;

                    @media (max-width:1399px) {
                        padding: 0.6rem 1.4rem;
                    }

                    .common_select_inner {
                        min-height: 100%;
                        min-width: 11rem;
                        width: 100%;

                        .react-select__control {
                            background: transparent;
                            color: $white;
                            min-height: 3rem;
                            vertical-align: middle;
                            align-items: center;

                            .react-select__value-container {

                                .react-select__placeholder,
                                .react-select__single-value {
                                    color: $white;
                                    font-weight: 400;
                                    font-size: 1.2rem;
                                }
                            }

                            .react-select__indicators {
                                height: 3rem;

                                .react-select__indicator {
                                    svg {
                                        width: 2rem;
                                        height: 2rem;
                                        fill: $white;
                                    }
                                }
                            }
                        }

                        .react-select__menu {
                            margin-top: 1.8rem;
                            border-radius: 0;

                            .react-select__menu-list {
                                padding: 0.8rem 1rem;
                                border: 2px solid $white;

                                .react-select__option {
                                    border-bottom: 1px solid #989898;
                                    padding: 0.8rem 0;
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    }
                }
            }

            .buy_btn {
                height: 4rem;
                width: 100%;
            }
        }

        .receive_payment {
            width: 100%;
            max-width: 28rem;
            margin: 2.8rem 0 4.5rem;

            @media (max-width:1679px) {
                margin: 2.2rem 0 4rem;
            }

            @media (max-width:1399px) {
                margin: 1.6rem 0 3rem;
            }

            @media (max-width:1199px) {
                margin: 1.2rem 0 2.2rem;
            }

            @media (max-width:991px) {
                margin: 1rem 0 1.8rem;
            }

            @media (max-width:767px) {
                max-width: 100%;
            }

            h6 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: $baseFont;
                color: $white;
                width: 100%;
                font-weight: bold;

                label {
                    width: 100%;
                    max-width: max-content;
                    min-width: 15rem;
                    color: $white;
                }

                span {
                    word-break: break-word;
                    color: $white;
                }
            }
        }

        .horizontal_line {
            border: none;
            height: 0.1rem;
            width: 100%;
            margin: 0;
            margin-bottom: 2.6rem;
            background: $gray707070;
            opacity: 1;
        }

        .pricelist {
            width: 100%;
            max-width: 28rem;
            padding-bottom: 2rem;

            @media (max-width:767px) {
                max-width: 100%;
            }

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0.8rem;

                &:last-child {
                    margin-bottom: 0;
                }

                &.highvalue {
                    h6 {
                        color: $white;
                        font-weight: bold;
                    }

                    span {
                        color: $white;
                    }
                }

                h6,
                label {
                    font-size: 1.2rem;
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-family: $baseFont;
                    color: $grayB5B5B5;
                    width: 100%;
                    max-width: max-content;
                    min-width: 10rem;
                }

                span {
                    word-break: break-word;
                    font-size: 1.2rem;
                    font-weight: 400;
                    color: $grayB5B5B5;
                }
            }

            p {
                font-size: 1.2rem;
                font-weight: 500;
                color: $light_gray3;
                font-family: $baseFont;
                line-height: 1;
                font-style: italic;
            }
        }
    }
}