@import '../../../../Assets/_var.scss';

.createModal {
    :global {
        .modal-header {
            padding: 0;

            .modal-title {
                display: none;
            }
        }

        .modal-body {
            padding: 5.5rem;

            @media (max-width: 1679px) {
                padding: 5rem;
            }

            @media (max-width: 1439px) {
                padding: 4rem;
            }

            @media (max-width: 1199px) {
                padding: 3rem;
            }

            @media (max-width: 767px) {
                padding: 2rem;
            }
        }
    }

    h2 {
        color: $yellow;
    }

    .subtitleText {
        font-size: 1.6rem;
        font-weight: 400;
        color: $white;
        margin: 1rem 0 2.5rem;

        @media (max-width: 991px) {
            font-size: 1.4rem;
            margin: 0.6rem 0 1rem;
        }
    }

    .createInputBox {
        margin: 0 00 4.4rem 0;

        @media (max-width: 1439px) {
            margin: 0 0 3rem 0;
        }

        @media (max-width: 1199px) {
            margin: 0 0 2rem 0;
        }
    }

    .socialLinks {
        li {
            margin-bottom: 4.4rem;

            @media (max-width: 1439px) {
                margin: 0 0 3rem 0;
            }

            @media (max-width: 1199px) {
                margin: 0 0 2rem 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

          
            :global {
                .leftIcon {

                    svg {
                        height: 3.6rem;
                        width: 3.6rem;

                        circle {
                            fill: $black;
                        }
                    }
                }
                input{
                    padding-left: 6.2rem;
                    margin-bottom: 0;
                }
            }
        }
    }

    .browse_file {
        margin: 4.4rem 0;
        border-radius: 5px;
        border: 2px dashed $white;
        padding: 4.4rem 2rem;
        width: 100%;
        text-align: center;
        color: $white;
        position: relative;


        @media (max-width: 1439px) {
            margin: 3rem 0;
        }

        @media (max-width: 1199px) {
            margin: 2rem 0;
            padding: 3rem 2rem;
        }

        input {
            cursor: pointer;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }

        p {
            font-size: 1.6rem;

            @media (max-width: 991px) {
                font-size: 1.4rem;
            }

            span {
                color: $yellow;
            }
        }
    }

    .terms_input {
        padding-bottom: 4.4rem;
        border-bottom: 2px solid $white;


        @media (max-width: 1439px) {
            padding-bottom: 3rem;
        }

        @media (max-width: 1199px) {
            padding-bottom: 2rem;
        }

        label {
            @media (max-width: 991px) {
                font-size: 1.4rem;
            }
        }

        a {
            font-weight: 700;
        }
    }
}