@import "../../../../../../Assets/_var.scss";

.table_sectionoffier {
  border: 1px solid $black;

  // height: 53rem;
  // overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $black;
  }

  :global {
    .offer_pagination {
      .pagination {
        margin: 1.5rem 0;

        li {
          .page-link {
            font-size: 1.3rem;
            width: 3rem;
            height: 3rem;
          }
        }
      }
    }
  }
}