@import '../../../../Assets/_var.scss';

.active_collection {
    @media (max-width:991px) {
        margin-top: 1rem;
    }

    // :global {
    //     .row {
    //         margin: -1.5rem;

    //         >div {
    //             padding: 1.5rem;
    //         }
    //     }
    // }

    .cardRow {
        display: flex;
        align-items: center;
        margin: -1.5rem -1.2rem;
        flex-wrap: wrap;

        .cardBox {
            width: calc(100% / 5);
            padding: 1.5rem 1.2rem;

            @media (max-width: 1679px) {
                width: calc(100% / 4);
            }

            @media (max-width: 1399px) {
                width: calc(100% / 3);
            }

            @media (max-width: 1199px) {
                width: calc(100% / 2);
            }

            // @media (max-width: 767px) {
            //     width: calc(100% / 2);
            // }

            @media (max-width: 575px) {
                width: 100%;
            }

            :global {
                .video_hover_sec {
                    position: relative;
                    height: auto;

                    &:before {
                        background-color: hsla(0, 0%, 100%, 0.6);
                        border-radius: 1.4rem;
                        content: "";
                        display: block;
                        padding-top: 122%;
                        width: 100%;
                    }

                    video {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }

                .CommonCard {
                    position: relative;
                    height: auto;

                    &:before {
                        background-color: hsla(0, 0%, 100%, 0.6);
                        border-radius: 1.4rem;
                        content: "";
                        display: block;
                        padding-top: 122%;
                        width: 100%;
                    }

                    &ContentHandle {
                        padding: 2rem 1.4rem;

                        &BottomLeft {
                            p {
                                font-size: 1rem;
                            }

                            :global {
                                .labelData {
                                    padding-bottom: 0.5rem;
                                }
                            }
                        }

                        &Top {
                            padding-bottom: 0.4rem;
                        }

                        &Bottom {
                            min-height: auto;
                        }
                    }

                    .textHandle {
                        h4 {
                            font-size: 1.4rem;
                            height: auto;
                            line-height: 1.2;
                            word-break: break-word;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -moz-box-orient: vertical;
                            -ms-box-orient: vertical;
                            box-orient: vertical;
                            -webkit-line-clamp: 2;
                            -moz-line-clamp: 2;
                            -ms-line-clamp: 2;
                            line-clamp: 2;
                            overflow: hidden;
                        }

                        p {
                            font-size: 1rem;
                        }
                    }

                    @media (max-width: 991px) {
                        h3 {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    &:hover {
                        transition: 0.3s all ease-in-out;
                        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
                        transform: scale(1.06);
                    }

                    .image_section_hover {
                        bottom: 0;
                        display: block;
                        height: 100%;
                        left: 0;
                        margin-left: auto;
                        margin-right: auto;
                        overflow: hidden;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }

                .video_hover_sec {
                    &:hover {
                        transition: 0.3s all ease-in-out;
                        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
                        transform: scale(1.06);

                        video {
                            z-index: 9;
                        }
                    }

                    .CommonCard {
                        &:hover {
                            transition: unset;
                            box-shadow: none;
                            transform: none;

                            &::before {
                                background: transparent;
                            }
                        }
                    }
                }

                .video_hover_sec {
                    &:hover {
                        transition: 0.3s all ease-in-out;
                        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
                        transform: scale(1.06);

                        >div {
                            &:first-child {
                                z-index: 11 !important;
                                transition: 0.2s all ease-in-out;
                            }
                        }

                        video {
                            z-index: 9;
                        }
                    }

                    .CommonCard {
                        &:hover {
                            transition: unset;
                            box-shadow: none;
                            transform: none;

                            &::before {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    .tablist {
        margin-top: 5rem;

        .card_colloection {
            padding: 6rem 0;
        }
    }
}