@import '../../../../../../../Assets/_var.scss';

.rafflesForm {
    display: flex;
    align-items: center;
    width: 100%;

    form {
        width: 100%;

        .timerSet {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 1rem;

            label {
                font-size: 1.8rem;
                font-weight: 400;
                color: $black;
                margin-right: 0.6rem;

                @media (max-width: 1439px) {
                    font-size: 1.6rem;
                }

                @media (max-width: 991px) {
                    font-size: 1.4rem;
                }

                @media (max-width: 767px) {
                    font-size: 1.2rem;
                }
            }
        }

        .innerForm {
            display: flex;
            align-items: center;
            border: 0.1rem solid $gray707070;
            width: 100%;
            padding: 2.7rem 3rem;
            border-radius: 0.5rem;

            @media (max-width:1679px) {
                padding: 2rem 2.4rem;
            }

            @media (max-width:1399px) {
                padding: 1.5rem 2rem 2rem;
            }

            @media (max-width:991px) {
                padding: 1rem 1.5rem 2rem;
            }

            &Leftside {
                display: flex;
                align-items: center;
                width: 100%;
                max-width: max-content;
                min-width: 15rem;

                @media (max-width: 1679px) {
                    min-width: 12rem;
                }

                @media (max-width: 1199px) {
                    min-width: 10rem;
                }

                label {
                    font-size: 1.8rem;
                    font-weight: 400;
                    color: $black;
                    margin-right: 0.4rem;

                    @media (max-width: 1439px) {
                        font-size: 1.6rem;
                    }

                    @media (max-width: 991px) {
                        font-size: 1.4rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.2rem;
                    }
                }
            }

            &Rightside {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                max-width: calc(100% - 15rem);

                @media (max-width: 1679px) {
                    max-width: calc(100% - 12rem);
                }

                @media (max-width: 1199px) {
                    max-width: calc(100% - 10rem);
                }

                .priceValue {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    max-width: max-content;
                    margin-right: 4rem;

                    @media (max-width: 1679px) {
                        margin-right: 2rem;
                    }

                    @media (max-width: 1399px) {
                        margin-right: 1.2rem;
                    }

                    span {
                        margin-left: 0.6rem;
                    }
                }

                .addqantity {
                    display: flex;
                    align-items: center;
                    margin-right: 3rem;

                    @media (max-width: 1679px) {
                        margin-right: 2rem;
                    }

                    @media (max-width: 1399px) {
                        margin-right: 1.2rem;
                    }

                    :global {
                        .checkField {
                            display: flex;
                            align-items: center;
                            margin-bottom: 0;
                            margin-right: 3.9rem;

                            @media (max-width: 1679px) {
                                margin-right: 2rem;
                            }

                            @media (max-width: 1399px) {
                                margin-right: 1.2rem;
                            }

                            .form-label {
                                font-size: 1.4rem;
                                color: #656565;
                                font-weight: 400;
                                margin-bottom: 0;
                                margin-right: 1.4rem;
                                max-width: max-content;
                            }

                            .common_input_inner {
                                .leftIcon {
                                    width: 1.2rem;
                                    left: 1rem;
                                }

                                .form-control {
                                    height: 4rem;
                                    width: 100%;
                                    max-width: 9rem;
                                    padding: 0 2.3rem;
                                    border: 0.2rem solid #C1C1C1;
                                    border-radius: 1.8rem;

                                    @media (max-width:1399px) {
                                        padding-inline: 2rem;
                                        font-size: 1.4rem;
                                    }

                                    @media (max-width:1199px) {
                                        height: 3.5rem;
                                    }
                                }

                                .rightIcon {
                                    width: 1.2rem;
                                    right: 1rem;
                                }
                            }

                            .error_message {
                                position: absolute;
                                bottom: -1.7rem;
                                right: 0;
                            }
                        }
                    }
                }

                .btnSize {
                    height: 4rem;
                    max-width: 12rem;
                    font-size: 1.6rem;
                    padding: 1rem 2rem;
                    width: 100%;

                    @media (max-width:1399px) {
                        max-width: 10rem;
                        font-size: 1.4rem;
                        padding: 0.7rem 1rem;
                    }

                    @media (max-width:1199px) {
                        height: 3.5rem;
                    }
                }
            }
        }
    }
}