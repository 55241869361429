@import '../../../Assets/_var.scss';

.commonTooltip {
    .btnSize {
        background: transparent;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        margin-left: 1rem;

        svg {
            path {
                fill: $white;
            }
        }
    }
}

.tooltip {
    --bs-tooltip-bg: #000;
    border-radius: 0.6rem;

    .tooltip-inner {
        font-size: 1.3rem;
        font-weight: 500;
        padding: 0.6rem;
    }
}