@import '../../../../Assets/_var.scss';

:global {
    .aboutSec {
        .no_recordtable {
            font-weight: 500;
            font-size: 2.2rem;
            line-height: 2.6rem;
            font-family: $baseFont2;
            color: $gray969696;
        }
    }
}