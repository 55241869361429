@import '../../../../Assets/_var.scss';

.commonSlider {
    position: relative;
    margin: 0 -1.25rem;

    :global {
        .slick-slide {
            >div {
                margin: 0 1.5rem;
            }

            .slick-track {
                min-width: 100%;
            }
        }

        .slick-arrow {
            position: absolute;
            right: 1.25rem;
            top: -9rem;
            z-index: 1;
            height: 3.3rem;
            width: 3.3rem;
            border-radius: 50%;
            border: 2px solid $black;
            color: transparent;
            font-size: 0;
            background-color: $white;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 2rem;
            transition: .3s all ease-in-out;

            &::before {
                color: transparent;
            }

            &.slick-prev {
                background-image: url(../../../../Assets/icons/rightArrow.svg);
                right: 7rem;
                transform: rotate(-180deg);
                left: unset;
            }

            &.slick-next {
                background-image: url(../../../../Assets/icons/rightArrow.svg);
                transform: unset;
            }

            &:hover {
                background-color: $yellow;
            }

            @media (max-width:1399px) {
                height: 2.8rem;
                width: 2.8rem;
                background-size: 1.8rem;
            }

            @media (max-width:991px) {
                transform: translateX(-50%);
                left: 50%;
                right: inherit !important;
                top: inherit !important;
                bottom: 1rem !important;

                &.slick-next {
                    right: inherit !important;
                    margin-left: 2.8rem !important;
                }

                &.slick-prev {
                    margin-left: -4rem !important;
                    right: inherit !important;
                }
            }
        }


    }

    @media (max-width:1679px) {

        padding-bottom: 3rem;
    }

    @media (max-width:991px) {
        &[dir="ltr"] {
            padding-bottom: 8rem;
        }

    }
}