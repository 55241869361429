@import '../../../Assets/_var.scss';

.HowItWorks {
    padding: 10rem 0;
    position: relative;

    img {
        margin: 2rem auto 0;
        display: table;
    }

    @media (max-width:1679px) {
        padding: 8rem 0;
    }

    @media (max-width:1439px) {
        padding: 7rem 0;
    }

    :global {
        .slider_How {
            .slick-arrow {
                top: unset;
                bottom: -7rem;
            }
        }
    }
}

.heroContainer {
    max-width: 100%;
    padding: 0;
}

.slider_hero {
    :global {
        .slick-arrow {
            display: none;
        }

        .slick-prev {
            left: unset;
        }

        .slick-prev,
        .slick-next {
            right: unset;
        }

        .slick-dots {
            bottom: 6rem;
            right: 10rem;
            width: 100%;
            text-align: right;

            @media (max-width:1679px) {
                right: 2rem;
            }

            @media (max-width:991px) {
                right: 1rem;
            }

            li {
                width: 1.6rem;
                height: 1.6rem;
                margin: 0 0.75rem;

                button {
                    border: none;
                    border-radius: 50%;
                    width: 1.6rem;
                    height: 1.6rem;
                    padding: 0;
                    background: $white;

                    &::before {
                        // font-size: 1.6rem;
                        // line-height: 3rem;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 1.6rem;
                        height: 1.6rem;
                        content: '•';
                        text-align: center;
                        opacity: 0;
                        background: #51bc89;
                        border-radius: 50%;
                        color: transparent;
                    }
                }

                &.slick-active {
                    button {

                        &::before {
                            content: '•';
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}