@import '../../../../../Assets/_var.scss';

.successfullyModal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                padding: 4rem 4rem 0;

                .modal-title {
                    font-size: 3rem;
                }
            }

            .modal-body {
                padding: 2rem 4rem 4rem;

                .InnerSec {
                    h3 {
                        color: white;
                        margin-bottom: 1.5rem;
                    }

                    h5 {
                        font-size: 1.8rem;
                        color: white;
                    }
                }
            }
        }
    }
}