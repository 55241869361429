@import '../../../../../Assets/_var.scss';

.searchItem {
    display: flex;
    align-items: center;

    img {
        flex-shrink: 0;
        width: 4rem;
        height: 4rem;
        margin-right: 2rem;
        border-radius: 0.5rem;
        background-color: $borderGray;

        @media (max-width: 1679px) {
            width: 3.8rem;
            height: 3.8rem;
            margin-right: 1.6rem;
        }

        @media (max-width: 1399px) {
            width: 3.3rem;
            height: 3.3rem;
            margin-right: 1.2rem;
        }

        @media (max-width: 1199px) {
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
        }

        @media (max-width: 991px) {
            width: 2.6rem;
            height: 2.6rem;
            margin-right: 0.8rem;
        }

    }

    h6 {
        font-size: 1.4rem;
        font-weight: bold;
        color: $black;
        line-height: 1;
    }

    p {
        font-size: 1.2rem;
        font-weight: 400;
        color: $gray6E6E6E  !important;
        margin-bottom: 0.7rem;
        line-height: 1;
    }
}