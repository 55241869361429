@import "../../../../Assets/_var.scss";

.trasnsaction_tab {
  :global {
    .tabTop {
      display: flex;
      align-items: center;

      .tablists {
        width: 100%;
      }

      .search_input {
        width: 100%;
        max-width: 66.5rem;
        padding-left: 2.9rem;
        top: 1.2rem;

        &_inner {
          height: 4rem;
          border-radius: 2.9rem;
          background: $white;
          padding: 0 2rem;
          border: 1px solid $gray707070;

          .search_input_icon {
            width: 1.8rem;
            height: 1.8rem;
            padding: 0;
            left: 1.7rem;

            svg {
              width: 100%;
              height: 100%;
            }
          }

          input {
            font-size: 1.4rem;
            font-weight: bold;
            color: $black;
            padding-left: 2.8rem;

            &::placeholder {
              color: $gray707070;
            }
          }
        }
      }
    }
  }

  .purchased_tab,
  .sold_tab {
    padding: 3.2rem 0;

    .searchbar {
      margin-bottom: 2.2rem;

      :global {

        form,
        .form {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .search_input {
            width: 100%;

            .search_input_inner {
              height: 5.8rem;
              border-radius: 2.9rem;
              background: $white_light3;
              padding: 0 2.8rem;

              @media (max-width: 991px) {
                height: 5rem;
              }

              @media (max-width: 767px) {
                height: 4.5rem;
              }

              .search_input_icon {
                border: none;
                left: 2rem;

                svg {
                  path {
                    fill: $gray5;
                  }
                }
              }

              .form-control {
                border: none;
                background: transparent;
                font-size: 2rem;

                @media (max-width: 991px) {
                  font-size: 1.8rem;
                }

                @media (max-width: 767px) {
                  font-size: 1.6rem;
                }

                &::placeholder {
                  color: $gray6;
                }
              }
            }
          }
        }
      }
    }

    .table_section {
      .artowned_table {
        :global {
          table {
            tbody {
              tr {
                td {
                  padding: 2.5rem 2rem;
                  border-top: 1px solid $gray707070;
                  border-bottom: 1px solid $gray707070;
                  min-width: 16rem;

                  @media (max-width: 991px) {
                    padding: 2rem 1.5rem;
                  }

                  @media (max-width: 767px) {
                    padding: 1.5rem 1rem;
                  }

                  &:nth-child(2) {
                    min-width: 24rem;

                    @media (max-width: 991px) {
                      min-width: 20rem;
                    }
                  }

                  &:first-child {
                    border-left: 1px solid $gray707070;
                    min-width: 24rem;
                    max-width: 32rem;

                    @media (max-width: 991px) {
                      min-width: 20rem;
                    }
                  }

                  &:last-child {
                    border-right: 1px solid $gray707070;
                  }

                  .field_table {
                    display: flex;
                    align-items: center;

                    img {
                      margin-right: 2rem;
                      width: 7rem;
                      height: 7rem;
                      object-fit: cover;

                      @media (max-width: 991px) {
                        margin-right: 1.5rem;
                        width: 4rem;
                        height: 4rem;
                      }
                    }

                    .text_field {
                      h5 {
                        font-size: 1.4rem;
                        line-height: 1.2;
                        font-weight: bold;
                        font-family: $baseFont;
                        color: $black;
                        margin-bottom: 0.7rem;
                        white-space: normal;
                        word-break: break-word;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -moz-box-orient: vertical;
                        -ms-box-orient: vertical;
                        box-orient: vertical;
                        -webkit-line-clamp: 2;
                        -moz-line-clamp: 2;
                        -ms-line-clamp: 2;
                        line-clamp: 2;
                        overflow: hidden;

                        @media (max-width: 991px) {
                          font-size: 1.2rem;
                          margin-bottom: 0;
                        }
                      }

                      p {
                        font-size: 1.2rem;
                        font-weight: 400;
                        font-family: $baseFont;
                        color: $black4;
                      }
                    }
                  }

                  .Secfield_table {
                    display: flex;
                    align-items: center;

                    img {
                      margin-right: 2rem;
                      width: 7rem;
                      height: 7rem;
                      object-fit: cover;
                      border-radius: 50%;

                      @media (max-width: 991px) {
                        margin-right: 1.5rem;
                        width: 4rem;
                        height: 4rem;
                      }
                    }

                  }

                  .text_field_all {
                    h6 {
                      font-size: 1.6rem;
                      line-height: 1.2;
                      font-weight: bold;
                      color: $black;
                      margin-bottom: 0.4rem;

                      &.smallText {
                        font-size: 1.4rem;
                      }
                    }

                    p {
                      font-size: 1.4rem;
                      font-weight: 400;
                      color: $black;

                      &.smallText {
                        font-size: 1.2rem;
                      }
                    }
                  }

                  .btn_section {
                    .btneditsize {
                      height: 3rem;
                      font-size: 1.2rem;
                      font-weight: bold;
                      padding: 0.5rem 2rem;
                      width: 100%;
                      max-width: max-content;
                      margin-left: 1rem;

                      &:first-child {
                        margin-left: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sold_tab {
    .table_section {
      :global {
        table {
          tbody {
            tr {
              td {
                &:nth-child(3) {
                  min-width: 24rem;

                  @media (max-width: 991px) {
                    min-width: 20rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}