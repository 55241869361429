@import '../../../Assets/_var.scss';

.createSec {
    >div {
        width: 100%;
        max-width: 120rem;
        margin: auto;

        p {
            margin-bottom: 1.6rem;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    // .applyBtn {
    //     border-color: $white;
    //     background-color: $black;
    //     color: $yellow;
    // }
    &::before {
        background-image: url(../../../Assets/images/createSecBgBlack.png)
    }
}

.NextRelease {
    border-top: 2px solid $black;
    padding: 0 0 13.5rem 0;

    @media (max-width:1679px) {
        padding: 0 0 10rem 0;
    }

    @media (max-width:1439px) {
        padding: 0 0 7rem 0;
    }

    @media (max-width:1199px) {
        padding: 0 0 6rem 0;
    }

    @media (max-width:991px) {
        padding: 0 0 4rem 0;
    }
}