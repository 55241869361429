@import "../../../../../Assets/_var.scss";

// .import_modal {
// }

.modal_wallet,
.modal_address {
  .btnaddress {
    margin-bottom: 4rem;
  }

  :global {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding: 4rem 6rem !important;
        }
      }
    }
  }
}
.noteInfo {
  display: flex;
  align-items: flex-start;
  margin: 1.5rem;
  label {
    font-size: 1.2rem;
    font-weight: bold;
    color: $black;
    text-transform: capitalize;
    width: 10rem;
    word-break: break-word;
  }
  p {
    font-size: 1.2rem;
    word-break: break-word;
    color: $red;
  }
}
.modal_Import,
.modal_wallet,
.modal_address {
  .feilds {
    text-align: center;

    img {
      width: 100%;
      margin-bottom: 3.9rem;
    }

    .btnaddress {
      color: $black;
      width: 100%;
      margin: 10px;

      &:hover {
        color: $white;
      }
    }

    .headings {
      margin-bottom: 4rem;

      h3 {
        font-size: 3rem;
        font-weight: bold;
        color: $black;
        font-family: "Raleway";
        margin-bottom: 2rem;
      }

      h5 {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 1.8rem;
          font-weight: bold;
          margin-left: 2rem;
          color: $black;
          font-family: "Roboto";
        }
      }
    }
  }

  .listwallet {
    li {
      padding-bottom: 2.4rem;

      .btnwallet {
        color: $black;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $light_gray3;
        border-radius: 4rem;
        padding: 1rem;
        width: 100%;

        p {
          color: $black;
          margin-left: 2.2rem;
          min-width: 9rem;
          font-size: 1.8rem;
          font-weight: bold;
          font-family: "Roboto";
        }
      }
    }
  }

  :global {
    .modal-dialog {
      width: 45rem;

      .modal-content {
        background-color: $white;

        .modal-header {
          padding: 0;
        }

        .modal-body {
          padding: 6rem 7rem 4.2rem;

          form {
            .common_input {
              margin-bottom: 2.3rem;

              &_inner {
                .form-control {
                  border: 2px solid $light_gray4;
                  border-radius: 0.8rem;
                  font-size: 1.6rem;
                  font-weight: 400;
                  color: $black3;
                  line-height: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
