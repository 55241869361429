@import "../../../Assets/_var.scss";

@keyframes marquee {
  0% {
    background-position: 0;
  }

  100% {
    background-position: 300rem;
  }
}

.CuratedDrops {
  position: relative;
  padding-top: 7rem;

  :global {
    h2.big {
      padding-bottom: 5rem;

      @media (max-width: 1679px) {
        padding-bottom: 3rem;
      }
    }

    .slick-slider {
      padding-bottom: 0 !important;

      .slick-list {
        padding: 5rem 0;
      }

      .slick-prev {
        top: -6rem;

        &::before {
          color: #ffffff00 !important;
        }

        @media (max-width: 991px) {
          left: 50% !important;
          bottom: -1rem !important;
        }

        @media (max-width: 575px) {
          right: unset !important;
          left: 6rem !important;
        }
      }

      .slick-next {
        top: -6rem;

        &::before {
          color: #ffffff00 !important;
        }

        @media (max-width: 991px) {
          left: 50%;
          bottom: -1rem !important;
        }

        @media (max-width: 575px) {
          right: unset !important;
          left: 6rem;
        }
      }
    }
  }

  &Bottom {
    padding-bottom: 8rem;
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: calc(100% - 7rem);
      position: absolute;
      background-image: url(../../../Assets/images/upcomingBg.jpg);
      background-size: cover;
      background-position: center;
      background-repeat: repeat-x;
      animation: marquee 110s infinite linear;
      z-index: -1;
    }

    @media (max-width: 1679px) {
      padding-bottom: 6rem;

      &::before {
        bottom: 0;
      }
    }

    @media (max-width: 1439px) {
      padding-bottom: 4rem;
    }

    &_sliderbar {
      :global {
        .slick-arrow.slick-prev {
          right: 24rem !important;
        }

        .slick-arrow.slick-next {
          right: 19.45rem !important;
        }
      }
    }

    .cardhover {
      transition: 0.3s all ease-in-out;
      width: 100%;
      height: 45rem;

      img {
        height: 100%;
        // object-fit: fill;
        object-fit: cover;
      }

      &:hover {
        transition: 0.3s all ease-in-out;
        box-shadow: 0px 0px 13px #000000e6;
        transform: scale(1.06);
      }
    }
  }

  &NeverMissDrop {
    position: relative;

    h2[class*="Head_"] {
      border-top: 0;
      font-size: 0;
      margin: 0;
      padding: 0;
      line-height: 0;
    }
  }

  .notFound {
    width: 100%;
    z-index: 1;
    position: relative;
    background-color: rgba($white, 0);
    backdrop-filter: blur(0.5rem);
    height: 20rem;
    margin-bottom: -5.5rem;
    border: 1px solid rgba($white, 0.1);

    @media (max-width: 1679px) {
      margin-bottom: -3.5rem;
    }

    @media (max-width: 1439px) {
      margin-bottom: -1.5rem;
    }

    h6 {
      color: $white;
    }
  }
}

.notFound_clr {
  h6 {
    color: $white  !important;
  }
}

.DropCalender {
  padding: 7rem 0;
  position: relative;

  h2 {
    padding-bottom: 2rem;
  }

  &Action {
    padding-bottom: 2rem;
    // text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .viewBtn {
      border: none;
      background: transparent;
      font-size: 1.8rem;
      font-weight: 600;
      color: $black;
    }

    a {
      height: 4.6rem;
      width: 4.6rem;
      border-radius: 0.5rem;
      background-color: $yellow;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 2rem;

      svg {
        width: 3rem;
        height: 3rem;

        circle,
        line,
        rect {
          fill: $white;
          stroke: $white;
        }
      }

      @media (max-width: 991px) {
        width: 3.8rem;
        height: 3.8rem;

        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    a[class*="active"] {
      background-color: $black;

      svg {

        circle,
        line,
        rect {
          fill: $yellow;
          stroke: $yellow;
        }
      }
    }
  }

  .cardhover {
    transition: 0.3s all ease-in-out;
    width: 100%;
    height: 45rem;

    img {
      height: 100%;
      // object-fit: fill;
      object-fit: cover;
    }

    &:hover {
      transition: 0.3s all ease-in-out;
      box-shadow: 0px 0px 13px #000000e6;
      transform: scale(1.06);
    }
  }

  .drop_table {
    :global {
      table {
        border-spacing: 0;
        border-collapse: collapse;
        border: 2px solid $borderClr2;

        thead {
          tr {
            th {
              background-color: $black;
              color: $white;
              padding: 2.2rem 4rem;
              font-weight: 400;
              font-size: 2rem;
            }
          }
        }

        tbody {
          tr {
            td {
              border-top: 2px solid $borderClr2;
              border-bottom: 2px solid $borderClr2;
              padding: 1.5rem 4rem;
            }

            &:first-child {
              td {
                border-top: none;
              }
            }
          }
        }
      }
    }
  }

  .notifiedBtn {
    font-size: 2rem;
    font-weight: bold;
    color: $yellow;
    padding: 1rem;
    vertical-align: middle;
    background: $black;
    border: none;
    border-radius: 30px;
    width: 100%;
    max-width: 20rem;
  }
}

.previousDrops {
  padding-bottom: 10rem;

  .headingtext {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .viewbtnprevious {
      background: $green;
      color: $white;
      height: 4.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 15.1rem;
      border-radius: 3rem;
      font-size: 1.8rem;
      font-weight: 700;
    }
  }

  >div:not(.contianer) {
    padding-bottom: 3.2rem;

    @media (max-width: 1679px) {
      padding-bottom: 5rem;
    }

    @media (max-width: 1439px) {
      padding-bottom: 5.5rem;
    }

    @media (max-width: 991px) {
      padding-bottom: 3rem;
    }
  }

  @media (max-width: 1439px) {
    padding-bottom: 7rem;
  }

  @media (max-width: 991px) {
    padding-bottom: 5rem;
  }

  .CuratedDropsBottom {
    // border: 2px solid black;

    &_sliderbar {
      :global {
        .slick-arrow.slick-prev {
          right: 24rem !important;
          top: -9rem !important;

          @media (max-width: 991px) {
            left: 50%;
            bottom: -1rem !important;
            top: unset !important;
          }

          @media (max-width: 575px) {
            right: unset !important;
            left: 6rem;
          }
        }

        .slick-arrow.slick-next {
          right: 19.45rem !important;
          top: -9rem !important;

          @media (max-width: 991px) {
            top: unset !important;
            left: 50%;
            bottom: -1rem !important;
          }

          @media (max-width: 575px) {
            right: unset !important;
            left: 6rem;
          }
        }
      }
    }
  }
}