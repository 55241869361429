@import '../../../../Assets/_var.scss';

.other_transactions {
    padding: 3.2rem 0;

    &_table {
        width: 100%;

        :global {
            table {
                border-spacing: 0;
                border-collapse: collapse;
                border: 1px solid $black;

                thead {
                    tr {
                        th {
                            background-color: $black;
                            color: $white;
                            padding: 2rem 3rem;
                            font-weight: bold;
                            font-size: 1.6rem;

                            &:last-child {
                                text-align: left;
                            }

                            &.shortDrop {
                                min-width: 16rem;
                                max-width: 16rem;
                            }

                            .dropBtn {
                                margin-left: auto;
                                font-size: 1.6rem;
                                font-weight: bold;
                                font-family: $baseFont2;
                                padding: 0 1rem;
                                background: transparent;
                                border: none;
                                color: $white;
                                height: 2.3rem;
                                width: 100%;
                                max-width: 10rem;

                                &:focus,
                                &.active {
                                    color: $white  !important;
                                    border: none !important;
                                }

                                &.show {
                                    border: none;
                                    color: $white;

                                    &:focus,
                                    &.active {
                                        color: $white  !important;
                                        border: none !important;
                                    }
                                }

                                @media (max-width: 1439px) {
                                    font-size: 1.4rem;
                                }

                                @media (max-width: 991x) {
                                    font-size: 1.2rem;
                                }

                                svg {
                                    height: 1rem;
                                    width: 1rem;

                                    path {
                                        fill: $white;
                                    }
                                }
                            }

                            .dropdown-menu {
                                min-width: 10rem;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            border-top: 1px solid $black;
                            border-bottom: none;
                            padding: 1.5rem 3rem;
                            font-size: 1.6rem;

                            &:last-child {
                                text-align: left;
                            }

                            &.artDetails {
                                min-width: 30rem;
                                max-width: 30rem;
                                width: 100%;
                            }

                            .field_table {
                                display: flex;
                                align-items: center;

                                img {
                                    margin-right: 2rem;
                                    width: 7rem;
                                    height: 7rem;
                                    object-fit: cover;

                                    @media (max-width: 991px) {
                                        margin-right: 1.5rem;
                                        width: 4rem;
                                        height: 4rem;
                                    }
                                }

                                .text_field {
                                    h5 {
                                        font-size: 1.4rem;
                                        line-height: 1.2;
                                        font-weight: bold;
                                        font-family: $baseFont;
                                        color: $black;
                                        margin-bottom: 0.7rem;
                                        white-space: normal;
                                        word-break: break-word;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -moz-box-orient: vertical;
                                        -ms-box-orient: vertical;
                                        box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                        -moz-line-clamp: 2;
                                        -ms-line-clamp: 2;
                                        line-clamp: 2;
                                        overflow: hidden;

                                        @media (max-width: 991px) {
                                            font-size: 1.2rem;
                                            margin-bottom: 0;
                                        }
                                    }

                                    p {
                                        font-size: 1.2rem;
                                        font-weight: 400;
                                        font-family: $baseFont;
                                        color: $black4;
                                    }
                                }
                            }

                            .transfer_table {
                                display: flex;
                                align-items: center;

                                img {
                                    width: 4.5rem;
                                    height: 4.5rem;
                                    border-radius: 50%;
                                    margin-right: 1.3rem;
                                    object-fit: cover;
                                }

                                h5 {
                                    font-size: 1.6rem;
                                    color: $black;
                                    font-weight: bold;
                                    font-family: 'Roboto';
                                }
                            }
                        }

                        &:first-child {
                            td {
                                border-top: none;
                            }
                        }
                    }
                }
            }
        }
    }
}