@import "../../../../../Assets/_var.scss";

.common_input {
  position: relative;
  margin: 0 0 2.5rem 0;

  .form-label {
    margin-bottom: 0;
    line-height: 1.2;
    display: block;
  }

  &_inner {
    position: relative;

    .timerstart {
      position: absolute;
      top: 5rem;
      right: 1.2rem;
      font-size: 1.4rem;
      font-weight: 600;
      color: #51bc89;

      @media (max-width: 1279px) {
        top: 4.2rem;
      }
    }

    // height: 54px;
    .leftIcon {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;

      &.btn {
        cursor: pointer;
      }
    }

    .rightIcon,
    .btnStyle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      background-color: transparent;

      &.btn {
        cursor: pointer;
      }

      @media (max-width: 1279px) {
        width: 2rem;
      }

      @media (max-width: 991px) {
        right: 12px;

        svg {
          max-width: 15px;
        }
      }

      &.black {
        svg {

          path,
          polygon {
            fill: $black;
          }
        }
      }

      &.cursor-pointer {
        cursor: pointer;
        height: calc(100% - 0.5rem);
        width: 7.5rem;
        right: 2px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        // &:hover {
        //     background-color: $gray;
        // }
      }
    }

    .btnStyle {
      background: transparent;
      border: none;
      padding: 0;
    }

    input,
    textarea {
      height: 4.7rem;
      width: 100%;
      background-color: $white;
      border-radius: 0.53rem;
      border: 0;
      padding: 1.1rem 1.5rem;
      font-size: 1.6rem;
      border: 2px solid $black;
      color: $black;

      // color: rgba($white, 0.8);
      &:focus,
      &:focus-visible,
      &:hover {
        background-color: $white;
        color: $black;
        border: 2px solid $green;
        box-shadow: none;
        outline: none;
      }

      &::placeholder {
        color: rgba($black, 0.8);
      }

      &::-webkit-input-placeholder {
        color: rgba($black, 0.8);
      }

      &:-moz-placeholder {
        color: rgba($black, 0.8);
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $white inset;
        border: 2px solid $black;
        color: $black;
      }

      &.fieldBlack {
        background-color: $black700;
        border-color: rgba($white, 0.1);
        color: $white;

        &:focus,
        &:focus-visible,
        &:hover {
          background-color: $black700;
          color: $white;
          border-color: $yellow;
          outline: none;
        }

        &::placeholder {
          color: rgba($white, 0.8);
        }

        &::-webkit-input-placeholder {
          color: rgba($white, 0.8);
        }

        &:-moz-placeholder {
          color: rgba($white, 0.8);
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0px 1000px $black700 inset;
          color: $white;
          -webkit-text-fill-color: $white;
        }
      }

      @media (max-width: 1279px) {
        height: 4.2rem;
        font-size: 1.4rem;
      }

      &.input_border {
        border: 2px solid #d7d7d7;

        &:focus,
        &:focus-visible,
        &:hover {
          background-color: $white;
          color: $black;
          border: 2px solid $green;
          box-shadow: none;
          outline: none;
        }

        &:disabled {
          background: transparent;
          cursor: not-allowed;
          color: rgba($black, 0.3);

          &:hover {
            background: transparent;
            border: 2px solid #d7d7d7;
          }
        }
      }
    }

    &.rightIconInput {
      input {
        padding-right: 7.5rem;
      }
    }

    &.leftIconInput {
      input {
        padding-left: 45px;
      }
    }
  }

  input[type="checkbox"] {
    height: 2rem;
    width: 2rem;
    position: relative;
    top: -2px;
    border-radius: 0;

    +label {
      color: $white;
      vertical-align: middle;
      padding-left: 0.5rem;

      a {
        color: $white;

        &:hover {
          color: $green;
        }
      }
    }
  }

  &.input_number {
    .form-label {
      color: #000;
      font-size: 1.6rem !important;
      font-family: "Roboto";
      margin-bottom: 1.2rem;
      font-weight: 400;
    }

    .common_input_inner {
      .PhoneInput {
        height: 5rem;
        border: 2px solid #d7d7d7;
        border-radius: 0.8rem;
        padding: 1rem 2.4rem;

        &.phone_bgwhite {
          background: $white;
        }

        .PhoneInputInput {
          padding: 0;
          height: 2.5rem;
          margin-left: 1rem;
          border: none;
          line-height: 2rem;
        }
      }
    }
  }

  &.border_input {
    border: 2px solid #d7d7d7;
    border-radius: 0.8rem;
    margin: 0 0 1.9rem;
    padding: 1rem 2.4rem;
    height: 6rem;

    .error_message {
      margin-top: 10px;
    }

    .form-label {
      color: #d3d3d3;
      margin-bottom: 0;
      line-height: 1.4rem;
      display: block;
    }

    .common_input_inner {
      .form-control {
        padding: 0;
        height: 2.5rem;
        border: none;
        line-height: 2rem;

        &:disabled {
          background: transparent;
          opacity: 0.3;
          cursor: not-allowed;
        }
      }
    }
  }

  &.togglt_btn {
    .form-switch {
      display: flex;
      align-items: center;

      .form-check-input {
        width: 5rem;
        height: 2.5rem;
        border-radius: 2rem;

        &:focus {
          border-color: #51bc89;
          box-shadow: none;
          background-image: url(../../../../../Assets/icons/toggleIcon.svg);
        }

        &:checked {
          border-color: #51bc89;
          background-color: #51bc89;
          background-image: url(../../../../../Assets/icons/toggleactiveicom.svg);
        }
      }

      .form-check-label {
        color: $black;
        font-size: 1.6rem;
        font-weight: 600;
        font-family: "Roboto";
        margin: 0;
      }
    }
  }

  &.checkInfoBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .commonTooltip {
      .btnSize {
        svg {
          path {
            fill: $black;
          }
        }
      }
    }
  }
}

.phone_border {
  .PhoneInputInput {
    border: 2px solid #d7d7d7;
  }
}

.text_valida {
  font-size: 1.4rem !important;
  margin: 1.3rem 0 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400 !important;
  color: $black;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
}