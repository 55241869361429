@import '../../../../../Assets/_var.scss';

.cashoutHistory {
    padding-top: 2rem;

    :global {
        .tablists {
            margin-bottom: 1rem;
        }
    }
}