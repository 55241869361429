@import '../../../Assets/_var.scss';

.news_about {
    padding: 5rem 0;

    :global {
        .card_withouthover {
            margin-bottom: 3rem;
        }
    }

    &_Titles {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2.5rem;

        .headingtitle {
            h2 {
                @media (max-width: 479px) {
                    font-size: 2.2rem;
                }
            }
        }

        .btnfilter {
            display: flex;
            align-items: center;
            justify-content: space-between;


            @media (max-width: 991px) {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}