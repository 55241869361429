@import "../../../../Assets/_var.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black;
  transition: 0.3s all ease-in-out;
  height: auto;
  width: 100%;
  z-index: 999;

  &_navbar {
    padding: 1.5rem 0;

    @media (max-width: 1679px) {
      padding: 1rem 0;
    }
  }

  &_left {
    position: relative;
  }

  &_logo {
    height: 100%;
    display: block;

    img {
      width: 25.5rem;
      height: 5rem;

      @media (max-width: 1679px) {
        width: 20rem;
        height: 4.5rem;
      }

      @media (max-width: 991px) {
        width: 16rem;
        height: 3.5rem;
      }

      @media (max-width: 359px) {
        max-width: 12rem;
        height: 3rem;
      }
    }
  }
}
