@import '../../../../../Assets/_var.scss';


.request_edit {
    margin-top: 3rem;

    .request_table {
        :global {
            .table-responsive {
                border-radius: 0.8rem;
                border: 0.2rem solid $borderClr4;

                table {
                    border-spacing: 0;
                    border-collapse: collapse;
                    border: none;

                    thead {
                        tr {
                            th {
                                background-color: $black;
                                color: $white;
                                padding: 1.5rem 2.5rem;
                                font-size: 1.2rem;
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                border-top: 0.2rem solid $borderClr2;
                                border-bottom: 0.2rem solid $borderClr2;
                                border-left: 0;
                                border-right: 0;
                                padding: 1rem 2.5rem;
                                font-size: 1.2rem;
                                font-weight: 400;
                                color: $black;

                                &.dateSec {
                                    width: 17rem;
                                    max-width: 17rem;
                                    min-width: 17rem;
                                }

                                &.timeSec {
                                    width: 11rem;
                                    min-width: 11rem;
                                    max-width: 11rem;
                                }

                                &.activitySec {
                                    width: 14rem;
                                    min-width: 14rem;
                                    max-width: 14rem;
                                }

                                &.description {
                                    width: 50rem;
                                    max-width: 50rem;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }

                                .btn_size {
                                    font-size: 1.2rem;
                                    height: 3rem;
                                    padding: 0.8rem 1.8rem;
                                }
                            }

                            &:first-child {
                                td {
                                    border-top: none;
                                }
                            }

                            &:last-child {
                                td {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}