@import '../../../../Assets/_var.scss';

.privacy_settings {
    .privacy_form {
        padding: 3rem 0;
        width: 100%;
        max-width: 32rem;

        .input_check {
            position: relative;
            // margin-bottom: 5rem;

            // @media (max-width:1199px) {
            //     margin-bottom: 4rem;
            // }

            // @media (max-width:991px) {
            //     margin-bottom: 3rem;
            // }

            // @media (max-width:767px) {
            //     margin-bottom: 2rem;
            // }

            // &:last-child {
            //     margin-bottom: 0;
            // }
        }

        :global {
            form {
                width: 100%;

                .error_message {
                    font-size: 1.1rem;
                    font-weight: 400;
                    margin-top: 0.4rem;
                }

                .checkbox_input {
                    position: absolute;
                    top: 0.3rem;
                    right: 0;
                    margin-bottom: 0;
                    z-index: 9;

                    input {
                        border: 1px solid;
                        border-radius: 0.2rem;
                        height: 1rem;
                        width: 1rem;
                        margin: 0;
                        top: 0;

                        &:focus {
                            box-shadow: none;
                        }

                        &:checked {
                            background-color: $black;
                        }
                    }

                    .form-check-label {
                        font-size: 1.2rem;
                        font-weight: 400;
                        line-height: 1;
                        color: $black;
                    }
                }

                .input_spaces {
                    .form-label {
                        color: $black3;
                        font-size: 1.4rem !important;
                        margin-bottom: 1rem;
                        font-weight: bold;
                        line-height: 1.2;
                    }

                    .form-control {
                        border: 1px solid $black;
                        border-radius: 0.5rem;
                        padding: 0.5rem 1rem;
                        height: 3.5rem;
                        background: transparent;
                        font-size: 1.2rem;
                    }

                    .leftIconInput {
                        .form-control {
                            padding-left: 5.5rem;
                        }

                        span.leftIcon {
                            svg {

                                circle,
                                rect {
                                    fill: $green;
                                }

                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }

                    .leftIconInput {
                        .leftIcon {
                            left: 1rem;
                            width: 1.6rem;
                            height: 1.6rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .form-control {
                            padding-left: 3.5rem;
                        }
                    }
                }

                .input_number {
                    .form-label {
                        color: $black3;
                        font-size: 1.4rem !important;
                        margin-bottom: 1rem;
                        font-weight: bold;
                        line-height: 1.2;
                    }

                    .phone_border {
                        border: 1px solid $black  !important;
                        border-radius: 0.5rem !important;
                        padding: 0.5rem 1rem !important;
                        height: 3.5rem !important;
                        background: transparent;
                        font-size: 1.2rem !important;

                        .PhoneInputInput {
                            font-size: 1.2rem !important;
                        }
                    }
                }

                .quill {
                    border-radius: 0.5rem;
                    border: 0.1rem solid $black;

                    .ql-toolbar.ql-snow {
                        border-bottom: 0.1rem solid $black;
                    }

                    .ql-container.ql-snow {
                        .ql-editor {
                            padding: 1rem;
                        }
                    }
                }
            }
        }

        .input_checkBio {
            position: relative;
            margin-bottom: 2.5rem;

            :global {
                .checkbox_input {
                    position: relative;
                    text-align: right;
                }
            }

            .bio_text {
                margin-bottom: 2.5rem;

                span {
                    margin-top: 0.5rem;
                    text-align: end;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    font-size: 1.4rem;
                    font-weight: 400;
                    color: $gray;
                }
            }
        }

        .btnsave {
            margin-top: 5rem;

            @media (max-width:1679px) {
                margin-top: 4.5rem;
            }

            @media (max-width:1199px) {
                margin-top: 3rem;
            }

            @media (max-width:767px) {
                margin-top: 2rem;
            }

            .btnsize {
                min-width: 15rem;
                min-height: 4rem;
                font-size: 1.6rem;

                @media (max-width:1679px) {
                    font-size: 1.4rem;
                }

                @media (max-width:991px) {
                    font-size: 1.2rem;
                    height: 3.5rem;
                    min-width: 13rem;
                }
            }
        }
    }
}