@import "../../../Assets/_var.scss";

:global {
  .feature_Modal {
    .modal-dialog {
      max-width: 150rem;

      .modal-content {
        .modal-header {
          padding: 0;
        }

        .modal-body {
          padding: 3rem;
        }
      }
    }
  }
}

.feature_Modal_body {
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    max-height: 70rem;
    object-fit: contain;

    @media (max-width:991px) {
      height: 67rem;
      margin: auto;
    }

    @media (max-width:767px) {
      height: 57rem;
    }
  }
}