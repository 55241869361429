@import '../../../Assets/_var.scss';

.not_recard {
    display: flex;
    align-items: center;
    justify-content: center;

    h6 {
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 2.6rem;
        font-family: "Raleway", sans-serif;
        color: $black;
    }

    &.textWhite {
        position: relative;
        z-index: 9;
        min-height: 8rem;
        align-items: flex-start;

        h6 {
            color: $white  !important;
        }
    }
}