@import '../../../Assets/_var.scss';

.ContactUsBanner {
    padding: 12.6rem 0;
    text-align: center;
    background-color: $yellow;

    @media (max-width: 1679px) {
        padding: 10rem 0;
    }

    @media (max-width: 1439px) {
        padding: 8rem 0;
    }

    @media (max-width: 991px) {
        padding: 6rem 0;
    }
}

.ContactUs {
    padding-bottom: 3rem;

    &Inner {
        @media (min-width: 768px) {
            border-top: 2px solid $black;
        }

        &Left {


            h2 {
                margin-top: 2.4rem;
            }

            .subTitleText {
                margin: 1.6rem 0 3rem;

                @media (max-width: 1439px) {
                    margin: 1rem 0 2.4rem;
                }

                @media (max-width: 991px) {
                    margin: 1rem 0 2rem;
                }
            }

            .inputBox {
                margin-bottom: 4.4rem;

                @media (max-width: 1439px) {
                    margin-bottom: 3rem;
                }

                @media (max-width: 991px) {
                    margin-bottom: 2rem;
                }
            }
        }

        .btn_sizing {
            max-width: 18rem;
            width: 100%;
        }
    }

    &Details {
        @media (min-width: 768px) {
            text-align: right;
            border-left: 2px solid $black;
        }


        &Box {
            border-bottom: 2px solid $black;
            padding: 2.6rem 0;

            h2 {
                color: $black;
            }

            @media (max-width: 1439px) {
                padding: 2rem 0;
            }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }

            .socialLink {
                margin-left: 1.5rem;

                svg {
                    width: 5.4rem;
                    height: 5.4rem;

                    circle {
                        fill: $green;
                        transition: 300ms ease-in-out;
                    }
                }

                @media (max-width: 1199px) {
                    margin-left: 8px;

                    &:first-child {
                        margin-left: 0;
                    }

                    svg {
                        width: 4rem;
                        height: 4rem;
                    }
                }

                @media (max-width: 991px) {
                    svg {
                        width: 3.2rem;
                        height: 3.2rem;
                    }
                }

                &:hover {
                    svg {
                        circle {
                            fill: $yellow;
                        }
                    }
                }
            }

            .subDetailText {
                margin-top: 2.6rem;
                a {
                    color: $black;
                }

                @media (max-width: 1439px) {
                    margin-top: 2rem;
                }

                @media (max-width: 991px) {
                    margin-top: 1rem;
                    font-size: 1.4rem;
                }
            }
        }
    }
}