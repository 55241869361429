@import "../../../Assets/_var.scss";

.Droprankedauction_ended,
.Droprankedauction_comingsoon,
.Droprankedauction_live {
  padding: 10.4rem 0;

  @media (max-width: 1199px) {
    padding: 7.4rem 0;
  }

  @media (max-width: 991px) {
    padding: 5.4rem 0;
  }

  .artstab_containt {
    p {
      font-family: "Roboto", sans-serif;
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 400;
      color: $black3;
      margin-bottom: 4.5rem;

      @media (max-width: 1199px) {
        margin-bottom: 2.5rem;
      }

      @media (max-width: 991px) {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }

      &:nth-child(2) {
        margin-bottom: 0;
      }
    }

    .about_artlist {
      margin-top: 3.9rem;

      @media (max-width: 1199px) {
        margin-top: 1.9rem;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding-bottom: 1.6rem;

        &:last-child {
          padding-bottom: 0;
        }

        @media (max-width: 1199px) {
          padding-bottom: 1.2rem;
        }

        label {
          width: 100%;
          max-width: 15rem;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 2.2rem;
          color: $blacklight;
          font-family: "Roboto", sans-serif;

          @media (max-width: 1199px) {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }

        h6 {
          font-size: 1.8rem;
          font-weight: 400;
          color: $black2;
          line-height: 2.2rem;
          padding-left: 0.6rem;
          font-family: "Roboto", sans-serif;

          @media (max-width: 1199px) {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }
      }
    }

    .makeoffer {
      margin-top: 3.5rem;

      @media (max-width: 991px) {
        margin-top: 2rem;
      }
    }
  }

  .bid_activity {
    display: flex;
    align-items: flex-start;

    .bid_rank {
      flex: 0 0 30%;
      background-color: $white_light2;
      border-radius: 0.5rem;

      @media (min-width: 992px) and (max-width: 1199px) {
        display: none;
      }

      @media (max-width: 767px) {
        margin-bottom: 1rem;
        width: 100%;
        max-width: 32rem;
      }

      &_userinfo {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        background-color: $white;
        border: 2px solid $gray;
        padding: 2.4rem 0;

        h6 {
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 2rem;
          margin-bottom: 1.5rem;
          font-family: "Roboto", sans-serif;

          @media (max-width: 1439px) {}
        }

        .userIcon {
          width: 13.6rem;
          height: 13.6rem;
          border-radius: 50%;
          overflow: hidden;

          @media (max-width: 1439px) {
            width: 10.6rem;
            height: 10.6rem;
          }
        }
      }

      &_userprice {
        padding: 1.5rem 0 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h5 {
          font-size: 1.8rem;
          font-weight: 400;
          color: $black;
          line-height: 2rem;
          margin-bottom: 1rem;
          font-family: "Roboto", sans-serif;
        }

        h4 {
          font-size: 3rem;
          font-weight: bold;
          color: $black;
          line-height: 3rem;
          margin-bottom: 1.2rem;
          font-family: "Roboto", sans-serif;

          @media (max-width: 1439px) {
            font-size: 2.4rem;
            line-height: 2.4rem;
          }
        }

        p {
          color: $dark_gray;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.8rem;
          font-family: "Roboto", sans-serif;
        }
      }
    }

    .bid_table {
      flex: 0 0 70%;
      padding-left: 2.5rem;

      @media (max-width: 1679px) {
        width: 70%;
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        width: 100%;
        padding-left: 0;
        flex: 0 0 100%;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding-left: 0;
        flex: 0 0 100%;
      }

      .bidder_table {
        :global {
          table {
            border-spacing: 0;
            border-collapse: collapse;

            thead {
              tr {
                th {
                  background-color: $black;
                  color: $white;
                  padding: 1.2rem 2.5rem;
                  font-weight: 400;
                  font-size: 1.4rem;
                  font-family: "Roboto", sans-serif;
                  text-align: center;

                  &:first-child {
                    border-radius: 5px 0px 0px 0px;
                  }

                  &:last-child {
                    border-radius: 0px 5px 0px 0px;
                    text-align: left;
                  }
                }
              }
            }

            tbody {
              tr {
                border-bottom: 2px solid $borderClr5;

                td {
                  border: none;
                  padding: 1.2rem 2.5rem;
                  font-size: 1.4rem;
                  font-weight: bold;
                  font-family: "Roboto", sans-serif;
                  color: $black;
                  text-align: center;

                  .thumb {
                    border-radius: 50%;
                  }

                  h4 {
                    font-size: 1.4rem;
                    font-weight: bold;
                    font-family: "Roboto", sans-serif;
                    color: $black;
                  }

                  &:last-child {
                    text-align: left;
                  }
                }

                &:first-child {
                  display: none;

                  @media (min-width: 992px) and (max-width: 1199px) {
                    display: table-row;
                  }
                }

                &:last-child {
                  border: none;
                }
              }
            }
          }
        }
      }

      .showvalues {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 4rem;

        .showicons {
          display: flex;
          align-items: center;
          margin-right: 1.5rem;

          label {
            font-size: 1.8rem !important;
            font-weight: bold;
            color: $black;
            margin-bottom: 0;
            margin-right: 1.6rem;
            font-family: "Roboto", sans-serif;
          }
        }

        :global {
          .common_select_inner {
            min-width: 8rem;
            height: 4rem;

            .react-select {
              &__control {
                border-radius: 1.2rem;
                border: 1px solid;

                .react-select__indicators {
                  .react-select__indicator {
                    padding: 0;
                    padding-right: 0.5rem;
                  }
                }
              }
            }
          }
        }

        .btnArrow {
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            padding-right: 1rem;

            &:last-child {
              padding-right: 0;
            }

            button {
              background: transparent;
              border: 2px solid $black;
              border-radius: 50%;
              height: 3.1rem;
              width: 3.1rem;
              padding: 0;

              &:hover {
                background: $yellow;
              }

              &.leftbtn {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }

  &_leftsec {
    width: 100%;
    height: 100%;

    &__imageart {
      cursor: pointer;
      width: 100%;
      height: 100%;
      max-width: 67rem;
      max-height: 74rem;

      @media (max-width:991px) {
        height: 67rem;
        margin: auto;
      }

      @media (max-width:767px) {
        height: 57rem;
      }

      img {
        width: 100%;
        height: 100%;
        max-height: 74rem;
        box-shadow: 0px 0px 30px $boxshadow;

        @media (max-width:991px) {
          height: 67rem;
          margin: auto;
        }

        @media (max-width:767px) {
          height: 57rem;
        }
      }

      :global {
        .image_section_hover {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          height: 100%;

          .thumnail_img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            display: block;
          }

          .featureimg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: none;
          }

          &:hover {
            .thumnail_img {
              display: none;
            }

            .featureimg {
              display: block;
            }
          }

          &::after {
            content: "";
            height: 100%;
            z-index: 0;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: -moz-linear-gradient(top,
                rgba(0, 0, 0, 0.1) 30%,
                rgba(0, 0, 0, 0.8) 100%);
            background: -webkit-linear-gradient(top,
                rgba(0, 0, 0, 0.1) 30%,
                rgba(0, 0, 0, 0.8) 100%);
            background: linear-gradient(to bottom,
                rgba(0, 0, 0, 0.1) 30%,
                rgba(0, 0, 0, 0.8) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
            // z-index: -1;
            // transition: .5s all ease-in-out;
          }
        }

        .video_hover_sec_second {
          width: 100%;
          height: 100%;

          >div {
            z-index: 1 !important;
          }

          video {
            display: block;
            width: 100%;
            object-fit: contain !important;
            height: 100%;
            background: $black;
          }
        }
      }
    }

    &__listing {
      display: flex;
      align-items: center;
      margin-top: 2.9rem;
      flex-wrap: wrap;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
        white-space: normal;
        margin-bottom: 0.8rem;
        height: auto;
        word-break: break-word;
        margin-right: 1rem;
        max-width: 100%;
      }
    }
  }

  &_rightsec {
    @media (max-width: 1679px) {
      padding-left: 8.3rem;
    }

    @media (max-width: 1439px) {
      padding-left: 4.3rem;
    }

    @media (max-width: 1199px) {
      padding-left: 2.3rem;
    }

    @media (max-width: 991px) {
      padding-left: 0;
      margin-top: 3rem;
    }

    &_auction {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 3rem;

      @media (max-width: 767px) {
        margin-bottom: 2rem;
        flex-wrap: wrap;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.7rem 1.5rem 0.5rem;
        min-width: 10rem;
        height: 3.1rem;
        background: $white_light;
        border-radius: 0.5rem;
        margin-right: 2rem;
        font-size: 1.6rem;
        font-weight: 400;
        color: $black;
        line-height: 2rem;
        font-family: "Roboto", sans-serif;

        @media (max-width: 1199px) {
          min-width: 9rem;
          margin-right: 1rem;
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }

      h6 {
        font-size: 1.6rem;
        font-weight: bold;
        margin-right: 2rem;
        margin-bottom: 0;
        color: $black;
        line-height: 2rem;
        font-family: "Roboto", sans-serif;

        @media (max-width: 1199px) {
          margin-right: 1rem;
          font-size: 1.4rem;
          line-height: 1.8rem;
        }

        @media (max-width: 331px) {
          margin-top: 1rem;
        }
      }

      .textlive {
        font-size: 1.8rem;
        font-weight: bold;
        margin: 0;
        color: $black;
        line-height: 2rem;
        text-transform: uppercase;
        font-family: "Roboto", sans-serif;
        display: flex;
        align-items: center;

        span {
          width: 1.3rem;
          height: 1.3rem;
          background-color: $green;
          border-radius: 50%;
          margin-left: 1rem;
        }

        @media (max-width: 1199px) {
          margin-right: 1rem;
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }

      .notifiedBtn {
        font-size: 1.4rem;
        font-weight: bold;
        color: $yellow;
        padding: 0.5rem 1rem;
        vertical-align: middle;
        background: $black;
        border: none;
        border-radius: 3rem;
        width: 100%;
        max-width: 13rem;
        height: 3.7rem;
        font-family: "Roboto", sans-serif;

        @media (max-width: 457px) {
          margin-top: 1rem;
        }
      }
    }

    &_headtext {
      margin-bottom: 1.2rem;

      h2 {
        font-size: 4.8rem;
        font-weight: bold;
        line-height: 5.6rem;
        color: $black;
        margin-bottom: 1.8rem;
        font-family: "Roboto", sans-serif;

        @media (max-width: 1439px) {
          font-size: 3.8rem;
          line-height: 4.6rem;
        }

        @media (max-width: 1199px) {
          font-size: 3.2rem;
          line-height: 4rem;
        }

        @media (max-width: 767px) {
          font-size: 2.6rem;
          line-height: 3.4rem;
        }
      }

      p {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.4rem;
        color: $black2;
        font-family: "Roboto", sans-serif;

        @media (max-width: 991px) {
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }
    }

    &_creatorInfo {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      border-top: 2px solid $gray;

      @media (max-width: 479px) {
        flex-direction: column;
        border-top: 1px solid $gray;
      }

      .creator_details {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-right: 2px solid $gray;
        flex: 0 0 23%;
        padding: 1.8rem;

        @media (max-width: 1439px) {
          padding: 1.2rem;
        }

        @media (max-width: 1199px) {
          padding: 1rem;
        }

        @media (max-width: 479px) {
          flex: 0 0 100%;
          border-bottom: 1px solid $gray;
          border-right: none;
          width: 100%;
        }

        &_userImage {
          width: 4.9rem;
          height: 4.9rem;
          margin-right: 2rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }

          @media (max-width: 1199px) {
            width: 3.9rem;
            height: 3.9rem;
            margin-right: 1rem;
          }
        }

        &_texts {
          label {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.8rem;
            color: $gray3;
            margin-bottom: 0.4rem;
            font-family: "Roboto", sans-serif;

            @media (max-width: 1199px) {
              font-size: 1.4rem;
            }
          }

          h6 {
            color: $black;
            font-size: 2rem;
            font-weight: bold;
            line-height: 2.4rem;
            font-family: "Roboto", sans-serif;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            max-width: 13rem;

            @media (max-width: 1199px) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
        }
      }

      .series_details {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-right: 2px solid $gray;
        flex: 0 0 60%;
        padding: 1.8rem;

        @media (max-width: 1679px) {
          flex: 0 0 52%;
        }

        @media (max-width: 1439px) {
          flex: 0 0 50%;
          padding: 1.2rem;
        }

        @media (max-width: 1199px) {
          flex: 0 0 44%;
          padding: 1rem;
        }

        @media (max-width: 479px) {
          flex: 0 0 100%;
          border-bottom: 1px solid $gray;
          border-right: none;
          width: 100%;
        }

        &_userImage {
          width: 4.9rem;
          height: 4.9rem;
          margin-right: 2rem;

          @media (max-width: 1199px) {
            width: 3.9rem;
            height: 3.9rem;
            margin-right: 1rem;
          }
        }

        &_texts {
          label {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.8rem;
            color: $gray3;
            margin-bottom: 0.4rem;
            font-family: "Roboto", sans-serif;

            @media (max-width: 1199px) {
              font-size: 1.4rem;
            }
          }

          h6 {
            color: $black;
            font-size: 2rem;
            font-weight: bold;
            line-height: 2.4rem;
            font-family: "Roboto", sans-serif;

            @media (max-width: 1199px) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
        }
      }

      .shareIcon {
        flex: 0 0 17%;

        @media (max-width: 479px) {
          flex: 0 0 100%;
          width: 100%;
        }

        &_list {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 1.8rem;

          @media (max-width: 1199px) {
            padding-left: 0.5rem;
          }

          @media (max-width: 479px) {
            border-bottom: 1px solid $gray;
            border-right: none;
          }

          li {
            padding: 1.8rem 0.5rem;

            button {
              display: block;
              width: 3.6rem;
              height: 3.6rem;
              background: transparent;
              border: none;
              padding: 0;

              :global {
                .ShareBtn {
                  width: 100%;
                  height: 100%;
                }
              }

              @media (max-width: 1199px) {
                width: 3rem;
                height: 3rem;
              }

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    &_activitydetails,
    &_tabpartlist {
      :global {
        .tab-content {
          padding: 3rem 0;
        }
      }

      .data_activity {
        .activity_table {
          :global {
            table {
              border-spacing: 0;
              border-collapse: collapse;

              thead {
                tr {
                  th {
                    background-color: $black;
                    color: $white;
                    padding: 1.2rem 2.5rem;
                    font-weight: 400;
                    font-size: 1.4rem;
                    font-family: "Roboto", sans-serif;
                    text-align: center;

                    &:first-child {
                      border-radius: 5px 0px 0px 0px;
                    }

                    &:last-child {
                      border-radius: 0px 5px 0px 0px;
                      text-align: left;
                    }
                  }
                }
              }

              tbody {
                tr {
                  border-bottom: 2px solid $borderClr5;

                  td {
                    border: none;
                    padding: 1.2rem 2.5rem;
                    font-size: 1.4rem;
                    font-weight: bold;
                    font-family: "Roboto", sans-serif;
                    color: $black;
                    text-align: center;

                    .thumb {
                      border-radius: 50%;
                    }

                    h4 {
                      font-size: 1.4rem;
                      font-weight: bold;
                      font-family: "Roboto", sans-serif;
                      color: $black;
                    }

                    &:last-child {
                      text-align: left;
                    }
                  }

                  &:first-child {
                    @media (min-width: 992px) and (max-width: 1199px) {
                      display: table-row;
                    }
                  }

                  &:last-child {
                    border: none;
                  }
                }
              }
            }
          }

          .notifiedBtn {
            font-size: 1.6rem;
            font-weight: bold;
            color: $yellow;
            padding: 0.5rem 1rem;
            vertical-align: middle;
            background: $black;
            border: none;
            border-radius: 3rem;
            width: 100%;
            max-width: 13rem;
            height: 3.7rem;
            font-family: "Roboto", sans-serif;

            @media (max-width: 991px) {
              font-size: 1.4rem;
            }
          }

          .borderBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3rem;
            width: 100%;
            max-width: 13rem;
            height: 3.7rem;
            border: 2px solid $black;
            padding: 0.5rem 1rem;
            margin-left: 2rem;
            font-size: 1.6rem;
            font-weight: bold;
            font-family: "Roboto", sans-serif;
            cursor: pointer;
            background: $white;
          }
        }

        .showvalues {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 4rem;

          .showicons {
            display: flex;
            align-items: center;
            margin-right: 1.5rem;

            label {
              font-size: 1.8rem !important;
              font-weight: bold;
              color: $black;
              margin-bottom: 0;
              margin-right: 1.6rem;
              font-family: "Roboto", sans-serif;
            }
          }

          :global {
            .common_select_inner {
              min-width: 8rem;
              height: 4rem;

              .react-select {
                &__control {
                  border-radius: 1.2rem;
                  border: 1px solid;

                  .react-select__indicators {
                    .react-select__indicator {
                      padding: 0;
                      padding-right: 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .btnArrow {
            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
              padding-right: 1rem;

              &:last-child {
                padding-right: 0;
              }

              button {
                background: transparent;
                border: 2px solid $black;
                border-radius: 50%;
                height: 3.1rem;
                width: 3.1rem;
                padding: 0;

                &:hover {
                  background: $yellow;
                }

                &.leftbtn {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }
}