@import '../../../Assets/_var.scss';

.HowItWorks {
    padding: 10rem 0;
    position: relative;

    img {
        margin: 2rem auto 0;
        display: table;
    }

    h4 {
        margin: 4rem 0 2rem;
    }

    @media (max-width:1679px) {
        padding: 8rem 0;
    }

    @media (max-width:1439px) {
        padding: 7rem 0;

        h4 {
            margin: 2rem 0;
        }
    }

    @media (max-width: 991px) {
        paddign: 6rem 0;

        h4 {
            margin: 1rem 0;
        }
    }
}


.BenefitsSec {
    padding: 10rem 0;

    :global {
        .row {
            position: relative;
        }
    }

    .imagesSlider {
        @media (min-width: 992px) {

            :global {
                position: static;

                .slick-arrow {
                    left: 6rem;
                    right: unset;
                    top: unset;
                    bottom: -2rem;

                    &.slick-prev {
                        left: 1.5rem;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .BenefitsSecImg {
            img {
                margin: 0 auto;
            }
        }
    }

    @media (max-width:1679px) {
        padding: 8rem 0;

        h2 {
            img {
                max-width: 26rem;
            }
        }

    }

    @media (max-width:1439px) {
        padding: 7rem 0;

    }

    @media (min-width: 768px) and (max-width: 1279px) {
        h2 {
            font-size: 6rem;
        }
    }

    @media (max-width: 767px) {
        h2 {
            img {
                max-width: 16rem;
                margin-left: 0.6rem;
            }
        }
    }

    @media (max-width: 991px) {
        paddign: 6rem 0;
    }

    &Content {
        margin-top: 2rem;

        @media (min-width: 1680px) {
            padding-right: 14rem;
        }

        @media (max-width: 767px) {
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }

            @media (max-width: 991px) {
                margin-bottom: 1rem;
            }
        }
    }
}