@import '../../../../Assets/_var.scss';

.notification_settings {
    .alerts_notification {
        padding: 3rem 0;

        .btn_notificat {
            margin-bottom: 4.6rem;

            @media (max-width:1679px) {
                margin-bottom: 4rem;
            }

            @media (max-width:1399px) {
                margin-bottom: 3.2rem;
            }

            @media (max-width:1199px) {
                margin-bottom: 2.8rem;
            }

            @media (max-width:991px) {
                margin-bottom: 2rem;
            }

        }

        .form_alerts {
            display: flex;
            // align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;

            .check_list {
                margin-bottom: 3.6rem;
                flex: 0 0 50%;

                @media (max-width:1679px) {
                    margin-bottom: 3rem;
                }

                @media (max-width:1399px) {
                    margin-bottom: 2.6rem;
                }

                @media (max-width:1199px) {
                    margin-bottom: 2rem;
                }

                @media (max-width:991px) {
                    margin-bottom: 1.6rem;
                }

                @media (max-width:767px) {
                    margin-bottom: 1rem;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                h6 {
                    font-size: 1.4rem;
                    font-weight: bold;
                    line-height: 1.2;
                    margin-bottom: 1.8rem;
                }
            }
        }
    }

    .btnsize {
        margin-top: 1.4rem;
        min-width: 16rem;
        height: 4rem;
        padding: 0.6rem 1rem;
        font-size: 1.6rem;
        font-weight: bold;
        font-family: $baseFont;

        @media (max-width:1679px) {
            font-size: 1.4rem;
        }

        @media (max-width:991px) {
            min-width: 14rem;
            margin-top: 1rem;
            font-size: 1.2rem;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    :global {
        .checkbox_input {
            margin-bottom: 1rem;

            .form-check {
                display: flex;
                align-items: center;

                .form-check-input {
                    border: 0.1rem solid $black313131;
                    border-radius: 0;
                    height: 1rem;
                    width: 1rem;

                    &:checked {
                        background-color: $black313131;
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }

                .form-check-label {
                    font-size: 1.2rem;
                    font-weight: 400;
                    color: $black3;
                    padding-left: 1.3rem;
                    line-height: 1.1;
                }
            }
        }
    }
}