@import '../../../../Assets/_var.scss';

.add_changeart {
    margin-top: 2rem;
    @media (max-width:991px) {
    }

    .card_art {
        display: inline-block;
        margin-bottom: 16px;
        position: relative;
        height: 41.8rem;
        width: 100%;

        &::before {
            border-radius: 5px;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.2);
        }

        img,video {
            width: 100%;
            border-radius: 5px;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        &__body {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding: 30px 30px;
            color: #fff;
            display: flex;
            flex-direction: column;

            .artist_name {
                font-size: 2.4rem;
                line-height: 1.2;
                font-family: "Roboto", sans-serif;
                font-weight: bold;
            }

            .links_userId {
                font-size: 1.6rem;
                line-height: 1.2;
                font-family: "Roboto", sans-serif;
                font-weight: 400;
            }

            .amount_Buy {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 1.5rem;
                padding-top: 1.5rem;
                border-top: 1px solid $white;

                .buy_select_srt {
                    background-color: $white;
                    color: $black;
                    border-color: $white;
                    height: 3.8rem;
                    font-size: 1.6rem;
                    font-family: $baseFont;
                    padding: 1rem 2rem;
                    font-weight: 500;

                    &:hover {
                        background-color: $yellow;
                        color: $black;
                        border-color: $yellow;
                    }
                }

            }
        }
    }
}