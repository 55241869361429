@import '../../../../../Assets/_var.scss';

.artDetails {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 3.6rem;

    @media (min-width:992px) and (max-width:1279px) {
        flex-direction: column;
        margin-top: 2rem;
    }

    @media (max-width:575px) {
        flex-direction: column;
        margin-top: 1rem;
    }

    &Leftside {
        flex: 0 0 25.5rem;
        width: 100%;
        max-width: 25.5rem;

        @media (min-width:992px) and (max-width:1279px) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 0.6rem;
        }

        @media (max-width:991px) {
            flex: 0 0 20rem;
            max-width: 20rem;
        }

        @media (max-width:575px) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 0.6rem;
        }

        .artList {
            @media (min-width:992px) and (max-width:1279px) {
                display: flex;
                flex-wrap: wrap;
            }

            @media (max-width:575px) {
                display: flex;
                flex-wrap: wrap;
            }

            li {
                display: flex;
                align-items: center;
                padding: 0.6rem 0;

                @media (min-width:992px) and (max-width:1279px) {
                    flex: 0 0 calc(100% / 2);
                    width: 100%;
                    max-width: calc(100% / 2);
                }

                @media (max-width:575px) {
                    flex: 0 0 calc(100% / 2);
                    width: 100%;
                    max-width: calc(100% / 2);
                }

                @media (max-width:439px) {
                    flex: 0 0 100%;
                    width: 100%;
                    max-width: 100%;
                }

                label {
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: #191919;
                    line-height: 1.2;
                    min-width: 11.4rem;
                    font-family: $baseFont;
                    padding-right: 1.7rem;

                    @media (max-width:1199px) {
                        font-size: 1.2rem;
                        min-width: 9.5rem;
                        padding-right: 1.2rem;
                    }
                }

                p {
                    color: #161616;

                    @media (max-width:1199px) {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    &Rightside {
        border-left: 0.2rem solid #D4D4D4;
        flex: 0 0 calc(100% - 25.5rem);
        width: 100%;
        max-width: calc(100% - 25.5rem);
        padding-left: 4.3rem;

        @media (max-width:1679px) {
            padding-left: 3rem;
        }

        @media (min-width:992px) and (max-width:1279px) {
            max-width: 100%;
            flex: 0 0 100%;
            border-left: 0;
            border-top: 0.2rem solid #D4D4D4;
            padding-top: 0.6rem;
            padding-left: 0;
        }

        @media (max-width:991px) {
            flex: 0 0 calc(100% - 20rem);
            max-width: calc(100% - 20rem);
            padding-left: 2rem;
        }

        @media (max-width:575px) {
            max-width: 100%;
            flex: 0 0 100%;
            border-left: 0;
            border-top: 0.2rem solid #D4D4D4;
            padding-top: 0.6rem;
            padding-left: 0;
        }

        :global {
            .tablists {
                .nav-item {
                    .nav-link {
                        padding: 0.6rem 1.5rem;
                        line-height: 1.3;
                    }
                }
            }

            .tab-content {
                margin-top: 2.1rem;
            }
        }

        .tabArt {
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;
            min-height: 8rem;
            height: 100%;
            max-height: 23.5rem;
            padding-right: 0.7rem;

            &::-webkit-scrollbar {
                width: 0.8rem;
                height: 3px;
                background: #D7D7D7;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #363636;
                width: 0.8rem;
            }

            p {
                margin-bottom: 2rem;
                color: #020302;
                width: 100%;
                max-width: 66.5rem;
                line-height: 2rem;
            }
        }
    }
}