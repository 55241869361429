@import '../../../../../Assets/_var.scss';

.topsection {
    width: 100%;
    height: 100%;
    background: url(../../../../../Assets/images/flauntTopBg.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 10rem;

    @media (max-width:1679px) {
        padding-top: 8rem;
    }

    @media (max-width:1399px) {
        padding-top: 7rem;
    }

    @media (max-width:1199px) {
        padding-top: 5.5rem;
    }

    @media (max-width:991px) {
        padding-top: 4rem;
    }

    @media (max-width:767px) {
        padding-top: 4rem;
    }

    .textSec {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 100rem;
        margin-inline: auto;

        @media (max-width:1439px) {
            max-width: 94rem;
            padding-inline: 2rem;
        }

        @media (max-width:991px) {
            max-width: 81.8rem;
            padding-inline: 1.5rem;
        }

        h1 {
            font-family: $baseFont2;
            text-align: center;
        }

        h5 {
            font-weight: 400;
            color: $black;
            text-align: center;
            margin-top: 4rem;
            line-height: 2.7rem;

            @media (max-width:1679px) {
                margin-bottom: 3rem;
                line-height: 2.2rem;
            }

            @media (max-width:1399px) {
                margin-bottom: 2.4rem;
            }

            @media (max-width:1199px) {
                margin-bottom: 2rem;
            }

            @media (max-width:991px) {
                margin-top: 1.5rem;
                line-height: 2rem;
            }
        }
    }
}