@import "../../../Assets/_var.scss";

.check_out {
  padding: 10.4rem 0;

  @media (max-width: 1199px) {
    padding: 7.4rem 0;
  }

  @media (max-width: 991px) {
    padding: 5.4rem 0;
  }

  &_artistdetails {
    display: flex;
    align-items: flex-start;
    border-right: 3px solid #dddddd;

    @media (max-width: 991px) {
      border-bottom: 3px solid #dddddd;
      border-right: none;
      padding-bottom: 2rem;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .imgart {
      width: 100%;
      max-width: 50.6rem;
      height: 68.6rem;

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: 767px) {
        max-width: 100%;
        height: 100%;
        margin-bottom: 2rem;
      }
    }

    &_list {
      margin-left: 7rem;
      width: 100%;
      max-width: 30rem;

      @media (max-width: 1199px) {
        margin-left: 4rem;
      }

      @media (max-width: 767px) {
        margin-left: 0;
      }

      li {
        display: flex;
        align-items: center;
        padding-bottom: 1.5rem;

        @media (max-width: 767px) {
          padding-bottom: 1rem;
        }

        label {
          font-size: 1.8rem;
          line-height: 2.4rem;
          font-weight: bold;
          color: $blacklight;
          width: 100%;
          max-width: 11.5rem;
          font-family: "Roboto", sans-serif;

          @media (max-width: 767px) {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }

        h6 {
          font-size: 1.8rem;
          line-height: 2.4rem;
          font-family: "Roboto", sans-serif;
          color: $black2;
          width: 100%;
          font-weight: 400;
          margin-left: 0.5rem;
          max-width: 17.6rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          @media (max-width: 767px) {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }
      }
    }
  }

  &_pricedetails {
    @media (max-width: 991px) {
      padding-top: 2rem;
    }

    &_list {
      padding-left: 4rem;
      width: 100%;

      @media (max-width: 991px) {
        padding-left: 0;
      }

      li {
        padding-bottom: 2rem;
        width: 100%;

        @media (max-width: 767px) {
          padding-bottom: 1rem;
        }

        &:last-child {
          padding-bottom: 0;
        }

        .coupen_info {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 30rem;

          .couponAdd {
            margin-bottom: 0.5rem;
            width: 100%;
          }

          :global {
            .commonBtn {
              height: 1.8rem;
              font-size: 1.4rem;
            }
          }
        }

        .form {
          display: flex;
          align-items: center;

          label {
            font-size: 1.8rem;
            line-height: 2.4rem;
            font-weight: bold;
            color: $blacklight;
            width: 100%;
            max-width: 15.6rem;
            font-family: "Roboto", sans-serif;

            @media (max-width: 767px) {
              font-size: 1.6rem;
              line-height: 2rem;
            }
          }

          h6 {
            font-size: 1.8rem;
            line-height: 2.4rem;
            font-family: "Roboto", sans-serif;
            color: $black2;
            width: 100%;
            font-weight: 400;
            margin-left: 0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (max-width: 767px) {
              font-size: 1.6rem;
              line-height: 2rem;
            }
          }

          :global {
            .common_input {
              margin: 0;
              // padding-left: 0.5rem;

              &.checkField {
                max-width: 15.6rem;
              }

              &_inner {
                .leftIcon {
                  left: 1rem;
                  width: 2rem;
                }

                #number {
                  padding: 1rem 3rem 1rem 3.5rem;
                  border-radius: 3rem;
                  border: 1px solid $borderClr6;
                }

                #gstvalue {
                  border: 1px solid $borderClr6;
                  border-radius: 3rem;
                }

                .rightIcon {
                  right: 1rem;
                  width: 2rem;
                }
              }

              &.checkselect {
                width: 100%;
                max-width: 15.6rem;

                .form-check {
                  .form-check-label {
                    color: $borderClr2;
                    font-size: 1.4rem;
                    font-weight: 400;
                    font-family: "Roboto", sans-serif;
                  }
                }
              }
            }

            .common_select {
              width: 100%;
              // max-width: 25rem;

              &_inner {
                width: 100%;

                .react-select {
                  &__control {
                    border: 1px solid $borderClr6;
                  }
                }
              }
            }
          }

          &.form_gstinfo {
            align-items: flex-start;
            flex-direction: column;

            .gst_details {
              .select {
                margin-bottom: 1.5rem;

                :global {
                  .common_select_inner {
                    .react-select {
                      .react-select__control {
                        .react-select__placeholder {
                          color: $black;
                        }
                      }
                    }
                  }
                }
              }

              .checkField {
                border: 1px solid $borderClr6;
                border-radius: 3rem;
              }

              :global {

                .input_border,
                .phone_border {
                  border-radius: 3rem !important;
                }
              }
            }

            :global {
              .common_input {
                margin-bottom: 1.5rem;

                &.checkselect {
                  max-width: 100%;

                  .form-check-label {
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }

        :global {
          .radio_btn {
            span {
              margin-left: 1.5rem;
            }
          }

          .spacesme4 {
            margin-right: 0.4rem;
          }
        }
      }
    }

    .paymentmode {
      padding-left: 4rem;
      margin-top: 4rem;

      @media (max-width: 991px) {
        padding-left: 0;
      }

      :global {
        .common_select {
          width: 100%;
          max-width: 25rem;
          margin-bottom: 2rem;

          &_inner {
            width: 100%;

            .react-select {
              &__control {
                border: 1px solid $borderClr6;
              }
            }
          }
        }
      }

      .selectaccount {
        display: flex;
        align-items: flex-start;

        input {
          margin-right: 2.5rem;
        }

        .textline {
          h5 {
            font-size: 1.8rem;
            font-family: "Roboto", sans-serif;
            font-weight: bold;
            line-height: 2.4rem;
            color: $blacklight;
            margin-bottom: 0.6rem;

            @media (max-width: 767px) {
              font-size: 1.6rem;
              line-height: 2rem;
            }
          }

          p {
            font-size: 1.8rem;
            font-family: "Roboto", sans-serif;
            font-weight: 400;
            line-height: 2.4rem;
            color: $black2;

            @media (max-width: 767px) {
              font-size: 1.6rem;
              line-height: 2rem;
            }
          }
        }
      }

      .paymentBtn {
        margin-top: 3rem;
      }
    }
  }
}