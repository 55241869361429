@import '../../../../../Assets/_var.scss';

.authrityModal {
    .modal-dialog {
        max-width: 75rem;

        .modal-header,
        .modal-body {
            padding-inline: 3rem;
        }
    }

    .innerPart {
        background: $white;
        padding: 2rem;
        border-radius: 1rem;

        .topheadings {
            margin-bottom: 1rem;

            h6 {
                font-size: 1.6rem;
                font-weight: 600;
                line-height: 1.2;
                margin-bottom: 0.5rem;
                color: $black;
            }

            p {
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1.2;
                margin-bottom: 0.5rem;
                color: $black;
            }
        }

        .mailSubject {
            margin-bottom: 1rem;

            h6 {
                font-size: 1.6rem;
                font-weight: 600;
                line-height: 1.2;
                margin-bottom: 0.5rem;
                color: $black;

                span {
                    color: $black;
                }
            }
        }

        .meinInner {
            margin-bottom: 1.5rem;

            p {
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1.3;
                margin-bottom: 1rem;
                color: $black;

                span {
                    font-weight: 600;
                    color: $black;
                }
            }

            .userInfo {
                margin: 1rem 0;

                .listing {
                    padding-left: 2rem;
                    margin-top: 1rem;

                    li {
                        list-style: disc;
                        color: $black;

                        h6 {
                            font-size: 1.6rem;
                            font-weight: 400;
                            line-height: 1.2;
                            margin-bottom: 0.5rem;
                            color: $black;

                            span {
                                font-weight: 600;
                                color: $black;
                            }
                        }
                    }
                }
            }
        }

        .footerData {
            p {
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1.2;
                margin-bottom: 0.5rem;
                color: $black;
            }
        }
    }
}