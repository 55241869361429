@import "../../../../Assets/_var.scss";

.BrowseMarketplace {
   padding-top: 10rem;

   h2 {
      text-align: center;
      // margin-bottom: 4.6rem;
   }

   @media (max-width: 1679px) {
      padding-top: 8rem;
   }

   @media (max-width: 1439px) {
      padding-top: 7rem;
   }

   @media (max-width: 991px) {
      padding-top: 4rem;
   }

   :global {
      .video_hover_sec {
         position: relative;
         height: auto;

         &:before {
            background-color: hsla(0, 0%, 100%, 0.6);
            border-radius: 1.4rem;
            content: "";
            display: block;
            padding-top: 122%;
            width: 100%;
         }

         video {
            position: absolute;
            left: 0;
            top: 0;
         }
      }

      .CommonCard {
         position: relative;
         height: auto;

         &:before {
            background-color: hsla(0, 0%, 100%, 0.6);
            border-radius: 1.4rem;
            content: "";
            display: block;
            padding-top: 122%;
            width: 100%;
         }

         @media (max-width: 991px) {
            h3 {
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
            }
         }

         &:hover {
            transition: 0.3s all ease-in-out;
            box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
            transform: scale(1.06);
         }

         .image_section_hover {
            bottom: 0;
            display: block;
            height: 100%;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;
         }
      }

      .video_hover_sec {
         &:hover {
            transition: 0.3s all ease-in-out;
            box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
            transform: scale(1.06);

            video {
               z-index: 9;
            }
         }

         .CommonCard {
            &:hover {
               transition: unset;
               box-shadow: none;
               transform: none;

               &::before {
                  background: transparent;
               }
            }
         }
      }

      .video_hover_sec {
         &:hover {
            transition: 0.3s all ease-in-out;
            box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
            transform: scale(1.06);

            >div {
               &:first-child {
                  z-index: 11 !important;
                  transition: 0.2s all ease-in-out;
               }
            }

            video {
               z-index: 9;
            }
         }

         .CommonCard {
            &:hover {
               transition: unset;
               box-shadow: none;
               transform: none;

               &::before {
                  background: transparent;
               }
            }
         }
      }

      .slick-slider {
         padding-bottom: 0 !important;

         .slick-list {
            padding: 5rem 0;

            @media (max-width: 575px) {
               padding-bottom: 8rem;
            }
         }

         .slick-prev {
            top: unset;
            left: unset;
            bottom: -4rem !important;
            right: 5.6rem !important;

            @media (max-width: 575px) {
               bottom: 3rem !important;
               left: 50%;
               right: unset;
               margin-left: -3rem !important;
            }

            &::before {
               color: #ffffff00 !important;
            }
         }

         .slick-next {
            top: unset;
            left: unset;
            bottom: -4rem !important;
            right: 1.5rem !important;

            @media (max-width: 575px) {
               bottom: 3rem !important;
               left: 50%;
               right: unset;
               margin-left: 1.8rem !important;
            }

            &::before {
               color: #ffffff00 !important;
            }
         }
      }
   }

   .cardhover {
      transition: 0.3s all ease-in-out;

      &:hover {
         transition: 0.3s all ease-in-out;
         box-shadow: 0px 0px 13px #000000e6;
         transform: scale(1.06);
      }
   }

   .actionsBtn {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 359px) {
         flex-direction: column;
      }

      .btnSize {
         margin: 0 1.5rem;
         font-family: $baseFont;
         padding-inline: 2rem;
         text-decoration: unset;

         @media (max-width: 991px) {
            margin: 0 1rem;
            padding-inline: 1rem;
         }

         @media (max-width: 479px) {
            margin: 0 0.4rem;
            font-size: 1.2rem;
         }

         @media (max-width: 359px) {
            margin: 0.6rem 0;

            &:first-child {
               margin-top: 0;
            }

            &:last-child {
               margin-bottom: 0;
            }
         }

         &:first-child {
            margin-left: 0;
         }

         &:last-child {
            margin-right: 0;
            padding: 0 0.3rem;
         }
      }
   }
}