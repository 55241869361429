@import "../../../Assets/_var.scss";

.dropaction_ended,
.dropaction_comingsoon,
.dropaction_live {
  padding: 10.4rem 0;

  @media (max-width: 1199px) {
    padding: 7.4rem 0;
  }

  @media (max-width: 991px) {
    padding: 5.4rem 0;
  }

  .art_tabcont {
    padding-top: 3rem;
  }

  .artstab_containt {
    padding: 3rem 0;

    p {
      font-family: $baseFont;
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 400;
      color: $black3;
      margin-bottom: 4.5rem;
      word-break: break-word;

      @media (max-width: 1199px) {
        margin-bottom: 2.5rem;
      }

      @media (max-width: 991px) {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }

      &:nth-child(2) {
        margin-bottom: 0;
      }
    }

    .about_artlist {
      margin-top: 3.9rem;

      @media (max-width: 1199px) {
        margin-top: 1.9rem;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding-bottom: 1.6rem;

        &:last-child {
          padding-bottom: 0;
        }

        @media (max-width: 1199px) {
          padding-bottom: 1.2rem;
        }

        label {
          width: 100%;
          // max-width: 15rem;
          max-width: 18rem;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 2.2rem;
          color: $blacklight;
          font-family: $baseFont;

          @media (max-width: 1199px) {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }

        h6 {
          font-size: 1.8rem;
          font-weight: 400;
          color: $black2;
          line-height: 2.2rem;
          padding-left: 0.6rem;
          font-family: $baseFont;

          @media (max-width: 1199px) {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }
      }
    }
  }

  .bid_activity {
    display: flex;
    align-items: flex-start;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    .bid_rank {
      flex: 0 0 30%;
      background-color: $white_light2;
      border-radius: 0.5rem;

      @media (min-width: 992px) and (max-width: 1199px) {
        display: none;
      }

      @media (max-width: 767px) {
        margin-bottom: 1rem;
        width: 100%;
        max-width: 32rem;
      }

      &_userinfo {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        background-color: $white;
        border: 2px solid $gray;
        padding: 2.4rem 0;

        h6 {
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 2rem;
          margin-bottom: 1.5rem;
          font-family: $baseFont;

          @media (max-width: 1439px) {}
        }

        .userIcon {
          width: 13.6rem;
          height: 13.6rem;
          border-radius: 50%;
          overflow: hidden;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            border-radius: inherit;
            object-fit: cover;
          }

          @media (max-width: 1439px) {
            width: 10.6rem;
            height: 10.6rem;
          }
        }
      }

      &_userprice {
        padding: 1.5rem 0 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h5 {
          font-size: 1.8rem;
          font-weight: 400;
          color: $black;
          line-height: 2rem;
          margin-bottom: 1rem;
          font-family: $baseFont;
        }

        h4 {
          font-size: 3rem;
          font-weight: bold;
          color: $black;
          line-height: 3rem;
          margin-bottom: 1.2rem;
          font-family: $baseFont;

          @media (max-width: 1439px) {
            font-size: 2.4rem;
            line-height: 2.4rem;
          }
        }

        p {
          color: $dark_gray;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.8rem;
          font-family: $baseFont;
        }
      }
    }

    &.bidRanked {
      @media (min-width: 992px) and (max-width: 1199px) {
        flex-direction: column;
        align-items: center;

        .bid_rank {
          display: block !important;
          margin-bottom: 1rem;
          width: 100%;
          max-width: 32rem;
        }
      }
    }

    :global {
      .table_border {
        border: 2px solid $borderClr2;
        border-radius: 0.6rem;
        padding: 0 !important;
        margin-left: 2rem;

        .commonTable {
          .table-responsive {
            height: 38.5rem;
            border: none !important;
          }
        }
      }
    }

    .ranked_table {
      flex: 0 0 68% !important;
      width: 68% !important;
      margin-left: 2rem;

      @media (max-width: 1679px) {
        margin-left: 1.2rem;
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        width: 100% !important;
        flex: 0 0 100% !important;
        margin-left: 0 !important;
      }

      @media (max-width: 767px) {
        width: 100% !important;
        flex: 0 0 100% !important;
        margin-left: 0 !important;
      }
    }

    .tableNotdata {
      margin-left: 0;
    }

    .bid_table {
      flex: 0 0 100%;
      padding-left: 0;

      @media (max-width: 1679px) {
        width: 100%;
      }

      .bidder_table {
        :global {
          .table-responsive {
            border: 2px solid $black;
            border-radius: 0.6rem;
          }

          table {
            border-spacing: 0;
            border-collapse: collapse;

            thead {
              tr {
                th {
                  background-color: $black;
                  color: $white;
                  padding: 1.5rem 2.5rem;
                  font-weight: 400;
                  font-size: 1.4rem;
                  font-family: $baseFont;
                  text-align: center;

                  &:first-child {
                    border-radius: 5px 0px 0px 0px;
                  }

                  &:last-child {
                    border-radius: 0px 5px 0px 0px;
                  }
                }
              }
            }

            tbody {
              tr {
                border-bottom: 2px solid $borderClr5;

                td {
                  border: none;
                  padding: 1.5rem 2.5rem;
                  font-size: 1.4rem;
                  font-weight: bold;
                  font-family: $baseFont;
                  color: $black;
                  text-align: center;

                  .thumb {
                    border-radius: 50%;
                  }

                  h4 {
                    font-size: 1.4rem;
                    font-weight: bold;
                    font-family: $baseFont;
                    color: $black;
                  }
                }

                // &:first-child {
                //   display: none;

                //   @media (min-width: 992px) and (max-width: 1199px) {
                //     display: table-row;
                //   }
                // }

                &:last-child {
                  border: none;
                }
              }
            }
          }
        }
      }
    }

    .data_show_bid {
      flex: 0 0 70%;
      padding-left: 2rem;

      @media (max-width: 1679px) {
        width: 100%;
      }
    }
  }

  &_leftsec {
    width: 100%;
    height: 100%;
    display: inline-block;

    @media (max-width:991px) {
      max-width: 100%;
      margin: auto;
    }

    &__imageart {
      cursor: pointer;
      width: 100%;
      height: 100%;
      max-width: 67rem;
      max-height: 74rem;
      min-height: 74rem;

      @media (max-width:991px) {
        height: 67rem;
        margin: auto;
        max-height: auto;
        min-height: auto;
      }

      @media (max-width:767px) {
        height: 57rem;
      }

      img {
        width: 100%;
        height: 100%;
        max-height: 74rem;
        box-shadow: 0px 0px 30px $boxshadow;

        @media (max-width:991px) {
          height: 67rem;
          margin: auto;
        }

        @media (max-width:767px) {
          height: 57rem;
        }
      }

      :global {

        .image_section_hover {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          height: 100%;

          .thumnail_img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            display: block;
            opacity: 1;
            visibility: visible;
          }

          .featureimg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
          }

          .thumnail_img,
          .featureimg {
            transition: .5s all ease-in-out;
          }

          &:hover {
            .thumnail_img {
              opacity: 0;
              visibility: hidden;
            }

            .featureimg {
              opacity: 1;
              visibility: visible;
            }
          }

          &::after {
            content: "";
            height: 100%;
            z-index: 0;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: -moz-linear-gradient(top,
                rgba(0, 0, 0, 0.1) 30%,
                rgba(0, 0, 0, 0.8) 100%);
            background: -webkit-linear-gradient(top,
                rgba(0, 0, 0, 0.1) 30%,
                rgba(0, 0, 0, 0.8) 100%);
            background: linear-gradient(to bottom,
                rgba(0, 0, 0, 0.1) 30%,
                rgba(0, 0, 0, 0.8) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
            // z-index: -1;
            // transition: .5s all ease-in-out;
          }
        }

        .video_hover_sec_second {
          width: 100%;
          height: 100%;

          >div {
            z-index: 1 !important;
          }

          video {
            display: block;
            width: 100%;
            object-fit: contain !important;
            height: 100%;
            background: $black;
          }
        }
      }
    }

    &__listing {
      display: flex;
      align-items: center;
      margin-top: 2.9rem;
      flex-wrap: wrap;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
        white-space: normal;
        margin-bottom: 0.8rem;
        height: auto;
        word-break: break-word;
        margin-right: 1rem;
        max-width: 100%;
      }
    }
  }

  &_rightsec {

    @media (max-width: 1199px) {
      padding-left: 2rem;
    }

    @media (max-width: 991px) {
      padding-left: 0;
      margin-top: 3rem;
    }

    &_auction {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 3rem;

      @media (min-width: 992px) and (max-width: 1199px) {
        flex-wrap: wrap;
      }

      @media (max-width: 767px) {
        margin-bottom: 2rem;
        flex-wrap: wrap;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.7rem 1.5rem 0.5rem;
        min-width: 10rem;
        height: 3.1rem;
        background: $white_light;
        border-radius: 0.5rem;
        margin-right: 2rem;
        font-size: 1.6rem;
        font-weight: 400;
        color: $black;
        line-height: 2rem;
        font-family: $baseFont;

        @media (max-width: 1199px) {
          min-width: 9rem;
          margin-right: 1rem;
          font-size: 1.4rem;
          line-height: 1.8rem;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          flex: 0 0 40%;
        }
      }

      h6 {
        font-size: 1.6rem;
        font-weight: bold;
        margin-right: 2rem;
        margin-bottom: 0;
        color: $black;
        line-height: 2rem;
        font-family: $baseFont;
        display: flex;
        align-items: center;

        @media (max-width: 1199px) {
          margin-right: 1rem;
          font-size: 1.4rem;
          line-height: 1.8rem;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          flex: 0 0 55%;
        }

        @media (max-width: 331px) {
          margin-top: 1rem;
        }
      }

      .textlive {
        font-size: 1.8rem;
        font-weight: bold;
        margin: 0;
        color: $black;
        line-height: 2rem;
        text-transform: uppercase;
        font-family: $baseFont;
        display: flex;
        align-items: center;

        span {
          width: 1.3rem;
          height: 1.3rem;
          background-color: $green;
          border-radius: 50%;
          margin-left: 1rem;
        }

        @media (max-width: 1199px) {
          margin-right: 1rem;
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }

      .notifiedBtn {
        font-size: 1.4rem;
        font-weight: bold;
        color: $yellow;
        padding: 0.5rem 1rem;
        vertical-align: middle;
        background: $black;
        border: none;
        border-radius: 3rem;
        width: 100%;
        max-width: 13rem;
        height: 3.7rem;
        font-family: $baseFont;

        @media (min-width: 992px) and (max-width: 1199px) {
          margin-top: 1rem;
        }

        @media (max-width: 457px) {
          margin-top: 1rem;
        }
      }
    }

    &_headtext {
      margin-bottom: 1.2rem;

      h2 {
        font-size: 4.8rem;
        font-weight: bold;
        line-height: 5.6rem;
        color: $black;
        margin-bottom: 1.8rem;
        font-family: $baseFont;

        @media (max-width: 1439px) {
          font-size: 3.8rem;
          line-height: 4.6rem;
        }

        @media (max-width: 1199px) {
          font-size: 3.2rem;
          line-height: 4rem;
        }

        @media (max-width: 767px) {
          font-size: 2.6rem;
          line-height: 3.4rem;
        }
      }

      p {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.4rem;
        color: $black2;
        font-family: $baseFont;

        @media (max-width: 991px) {
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }
    }

    &_creatorInfo {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      border-top: 2px solid $gray;

      @media (min-width:991px) and (max-width: 1199px) {
        flex-direction: column;
        border-top: 1px solid $gray;
      }

      @media (max-width: 575px) {
        flex-direction: column;
        border-top: 1px solid $gray;
      }

      .creator_details {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-right: 2px solid $gray;
        flex: 0 0 20%;
        padding: 1.8rem;

        @media (max-width: 1439px) {
          padding: 1.2rem;
        }

        @media (max-width: 1199px) {
          padding: 1rem;
        }

        @media (min-width:992px) and (max-width: 1199px) {
          flex: 0 0 100%;
          border-bottom: 1px solid $gray;
          border-right: none;
          width: 100%;
        }

        @media (max-width: 575px) {
          flex: 0 0 100%;
          border-bottom: 1px solid $gray;
          border-right: none;
          width: 100%;
        }

        &_userImage {
          width: 4.9rem;
          height: 4.9rem;
          margin-right: 2rem;

          @media (max-width: 1199px) {
            width: 3.9rem;
            height: 3.9rem;
            margin-right: 1rem;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: fill;
            object-position: center;
          }
        }

        &_texts {
          label {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.8rem;
            color: $gray3;
            margin-bottom: 0.4rem;
            font-family: $baseFont;

            @media (max-width: 1199px) {
              font-size: 1.4rem;
            }
          }

          h6 {
            color: $black;
            font-size: 2rem;
            font-weight: bold;
            line-height: 2.4rem;
            font-family: $baseFont;

            @media (max-width: 1199px) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
        }
      }

      .series_details {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-right: 2px solid $gray;
        flex: 0 0 59%;
        padding: 1.8rem;

        @media (max-width: 1679px) {
          flex: 0 0 46%;
        }

        @media (max-width: 1439px) {
          flex: 0 0 40%;
          padding: 1.2rem 1rem;
        }

        @media (max-width: 1199px) {
          padding: 1rem;
        }

        @media (min-width:992px) and (max-width: 1199px) {
          flex: 0 0 100%;
          border-bottom: 1px solid $gray;
          border-right: none;
          width: 100%;
        }

        @media (max-width: 991px) {
          flex: 0 0 50%;
        }

        @media (max-width: 767px) {
          flex: 0 0 40%;
        }

        @media (max-width: 575px) {
          flex: 0 0 100%;
          border-bottom: 1px solid $gray;
          border-right: none;
          width: 100%;
        }

        &_userImage {
          width: 4.9rem;
          height: 4.9rem;
          margin-right: 2rem;

          @media (max-width: 1199px) {
            width: 3.9rem;
            height: 3.9rem;
            margin-right: 1rem;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: fill;
            object-position: center;
          }
        }

        &_texts {
          label {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.8rem;
            color: $gray3;
            margin-bottom: 0.4rem;
            font-family: $baseFont;

            @media (max-width: 1199px) {
              font-size: 1.4rem;
            }
          }

          h6 {
            color: $black;
            font-size: 2rem;
            font-weight: bold;
            line-height: 2.4rem;
            font-family: $baseFont;

            @media (max-width: 1199px) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }
          }
        }
      }

      .shareIcon {
        flex: 0 0 15%;

        @media (max-width: 1679px) {
          flex: 0 0 16%;
        }

        @media (min-width:992px) and (max-width: 1199px) {
          flex: 0 0 100%;
          width: 100%;
        }

        @media (max-width: 575px) {
          flex: 0 0 100%;
          width: 100%;
        }

        &_list {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 1.8rem;

          @media (max-width: 1679px) {
            padding-left: 1rem;
          }

          @media (max-width: 1199px) {
            padding-left: 0.5rem;
          }

          @media (max-width: 479px) {
            border-bottom: 1px solid $gray;
            border-right: none;
          }

          li {
            padding: 1.8rem 0.5rem;

            button {
              display: block;
              width: 3.6rem;
              height: 3.6rem;
              background: transparent;
              border: none;
              padding: 0;

              @media (max-width: 1199px) {
                width: 3rem;
                height: 3rem;
              }

              svg {
                width: 100%;
                height: 100%;
              }
            }

            :global {
              .ShareBtn {
                margin: 0;
              }
            }
          }
        }
      }
    }

    &_pricefloor {
      margin: 4.8rem 0 9rem;

      @media (max-width: 1199px) {
        margin: 3.8rem 0 6rem;
      }

      @media (max-width: 767px) {
        margin: 2.8rem 0 4.5rem;
      }

      .headprice {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (min-width: 992px) and (max-width: 1199px) {
          flex-direction: column;
          align-items: flex-start;
        }

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
        }

        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 479px) {
            flex-direction: column;
            align-items: flex-start;
          }

          li {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 2.2rem;

            &:last-child {
              margin-right: 0;
            }

            label {
              font-size: 1.6rem;
              color: $borderClr2;
              font-weight: 400;
              line-height: 2rem;
              font-family: $baseFont;
            }

            h6 {
              font-size: 1.6rem;
              color: $black;
              font-weight: 400;
              line-height: 2rem;
              margin-left: 0.5rem;
              font-family: $baseFont;
            }
          }
        }

        .timeedit {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 2rem;
          color: $black;
          font-family: $baseFont;

          @media (max-width: 1199px) {
            margin-top: 0.5rem;
          }
        }
      }

      .startinfo {
        width: 100%;
        padding: 2rem;
        display: flex;
        align-items: center;
        border: 2px solid $borderClr4;
        border-radius: 0.5rem;

        @media (max-width: 1199px) {
          padding: 2rem 1rem;
        }

        .auctioncomingsoon {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          @media (max-width: 479px) {
            flex-direction: column;
            align-items: flex-start;
          }

          h4 {
            font-size: 2.4rem;
            font-weight: bold;
            line-height: 2.8rem;
            color: $black;
            font-family: $baseFont;
            display: flex;
            align-items: center;

            @media (max-width: 1679px) {
              font-size: 2rem;
              line-height: 2.4rem;
            }

            @media (max-width: 1439px) {
              font-size: 1.8rem;
              line-height: 2.2rem;
            }

            @media (max-width: 1199px) {
              font-size: 1.6rem;
              line-height: 2rem;
            }

            @media (max-width: 479px) {
              margin-bottom: 1.5rem;
              font-size: 1.4rem;
              line-height: 1.8rem;
            }
          }
        }

        .bidhighest {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          @media (min-width: 992px) and (max-width: 1199px) {
            flex-direction: column;
            align-items: flex-start;
          }

          @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .bidvalues {
            flex: 0 0 30%;

            @media (max-width: 1679px) {
              width: 100%;
              margin-bottom: 1rem;
              flex: 0 0 40%;
            }

            .highest_bid {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 1rem;

              @media (max-width: 991px) {
                margin-bottom: 1rem;
              }

              label {
                font-size: 2rem;
                font-weight: bold;
                line-height: 2.4rem;
                width: 100%;
                max-width: 17rem;
                font-family: $baseFont;

                @media (max-width: 991px) {
                  font-size: 1.8rem;
                  line-height: 2.2rem;
                }
              }

              h5 {
                font-size: 2rem;
                font-weight: bold;
                line-height: 2.4rem;
                width: 100%;
                max-width: 10rem;
                margin-left: 0.5rem;
                font-family: $baseFont;

                @media (max-width: 991px) {
                  font-size: 1.8rem;
                  line-height: 2.2rem;
                }
              }
            }

            .lowest_bid {
              margin-top: 0.5rem;
            }

            .minrank_bid,
            .lowest_bid {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              label {
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 2rem;
                width: 100%;
                max-width: 17rem;
                font-family: $baseFont;

                @media (max-width: 991px) {
                  font-size: 1.4rem;
                  line-height: 1.8rem;
                }
              }

              h6 {
                font-size: 1.6rem;
                font-weight: bold;
                line-height: 2rem;
                width: 100%;
                max-width: 10rem;
                margin-left: 0.5rem;
                font-family: $baseFont;

                @media (max-width: 991px) {
                  font-size: 1.4rem;
                  line-height: 1.8rem;
                }
              }
            }
          }

          .bitlimitset {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (min-width: 992px) and (max-width: 1199px) {
              flex-wrap: wrap;
              width: 100%;
            }

            @media (max-width: 479px) {
              flex-wrap: unset;
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
            }

            button {
              @media (min-width: 992px) and (max-width: 1199px) {
                margin-top: 1rem;
              }

              @media (max-width: 393px) {
                margin-top: 1rem;
              }
            }

            :global {
              form {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width: 479px) {
                  flex-direction: column;
                  align-items: flex-start;
                }
              }

              .common_input {
                margin-bottom: 0 !important;
                margin-right: 1rem;
                width: 100%;

                .form-check {
                  .form-check-label {
                    color: $borderClr2;
                    font-size: 1.4rem;
                    font-weight: 400;
                    font-family: $baseFont;
                  }

                  .form-check-input {
                    border: 2px solid $borderClr6;
                  }
                }

                @media (min-width: 470px) {
                  .error_message {
                    position: absolute;
                    top: 100%;
                    white-space: nowrap;
                    top: calc(100% - 0.4rem);
                  }
                }

                &_inner {
                  .form-control {
                    height: 4.7rem;
                    border-radius: 2.4rem;
                    border: 2px solid $borderClr6;
                    font-weight: bold;
                    font-size: 1.6rem;
                    width: 100%;
                    max-width: 20.5rem;
                    font-family: $baseFont;

                    @media (max-width: 1199px) {
                      max-width: 100%;
                    }
                  }
                }

                &.priceadd {
                  @media (max-width: 479px) {
                    width: 100%;
                    margin: 1rem 0 !important;

                    .form-control {
                      max-width: 100% !important;
                    }
                  }
                }
              }

              .commonBtn {
                width: 100%;
                max-width: 15.5rem;
              }
            }
          }
        }

        .buyadd {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          @media (min-width: 992px) and (max-width: 1199px) {
            flex-direction: column;
            align-items: flex-start;
          }

          @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .valuelist {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            max-width: max-content;

            @media (min-width: 992px) and (max-width: 1199px) {
              margin-bottom: 1.5rem;
            }

            @media (max-width: 767px) {
              margin-bottom: 1.5rem;
            }

            label {
              font-size: 2rem;
              font-weight: bold;
              line-height: 2.4rem;
              font-family: $baseFont;
              color: $black;
            }

            span {
              font-size: 2rem;
              font-weight: bold;
              line-height: 2.4rem;
              margin: 0 0.6rem;
              font-family: $baseFont;
              color: $black;
            }

            h3 {
              font-size: 2rem;
              font-weight: bold;
              line-height: 2.4rem;
              font-family: $baseFont;
              color: $black;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              @media (max-width: 1439px) {
                font-size: 1.8rem;
              }
            }
          }

          .bitlimitset {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 575px) {
              flex-wrap: wrap;
            }

            :global {
              .common_input {
                margin: 0;
                padding-left: 0;
                margin-right: 2.2rem;

                @media (max-width: 1439px) {
                  margin-right: 1.8rem;
                }

                &_inner {
                  .leftIcon {
                    left: 1rem;
                    width: 2rem;
                  }

                  #number {
                    padding: 1rem 3rem 1rem 3.5rem;
                    border-radius: 3rem;
                    border: 1px solid $borderClr6;
                    width: 100%;
                    max-width: 13rem;

                    @media (max-width: 1439px) {
                      max-width: 11rem;
                    }
                  }

                  #gstvalue {
                    border: 1px solid $borderClr6;
                    border-radius: 3rem;
                  }

                  .rightIcon {
                    right: 1rem;
                    width: 2rem;
                  }
                }
              }
            }

            h4 {
              font-size: 2rem;
              margin-right: 1.2rem;
              font-weight: bold;
              line-height: 2rem;
              color: $black;
              font-family: $baseFont;

              @media (max-width: 1439px) {
                margin-right: 1rem;
                font-size: 1.8rem;
              }
            }
          }
        }
      }
    }

    &_tabpartlist {
      margin-top: 4.8rem;

      @media (max-width: 1199px) {
        margin-top: 2.8rem;
      }

      :global {
        .tab-content {
          padding: 3rem 0;
        }
      }
    }

    &_winnerDetails {
      :global {
        .tab-content {
          padding: 3rem 0;
        }
      }

      .data_winner {
        .drop_table {
          :global {
            table {
              border-spacing: 0;
              border-collapse: collapse;
              border: 2px solid $borderClr2;

              thead {
                tr {
                  th {
                    background-color: $black;
                    color: $white;
                    padding: 1.5rem 2.5rem;
                    font-weight: 400;
                    font-size: 1.4rem;
                    font-family: $baseFont;
                  }
                }
              }

              tbody {
                tr {
                  td {
                    border-top: 2px solid $borderClr2;
                    border-bottom: 2px solid $borderClr2;
                    padding: 1.5rem 2.5rem;
                    font-size: 1.4rem;
                    font-weight: bold;
                    font-family: $baseFont;

                    h4 {
                      font-size: 1.4rem;
                      font-weight: bold;
                      font-family: $baseFont;
                    }
                  }

                  &:first-child {
                    td {
                      border-top: none;
                    }
                  }
                }
              }
            }
          }

          .notifiedBtn {
            font-size: 1.6rem;
            font-weight: bold;
            color: $yellow;
            padding: 0.5rem 1rem;
            vertical-align: middle;
            background: $black;
            border: none;
            border-radius: 3rem;
            width: 100%;
            max-width: 13rem;
            height: 3.7rem;
            font-family: $baseFont;

            @media (max-width: 991px) {
              font-size: 1.4rem;
            }
          }

          .borderBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3rem;
            width: 100%;
            max-width: 13rem;
            height: 3.7rem;
            border: 2px solid $black;
            padding: 0.5rem 1rem;
            margin-left: 2rem;
            font-size: 1.6rem;
            font-weight: bold;
            font-family: $baseFont;
            cursor: pointer;
            background: $white;
          }
        }

        .bidder_table {
          :global {
            .table-responsive {
              border: 2px solid $black;
              border-radius: 0.6rem;
            }

            table {
              border-spacing: 0;
              border-collapse: collapse;

              thead {
                tr {
                  th {
                    background-color: $black;
                    color: $white;
                    padding: 1.5rem 2.5rem;
                    font-weight: 400;
                    font-size: 1.4rem;
                    font-family: $baseFont;
                  }
                }
              }

              tbody {
                tr {
                  td {
                    border-top: 2px solid $borderClr2;
                    border-bottom: 2px solid $borderClr2;
                    padding: 1.5rem 2.5rem;
                    font-size: 1.4rem;
                    font-weight: bold;
                    font-family: $baseFont;
                    color: $gray4;

                    h4 {
                      font-size: 1.4rem;
                      font-weight: bold;
                      font-family: $baseFont;
                      color: $gray4;
                    }
                  }

                  &:first-child {
                    td {
                      border-top: none;
                    }
                  }
                }
              }
            }
          }
        }

        .showvalues {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 4rem;

          .showicons {
            display: flex;
            align-items: center;
            margin-right: 1.5rem;

            label {
              font-size: 1.8rem !important;
              font-weight: bold;
              color: $black;
              margin-bottom: 0;
              margin-right: 1.6rem;
              font-family: $baseFont;
            }
          }

          :global {
            .common_select_inner {
              min-width: 10rem;
            }
          }

          .btnArrow {
            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
              padding-right: 1rem;

              &:last-child {
                padding-right: 0;
              }

              button {
                background: transparent;
                border: 2px solid $black;
                border-radius: 50%;
                height: 3.1rem;
                width: 3.1rem;
                padding: 0;

                &:hover {
                  background: $yellow;
                }

                &.leftbtn {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }

    &_activitydetails {
      :global {
        .tab-content {
          padding: 3rem 0;
        }
      }

      .data_activity {
        .activity_table {
          :global {
            .table-responsive {
              border: 2px solid $black;
              border-radius: 0.6rem;
            }

            table {
              border-spacing: 0;
              border-collapse: collapse;

              thead {
                tr {
                  th {
                    background-color: $black;
                    color: $white;
                    padding: 1.2rem 2.5rem;
                    font-weight: 400;
                    font-size: 1.4rem;
                    font-family: $baseFont;
                    text-align: center;

                    &:first-child {
                      border-radius: 5px 0px 0px 0px;
                    }

                    &:last-child {
                      border-radius: 0px 5px 0px 0px;
                      text-align: left;
                    }
                  }
                }
              }

              tbody {
                tr {
                  border-bottom: 2px solid $borderClr5;

                  td {
                    border: none;
                    padding: 1.2rem 2.5rem;
                    font-size: 1.4rem;
                    font-weight: bold;
                    font-family: $baseFont;
                    color: $black;
                    text-align: center;

                    .thumb {
                      border-radius: 50%;
                    }

                    h4 {
                      font-size: 1.4rem;
                      font-weight: bold;
                      font-family: $baseFont;
                      color: $black;
                    }

                    &:last-child {
                      text-align: left;
                    }
                  }

                  // &:first-child {
                  //   @media (min-width: 992px) and (max-width: 1199px) {
                  //     display: table-row;
                  //   }
                  // }

                  &:last-child {
                    border: none;
                  }
                }
              }
            }
          }

          .notifiedBtn {
            font-size: 1.6rem;
            font-weight: bold;
            color: $yellow;
            padding: 0.5rem 1rem;
            vertical-align: middle;
            background: $black;
            border: none;
            border-radius: 3rem;
            width: 100%;
            max-width: 13rem;
            height: 3.7rem;
            font-family: $baseFont;

            @media (max-width: 991px) {
              font-size: 1.4rem;
            }
          }

          .borderBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3rem;
            width: 100%;
            max-width: 13rem;
            height: 3.7rem;
            border: 2px solid $black;
            padding: 0.5rem 1rem;
            margin-left: 2rem;
            font-size: 1.6rem;
            font-weight: bold;
            font-family: $baseFont;
            cursor: pointer;
            background: $white;
          }
        }

        .showvalues {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 4rem;

          .showicons {
            display: flex;
            align-items: center;
            margin-right: 1.5rem;

            label {
              font-size: 1.8rem !important;
              font-weight: bold;
              color: $black;
              margin-bottom: 0;
              margin-right: 1.6rem;
              font-family: $baseFont;
            }
          }

          :global {
            .common_select_inner {
              min-width: 8rem;
              height: 4rem;

              .react-select {
                &__control {
                  border-radius: 1.2rem;
                  border: 1px solid;

                  .react-select__indicators {
                    .react-select__indicator {
                      padding: 0;
                      padding-right: 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .btnArrow {
            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
              padding-right: 1rem;

              &:last-child {
                padding-right: 0;
              }

              button {
                background: transparent;
                border: 2px solid $black;
                border-radius: 50%;
                height: 3.1rem;
                width: 3.1rem;
                padding: 0;

                &:hover {
                  background: $yellow;
                }

                &.leftbtn {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }
}

.auctionInfos {
  @media (min-width:1200px) and (max-width:1399px) {
    flex: 0 0 35% !important;

    .highest_bid {
      label {
        font-size: 1.8rem !important;
        line-height: 2rem !important;
        max-width: 13.4rem !important;
      }

      h5 {
        font-size: 1.8rem !important;
        line-height: 2rem !important;
        max-width: 7rem !important;
      }
    }

    .minrank_bid {
      label {
        font-size: 1.4rem !important;
        line-height: 1.8rem !important;
        max-width: 13.4rem !important;
      }

      h6 {
        font-size: 1.4rem !important;
        line-height: 1.8rem !important;
        max-width: 7rem !important;
      }
    }
  }
}

.auctionValues {
  flex: 0 0 60%;

  @media (min-width:1200px) and (max-width:1399px) {
    flex: 0 0 65%;
  }

  @media (max-width:767px) {
    flex: 0 0 100%;
    width: 100%;
  }

  form {
    position: relative;
  }

  :global {
    .checkMax {
      max-width: 12rem;
    }

    .common_input.priceadd {
      .common_input_inner {
        input {
          max-width: 100% !important;
        }
      }

      @media (min-width: 992px) and (max-width:1679px) {
        position: unset;

        .common_input_inner {
          position: unset;
        }

        .error_message {
          left: 0;

        }
      }
    }

    @media (max-width: 479px) {
      .error_message {
        position: unset !important;
      }
    }

    .commonBtn {
      @media (max-width:1679px) {
        max-width: 11rem !important;
        padding: 0.8rem 1rem;
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        margin-top: 0 !important;
      }

      @media (max-width: 479px) {
        margin-top: 0 !important;
      }
    }
  }
}

.notifiedBtn {
  font-size: 1.6rem;
  font-weight: bold;
  color: $yellow;
  padding: 0.5rem 1rem;
  vertical-align: middle;
  background: $black;
  border: none;
  border-radius: 3rem;
  width: 100%;
  max-width: 13rem;
  height: 3.7rem;
  font-family: $baseFont;

  @media (max-width: 991px) {
    font-size: 1.4rem;
  }
}

.borderBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  width: 100%;
  max-width: 13rem;
  height: 3.7rem;
  border: 2px solid $black;
  padding: 0.5rem 1rem;
  margin-left: 2rem;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: $baseFont;
  cursor: pointer;
  background: $white;
}