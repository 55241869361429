@import '../../../../../Assets/_var.scss';

.cashout-Modal {
    .modal-dialog {
        max-width: 99rem;

        .modal-header,
        .modal-body {
            padding: 1rem 2rem 3rem;
        }

        form {
            .fund_inputs {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                margin-bottom: 1.5rem;

                .common_input {
                    width: 100%;
                    margin-bottom: 0 !important;

                    .leftIconInput {
                        .leftIcon {
                            color: $black;
                            font-size: 1.6rem;
                            font-weight: 500;
                        }

                        .form-control {
                            padding-left: 4rem;
                        }
                    }

                    .form-label {
                        color: $white;
                        font-size: 1.6rem;
                        margin-bottom: 1.2rem;
                        font-weight: 400;
                        line-height: 1.2
                    }

                    .form-control {
                        height: 4.5rem;
                    }
                }
            }

            .addbtns {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3rem;

                .borderBtn {
                    color: $white;
                }
            }

            .bankDetails {
                margin-top: 1.5rem;

                h3 {
                    color: $white;
                    margin-bottom: 1.5rem;
                }

                .noteFiled {
                    margin-bottom: 2.5rem;

                    p {
                        font-size: 1.4rem;
                        font-weight: 500;
                        color: $red;

                        span {
                            color: $gray4;
                        }
                    }
                }
            }

            .finalAmount {
                margin-bottom: 2.5rem;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                width: 100%;

                h4 {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 5rem;
                    border-radius: 1rem;
                    padding: 1.1rem 1.5rem;
                    background: $white;
                    color: $black;
                    font-weight: 500;

                    span {
                        color: $black;
                        margin-right: 0.8rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }

}