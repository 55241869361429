@import '../../../../Assets/_var.scss';

.SocialMedia {
    display: table;

    li {
        display: inline-block;
        margin-left: 1rem;

        a {
            border-radius: 50%;
            transition: .3s all ease-in-out;
            // background-color: $green;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                width: 5.2rem;
                height: 5.2rem;

                // circle,
                // .circle {
                //     opacity: 1;
                //     fill: $green;
                //     transition: .3s all ease-in-out;
                // }

                // path {
                //     transition: .3s all ease-in-out;
                //     fill: $white;
                // }

                // rect,
                // .rect {
                //     opacity: 1;
                //     fill: $green;
                //     transition: .3s all ease-in-out;
                // }
            }

            &:hover {
                svg {

                    circle,
                    .circle {
                        fill: $yellow;
                    }

                    rect,
                    .rect {
                        fill: $yellow;
                    }
                }
            }

            @media (max-width:1679px) {
                svg {
                    height: 3.4rem;
                    width: 3.4rem;
                }
            }

            @media (max-width:1279px) {
                svg {
                    height: 2.8rem;
                    width: 2.8rem;
                }
            }
        }
    }

    &.yellow_social {
        li {
            a {
                svg {

                    circle {
                        fill: $yellow;
                    }

                    path {
                        fill: $black
                    }

                    rect,
                    .rect {
                        fill: $yellow;
                    }
                }

                &:hover {
                    svg {

                        circle,
                        .circle {
                            fill: $green;
                        }

                        path {
                            fill: $white;
                        }

                        g {

                            rect,
                            .rect {
                                fill: $green;
                            }
                        }
                    }
                }
            }
        }
    }

    &.vertical {

        >li {
            display: block;
            margin-left: 0;
            margin-bottom: 1rem;

        }
    }

}