@import '../../../Assets/_var.scss';

.newArtpage {
    padding: 10rem 0;

    @media (max-width:1679px) {
        padding: 8rem 0;
    }

    @media (max-width:1199px) {
        padding: 6rem 0;
    }

    @media (max-width:991px) {
        padding: 4rem 0;
    }

    &_innerPart {
        display: flex;
        align-items: flex-start;

        @media (max-width:991px) {
            flex-direction: column;
        }

        .leftImgcard {
            flex: 0 0 65.3rem;
            width: 100%;
            max-width: 65.3rem;

            @media (max-width:1679px) {
                flex: 0 0 60rem;
                max-width: 60rem;
            }

            @media (max-width:1199px) {
                flex: 0 0 50rem;
                max-width: 50rem;
            }

            @media (max-width:991px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 2rem;
            }

        }

        .rightSideSec {
            flex: 0 0 calc(100% - 65.3rem);
            width: 100%;
            max-width: calc(100% - 65.3rem);
            padding-left: 6.8rem;

            @media (max-width:1679px) {
                padding-left: 4.8rem;
                flex: 0 0 calc(100% - 60rem);
                max-width: calc(100% - 60rem);
            }

            @media (max-width:1439px) {
                padding-left: 3.5rem;
            }

            @media (max-width:1199px) {
                flex: 0 0 calc(100% - 50rem);
                max-width: calc(100% - 50rem);
            }

            @media (max-width:991px) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-left: 0;
            }
        }
    }
}