@import '../../../Assets/_var.scss';

.confirm_modal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                padding: 3rem 3rem 0;

                .modal-title {
                    font-size: 2.6rem;
                }
            }

            .modal-body {
                padding: 2rem 3rem 5rem;

                .main_content {
                    h5 {
                        font-size: 2rem;
                        color: $white;
                        margin-bottom: 3rem;
                        font-weight: 600;
                        line-height: 1.2;
                    }

                    .btn_section {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .btn_size {
                            height: 4rem;
                            width: 100%;
                            max-width: 12rem;
                            margin-left: 2rem;
                            font-size: 1.8rem;

                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}