@import "../../../../../Assets/_var.scss";

.artImages {
  .landscapeImg {
    .imageSec {
      position: relative;
      width: 100%;
      height: 35.6rem;
      margin-bottom: 2.2rem;

      &::before {
        content: "";
        position: absolute;
        top: auto;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #0000008a 0%, #00000000 100%);
      }

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .expandBtn {
        position: absolute;
        top: 1.8rem;
        right: 2rem;
        text-decoration: none;
        color: $white;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1;
        height: auto;
      }
    }

    .sliderSec {
      margin-bottom: 2rem;

      :global {
        .slick-slider {
          display: flex;
          align-items: center;
          justify-content: center;

          .slick-arrow {
            background: $black;
            height: 10rem;
            display: flex !important;
            align-items: center;
            justify-content: center;
            position: relative !important;
            top: 0;
            transform: unset;

            &::before {
              position: absolute;
              width: 0.9rem;
              height: 1.4rem;
              display: flex;
              align-items: center;
              justify-content: center;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }

            &.slick-prev {
              left: 0;

              &::before {
                content: "";
                opacity: 1;
                background: url(../../../../../Assets/icons/arrowLeftgreen.svg);
                background-size: cover;
                background-repeat: no-repeat;
              }
            }

            &.slick-next {
              right: 0;

              &::before {
                content: "";
                opacity: 1;
                background: url(../../../../../Assets/icons/arrowRightgreen.svg);
                background-size: cover;
                background-repeat: no-repeat;
              }
            }
          }

          .slick-list {
            width: 100%;
            margin: 0 0.6rem 0 0.2rem;

            .slick-slide {
              padding-inline: 0.7rem;
              height: 10rem;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0.7;

              >div {
                width: 100%;
                height: 100%;

                &:focus-visible {
                  outline: none;
                }

                >div {
                  width: 100%;
                  height: 100%;

                  &:focus-visible {
                    outline: none;
                  }

                  img,
                  video {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;

                    &:focus-visible {
                      outline: none;
                    }
                  }
                }
              }

              &.slick-center {
                opacity: 1;
              }
            }
          }
        }

        .videoNewIcon {
          position: relative;
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 1;

          img {
            width: 6rem !important;
            height: 6rem !important;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
          }

          // &::after {
          //   content: "";
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   width: 100%;
          //   height: 100%;
          //   background: rgba($black, 0.4);
          //   // backdrop-filter: blur(2px);
          //   z-index: 1;
          // }
          // &::before {
          //   content: "";
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   width: 100%;
          //   height: 100%;
          //   background: url(../../../../../Assets/icons/videoIconwhite.svg);
          //   background-size: 6rem;
          //   background-repeat: no-repeat;
          //   background-position: center;
          //   z-index: 2;
          // }
        }
      }
    }

    .tagFilter {
      .listing {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-inline: -0.6rem;

        li {
          padding-inline: 0.6rem;
          padding-bottom: 0.8rem;

          &.deviceDrop {
            width: 100%;
            max-width: max-content;
            min-width: 18rem;
          }

          .dropDown {
            min-width: 18rem;

            :global {
              .dropBtn {
                border-radius: 1.5rem;
                padding: 0.5rem 1rem;
                height: 3rem;

                svg {
                  width: 0.9rem;
                  height: 0.5rem;
                }
              }

              .dropdown-menu {
                min-width: 18rem;
                height: auto;
                max-height: 40rem;
                min-height: 5rem;
                overflow-y: auto;
                background: $black;

                .dropdown-item {
                  color: $white;

                  &.active {
                    color: $white;
                    background: $green;
                    font-weight: 500;
                  }
                }
              }
            }
          }

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: max-content;
            min-width: 6.9rem;
            font-size: 1.2rem;
            color: #020302;
            border: 0.1rem solid #a3a3a3;
            padding: 0.5rem 0.9rem;
            border-radius: 1rem;
          }
        }
      }
    }
  }

  .portraitImg {
    width: 100%;
    max-width: 35.6rem;
    margin-inline: auto;

    .imageSec {
      position: relative;
      width: 100%;
      height: 63.3rem;
      margin-bottom: 2.2rem;

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .expandBtn {
        position: absolute;
        top: 1.8rem;
        right: 2rem;
        text-decoration: none;
        color: $white;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1;
        height: auto;
      }
    }

    .sliderSec {
      margin-bottom: 2rem;

      .imgSlide {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      :global {
        .slick-slider {
          display: flex;
          align-items: center;
          justify-content: center;

          .slick-arrow {
            background: $black;
            height: 10rem;
            display: flex !important;
            align-items: center;
            justify-content: center;
            position: relative !important;
            top: 0;
            transform: unset;

            &::before {
              position: absolute;
              width: 0.9rem;
              height: 1.4rem;
              display: flex;
              align-items: center;
              justify-content: center;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }

            &.slick-prev {
              left: 0;

              &::before {
                content: "";
                opacity: 1;
                background: url(../../../../../Assets/icons/arrowLeftgreen.svg);
                background-size: cover;
                background-repeat: no-repeat;
              }
            }

            &.slick-next {
              right: 0;

              &::before {
                content: "";
                opacity: 1;
                background: url(../../../../../Assets/icons/arrowRightgreen.svg);
                background-size: cover;
                background-repeat: no-repeat;
              }
            }
          }

          .slick-list {
            width: 100%;
            margin: 0 0.6rem 0 0.2rem;

            .slick-slide {
              padding-inline: 0.7rem;
              height: 10rem;
              display: flex;
              align-items: center;
              justify-content: center;
              opacity: 0.7;

              >div {
                width: 100%;
                height: 100%;
                &:focus-visible {
                  outline: none;
                }

                >div {
                  width: 100%;
                  height: 100%;
                  &:focus-visible {
                    outline: none;
                  }

                  img,
                  video {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    &:focus-visible {
                      outline: none;
                    }
                  }
                }
              }
              &.slick-center {
                opacity: 1;
              }
            }
          }
        }

        .videoNewIcon {
          position: relative;
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 1;
          img {
            width: 6rem !important;
            height: 6rem !important;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
          }
          // &::after {
          //   content: "";
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   width: 100%;
          //   height: 100%;
          //   background: rgba($black, 0.4);
          //   // backdrop-filter: blur(2px);
          //   z-index: 1;
          // }
          // &::before {
          //   content: "";
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   width: 100%;
          //   height: 100%;
          //   background: url(../../../../../Assets/icons/videoIconwhite.svg);
          //   background-size: 6rem;
          //   background-repeat: no-repeat;
          //   background-position: center;
          //   z-index: 2;
          // }
        }
      }
    }

    .tagFilter {
      .dropDown {
        min-width: 18rem;
        margin-bottom: 1.5rem;
        max-width: 20rem;

        :global {
          .dropBtn {
            border-radius: 1.5rem;
            padding: 0.5rem 1rem;
            height: 3rem;

            svg {
              width: 0.9rem;
              height: 0.5rem;
            }
          }
        }
      }

      .listing {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-inline: -0.6rem;

        li {
          padding-inline: 0.6rem;
          padding-bottom: 0.8rem;

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: max-content;
            min-width: 6.9rem;
            font-size: 1.2rem;
            color: #020302;
            border: 0.1rem solid #a3a3a3;
            padding: 0.5rem 0.9rem;
            border-radius: 1rem;
          }
        }
      }
    }
  }
}