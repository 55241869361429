@import '../../../../../Assets/_var.scss';

.common_date {

    .common_input_inner {
        input {
            border-radius: 2.4rem;
            font-size: 1.8rem;
            font-weight: bold;

            @media (max-width:1199px) {
                font-size: 1.6rem;
            }
        }
    }

    .react-datepicker-wrapper {
        height: 100%;
        width: 100%;

        .react-datepicker__input-container {
            height: inherit;

            .react-datepicker__close-icon {
                padding-right: 12px;
                &::after {
                    background: $black;
                }
            }
        }
    }

    .react-datepicker__tab-loop {
        width: 100%;

        .react-datepicker-popper {
            width: 100%;
            // max-width: 25rem;
            min-width: 25rem;

            .react-datepicker {
                font-size: 1.6rem;
                width: 100%;
                // max-width: 25rem;
                height: 25rem;
                min-width: 25rem;

                button.react-datepicker__navigation {
                    width: 2.5rem;
                    height: 2.6rem;
                    top: 1.4rem;
                }

                &__triangle {
                    left: -6px !important;
                }

                &__month-container {
                    width: 100%;
                    height: 100%;

                    .react-datepicker__header {
                        padding: 0.8rem 0 1.3rem;

                        .react-datepicker__current-month {
                            margin-bottom: 0.5rem;
                            font-size: 1.6rem;
                        }

                        .react-datepicker__day-names {
                            .react-datepicker__day-name {
                                min-width: 3rem;
                            }
                        }
                    }

                    .react-datepicker__month {
                        .react-datepicker__week {
                            .react-datepicker__day {
                                min-width: 3rem;
                                min-height: 3rem;
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }
}