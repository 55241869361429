@import "../../../../Assets/_var.scss";

.uploadedArt {
  .uploadSelect {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: max-content;

    .uploadBtn {
      height: 4rem;
      font-family: $baseFont2;
    }

    .selectBtn {
      margin-left: 3rem;
      display: flex;
      align-items: center;
      width: 100%;

      .btnSize {
        text-decoration: none;
        width: 100%;
        max-width: 10rem;
        min-width: 8rem;
        padding: 0.5rem 1rem;
        margin-left: 1rem;
        white-space: nowrap;
        height: 3.5rem;
        font-family: $baseFont2;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &_section {
    margin-top: 5rem;

    // @media (max-width: 1679px) {
    //     margin-top: 4rem;
    // }

    // @media (max-width: 1399px) {
    //     margin-top: 3rem;
    // }

    // @media (max-width: 1199px) {
    //     margin-top: 2.5rem;
    // }

    // @media (max-width: 991px) {
    //     margin-top: 2rem;
    // }

    // @media (max-width: 767px) {
    //     margin-top: 1.5rem;
    // }

    &_arttable {
      .uploadedArt_table {
        .info_user {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          flex-direction: column;
          cursor: pointer;
          height: 100%;
          width: 100%;

          &_innerSec {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            width: 100%;

            // &:hover {
            //     .checkFile {
            //         opacity: 1;
            //         z-index: 1;
            //     }
            // }

            .checkFile {
              position: absolute;
              top: 0;
              left: 0;
              width: 15rem;
              height: 15rem;
              z-index: 0;
              // backdrop-filter: blur(2px);
              // background: rgba($black, 0.3);
              // box-shadow: 0px 0px 16px rgba($black, 0.54);
              padding: 1rem;
              opacity: 1;
              z-index: 1;

              :global {
                .fieldBlack {
                  .form-check-input {
                    height: 1rem;
                    width: 1rem;
                    border: 1px solid $gray707070;
                    margin: 0;
                    top: 0;

                    &:checked {
                      background-color: $black;
                      border-color: $black;
                    }
                  }
                }
              }

              // &.active {
              //   backdrop-filter: blur(2px);
              //   background: rgba($black, 0.3);
              //   box-shadow: 0px 0px 16px rgba($black, 0.54);
              // }

              @media (max-width: 1679px) {
                width: 13rem;
                height: 13rem;
              }

              @media (max-width: 1399px) {
                width: 11rem;
                height: 11rem;
              }

              @media (max-width: 1199px) {
                width: 9rem;
                height: 9rem;
              }

              @media (max-width: 575px) {
                width: 8rem;
                height: 8rem;
              }

              @media (max-width: 479px) {
                left: 50%;
                transform: translateX(-50%);
              }
            }

            @media (max-width: 575px) {
              justify-content: center;
            }

            @media (max-width: 479px) {
              flex-direction: column;
              align-items: center;
              text-align: left;
            }

            .mediaContainer {
              width: 100%;
              max-width: 15rem;
              height: 15rem;
              display: flex;

              @media (max-width: 1679px) {
                max-width: 13rem;
                height: 13rem;
              }

              @media (max-width: 1399px) {
                max-width: 11rem;
                height: 11rem;
              }

              @media (max-width: 1199px) {
                width: 9rem;
                height: 9rem;
              }

              @media (max-width: 575px) {
                max-width: 8rem;
                height: 8rem;
              }

              img,
              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

            .text_info {
              padding-left: 2rem;
              cursor: pointer;
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 100%;
              width: 100%;
              max-width: 23rem;

              @media (max-width: 1679px) {
                padding-left: 1.5rem;
              }

              @media (max-width: 575px) {
                padding-left: 1rem;
              }

              @media (max-width: 479px) {
                padding-left: 0;
                padding-top: 1rem;
                text-align: center;
                align-items: center;
              }

              h6 {
                font-weight: bold;
                color: $black;
                font-family: $baseFont;
                line-height: 1.5;
                word-break: break-word;
                margin-bottom: 2rem;

                @media (max-width: 1679px) {
                  margin-bottom: 1.5rem;
                }

                @media (max-width: 1199px) {
                  margin-bottom: 1rem;
                }

                @media (max-width: 991px) {
                  margin-bottom: 0.5rem;
                }
              }

              :global {
                .react-select.selectDevice {
                  height: 3rem;

                  @media (max-width: 991px) {
                    height: 2.8rem;
                  }

                  .react-select__control {
                    min-width: 12rem;
                    max-width: 18rem;
                    border: 0.1rem solid $black;

                    @media (max-width: 991px) {
                      height: 2.8rem;
                    }

                    .react-select__value-container {
                      padding: 0 0.6rem;

                      .react-select__placeholder,
                      .react-select__input-container,
                      .react-select__single-value {
                        font-size: 1.2rem;
                        color: $black;
                        font-weight: bold;

                        @media (max-width: 991px) {
                          font-size: 1rem;
                        }
                      }
                    }

                    .react-select__indicators {
                      .react-select__indicator {
                        svg {
                          width: 2rem;
                          height: 2rem;

                          @media (max-width: 991px) {
                            width: 1.8rem;
                            height: 1.8rem;
                          }
                        }
                      }
                    }
                  }

                  .react-select__menu {
                    // border-radius: 10px;
                    // border: none;
                    // box-shadow: 1px 0px 16px #0000001a;
                    // background-color: #00000091;

                    .react-select__menu-list {
                      &::-webkit-scrollbar {
                        width: 3px;
                        height: 3px;
                        background-color: $grayC1C1C1;
                      }

                      &::-webkit-scrollbar-thumb {
                        background-color: $green;
                      }

                      .react-select__option {
                        cursor: pointer;
                        // background-color: transparent;
                        margin-bottom: 1px;
                        font-size: 1.2rem;
                        padding: 0.4rem 0.6rem;
                        text-align: left;
                      }

                      .react-select__option--is-disabled {
                        color: rgb($white, 0.5);
                      }

                      .react-select__menu-notice {
                        font-weight: 500;
                        font-size: 1.2rem;
                      }
                    }
                  }
                }
              }
            }
          }

          .btnfollow {
            width: 100%;
            height: 3rem;
            max-width: 9rem;
            padding: 0.8rem 2rem;
            font-size: 1.2rem;
            font-weight: bold;
            font-family: $baseFont;
            margin-top: auto;

            @media (max-width: 1399px) {
              padding: 0.5rem 1.6rem;
            }

            @media (max-width: 991px) {
              padding: 0.5rem 1rem;
              height: 2.6rem;
            }
          }
        }

        .noData {
          margin: 5rem 0;
        }

        .artFooter {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 2rem;

          @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 1.5rem;
          }

          .storageData {
            display: flex;
            align-items: flex-start;
            width: 100%;
            max-width: 40rem;
            padding-right: 2rem;

            @media (max-width: 1199px) {
              padding-right: 1rem;
            }

            @media (max-width: 767px) {
              margin-bottom: 1.5rem;
            }

            label {
              font-size: 1.6rem;
              font-weight: bold;
              line-height: 1.2;
              margin-right: 1.5rem;
              color: $black;
              min-width: max-content;

              @media (max-width: 1199px) {
                font-size: 1.4rem;
                margin-right: 1rem;
              }
            }

            &_innerSec {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              flex-direction: column;
              width: 100%;

              :global {
                .progress {
                  width: 100%;
                  margin-bottom: 0.7rem;
                  border: 0.1rem solid #707070;
                  border-radius: 0;

                  .progress-bar {
                    &.bg-danger {
                      background: $red;
                    }

                    &.bg-success {
                      background: $green;
                    }
                  }
                }
              }

              p {
                font-size: 1.1rem;
                font-weight: 400;
                color: $black;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }

  .rowFollow {
    margin: -3.1rem -1.5rem;

    @media (max-width: 1679px) {
      margin: -2.8rem -1.5rem;
    }

    @media (max-width: 1399px) {
      margin: -2.2rem -1.5rem;
    }

    @media (max-width: 991px) {
      margin: -1.8rem -1.5rem;
    }

    @media (max-width: 767px) {
      margin: -1.2rem -1.5rem;
    }

    .colFollow {
      padding: 3.1rem 1.5rem;

      @media (max-width: 1679px) {
        padding: 2.8rem 1.5rem;
      }

      @media (max-width: 1399px) {
        padding: 2.2rem 1.5rem;
      }

      @media (max-width: 991px) {
        padding: 1.8rem 1.5rem;
      }

      @media (max-width: 767px) {
        padding: 1.2rem 1.5rem;
      }
    }
  }
}