@import '../../../../../Assets/_var.scss';

.dfabuyForm {
    margin-top: 2.7rem;

    @media (max-width:1679px) {
        margin-top: 2rem;
    }

    @media (max-width:1199px) {
        margin-top: 1.5rem;
    }
}