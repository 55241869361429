@import '../../../Assets/_var.scss';

.hoowitWork {
    .howitList {
        padding-left: 2rem;

        li {
            list-style: none;
            padding-left: 0.6rem;
            margin-bottom: 1rem;

            p {
                margin: 0;
                font-weight: 400;
                font-size: 1.4rem;
                color: #000;
                line-height: 2rem;

                span {
                    font-weight: 700;
                }
            }
        }
    }
}