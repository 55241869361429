@import "../../../../Assets/_var.scss";

.art_owned {
  .searchbar {
    margin-bottom: 5rem;

    :global {
      form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 575px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .search_input {
          width: 100%;

          .search_input_inner {
            height: 5.8rem;
            border-radius: 2.9rem;
            background: $white_light3;
            padding: 0 2.8rem;

            @media (max-width: 991px) {
              height: 5rem;
            }

            @media (max-width: 767px) {
              height: 4.5rem;
            }

            .search_input_icon {
              border: none;
              left: 2rem;

              svg {
                path {
                  fill: $gray5;
                }
              }
            }

            .form-control {
              border: none;
              background: transparent;
              font-size: 2rem;

              @media (max-width: 991px) {
                font-size: 1.8rem;
              }

              @media (max-width: 767px) {
                font-size: 1.6rem;
              }

              &::placeholder {
                color: $gray6;
              }
            }
          }
        }
      }
    }

    .btnimport {
      width: 100%;
      max-width: 22rem;
      height: 4.7rem;
      margin-left: 3.3rem;
      padding: 0.8rem 1rem;
      font-size: 2rem;
      line-height: 2.2rem;

      @media (max-width: 991px) {
        font-size: 1.8rem;
      }

      @media (max-width: 767px) {
        font-size: 1.6rem;
      }

      @media (max-width: 575px) {
        margin: 1.5rem 0 0;
      }
    }
  }

  .textfeilds {
    width: 100%;
    max-width: 40rem;
  }

  .table_section {
    .artowned_table {
      :global {
        .table-responsive {
          border: 2px solid $gray707070;
        }

        table {
          border-spacing: 0;
          border-collapse: collapse;

          thead {
            tr {
              th {
                background-color: $black;
                color: $white;
                padding: 1.8rem 1.6rem;
                font-weight: 500;
                font-size: 1.6rem;
                font-family: $baseFont;

                &.username {
                  width: 14rem;
                  max-width: 14rem;
                  min-width: 14rem;
                }

                &:first-child {
                  min-width: 20rem;
                  padding-left: 3rem;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                border-top: 2px solid $borderClr2;
                border-bottom: 2px solid $borderClr2;
                border-right: none;
                border-left: none;
                padding: 1.8rem 1.6rem;
                font-size: 1.4rem;
                font-weight: bold;
                font-family: $baseFont;

                &:first-child {
                  min-width: 30rem;
                  padding-left: 3rem;
                }

                .thumb-outer {
                  img {
                    width: 100%;
                    height: 7rem;
                    max-width: 7rem;
                    min-width: 7rem;
                    flex: unset;
                  }
                }

                .black_label_input {
                  .form-check-label {
                    color: $black;
                  }
                }

                &.username {
                  width: 14rem;
                  max-width: max-content;
                  min-width: 14rem;
                }

                .react-select.selectDevice {
                  .react-select__control {
                    min-width: 12rem;

                    .react-select__value-container {
                      padding: 0 0.6rem;

                      .react-select__placeholder,
                      .react-select__input {
                        font-size: 1.2rem;
                      }
                    }

                    .react-select__indicators {
                      .react-select__indicator {
                        svg {
                          width: 2rem;
                          height: 2rem;
                        }
                      }
                    }
                  }

                  .react-select__menu {
                    // border-radius: 10px;
                    // border: none;
                    // box-shadow: 1px 0px 16px #0000001a;
                    // background-color: #00000091;

                    .react-select__menu-list {

                      &::-webkit-scrollbar {
                        width: 3px;
                        height: 3px;
                        background-color: $grayC1C1C1;
                      }

                      &::-webkit-scrollbar-thumb {
                        background-color: $green;
                      }

                      .react-select__option {
                        cursor: pointer;
                        // background-color: transparent;
                        margin-bottom: 1px;
                        font-size: 1.2rem;
                        padding: 0.4rem 0.6rem;
                        text-align: left;
                      }

                      .react-select__option--is-disabled {
                        color: rgb($white, 0.5);
                      }

                      .react-select__menu-notice {
                        font-weight: 500;
                        font-size: 1.2rem;
                      }
                    }
                  }
                }

                h4 {
                  font-size: 1.4rem;
                  font-weight: bold;
                  font-family: $baseFont;
                }

                &.select_multisign {
                  width: 100%;
                  max-width: 22rem;
                  min-width: 22rem;

                  // .react-select {
                  //   background-color: red;
                  // }
                  .react-select {
                    .react-select__control {
                      width: 100%;
                      background-color: $white;
                      border-radius: 4rem;
                      cursor: pointer;
                      border: 2px solid $black;
                      height: 100%;

                      &.react-select__control--is-focused {
                        border: 2px solid $green;
                        box-shadow: none;
                      }

                      .react-select__value-container {

                        .react-select__placeholder,
                        .react-select__input-container,
                        .react-select__single-value {
                          font-size: 1.4rem;
                        }
                      }

                      .react-select__indicators {
                        .react-select__clear-indicator {
                          padding: 0.2rem 0.4rem;
                        }

                        .react-select__dropdown-indicator {
                          padding: 0.2rem 0.4rem;

                          svg {
                            width: 2.3rem;
                            height: 2.3rem;
                          }

                        }
                      }
                    }

                    .react-select__menu {
                      // border-radius: 10px;
                      // border: none;
                      // box-shadow: 1px 0px 16px #0000001a;
                      // background-color: #00000091;

                      .react-select__menu-list {
                        .react-select__option {
                          cursor: pointer;
                          // background-color: transparent;
                          margin-bottom: 1px;
                          font-size: 16px;
                          // color: $black;

                          @media (max-width: 991px) {
                            font-size: 14px;
                          }

                          @media (max-width: 575px) {
                            font-size: 12px;
                          }

                          // &:hover,
                          // &.react-select__option--is-selected {
                          //     background-color: $yellow;
                          //     color: $white;
                          // }
                        }

                        .react-select__option--is-disabled {
                          color: rgb($white, 0.5);
                        }

                        .react-select__menu-notice {
                          font-weight: 500;
                          font-size: 16px;

                          @media (max-width: 1199px) {
                            font-size: 14px;
                          }
                        }
                      }
                    }
                  }
                }

                .textUser {
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  justify-content: space-between;
                  height: 100%;
                  min-height: 7rem;

                  h4 {
                    word-break: break-word;
                    white-space: normal;
                  }

                  p {
                    font-size: 1.2rem;
                    word-break: break-word;
                    white-space: normal;
                  }
                }
              }

              &:first-child {
                td {
                  border-top: none;
                }
              }

              &:last-child {
                td {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }

      .notifiedBtn {
        padding: 0.5rem 1.9rem;
        vertical-align: middle;
        width: 100%;
        max-width: 13rem;
        margin-left: 2rem;
        height: 3rem;
        font-size: 1.2rem;

        @media (max-width: 991px) {
          margin-left: 1rem;
        }
      }

      .borderBtn {
        width: 100%;
        max-width: 21rem;
        height: 3rem;
        padding: 0.5rem 1.9rem;
        margin-left: 2rem;
        font-size: 1.2rem;

        @media (max-width: 991px) {
          margin-left: 1rem;
        }
      }

    }
  }

  .colSmall {
    @media (max-width:479px) {
      width: 100%;
    }
  }
}