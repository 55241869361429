@import '../../../Assets/_var.scss';

.helpCenter {
    text-align: center;
    background-color: $black;
    color: $white;
    padding: 12.5rem 0;
    .searchInput {
        position: relative;
        margin-top: 4rem;
        @media (max-width: 991px) {
            margin-top: 2rem;
        }

        input {
            padding: 0 6rem;
            border-radius: 4rem;

            @media (max-width: 991px) {
                padding: 0 4.3rem;
            }
        }

        span {
            max-width: 2.4rem;

            img {
                filter: brightness(0);
            }

            @media (max-width: 991px) {
                max-width: 2rem
            }
        }

        .resetBtn {
            position: absolute;
            top: 1.6rem;
            right: 2rem;
            width: fit-content;
            height: fit-content;
            background-color: transparent;
            border: none;
            box-shadow: none;
            padding: 0;

            img {
                filter: brightness(0)
            }

            @media (max-width: 991px) {
                max-width: 2.4rem;
            }

            @media (max-width: 1279px) {
                top: 1.2rem;
            }
        }
    }

    @media (max-width: 1679px) {
        padding: 10rem 0;
    }

    @media (max-width: 1439px) {
        padding: 8rem 0;
    }

    @media (max-width: 1199px) {
        padding: 6rem 0;
    }

    @media (max-width: 991px) {
        padding: 4rem 0;
    }
}

.faqSec {
    padding: 12.4rem 0 14.6rem;

    &Category {
        padding-bottom: 7.5rem;

        &:last-child {
            padding-bottom: 0;
        }
    }

    @media (max-width: 1679px) {
        padding: 10rem 0;

        &Category {
            padding-bottom: 6rem;
        }
    }

    @media (max-width: 1439px) {
        padding: 8rem 0;

        &Category {
            padding-bottom: 5rem;
        }
    }

    @media (max-width: 1199px) {
        padding: 7rem 0;

        &Category {
            padding-bottom: 4rem;
        }
    }

    @media (max-width: 991px) {
        padding: 6rem 0;

        &Category {
            padding-bottom: 3rem;
        }
    }
}