@import '../../../../Assets/_var.scss';


.articlesSec {
    &Slider {
        margin: 0;

        :global {

            .slick-slide {
                >div {
                    margin: 0;
                }
            }

            .slick-arrow {
                top: -5rem;
            }
        }
    }

    h2 {
        border-top: 2px solid $borderGray;
        border-bottom: 2px solid $borderGray;
        padding: 1.5rem 0;
    }

    &Content {
        padding-top: 4rem;

        @media (min-width: 1680px) {
            max-width: 45.5rem;
        }

        @media (max-width: 1679px) {
            padding: 4rem 1.5rem 0 0;
        }

        p {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: 991px) {
        &Content {
            padding: 3rem 2rem 0 0;
        }
    }

    @media (max-width: 575px) {

        h2,
        &Content {
            text-align: center;
        }

        &Content {
            padding: 2rem 0 0 0;
        }
    }
}