@import '../../../Assets/_var.scss';

.closedDropModal {
    :global {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    padding: 2rem 3rem 1rem;

                    .modal-title {
                        font-size: 3.2rem;
                        font-family: $baseFont;

                        @media (max-width:1199px) {
                            font-size: 2.6rem;
                        }

                        @media (max-width:991px) {
                            font-size: 2.2rem;
                        }
                    }
                }

                .modal-body {
                    padding: 1rem 3rem 3rem;
                }
            }
        }
    }

    .innerSec {
        p {
            font-size: 1.6rem;
            font-family: $baseFont;
            font-weight: 500;

            @media (max-width:767px) {
                font-size: 1.4rem;
            }

            a {
                font-weight: 700;
                padding: 0 0.6rem;
                color: $yellow2;
            }
        }
    }
}