@import '../../../../Assets/_var.scss';

:global {
    .fade.modal-backdrop.show {
        background-color: $black  !important;
        opacity: 0.85;
    }
}

.moremodal {

    :global {
        .modal-dialog {
            max-width: 110rem;

            @media (max-width: 1439px) {
                max-width: 100rem;
            }

            @media (max-width: 1199px) {
                max-width: 90rem;
            }


            .modal-content {
                border: none;


                .modal-header,
                .modal-body {
                    padding: 0;

                    .searchfield {
                        .searchbar {
                            margin-bottom: 1rem;
                            width: 100%;
                            max-width: 32rem;
                            height: 4rem;
                            border-radius: 2rem;
                            padding: 1rem;
                            border: none;
                            font-size: 1.6rem;

                            &:focus {
                                outline: none;
                                box-shadow: none;
                            }
                        }
                        .btnSize {
                            height: 4rem;
                            margin-left: 1rem;
                        }
                    }

                    .text_list {
                        height: 41rem;
                        display: flex;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                        flex-direction: column;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        align-content: flex-start;
                        white-space: nowrap;
                        overflow-x: auto;
                        overflow-y: hidden;
                        color: #fff;
                        margin-top: 1.5rem;

                        li {
                            padding-right: 2rem;
                            margin-bottom: 0.4rem;
                            min-width: 13rem;

                            &.titleletter {
                                font-size: 2rem;
                                font-weight: 700;
                                display: flex;
                                align-items: center;
                            }

                            :global {
                                .fieldBlack {
                                    margin-bottom: 0 !important;
                                }
                            }
                        }
                    }

                }
            }
        }
    }

    .modalcontent {
        padding: 4rem 2rem 1rem;
    }
}