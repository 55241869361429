@import '../../../../../../Assets/_var.scss';

.inprocess {
    .table_section {
        padding-bottom: 3rem;

        .artowned_table {
            table {
                border-collapse: collapse;
                border-spacing: 0;
                border: 1px solid $gray707070;

                thead {
                    tr {
                        border: 1px solid $gray707070;

                        th {
                            border: none;
                            font-size: 1.6rem;
                            font-weight: bold;
                            color: $white;
                            font-family: 'Roboto';
                            padding: 2rem 1.8rem;
                            background: $black;

                            @media (max-width:1679px) {
                                padding: 1.6rem;
                            }

                            @media (max-width:1399px) {
                                padding: 1.4rem;
                                font-size: 1.4rem;
                            }

                            @media (max-width:991px) {
                                padding: 1rem;
                                font-size: 1.2rem;
                            }

                            &.btnthead {
                                width: 15rem;
                                min-width: 15rem;
                                max-width: 15rem;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            font-size: 1.6rem;
                            font-weight: 400;
                            color: $black;
                            padding: 2.2rem 1.8rem;
                            border: 0.1rem solid $gray707070;

                            @media (max-width:1679px) {
                                padding: 2rem 1.6rem;
                            }

                            @media (max-width:1399px) {
                                padding: 1.8rem 1.4rem;
                                font-size: 1.4rem;
                            }

                            @media (max-width:991px) {
                                padding: 1.4rem 1rem;
                                font-size: 1.2rem;
                            }

                            .dateTime {
                                h6 {
                                    font-weight: 400;
                                    color: $black;
                                }

                                p {
                                    font-size: 1.2rem;
                                    font-weight: 400;
                                    color: $black;
                                }
                            }

                            &:last-child {
                                text-align: center;
                            }

                            .btn_size {
                                height: 3rem;
                                font-size: 1.2rem;
                                font-weight: bold;
                                padding: 0.8rem 1.6rem;
                                width: 100%;
                                max-width: 10rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cancel_cashoutmodal {
    .modal-dialog {
        max-width: 50rem;

        .modal-content {

            .modal-header {
                padding: 2rem;
            }

            .modal-body {
                padding: 1rem 2rem 4rem;

                .main_content {
                    text-align: center;

                    h5 {
                        color: $white;
                        font-weight: bold;
                    }

                    .btns_section {
                        margin-top: 3rem;

                        .btn_sizeing {
                            height: 3.6rem;
                            font-weight: bold;
                            font-size: 1.6rem;
                            margin-inline: 1rem;
                            min-width: 10rem;
                            padding: 0.5rem 1rem;

                            &:first-child {
                                margin-left: 0;
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.reject_modal {

    .modal-dialog {
        .modal-content {
            .modal-header {
                padding: 2rem;

                .modal-title {
                    font-size: 2rem;
                }
            }

            .modal-body {
                padding: 1rem 2rem 4rem;

                .main_content {
                    p {
                        font-size: 1.4rem;
                        font-weight: 400;
                        color: $white;
                    }

                    .iconUp {
                        display: flex;
                        margin: 1.5rem 0;
                        border: 0.1rem solid;
                        background: transparent;
                        padding: 0.6rem 1.5rem;
                        color: $white;
                        align-items: center;
                        font-size: 1.6rem;
                        font-weight: bold;
                        border-radius: 3.5rem;
                        width: 100%;
                        max-width: max-content;

                        svg {
                            width: 2.4rem;
                            height: auto;
                            margin-right: 0.5rem;

                            path {
                                fill: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}