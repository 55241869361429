@import "../../../../Assets/_var.scss";

.notification_tabs {
  padding-top: 0;
  margin-bottom: 2rem;
  width: fit-content;
  :global {
    .common_tab {
      padding: 0 0 1.5rem;
      margin-left: 3rem;

      @media (max-width: 1199px) {
        padding: 0 4px 0.6rem;
        margin-left: 2rem;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.notification_tabsContent {
  padding-bottom: 0;

  .btnNotfi {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 2rem;
    display: inline-block;
    height: unset;
    border: none !important;
    width: unset;

    &::after {
      content: unset;
    }

  }
  .clearBtn {
    padding: 0rem 1rem;
    font-size: 1.6rem;
    font-weight: 400;
    border-radius: 0;

    &:disabled {
      color: $borderGray;
    }

    @media (max-width: 991px) {
      font-size: 1.4rem;
    }
  }

  .notifyBox {
    border-bottom: 2px solid #b9b9b9;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .thumbIcon {
      width: 5rem;
      height: 5rem;
      flex-shrink: 0;
      border-radius: 50%;
    }

    &Inner {
      position: relative;
      width: 100%;
      padding: 0 2.6rem 0 1.5rem;

      h4 {
        font-family: $baseFont;
        font-weight: 400;
        font-size: 1.4rem;
        word-break: break-word;

        p {
          font-family: $baseFont;
          font-weight: 400;
          font-size: 1.4rem;
          word-break: break-word;
          color: #212529;
        }

        span {
          &.textBold {
            font-weight: bold;
          }

          &.textYellow {
            color: $yellow;
            font-weight: bold;
          }

          &.textMute {
            color: $borderClr2;
          }
        }
      }

      p {
        font-size: 1.4rem;
        font-weight: 400;
        color: $borderClr2;
        margin-top: 0.5rem;
        word-break: break-word;
      }

      .readDot {
        width: 0.8rem;
        height: 0.8rem;
        background-color: $borderClr2;
        border-radius: 50%;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        transform: translateY(-50%);

        &.active {
          background-color: $yellow;
        }
      }
    }

    @media (max-width: 1439px) {
      padding: 1.5rem 0;

      .thumbIcon {
        width: 5rem;
        height: 5rem;
      }

      &Inner {
        padding: 0 1.4rem 0 2rem;
      }
    }

    @media (max-width: 1199px) {
      padding: 1rem 0;

      .thumbIcon {
        width: 4.4rem;
        height: 4.4rem;
      }

      &Inner {
        padding: 0 1.4rem 0;
      }
    }

    @media (max-width: 991px) {
      padding: 1rem 0;

      .thumbIcon {
        width: 4rem;
        height: 4rem;
      }

      &Inner {
        h4,
        p {
          font-size: 1.4rem;
        }

        p {
          margin-top: 0;
        }

        .readDot {
          width: 6px;
          height: 6px;
        }
      }
    }
  }
}
