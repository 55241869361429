@import '../../../Assets/_var.scss';

.link_devices {
    &_section {
        .tablist {
            border-top: 0.1rem solid #707070;

            :global {
                .nav_tabslist {
                    border-right: 0.1rem solid #707070;

                    .nav-item {
                        @media (max-width:991px) {
                            width: 100%;
                            max-width: max-content;
                            text-align: center;
                            margin-right: 2rem;

                            &:last-child {
                                margin-right: 0;
                            }
                        }

                        .nav-link {
                            font-size: 1.6rem;
                            font-weight: 400;
                            color: #969696;
                            line-height: 2.4rem;
                            padding: 2rem 0;
                            border-bottom: 1px solid #707070;
                            box-shadow: none;
                            border-radius: 0;

                            @media (max-width:1199px) {
                                font-size: 1.4rem;
                                padding: 1.2rem 0;
                            }

                            &.active {
                                font-weight: bold;
                                color: #313131;
                            }
                        }
                    }
                }
            }
        }
    }
}