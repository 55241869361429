@import '../../../../../Assets/_var.scss';

.checkoutDetails {
    .topHeading {
        border-bottom: 0.2rem solid $borderClr4;
        padding-bottom: 2rem;

        h3 {
            font-family: $baseFont2;
        }
    }

    &_innerSec {
        display: flex;
        align-items: flex-start;

        @media (min-width:992px) and (max-width:1279px) {
            flex-direction: column;
        }

        @media (max-width:767px) {
            flex-direction: column;
        }

        &Leftside {
            flex: 0 0 25.5rem;
            width: 100%;
            max-width: 25.5rem;
            padding-top: 3.9rem;

            @media (max-width:1679px) {
                padding-top: 3rem;
            }

            @media (max-width:1439px) {
                padding-top: 2.5rem;
                flex: 0 0 23.5rem;
                max-width: 23.5rem;
            }

            @media (min-width:992px) and (max-width:1279px) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
            }

            @media (max-width:767px) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            .listing {
                margin: -0.7rem 0;

                @media (min-width:992px) and (max-width:1279px) {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }

                @media (min-width:480px) and (max-width:767px) {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }

                li {
                    display: flex;
                    align-items: center;
                    padding: 0.7rem 0;

                    @media (min-width:992px) and (max-width:1279px) {
                        flex: 0 0 calc(100% / 2);
                        width: 100%;
                        max-width: calc(100% / 2);
                    }

                    @media (min-width:480px) and (max-width:767px) {
                        flex: 0 0 calc(100% / 2);
                        width: 100%;
                        max-width: calc(100% / 2);
                    }

                    label {
                        font-size: 1.4rem;
                        font-weight: bold;
                        color: $blacklight;
                        line-height: 1.2;
                        width: 100%;
                        max-width: 10rem;
                    }

                    p {
                        color: $black2;
                        font-weight: 400;
                    }
                }
            }
        }

        &Rightside {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(100% - 25.5rem);
            width: 100%;
            max-width: calc(100% - 25.5rem);
            padding-left: 3.5rem;
            padding-top: 3.9rem;
            border-left: 0.1rem solid $light_gray4;

            @media (max-width:1679px) {
                padding-top: 3rem;
                padding-left: 3rem;
            }

            @media (max-width:1439px) {
                padding-top: 2.5rem;
                padding-left: 2.5rem;
                flex-basis: calc(100% - 23.5rem);
                max-width: calc(100% - 23.5rem);
            }

            @media (min-width:992px) and (max-width:1279px) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-top: 1rem;
                padding-left: 0;
                border-top: 0.1rem solid $light_gray4;
                border-left: 0;
            }

            @media (max-width:767px) {
                flex: 0 0 100%;
                max-width: 100%;
                padding-top: 1rem;
                padding-left: 0;
                border-top: 0.1rem solid $light_gray4;
                border-left: 0;
            }

            &_list {
                width: 100%;
                max-width: 45.2rem;

                li {
                    .priceInfo {
                        display: flex;
                        align-items: center;
                        margin-bottom: 1.8rem;

                        label {
                            color: $blacklight;
                            font-size: 1.4rem;
                            font-weight: bold;
                            line-height: 1.2;
                            width: 100%;
                            max-width: 11.5rem;
                        }

                        span {
                            margin-left: 0.8rem;
                            font-weight: 400;
                            color: $blacklight;
                            margin-right: 0.3rem;
                            line-height: 1;
                        }

                        p {
                            font-weight: 400;
                            color: $blacklight;
                        }

                        :global {
                            .common_input {
                                margin: 0;

                                &.checkField {
                                    max-width: 9.6rem;
                                }

                                &_inner {
                                    .leftIcon {
                                        left: 0.3rem;
                                        width: 1rem;
                                        margin-inline: 0.4rem;
                                    }

                                    #number {
                                        padding: 0.5rem 1.8rem;
                                        border-radius: 1rem;
                                        border: 1px solid $borderClr6;
                                        height: 3rem;
                                    }

                                    #gstvalue {
                                        border: 1px solid $borderClr6;
                                        border-radius: 3rem;
                                    }

                                    .rightIcon {
                                        right: 0.3rem;
                                        width: 1rem;
                                        margin-inline: 0.4rem;
                                    }
                                }
                            }
                        }
                    }

                    .totalAmount {
                        display: flex;
                        align-items: center;
                        margin: 3.7rem 0 1.4rem;

                        @media (max-width:1679px) {
                            margin: 3rem 0 1.2rem;
                        }

                        @media (max-width:1399px) {
                            margin: 2.5rem 0 1rem;
                        }

                        @media (max-width:991px) {
                            margin-top: 2rem;
                        }

                        @media (max-width:575px) {
                            margin-top: 1rem;
                        }

                        label {
                            color: $blacklight;
                            font-size: 1.8rem;
                            font-weight: bold;
                            line-height: 1.2;
                            width: 100%;
                            max-width: 11.5rem;

                            @media (max-width:1439px) {
                                font-size: 1.6rem;
                            }

                            @media (max-width:1199px) {
                                font-size: 1.4rem;
                            }
                        }

                        span {
                            margin-left: 0.8rem;
                            font-weight: 400;
                            color: $blacklight;
                            margin-right: 0.3rem;
                            line-height: 1;
                        }

                        p {
                            font-size: 1.8rem;
                            font-weight: 400;
                            color: $blacklight;

                            @media (max-width:1439px) {
                                font-size: 1.6rem;
                            }

                            @media (max-width:1199px) {
                                font-size: 1.4rem;
                            }
                        }
                    }

                    :global {
                        .common_input {
                            &.radio_btn {
                                margin-bottom: 1.3rem;

                                .form-check {
                                    display: flex;
                                    align-items: center;
                                    padding: 0;

                                    &-input {
                                        border: 1px solid $gray707070;
                                        width: 1.2rem;
                                        height: 1.2rem;
                                        line-height: 1;
                                        margin: 0;

                                        &:checked {
                                            background: $black;
                                            border-color: $black;
                                            box-shadow: none;
                                        }
                                    }

                                    &-label {
                                        font-size: 1.4rem;
                                        font-weight: bold;
                                        color: $blacklight;
                                        padding-left: 1.8rem;
                                    }
                                }
                            }
                        }
                    }
                }

                .couponField {
                    max-width: 31.1rem;
                    width: 100%;

                    .availCoupen {
                        display: flex;
                        align-items: flex-start;

                        .inputField {
                            margin-bottom: 2rem;
                            width: 100%;
                            text-align: right;

                            @media (max-width:1679px) {
                                margin-bottom: 1.5rem;
                            }

                            @media (max-width:1199px) {
                                margin-bottom: 1rem;
                            }

                            :global {
                                .input_spaces {
                                    margin-bottom: 0;
                                }
                            }

                            .btnAvail {
                                font-size: 1.2rem;
                                font-weight: 400;
                                color: $black020202;
                                line-height: 1.2;
                                height: auto;
                            }
                        }

                        .btnApply {
                            height: 3.5rem;
                            font-size: 1.4rem;
                            padding: 0.4rem 2rem;
                            max-width: 8rem;
                            width: 100%;
                            border-radius: 1.8rem;
                            margin-left: 2rem;
                        }
                    }


                    .discountValue {
                        display: flex;
                        align-items: center;
                        margin-bottom: 3.9rem;

                        @media (max-width:1679px) {
                            margin-bottom: 3rem;
                        }

                        @media (max-width:1439px) {
                            margin-bottom: 2.5rem;
                        }

                        label {
                            color: $blacklight;
                            font-size: 1.4rem;
                            font-weight: bold;
                            line-height: 1.2;
                        }

                        span {
                            margin-left: 0.8rem;
                            font-weight: 400;
                            color: $blacklight;
                            margin-right: 0.3rem;
                        }

                        p {
                            font-weight: 400;
                            color: $blacklight;
                        }
                    }
                }

                .form_gstinfo {
                    .gst_details {
                        margin-top: 2.1rem;

                        @media (max-width:1679px) {
                            margin-bottom: 1.8rem;
                        }

                        @media (max-width:1399px) {
                            margin-bottom: 1.3rem;
                        }

                        @media (max-width:991px) {
                            margin-bottom: 1rem;
                        }


                        .select {
                            margin-bottom: 2.1rem;

                            @media (max-width:1679px) {
                                margin-bottom: 1.8rem;
                            }

                            @media (max-width:1399px) {
                                margin-bottom: 1.3rem;
                            }

                            @media (max-width:991px) {
                                margin-bottom: 1rem;
                            }

                        }

                        .checkField {
                            border: 1px solid $borderClr6;
                            border-radius: 3rem;
                        }

                        :global {
                            .row {
                                @media (max-width:1439px) {
                                    margin-inline: -1rem;
                                }

                                >div {
                                    @media (max-width:1439px) {
                                        padding-inline: 1rem;
                                    }
                                }
                            }
                        }
                    }

                }
            }

            .paymentBtn {
                height: 4rem;
                max-width: 18rem;
                width: 100%;
                padding: 0.7rem 1.2rem;
                font-size: 1.6rem;
            }

            :global {

                .input_spaces,
                .input_number {
                    margin-bottom: 2.1rem;

                    @media (max-width:1679px) {
                        margin-bottom: 1.8rem;
                    }

                    @media (max-width:1399px) {
                        margin-bottom: 1.3rem;
                    }

                    @media (max-width:991px) {
                        margin-bottom: 1rem;
                    }

                    .common_input_inner {

                        .input_border,
                        .phone_border {
                            border-radius: 1rem !important;
                            height: 3.5rem;
                            font-size: 1.2rem;
                            border: 1px solid $black;
                            padding: 0.9rem 1.9rem;
                            font-weight: bold;
                            color: $black020202;

                            .PhoneInputInput {
                                font-size: 1.2rem;
                                font-weight: bold;
                                margin-left: 0.5rem;
                                color: $black020202;
                            }
                        }
                    }
                }

                .common_input {
                    &.checkselect {
                        max-width: 100%;
                        margin-bottom: 0.9rem;

                        .form-check {
                            display: flex;
                            align-items: center;

                            &-input {
                                height: 1.2rem;
                                width: 1.2rem;
                                border-color: $gray707070;

                                &:checked {
                                    background-color: $black;
                                    border-color: $black;
                                }
                            }

                            &-label {
                                max-width: 100%;
                                font-size: 1.2rem;
                                color: $blacklight;
                                padding-left: 1.4rem;
                            }
                        }
                    }
                }

                .common_select_inner {
                    height: 3.5rem;

                    .react-select {
                        .react-select__control {
                            font-size: 1.2rem;
                            font-weight: bold;
                            border: 1px solid $black;
                            border-radius: 1rem;

                            .react-select__placeholder {
                                color: $black020202;
                                font-size: 1.2rem;
                                font-weight: bold;
                            }

                            .react-select__value-container {
                                padding: 0 0.6rem;
                                padding-left: 1.9rem;

                                .react-select__input {
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                }
                            }

                            .react-select__indicators {
                                .react-select__indicator-separator {
                                    display: none;
                                }

                                .react-select__dropdown-indicator {
                                    svg {
                                        fill: $black;
                                        width: 2rem;
                                        height: 2rem;
                                    }
                                }
                            }
                        }

                        .react-select__menu {
                            &-list {
                                .react-select__option {
                                    font-size: 1.2rem;
                                    padding: 0.5rem 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}