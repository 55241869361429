@import "../../../../Assets/_var.scss";

.ShareBtn {
  position: relative;
  margin-right: 1rem;

  &.dropdown.show {
    .dropdown-toggle.show {
      svg {
        .Icon_path {
          fill: $black;
        }
      }
    }
  }

  .dropdown-toggle {
    background-color: transparent !important;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;

    svg {
      height: 5.3rem;
      width: 5.3rem;
      transition: 0.3s all ease-in-out;

      @media (max-width: 1679px) {
        width: 4.8rem;
        height: 4.8rem;
      }

      @media (max-width: 1439px) {
        width: 4rem;
        height: 4rem;
      }
    }

    &:after {
      content: none;
    }

    &:focus,
    &:focus-within,
    &:focus-visible,
    &:hover {
      outline: none;
      background-color: transparent;
      box-shadow: none;
    }

    &.show {
      svg {
        path {
          &:first-of-type {
            fill: $black;
          }

          &:last-of-type {
            fill: $black  !important;
          }
        }
      }
    }
  }

  .dropdown-menu {
    z-index: 9992;
  }

  > div {
    margin-top: 1rem;
    background-color: rgba($black, 0.7);
    border: 0 !important;

    padding: 1.8rem 2rem;
    min-width: 27rem;
    border: 2px solid $black;
    border-radius: 0.7rem;

    @media (max-width: 1199px) {
      min-width: 20rem;
    }

    @media (max-width: 991px) {
      padding: 1rem 1rem;
    }

    a {
      color: $white;
      font-size: 1.8rem;
      border: 1px solid $white;
      margin: 2rem 0;
      padding: 1rem;
      transition: 0.3s all ease-in-out;
      border-radius: 0.5rem;
      background-color: $black;
      height: 5.6rem;

      span {
        padding-right: 1rem;

        svg {
          width: 3rem !important;
          height: 3rem !important;

          path,
          polygon {
            transition: 0.3s all ease-in-out;
          }
        }
      }

      @media (max-width: 1199px) {
        font-size: 1.6rem;
        padding: 0.5rem 1rem;
        margin: 1rem 0;
      }

      @media (max-width: 575px) {
        font-size: 1.4rem;
      }

      &:last-child {
        border-bottom: 1px solid $white;
      }

      &:hover {
        background-color: $yellow;
        color: $white;

        span {
          svg {

            path,
            polygon {
              fill: $white;
            }
          }
        }
      }
    }
  }
}

.btnShare {
  .shareIcons {
    background-color: rgba($black, 0.7) !important;
    border: 0 !important;
    padding: 1.8rem 2rem;
    border: 2px solid $black  !important;
    width: 100%;
    text-align: left;
    height: 100%;

    svg {
      width: 3rem !important;
      height: 3rem !important;
      margin-right: 1rem;

      &.fillData {
        path {
          fill: $white;
        }
      }
    }
  }
}

.dropdown {
  &.show {
    .dropdown-toggle {
      &.show {
        svg {
          transform: rotate(180deg) !important;
          transition: 0.3s all ease-in-out;
        }
      }
    }
  }
}