@import "../../../../../../Assets/_var.scss";

.invoice_modal {
  .btnsize {
    font-size: 1.8rem;
    height: 4.5rem;

    svg {
      margin-left: 0.8rem;
      width: 2.5rem;
      height: 2.5rem;

      path {
        stroke: $black;
        stroke-width: 3px;
      }
    }

    &:hover {
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }

  .modal-dialog {
    max-width: 102rem !important;
    width: 100%;
  }

  .main-handle {
    border: 0;
    width: 100%;
    margin: 15px auto !important;
    background-color: #fff;
    font-family: "Roboto", sans-serif !important;
    padding: 0;
    border-collapse: collapse;
    // background-image: url(../../../../../../Assets/images/BG.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    border: none;
  }

  .main_table {
    padding: 20px 25px;
    width: 100%;
    display: block;

    tbody {
      width: 100%;
      display: inline-table;
    }
  }

  table {
    border-spacing: 0;
  }

  .header-text {
    width: 600px;
    height: 40px;
    background-color: $white;
    border-collapse: collapse;
    padding-bottom: 30px;
  }

  .heading_detail {
    font-size: 10px !important;
    text-align: left !important;
    line-height: 16px !important;
    color: $white;
    width: 90px;
  }

  .heading_data {
    font-size: 10px !important;
    color: #ffffffcc;
  }

  .heading_table {
    width: 100%;
  }

  .heading_table img {
    width: 180px;
    height: 35px;
  }

  .header-text.ftr {
    height: 30px;
  }

  .header-text.ftr_link {
    background-color: $white;
    height: 50px;
    padding: 0;
    padding-bottom: 15px;
  }

  .header-text.ftr_link p {
    color: #6f6f6f;
    font-family: "Roboto", sans-serif !important;
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .header-text p {
    font-size: 11px;
    text-align: center;
    line-height: 13px;
    color: #ffffff;
    font-family: "Roboto", sans-serif !important;
  }

  .heading_logo {
    width: 60%;
  }

  .heading_td {
    width: 40%;

    h6 {
      text-align: right;
      font-size: 12px;
      line-height: 1;
      color: $black;
    }
  }

  .invoicDetails {
    border-top: 1px solid $borderClr4;
    padding: 20px 0;

    .list {
      li {
        padding-bottom: 10px;
        font-family: "Roboto", sans-serif;

        label {
          min-width: 100px;
          font-size: 10px;
          font-weight: 400;
          color: $black;
          font-family: "Roboto", sans-serif;
          float: left;
          padding-right: 27px;
        }

        h6 {
          font-size: 10px;
          font-weight: 400;
          color: $black;
          font-family: 'Roboto', sans-serif;

          sub {
            padding-right: 7px;
            bottom: 1px;
            font-size: 10px;
            font-weight: 400;
          }
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  .innerSec {
    td {
      .conaint {
        width: 100%;

        tbody {
          tr {
            .addressDetails {
              width: 50%;
              vertical-align: top;

              .leftDetails {
                width: 100%;
                max-width: 94%;

                tbody {
                  .topHeading {
                    td {
                      font-size: 10px;
                      font-weight: 400;
                      border-bottom: 1px solid $borderClr4;
                      width: 190px;
                      display: inline-block;
                      line-height: 18px;
                      margin-bottom: 11px;
                      font-family: "Roboto", sans-serif !important;
                    }
                  }

                  .infoSeller {
                    td {
                      .Headingtitle {
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 16px;
                        word-spacing: 3.5px;
                        white-space: pre-wrap;
                        font-family: "Roboto", sans-serif;
                      }

                      .listing {
                        li {
                          p {
                            font-size: 10px;
                            font-weight: 400;
                            color: $black;
                            line-height: 18px;
                            font-family: "Roboto", sans-serif !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &.lineHoriz {
              margin: 20px 0;
              height: 1px;
              width: 100%;
              background-color: $borderClr4;
              display: inline-table;
            }

            &.detailsCard {
              display: inline-table;
              height: 100%;
              width: 100%;

              .imagCard {
                width: 150px;

                .up-Img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: top center;
                }
              }

              .rightSide {
                width: calc(100% - 150px);
                padding-left: 30px;

                table {
                  width: 100%;

                  tbody {
                    .detailId {
                      td {
                        width: 100%;

                        h4 {
                          font-size: 16px;
                          font-weight: 800;
                          margin-bottom: 5px;
                          line-height: 20px;
                          color: $black;
                          font-family: "Roboto", sans-serif !important;
                        }

                        .edition {
                          display: inline-table;
                          width: 100%;

                          label {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            float: left;
                            color: $black;
                            font-family: "Roboto", sans-serif !important;
                            min-width: 80px;
                          }

                          p {
                            color: $black;
                            font-size: 12px;
                            line-height: 16px;
                            font-weight: 400;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &.artistDetail {
              border: 1px solid $borderClr4;
              border-left: 0;
              border-right: 0;

              .details {
                width: 50%;
                display: inline-block;
                border-right: 1px solid $borderClr4;

                table {
                  width: 100%;
                  margin: 8px 0;

                  tbody {
                    tr {
                      .artistImg {
                        width: 36px;
                        height: 36px;
                      }

                      .artisInfo {
                        padding-left: 10px;

                        p {
                          color: #6e6e6e;
                          font-size: 10px;
                          font-weight: 400;
                          line-height: 14px;
                          font-family: "Roboto", sans-serif;
                        }

                        h6 {
                          color: $black;
                          font-size: 10px;
                          line-height: 15px;
                          font-family: 'Roboto', sans-serif;
                        }
                      }
                    }
                  }
                }
              }

              .seriesInfo {
                width: 50%;
                display: inline-block;
                padding-left: 15px;

                p {
                  color: #6e6e6e;
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 16px;
                  font-family: "Roboto", sans-serif;
                }

                h6 {
                  color: $black;
                  font-size: 10px;
                  line-height: 16px;
                  font-family: "Raleway", sans-serif;
                }
              }
            }

            &.priceInfo {
              padding-top: 20px;
              display: inline-block;

              td {
                font-size: 16px;
                line-height: 1.2;

                .listingprice {
                  width: 100%;
                  max-width: 200px;

                  span {
                    width: 100%;
                    display: inline-block;
                    padding-bottom: 0;
                    font-family: "Roboto", sans-serif;
                    // &.disable {
                    //   opacity: 0.6;

                    //   label {
                    //     text-align: right;
                    //   }
                    // }

                    label {
                      min-width: 85px;
                      font-size: 10px;
                      font-weight: bold;
                      color: $black;
                      font-family: "Roboto", sans-serif;
                      float: left;
                      padding-right: 15px;
                    }

                    sub {
                      margin-right: 0;
                      bottom: 6px;

                      color: $black;
                    }

                    p {
                      text-align: right;
                      font-size: 10px;
                      font-weight: 400;
                      color: $black;
                      font-family: "Roboto", sans-serif;
                      float: right;
                      width: 110px;
                    }

                    &:last-child {
                      padding-bottom: 0;
                    }
                  }
                }
              }
            }

            &.signatory {
              padding: 20px 0 40px;
              display: inline-block;
              width: 100%;
              max-width: 150px;

              td {
                text-align: center;
                display: inline-table;
                width: 100%;

                img {
                  width: 71px;
                  height: 36px;
                  object-fit: cover;
                }

                h6 {
                  font-size: 12px;
                  line-height: 24px;
                  font-weight: 700;
                  padding-top: 5px;
                  color: $black;
                  font-family: 'Roboto', sans-serif;

                  span {
                    color: $black;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .nif_bg {
    height: 180px;
    background-image: url(../../../../../../Assets/images/nifttybg.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
  }

  .logo-outer {
    width: 100%;
    text-align: center;
  }

  .billing_table {
    width: 100%;
  }

  .invoice_billing {
    background-color: $white;
    width: 100%;
  }

  .sold_data {
    width: 100%;
    max-width: 470px;
    margin: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid $borderClr4;
  }

  .sold_data td {
    font-size: 10px;
    line-height: 18px;
    color: $black;
  }

  .billing_data {
    margin-top: 0;
    padding-bottom: 0 !important;
    border-bottom: 0 !important;
  }

  .gst_no {
    padding-top: 20px;

    span {
      margin-left: 6px;
      color: $black;
    }
  }

  .invoice_table {
    border-spacing: 0;
    width: 100%;
  }

  .invoice_table tbody tr {
    vertical-align: top;
  }

  .invoice_table th {
    background-color: #d6d6d6;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    padding: 5px 8px;
    text-align: left;
    border-right: 1px solid #eaeaea;
  }

  .invoice_table th:first-child {
    padding-left: 10px;
  }

  .invoice_table th:last-of-type {
    border-right: 0;
  }

  .art_table td {
    border-right: 0 !important;
    padding: 3px !important;
    font-size: 9px !important;

    &.pt-0 {
      padding-top: 0 !important;
    }
  }

  .art_detail td {
    width: 100px;
  }

  .invoice_table td {
    background-color: #f8f8f8;
    font-size: 9px;
    /* line-height: 18px; */
    font-weight: 400;
    padding: 5px 8px;
    text-align: left;
    border-right: 1px solid #eaeaea;
  }

  .invoice_table td:first-child {
    padding-left: 10px;
  }

  .invoice_table td:last-of-type {
    border-right: 0;
  }

  .invoice_table td img {
    width: 50px;
    height: 59px;
  }

  .total_balance td {
    height: 30px;
    background-color: #121212;
    border-collapse: collapse;
    border-right: 0;
    color: $white;
    vertical-align: middle !important;
  }

  /***Media Query***/

  @media (max-width: 767px) {
    .main_table {
      padding: 10px 15px;
    }

    .ftr_link {
      padding: 0 15px 15px !important;
    }

    .header-text {
      padding-bottom: 10px;
    }

    .heading_logo,
    .heading_td {
      width: 50%;
    }

    .sold_data {
      margin: 7px;
    }
  }

  @media (max-width: 575px) {
    .main_table {
      padding: 10px;
    }

    .ftr_link {
      padding: 0 10px 10px !important;
    }

    .innerSec {
      td {
        .conaint {
          tbody {
            tr {
              .addressDetails {
                width: 100%;
                display: inline-block;
                vertical-align: top;
              }

              &.detailsCard {
                display: inline-table;
                height: 100%;
                width: 100%;

                .imagCard {
                  width: 150px;
                  display: inline-block;

                  .up-Img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top center;
                  }
                }

                .rightSide {
                  width: 100%;
                  display: inline-block;
                  padding-top: 15px;
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}