@import '../../../Assets/_var.scss';

.modalSuccess {
    .modal-dialog {
        width: 50rem;

        .modal-content {
            background-color: $white;

            .modal-header {
                padding: 0;

                .btn-close {
                    background-image: url(../../../Assets/icons/closeIconBlack.svg);

                    &:hover {
                        background-image: url(../../../Assets/icons/closeIcon-yellow.png);
                    }
                }
            }

            .modal-body {
                padding: 4rem;

                h3 {
                    font-size: 3.2rem;
                    font-weight: 700;
                    line-height: 1.6;
                    margin-bottom: 2rem;
                    color: $black;
                    text-align: center;
                }

                p {
                    font-size: 1.6rem;
                    font-weight: 400;
                    line-height: 1.6;
                    text-align: center;
                    color: $black;
                }
            }
        }
    }
}

.modalcontactsupport {
    .modal-dialog {
        width: 50rem;

        .modal-content {
            background-color: $white;

            .modal-header {
                padding: 0;

                .btn-close {
                    background-image: url(../../../Assets/icons/closeIconBlack.svg);

                    &:hover {
                        background-image: url(../../../Assets/icons/closeIcon-yellow.png);
                    }
                }
            }

            .modal-body {
                padding: 4rem 5rem;

                p {
                    font-size: 1.6rem;
                    font-weight: 400;
                    line-height: 1.6;
                    text-align: center;
                    color: $black;
                    margin-bottom: 1rem;

                    a {
                        color: $black;
                        font-weight: 700;
                        text-decoration: underline;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}