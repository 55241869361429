@import "../../../../Assets/_var.scss";

.heroSec {
  position: relative;
  overflow: hidden;
  margin-bottom: 4rem;
  margin-top: 0;
  padding: 0 15px;

  h1 {
    font-size: 10.4rem;
    font-weight: bold;
    font-family: $baseFont2;
    color: $black;
    line-height: 1;
    padding-top: 13rem;
    margin-bottom: 3rem;
    word-break: break-word;
  }

  &LeftSlider,
  &RightSlider {
    &Slide {
      height: auto;
      width: 100%;
      margin: 1rem 0;

      img {
        width: 100%;
        max-width: 100%;
      }

      &:hover,
      &:focus {
        outline: none;
      }
    }
  }

  &RightSlider {
    transform: rotate(180deg);

    &Slide {
      transform: rotate(-180deg);
    }
  }

  :global {
    .slick-list {
      padding: 20% 0 20% 0 !important;
      // height: calc(100vh - 17rem) !important;
      height: 100%;

      // @media (max-width:1679px) {
      // height: calc(100vh - 15rem) !important;
      // }

      // @media (max-width:767px) {
      // height: calc(100vh - 26rem) !important;
      // }
    }
  }

  @media (max-width: 1679px) {
    margin-bottom: 2rem;

    h1 {
      font-size: 9.4rem;
      padding-top: 7rem;
    }
  }

  @media (max-width: 1439px) {
    h1 {
      font-size: 7rem;
    }
  }

  @media (max-width: 1199px) {
    margin-bottom: 6rem;

    h1 {
      font-size: 5rem;
      line-height: 1.2;
    }
  }

  @media (max-width: 991px) {
    h1 {
      font-size: 4rem;
    }
  }

  @media (max-width: 767px) {
    text-align: center;

    br {
      display: none;
    }

    h1 {
      font-size: 3.4rem;
      padding-top: 3rem;
      margin-bottom: 0;
      padding-bottom: 1rem;
    }

    margin-bottom: 6rem;
  }

  @media (max-width: 575px) {
    h1 {
      font-size: 2.4rem;
    }
  }
}

.videoSection {
  >div {
    height: 100%;

    iframe {
      height: 100%;
    }
  }
}

.imageSection,
.videoSection {
  margin-top: 0;
  margin-bottom: 4rem;
  width: 100%;
  position: relative;
  padding: 5rem;
  z-index: 1;
  height: calc(100vh - 8rem);
  // min-height: 100vh;

  @media (max-width: 1679px) {
    min-height: calc(100vh - 6.5rem);
    padding: 4rem 2rem;
  }

  @media (max-width: 1099px) {
    min-height: calc(100vh - 3.5rem);
    padding: 2.5rem 2rem;
  }

  @media (max-width: 991px) {
    min-height: calc(100vh - 5.5rem);
    padding: 3rem 1rem;
  }

  @media (max-width: 425px) {
    min-height: calc(100vh - 5.4rem);
    padding: 2rem 0.5rem;
  }

  @media (max-height: 479px) {
    min-height: calc(100vh - 2.5rem);
  }

  /* Landscape */
  @media screen and (orientation: landscape) and (max-width: 991px) {
    min-height: calc(100vh - 0.5rem);
  }

  @media screen and (orientation: landscape) and (max-width: 800px) {
    min-height: calc(100vh + 6rem);
    padding: 2rem;
  }



  .imageTextSec {

    display: flex;
    align-items: center;
    // justify-content: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    margin: auto;

    @media (min-width:1990px) {
      padding-top: 7rem;
    }


    @media (max-width:1780px) {
      padding-top: 1rem;
    }

    @media (max-width:1780px) {
      padding-top: 1rem;
    }

    @media (max-width:1490px) {
      padding-top: 0rem;
    }

    @media (max-width:1199px) {
      padding-top: 3rem;

    }

    @media (max-width:1099px) {
      padding-top: 0rem;
    }

    @media (max-width:991px) {
      padding-top: 16rem;

    }

    @media (max-width:767px) {
      padding-top: 14rem;

    }

    @media (max-width:480px) {
      padding-top: 18rem;
    }

    @media (max-width:375px) {
      padding-top: 9rem;
    }

    /* Landscape */
    @media screen and (orientation: landscape) and (max-width: 991px) {
      padding-top: 0rem !important;
    }

    .newVideoSee {
      margin: 3.5rem 0;
      width: 30vw;

      @media (max-height: 899px) {
        width: 28vw;
        margin: 2.5rem auto;
      }

      @media (max-height: 799px) {
        width: 26vw;
        margin: 2rem auto;
      }

      @media (max-width: 991px) {
        margin: 1.5rem auto;
      }

      @media (max-width: 767px) {
        min-width: 24rem;
        width: 40vw;
      }
    }

    .textSec {
      color: $white;

      h4 {
        font-family: $baseFont2;
        margin-bottom: 1rem;
      }

      h1 {
        font-family: $baseFont2;
      }
    }

    .btnAction {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 359px) {
        flex-direction: column;
      }

      .btnSize {
        margin: 0 1.5rem;
        font-family: $baseFont;
        padding-inline: 2rem;

        @media (max-width: 991px) {
          margin: 0 1rem;
          padding-inline: 1rem;
        }

        @media (max-width: 479px) {
          margin: 0 0.4rem;
          font-size: 1.2rem;
        }

        @media (max-width: 359px) {
          margin: 0.6rem 0;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .imgBgnew {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-view-box: none;
    object-position: bottom;



  }

  .imgBgnewWallarea {
    content: url("../../../../Assets/images/4banner.jpg");

    @media (orientation: landscape) and (max-width: 991px) {
      content: url("../../../../Assets/images/1banner.jpg");
      height: 45rem;
    }

    @media (orientation: landscape) and (max-width: 860px) {
      height: 42rem;
    }
  }

}