@import "../../../Assets/_var.scss";

.ExportModal {
  :global {
    .modal-dialog {
      max-width: 121rem !important;

      @media (max-width: 1439px) {
        max-width: 100rem !important;
      }

      @media (max-width: 1199px) {
        max-width: 90rem !important;
      }

      .modal-content {
        background-color: transparent;
        border: none;

        .modal-header,
        .modal-body {
          padding: 0;
        }
      }
    }

    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            padding: 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      &.sliderList {
        display: flex;
        align-items: center;

        .slick-arrow {
          position: unset;
          right: 0;
          background: $black;
          height: 100%;
          transform: unset;
          // width: 10rem;

          &.slick-prev {
            &::before {
              content: "";
              background-image: url(../../../Assets/icons/lefticonarrow.svg);
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              width: 2rem;
              height: 2rem;
              display: block;
              opacity: 1;
            }
          }

          &.slick-next {
            &::before {
              content: "";
              background-image: url(../../../Assets/icons/righticonarrow.svg);
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              width: 2rem;
              height: 2rem;
              display: block;
              opacity: 1;
            }
          }
        }

        .slick-list {
          .slick-track {
            .slick-slide {
              padding: 0 0.5rem;
              height: 9rem;
              > div {
                height: 100%;

                > div {
                  height: 100%;
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }

  &Left,
  &Right {
    flex: 0 0 50%;
    padding: 5.8rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1679px) {
      padding: 4rem;
    }

    @media (max-width: 1439px) {
      padding: 3rem;
    }

    @media (max-width: 991px) {
      padding: 2.4rem;
    }

    .bottomLine {
      margin-top: auto;
      width: 100%;
      height: 2px;
      background-color: $white;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &Left {
    background-color: $white;
    width: 100%;
    max-width: 50%;
    @media (max-width:767px) {
      max-width: 100%;      
    }
    .bottomLine {
      background-color: $black;
    }

    &Inner {
      height: 50rem;
      width: 100%;

      img {
        box-shadow: 0 0 6.2rem rgb(163, 163, 163, 0.3);
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
  &Right {
    color: $white;
    background-color: $black;
    form {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    h3 {
      font-size: 3.6rem;
      line-height: 1.2;
      margin: 1rem 0;

      @media (max-width: 1439px) {
        font-size: 3rem;
      }

      @media (max-width: 991px) {
        font-size: 2.4rem;
      }

      @media (max-width: 767px) {
        font-size: 2rem;
      }
    }

    .artistDetails {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 3rem;

      h4 {
        font-size: 1.8rem;
        font-weight: 700;
        color: $gray2;

        @media (max-width: 991px) {
          font-size: 1.6rem;
        }

        @media (max-width: 767px) {
          font-size: 1.4rem;
        }
      }

      &Edition {
        display: flex;
        align-items: center;

        p {
          color: $gray;
          text-transform: capitalize;
          font-weight: 500;

          @media (max-width: 991px) {
            font-size: 1.4rem;
          }
        }

        span {
          font-weight: 700;
          font-size: 1.6rem;
          margin-left: 0.8rem;

          @media (max-width: 991px) {
            font-size: 1.4rem;
          }
        }
      }
    }

    .infoarts {
      .dynamicInfo {
        display: flex;
        align-items: center;
        border: 1px solid $white;
        border-left: 0;
        border-right: 0;
        padding: 1rem 0;

        p {
          color: $gray;
          text-transform: capitalize;
          font-weight: 500;

          @media (max-width: 991px) {
            font-size: 1.4rem;
          }
        }

        span {
          font-weight: 700;
          font-size: 1.6rem;
          margin-left: 0.8rem;

          @media (max-width: 991px) {
            font-size: 1.4rem;
          }
        }
      }

      .walletInfo {
        margin-top: 2rem;

        .editionDetails {
          margin: 4rem 0 2rem;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          @media (max-width: 1679px) {
            margin: 3.5rem 0 1.6rem;
          }

          @media (max-width: 1439px) {
            margin: 3rem 0 1.6rem;
          }

          @media (max-width: 1199px) {
            margin: 2.5rem 0 1.6rem;
          }

          @media (max-width: 991px) {
            margin: 2rem 0 1.6rem;
          }

          @media (max-width: 767px) {
            margin: 1.6rem 0 1rem;
          }

          .enter_text {
            width: 100%;
            max-width: 18rem;
            padding-top: 0.4rem;

            label {
              color: #d3d3d3;
              font-size: 1.4rem;
              font-weight: 500;
              line-height: 1;
              margin: 0;

              @media (max-width: 991px) {
                font-size: 1.6rem;
              }

              @media (max-width: 767px) {
                font-size: 1.4rem;
              }
            }
          }

          :global {
            .common_input {
              margin: 0;

              &_inner {
                width: 100%;
                max-width: 25rem;

                .leftIcon {
                  font-size: 2rem;
                  font-weight: 600;

                  @media (max-width: 1439px) {
                    font-size: 1.8rem;
                  }

                  @media (max-width: 991px) {
                    font-size: 1.6rem;
                    left: 1.5rem;
                  }
                }

                .form-control {
                  padding: 1.1rem 1.4rem;
                  background: $black5;
                  border: 2px solid $borderGraydark;
                  color: $white;
                  font-size: 1.8rem;
                  font-weight: 400;

                  @media (max-width: 1439px) {
                    font-size: 1.8rem;
                  }

                  @media (max-width: 991px) {
                    font-size: 1.6rem;
                    padding: 0.7rem 1.5rem;
                    padding-left: 2.6rem;
                  }

                  &::placeholder {
                    color: $white;
                  }
                }
              }
            }
          }
        }
        .walletAddress {
          display: flex;
          align-items: flex-start;

          label {
            color: $gray;
            text-transform: capitalize;
            font-weight: 500;
            font-size: 1.4rem;
            margin-right: 1.5rem;
          }

          p {
            // padding: 0.6rem 1.6rem;
            // background: #252525;
            // border: 2px solid #3c3d3c;
            color: #ffffff;

            @media (max-width: 991px) {
              font-size: 1.4rem;
            }
          }
        }

        .exportfee {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          max-width: 26rem;
          margin-top: 1rem;

          label,
          p {
            flex: 0 0 50%;
            color: $gray;
            text-transform: capitalize;
            font-weight: 500;
            font-size: 1.4rem;
            margin-right: 1.5rem;
          }

          p {
            text-align: center;
          }
        }
      }
    }

    .buy_btn_box {
      margin-top: auto;
      padding-top: 2.2rem !important;

      .buy_btn {
        border-color: $white;
        width: 100%;
        color: $black;

        &:hover {
          color: $white;
        }
      }
    }
  }
}

.imageSlide {
  height: 50rem;
  margin-bottom: 1rem;

  img,
  video {
    box-shadow: 0 0 6.2rem rgb(163, 163, 163, 0.3);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
