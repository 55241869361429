@import "../../../Assets/_var.scss";

.stats {
  position: relative;
  padding: 7rem 0;

  &Head {
    border-bottom: 1px solid $black;
    padding-bottom: 4rem;
  }

  @media (max-width: 1679px) {
    padding: 5rem 0;
  }
}

:global {

  .selectThead {
    width: 19rem;
    min-width: 19rem;
    max-width: 19rem;
    @media (max-width: 1679px) {
      width: 17rem;
      min-width: 17rem;
      max-width: 17rem;
    }
    @media (max-width: 1199px) {
      width: 16rem;
      min-width: 16rem;
      max-width: 16rem;
    }
    @media (max-width: 767px) {
      width: 14rem;
      min-width: 14rem;
      max-width: 14rem;
    }
  }

  .spacesTables {
    .commonTable {
      table {

        tbody {
          tr {
            td {
              &:nth-child(1) {
                min-width: 22rem;
              }

              &:nth-child(3),
              &:nth-child(5),
              &:nth-child(6) {
                min-width: 22rem;
              }

              &:nth-child(2) {
                min-width: 12rem;
              }

              &:nth-child(4) {
                min-width: 10rem;
              }
            }
          }
        }
      }
    }
  }

  .react-select {
    height: 100%;

    .react-select__control {
      background-color: $white;
      border-radius: 4rem;
      cursor: pointer;
      height: 100%;
      min-height: 3rem;

      &.react-select__control--is-focused {
        border: 2px solid $green;
        box-shadow: none;
      }

      .react-select__value-container {

        .react-select__placeholder,
        .react-select__input-container,
        .react-select__single-value {
          font-size: 16px;

          @media (max-width: 991px) {
            font-size: 14px;
          }

          @media (max-width: 575px) {
            font-size: 12px;
          }
        }
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }

        .react-select__indicator {
          padding: 0 0.4rem;
        }

        .react-select__dropdown-indicator {
          svg {
            fill: $black;
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .react-select__menu {
      border-radius: 10px;
      border: none;
      box-shadow: 1px 0px 16px rgb(0 0 0 / 10%);
      background-color: $black;

      .react-select__menu-list {
        .react-select__option {
          cursor: pointer;
          background-color: transparent;
          margin-bottom: 1px;
          font-size: 16px;
          color: $white;

          @media (max-width: 991px) {
            font-size: 14px;
          }

          @media (max-width: 575px) {
            font-size: 12px;
          }

          &:hover,
          &.react-select__option--is-selected {
            background-color: $yellow;
            color: $white;
          }
        }

        .react-select__option--is-disabled {
          color: rgb($white, 0.5);
        }

        .react-select__menu-notice {
          font-weight: 500;
          font-size: 16px;

          @media (max-width: 1199px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .digitalArt {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 5rem;
      height: 5rem;
      border-radius: 0.5rem;
    }

    &_textSec {
      margin-left: 1rem;
      width: 100%;

      h5 {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 0.3rem;
        line-height: 1;
      }

      p {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1;
      }
    }
  }

  .personIcon-outer {
    height: 5rem;
    width: 5rem;

    img {
      border-radius: 0.5rem;
    }
  }

  .artDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 5rem;
      height: 5rem;
      border-radius: 0.5rem;
    }

    &_textSec {
      margin-left: 1rem;
      width: 100%;

      p {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1;
      }
    }
  }
}