@import '../../../../Assets/_var.scss';

@keyframes marquee {
  0% {
    background-position: 0;
  }

  100% {
    background-position: -300rem;
  }
}

.CreateSec {
  position: relative;
  text-align: center;
  padding-top: 10rem;
  padding-bottom: 4rem;

  &:before {
    content: "";
    position: absolute;
    height: 31.2rem;
    width: 100%;
    max-width: 100%;
    left: 0;
    top: 14rem;
    background-image: url(../../../../Assets/images/createSecBg.png);
    background-position: center;
    background-repeat: repeat-x;
    z-index: -1;
    animation: marquee 110s infinite linear;
  }

  &_inner {
    padding: 5rem;
    border: 3px solid $black;
    background-color: $white;
    position: relative;

    .headLogo {
      position: absolute;
      top: -7rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      background-color: $white;
      color: $black;
      padding: 1.3rem 2.8rem;
      width: 100%;
      max-width: max-content;

      img {
        margin-bottom: 1.5rem;
        width: 30rem;
      }

      p {
        color: #D3D3D3;
        font-size: 1.6rem;
        line-height: 1;
        font-weight: bold;
      }
    }

    .innerImage {
      width: 100%;
      max-width: 62.4rem;
      height: 23.7rem;
      margin: 0 auto 2.8rem;
    }
  }

  h2 {
    position: absolute;
    top: -4rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    background-color: $white;
    color: $black;
    font-size: 10rem;
    display: table;
    line-height: 0.8;
    padding: 0 2.8rem;
  }

  h3 {
    position: absolute;
    top: -4rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    background-color: $white;
    color: $black;
    display: table;
    line-height: 0.8;
    padding: 2.8rem;
    width: 100%;
    max-width: max-content;
  }

  p {
    color: $black;
  }

  .btn_create {
    display: table;
    margin: 2rem auto 0;
    position: absolute;
    bottom: -2.6rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: max-content;

    @media (max-width:1439px) {
      bottom: -2.2rem;
    }
  }

  @media (max-width:1679px) {
    padding-top: 8rem;
    padding-bottom: 14rem;

    .innerImage {
      margin-top: 2rem;
    }

    h2 {
      font-size: 17rem;
      margin: 0 auto -5rem;
    }

    &:before {
      height: 30.2rem;
    }
  }

  @media (max-width:1439px) {
    padding-top: 7rem;
    padding-bottom: 10rem;

    h2 {
      font-size: 12rem;
      margin: 0 auto -4rem;
      padding: 0 1.8rem;
    }

    &:before {
      height: 26.2rem;
    }
  }

  @media (max-width:1279px) {
    padding-bottom: 7rem;

    h2 {
      font-size: 8rem;
      margin: 0 auto -3rem;
    }

    &:before {
      height: 24.2rem;
    }
  }

  @media (max-width:991px) {
    padding-bottom: 6rem;

    h2 {
      font-size: 6rem;
      margin: 0 auto -2rem;
      padding: 0 1rem;
    }

    &:before {
      height: 23.2rem;
    }
  }

  @media (max-width:767px) {
    padding-bottom: 4rem;

    h2 {
      font-size: 5rem;
      margin: 0 auto -2rem;
    }

    &:before {
      height: 20.2rem;
    }
  }

  @media (max-width:575px) {
    .innerImage {
      height: 17.6rem;
    }
  }

  @media (max-width:429px) {
    .innerImage {
      height: 13rem;
    }
  }
}