@import '../../../../Assets/_var.scss';

.connnected_device {
    padding-top: 3.3rem;

    .bidder_table {
        :global {
            .table-responsive {
                border: 1px solid #707070;
                border-radius: 0;
            }

            table {
                border-spacing: 0;
                border-collapse: collapse;

                thead {
                    tr {
                        th {
                            background-color: $black;
                            color: $white;
                            padding: 2rem 3rem;
                            font-size: 1.6rem;
                            font-family: $baseFont;
                            border: 1px solid $black;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            border-top: 2px solid $borderClr2;
                            border-bottom: 2px solid $borderClr2;
                            padding: 1.5rem 2.5rem;
                            font-size: 1.6rem;
                            font-weight: bold;
                            font-family: $baseFont;
                            color: $black;

                            &:nth-child(4) {
                                min-width: 29rem;
                                width: 29rem;
                                max-width: 29rem;
                            }

                            .art_devices {
                                display: flex;
                                align-items: center;

                                img {
                                    width: 7rem;
                                    height: 7rem;
                                    object-fit: cover;
                                }

                                .textSec {
                                    display: flex;
                                    align-items: flex-start;
                                    flex-direction: column;
                                    margin-left: 1rem;
                                    height: 100%;

                                    span {
                                        font-size: 1.4rem;
                                        font-weight: bold;
                                        font-family: $baseFont;
                                        color: $black;
                                        width: 100%;
                                        line-height: 1.2;
                                        word-break: break-word;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -moz-box-orient: vertical;
                                        -ms-box-orient: vertical;
                                        box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                        -moz-line-clamp: 2;
                                        -ms-line-clamp: 2;
                                        line-clamp: 2;
                                        overflow: hidden;
                                        white-space: normal;
                                    }

                                    p {
                                        margin-top: auto;
                                        font-size: 1.2rem;
                                        font-weight: bold;
                                        color: $black;
                                    }
                                }
                            }

                            .btns_devices {
                                .btn_size {
                                    font-size: 1.4rem;
                                    font-weight: 500;
                                    font-family: $baseFont;
                                    padding: 1rem 1.8rem;
                                    min-width: 8rem;
                                    height: 3rem;
                                }
                            }
                        }

                        &:first-child {
                            td {
                                border-top: none;
                            }
                        }

                        &:last-child {
                            td {
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

:global {

    .remove_devicemodal,
    .rename_devicemodal {
        .modal-dialog {
            max-width: 67.5rem;

            .modal-content {
                .modal-header {
                    padding-left: 5.1rem;
                    padding-right: 5.1rem;

                    .modal-title {
                        font-size: 3rem;
                        font-family: "Roboto", sans-serif;
                        line-height: 1.3;

                    }

                    @media (max-width:1439px) {
                        padding: 3.5rem 3.5rem 0;
                        margin: auto;

                        .modal-title {
                            font-size: 2.8rem;
                        }
                    }

                    @media (max-width:1199px) {
                        padding: 3.5rem 2.5rem 0;

                        .modal-title {
                            font-size: 2.4rem;
                        }
                    }

                    @media (max-width:991px) {
                        padding: 3.5rem 2rem 0;

                        .modal-title {
                            font-size: 2rem;
                        }
                    }

                    @media (max-width:575px) {
                        padding: 3.5rem 1.5rem 0;

                        .modal-title {
                            font-size: 1.8rem;
                        }
                    }
                }

                .modal-body {
                    padding-left: 5.1rem;
                    padding-right: 5.1rem;

                    .main_content {
                        .btns_section {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 4rem;

                            .btn_sizeing {
                                width: 100%;
                                max-width: 100%;
                                font-size: 2rem;
                            }

                            .bordred-black {
                                border-color: $yellow;

                                &:hover {
                                    background-color: $yellow;
                                    color: $black;
                                    border-color: $white;
                                }
                            }

                            .btn-yellow-black {
                                margin-left: 1rem;
                                border-color: $white;

                                &:hover {
                                    border-color: $yellow;
                                }
                            }
                        }

                        .device_enterform {
                            margin-top: 1rem;

                            .commonBtn {
                                border-color: $white;
                                width: 100%;
                                margin-top: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}