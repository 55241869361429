@import '../../../../Assets/_var.scss';

:global {
    .naxCanvassec {
        // background-color: red;
        background-color: #E1DFDE;

        /* chrome clr */
        // background-color: #E5e4e2;
        /* safari clr */
        // background-image: url(../../../../Assets/images/BgNaxcanvas.png);
        // background-size: cover;
        // background-position: center;
        // background-repeat: no-repeat;

        // @media screen and (-webkit-min-device-pixel-ratio: 2) {
        //     background-color: #E5E4E2 !important;
        // }

        // @media screen and (min-resolution: 192dpi) {
        //     body {
        //         background-color: #E5E4E2 !important;
        //     }
        // }

        @media only screen and (min-width: 320px) and (max-width: 990px) and (min-resolution: 2dppx) {
            background-color: #E1DFDE !important;
        }

        @media screen and (-webkit-min-device-pixel-ratio: 1),
        screen and (-webkit-min-device-pixel-ratio: 1.5),
        screen and (-webkit-min-device-pixel-ratio: 2),
        screen and (-webkit-min-device-pixel-ratio: 2.5),
        screen and (-webkit-min-device-pixel-ratio: 3),
        // screen and (min-resolution: 96dpi),
        screen and (min-resolution: 120dpi),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 288dpi) {
            background-color: #E5E4E2;
        }
    }

}

.naxCanvas {
    margin-bottom: 15rem;
    position: relative;
    padding-top: 8.8rem;

    @media (max-width:1679px) {
        margin-bottom: 12rem;
        padding-top: 6.8rem;
    }

    @media (max-width:1399px) {
        margin-bottom: 10rem;
        padding-top: 4.8rem;
    }

    @media (max-width:991px) {
        margin-bottom: 7rem;
    }

    @media (max-width:767px) {
        margin-bottom: 5rem;
    }

    &_innerSec {
        text-align: center;
        width: 100%;
        max-width: 135rem;
        margin: auto;
        display: flex;
        flex-direction: column;

        .headLogo {
            margin-bottom: 3.1rem;

            @media (max-width:991px) {
                margin-bottom: 2rem;
            }

            p {
                font-size: 1.6rem;
                color: $black;
                margin-top: 1rem;
                font-weight: bold;
                line-height: 2rem;

                @media (max-width:991px) {
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }
            }
        }

        .mainContaint {
            .videoData {
                width: 100%;
                height: 100%;
                margin-bottom: 4.5rem;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .textSec {
                p {
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: $black;
                    line-height: 2.4rem;

                    @media (max-width:991px) {
                        font-size: 1.4rem;
                        line-height: 2rem;
                    }
                }

                .btn_create {
                    margin-top: 2.2rem;
                }
            }
        }
    }
}