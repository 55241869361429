@import '../../../../Assets/_var.scss';

.couponModal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                padding: 3rem 3rem 1.5rem;

                .modal-title {
                    font-size: 2.6rem;
                }
            }

            .modal-body {
                padding: 0 3rem 3rem;

                .couponList {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    max-height: 60rem;
                    overflow-y: auto;
                    overflow-x: hidden;

                    &::-webkit-scrollbar {
                        width: 3px;
                        height: 3px;
                        background-color: gray;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $white;
                    }

                    li {
                        width: 100%;
                        padding: 2rem 1rem;
                        border-bottom: 1px solid $borderGray;

                        &:last-child {
                            border: none;
                        }

                        .coupon {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;

                            .coupon_text {
                                h3 {
                                    color: $white;
                                    margin-bottom: 0.5rem;
                                    font-size: 2.2rem;
                                    font-weight: 700;
                                    line-height: 2.6rem;
                                    font-family: $baseFont;
                                }

                                p {
                                    font-size: 1.4rem;
                                    font-weight: 500;
                                    margin: 0;
                                    line-height: 1.8rem;
                                    color: $light_gray3;
                                }
                            }

                            .couponApply {
                                border-radius: 1rem;
                                font-size: 1.8rem;
                                padding: 0.5rem 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}