@import '../../../Assets/_var.scss';

.not_found {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black;

    &-inner {
        text-align: center;
        color: $white;
        h2 {
            font-size: 18rem;
            span {
                transform: translateY(1.2rem);
                display: inline-block;
                color: $yellow;
            }
        }

        p {
            font-size: 4rem;
            margin: 2rem 0 4rem;
        }
        @media (max-width: 1199px) {
            h2{
                font-size: 10rem;
            }
            p{
                font-size: 3rem;
            }
        }
    }
}