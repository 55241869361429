@import '../../../../Assets/_var.scss';

.table_section {
    width: 100%;
    // max-width: 140rem;
    margin-top: 1.5rem;

    .notification_table {
        :global {
            table {
                border-collapse: collapse;

                tbody {
                    tr {
                        cursor: pointer;

                        td {
                            border: none;
                            border-bottom: 2px solid #e4e4e4;
                            font-size: 1.6rem;
                            font-weight: 400;
                        }

                        &.active {
                            td {
                                .bordred-black {
                                    display: none;
                                }

                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }

        .textSec {
            h6 {
                font-size: 1.6rem;
                margin-bottom: 0.4rem;
                line-height: 1;
            }

            p {
                font-size: 1.6rem;
                font-weight: 100;
            }
        }

        .borderBtn {
            font-size: 1.6rem;
            padding: 0.5rem 1.6rem;
            font-weight: 600;
        }
    }
}