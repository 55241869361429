@import '../../../../Assets/_var.scss';

.quickModal {
    :global {
        .modal-dialog {
            max-width: 131rem !important;

            @media (max-width: 1439px) {
                max-width: 100rem !important;
            }

            @media (max-width: 1199px) {
                max-width: 90rem !important;
            }



            .modal-content {
                background-color: transparent;
                border: none;

                .modal-header,
                .modal-body {
                    padding: 0;
                }
            }
        }
    }

    &Left,
    &Right {
        flex: 0 0 50%;
        padding: 5.8rem;
        border-radius: 5px;
        display: flex;
        flex-direction: column;

        @media (max-width: 1679px) {
            padding: 4rem;
        }

        @media (max-width: 1439px) {
            padding: 3rem;
        }

        @media (max-width: 991px) {
            padding: 2.4rem;
        }

        .bottomLine {
            margin-top: auto;
            width: 100%;
            height: 2px;
            background-color: $white;

            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    &Left {
        background-color: $white;

        .bottomLine {
            background-color: $black;
        }

        &Inner {
            padding-bottom: 7rem;

            @media (max-width: 1439px) {
                padding-bottom: 2.4rem;
            }

            @media (max-width: 767px) {
                padding-bottom: 0;
            }


            img {
                box-shadow: 0 0 6.2rem rgb(163, 163, 163, 0.3);
                width: 100%;
            }
        }
    }

    &Right {
        color: $white;
        background-color: $black;

        h3 {
            font-size: 4.6rem;
            margin-bottom: 5rem;
            line-height: 1;

            @media (max-width: 1679px) {
                font-size: 3.6rem;
                margin-bottom: 3rem;
            }

            @media (max-width: 1439px) {
                font-size: 3rem;
                margin-bottom: 2rem;
            }

            @media (max-width: 991px) {
                font-size: 2.4rem;
            }
        }

        .artistDetails {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            h4 {
                font-size: 2.4rem;
                font-weight: 700;

                @media (max-width: 1679px) {
                    font-size: 2.2rem;
                }

                @media (max-width: 1439px) {
                    font-size: 2rem;
                }

                @media (max-width: 1199px) {
                    font-size: 1.8rem;
                }

            }

            &Edition {
                text-align: right;

                p {
                    color: #a3a3a3;

                    @media (max-width: 991px) {
                        font-size: 1.4rem;
                    }
                }

                span {
                    font-weight: 700;
                    font-size: 1.6rem;

                    @media (max-width: 991px) {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        .editionDetails {
            margin: 2.9rem 0 6.2rem;

            @media (max-width: 1679px) {
                margin: 2rem 0 2.6rem;
            }

            @media (max-width: 767px) {
                margin: 2rem 0;
            }
        }

        .amountDetails {

            h5 {
                font-size: 2rem;
                margin-bottom: 4.4rem;

                @media (max-width: 1679px) {
                    margin-bottom: 2rem;
                }

                @media (max-width: 1199px) {
                    font-size: 1.8rem;
                }

                @media (max-width: 991px) {
                    font-size: 1.6rem;
                    margin-bottom: 1.6rem;
                }
            }

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2rem;

                @media (max-width: 991px) {
                    margin-bottom: 1.6rem;
                }

                &:last-child {
                    margin: 2.9rem 0 0 0;

                    h6 {
                        font-size: 2rem;
                        font-weight: 700;

                        @media (max-width: 1199px) {
                            font-size: 1.8rem;
                        }

                        @media (max-width: 991px) {
                            font-size: 1.6rem;
                        }
                    }

                    @media (max-width: 991px) {
                        margin: 2rem 0 0 0;
                    }
                }

                p {
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: $white;

                    &:last-child {
                        text-align: right;
                    }

                    @media (max-width: 991px) {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        .buy_btn_box {
            padding: 5.8rem 0 6.4rem;

            .buy_btn {
                border-color: $white;
                width: 100%;
            }

            @media (max-width: 1679px) {
                padding: 5rem 0;
            }

            @media (max-width: 1439px) {
                padding: 7.6rem 0 2.4rem;
            }

            @media (max-width: 1023px) {
                padding: 4rem 0;
            }

            @media (max-width: 991px) {
                padding: 2rem 0;
            }

            @media (max-width: 767px) {
                padding-bottom: 0;
            }
        }
    }
}