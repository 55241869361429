@import '../../../../Assets/_var.scss';


.accordion {
    &-item {
        // margin-top: 4.5rem;
        border: 2px solid transparent !important;
        border-bottom-color: $black  !important;

        // @media (max-width: 1439px) {
        //     margin-top: 3.4rem;
        // }

        // @media (max-width: 1199px) {
        //     margin-top: 2.8rem;
        // }

        // @media (max-width: 991px) {
        //     margin-top: 2rem;
        // }
    }

    &-header {
        border: none !important;
    }

    .accordion-button {
        border-radius: 0;
        box-shadow: none;
        border: none;
        background-color: transparent;
        font-size: 2rem;
        font-weight: 700;
        padding: 4.2rem 0 1rem;
        color: $black;

        @media (max-width: 1439px) {
            font-size: 1.8rem;
            padding: 3.2rem 0 1rem;
        }

        @media (max-width: 1199px) {
            font-size: 1.6rem;
            padding: 2.6rem 0 1rem;
        }

        .accordion-arrow {
            margin-left: auto;
            transition: 300ms ease-in-out;

            svg {
                polygon {
                    transition: 300ms ease-in-out;
                }

                @media (max-width: 1199px) {
                    max-width: 1.8rem;
                }

            }
        }

        &:focus {
            box-shadow: none;
        }

        &:not(.collapsed) {
            box-shadow: none;
            background-color: transparent;
            border: none;
            color: $yellow;

            .accordion-arrow {
                transform: rotate(90deg);

                svg {
                    polygon {
                        fill: $yellow;
                    }
                }
            }
        }

        &::after {
            content: unset !important;
        }
    }

    &-body {
        padding: 3rem 0 4.2rem 0;

        p {
            font-size: 1.6rem;
            color: $black;
            font-weight: 400;
        }

        @media (max-width: 1439px) {
            padding: 2rem 0 3rem 0;
        }

        @media (max-width: 1199px) {
            padding: 2rem 0;
        }

        @media (max-width: 991px) {
            padding: 1rem 0;
        }

        @media (max-width: 575px) {
            padding: 0 0 1rem 0;
            font-size: 1.4rem;
        }
    }
}

.listFaq {
    li {
        p {
            span {
                font-weight: 700;
            }
        }
    }
}

.listFaq_Sec {
    padding-left: 2rem;
    margin-bottom: 1rem;

    li {
        list-style: lower-alpha;
        padding-left: 0.5rem;

        p {
            span {
                font-weight: 700;
            }
        }
    }

}

.listFaq_Third {
    padding-left: 2rem;
    margin-bottom: 1rem;

    li {
        list-style: circle;
        padding-left: 0.5rem;

        p {
            span {
                font-weight: 700;
            }
        }
    }
}