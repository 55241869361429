@import '../../../../../Assets/_var.scss';

.auhtorization {
    padding: 3rem 0;

    .toggle_auht {
        margin-bottom: 3rem;
    }

    .text_fields {
        h6 {
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1.2;
            color: $black;
            margin-bottom: 0.5rem;
        }

        p {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.2;
            color: $black;
            margin-bottom: 0;
        }
    }

    :global {
        .togglt_btn {
            margin: 0 0 2.8rem !important;

            .form-switch {
                .form-check-input {
                    width: 3rem;
                    height: 1.6rem;
                    border-radius: 0.8rem;
                }

                .form-check-label {
                    color: $black;
                    font-size: 1.4rem;
                    font-weight: bold;
                    margin: 0;
                }
            }
        }

        form {
            width: 100%;
            max-width: 53rem;

            .common_input {
                width: 100%;
                max-width: 32rem;
                margin-bottom: 0.8rem;

                &.border_input {
                    margin-top: 2rem;
                }

                &.error_space {
                    margin-bottom: 2rem !important;
                }
            }

            .otpSendsec {
                justify-content: flex-end;
                max-width: 32rem;
                width: 100%;
                line-height: 1;

                span,
                button {
                    font-size: 1.2rem;
                    font-weight: 600;
                }

                // &.otpbtnresend {
                //     margin-left: 39rem;
                // }

                // @media (max-width:529px) {
                //     margin-left: 0;
                //     justify-content: flex-end;

                //     &.otpbtnresend {
                //         margin-left: 0 !important;
                //     }
                // }
            }
        }
    }

    .btnsave {
        margin-top: 3rem;

        .btnsize {
            margin-top: 1.4rem;
            min-width: 10rem;
            height: 4rem;
            padding: 0.6rem 1rem;
            font-size: 1.6rem;
            font-weight: bold;
            font-family: $baseFont;

            @media (max-width:1679px) {
                font-size: 1.4rem;
            }

            @media (max-width:991px) {
                min-width: 14rem;
                margin-top: 1rem;
                font-size: 1.2rem;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}