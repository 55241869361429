@import '../../../Assets/_var.scss';

.Useraccount_setting {
    .bodernone {
        border: none;
    }

    &_section {
        padding-bottom: 3rem;

        .tablist {
            border-top: 0.1rem solid $gray707070;

            :global {
                .nav_tabslist {
                    min-height: 50rem;

                    @media (max-width:991px) {
                        min-height: 100%;

                        // &::after {
                        //     content: "";
                        //     position: absolute;
                        //     top: 5.4rem;
                        //     left: 0;
                        //     width: 100%;
                        //     height: 0.2rem;
                        //     background-color: $borderClr;
                        //     z-index: -1;
                        // }
                    }

                    .nav-item {
                        @media (max-width:991px) {
                            padding-right: 2rem;
                            width: unset;
                            max-width: unset;

                            .nav-link {
                                font-size: 1.6rem;
                                padding: 1.5rem 1rem;
                            }

                            &:last-child {
                                padding-right: 0;
                            }
                        }

                        @media (max-width:479px) {
                            padding-right: 1rem;

                            .nav-link {
                                font-size: 1.4rem;
                                padding: 1.5rem 0.5rem;
                            }
                        }
                    }
                }

                .tab-content.mainTab {
                    margin-top: 3rem;
                }
            }
        }
    }
}