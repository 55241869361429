@import "../../../Assets/_var.scss";

.liveDrop {
  background: $white;
  width: 100%;
  height: 100%;

  &_topsection {
    // background-image: url(../../../Assets/images/blurred-bg.png);
    background: url(../../../Assets/images/DropCoverBg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    width: 100%;
    min-height: 100%;
    // position: relative;
    // z-index: 1;

    .user_upimg {
      width: 100%;
      height: 40rem;
      display: block;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .headingtext {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      width: 100%;
      padding-bottom: 4rem;
      margin-top: 2rem;
      // height: 100%;
      // background-image: url(../../../Assets/images/LiveDropbanner.jpg);
      // background-size: cover;
      // background-repeat: no-repeat;
      // background-position: top center;
      // position: relative;
      // top: 0;
      // left: 50%;
      // transform: translateX(-50%);
      // z-index: 1;

      @media (max-width: 1199px) {
        padding-bottom: 3rem;
        margin-top: 1.6rem;
      }

      h2 {
        font-size: 3rem;
        line-height: 1.6;
        color: $white;
        font-weight: bold;
        width: 100%;
        max-width: 48rem;
        margin-bottom: 1.2rem;
        border-bottom: 1px solid;
        text-align: center;
        padding-bottom: 1.2rem;
        word-break: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-transform: capitalize;

        @media (max-width: 1679px) {
          font-size: 2.8rem;
        }

        @media (max-width: 1199px) {
          font-size: 2.4rem;
        }

        @media (max-width: 767px) {
          font-size: 2rem;
        }

        @media (max-width: 479px) {
          max-width: 95%;
        }
      }

      .live_minted {
        display: flex;
        align-items: center;
        // justify-content: flex-end;
        justify-content: space-between;
        width: 100%;
        max-width: 47rem;
        color: $white;
        // background: url(../../../Assets/images/DropCoverBg.png);
        // background-size: 100%;
        // background-position: center;
        // background-repeat: no-repeat;
        // padding: 0.6rem 1rem;
        // border-radius: 0.8rem;

        @media (max-width: 479px) {
          max-width: 35rem;
          padding: 0 1rem;
        }

        p {
          font-size: 1.6rem;
          line-height: 2.2rem;
          font-weight: bold;
          font-family: "Roboto";

          @media (max-width: 575px) {
            font-size: 1.4rem;
            line-height: 1.8rem;
          }
        }

        h5 {
          font-size: 2.4rem;
          line-height: 2.4rem;
          font-weight: bold;
          font-family: "Roboto";
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 991px) {
            font-size: 2rem;
            line-height: 2rem;
          }

          @media (max-width: 575px) {
            font-size: 1.6rem;
            line-height: 1.8rem;
          }

          img {
            margin-left: 1rem;
          }
        }
      }

      .comingsoon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 47rem;
        color: $white;
        background: url(../../../Assets/images/DropCoverBg.png);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        padding: 0.6rem 1rem;
        border-radius: 0.8rem;
        // color: $gray2;

        @media (max-width: 479px) {
          max-width: 35rem;
          padding: 0 1rem;
        }

        &__textfile {
          label {
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 2rem;
            color: $white;
          }
        }

        .getnotifyBtn {
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 13.1rem;
          height: 3.7rem;
          border-radius: 3rem;
          font-size: 1.6rem;
          font-weight: bold;
          color: $white;
          background: $green;

          &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
          }
        }
      }
    }

    &_info {
      padding: 3.2rem;
      text-align: center;
      max-width: 145rem;
      width: 100%;
      margin: 0 auto;

      @media (max-width: 767px) {
        padding: 2rem 1.5rem;
        text-align: center;
        max-width: 100%;
      }

      p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.2rem;
        margin-bottom: 3.2rem;
        color: $light_gray;
        //  word-break: break-word;

        @media (max-width: 767px) {
          margin-bottom: 2rem;
        }
      }

      .readBtn {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.4rem;
        padding: 0 0.2rem;
        margin-bottom: 2rem;
        color: $light_gray;
        background: transparent;
        border: none;
        border-bottom: 1px solid $light_gray;

        @media (max-width: 767px) {
          margin-bottom: 2rem;
        }
      }
    }
  }

  .art_cardsec {
    .artcar_value {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      height: 3.6rem;
      padding: 0.9rem 2.4rem;
      background: $white_light;
      border-radius: 1.2rem;
    }

    .card_img {
      height: 50.5rem;

      @media (max-width: 1479px) {
        height: 40rem;
      }
    }

    :global {
      .video_hover_sec {
        height: 50.5rem;

        @media (max-width: 1479px) {
          height: 40rem;
        }
      }

      // .CommonCardContentHandle {
      //   z-index: 10000 !important;
      // }
    }
  }

  .browse_section {
    width: 100%;
    background-image: url(../../../Assets/images/scrollbg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    margin-top: 15.6rem;
    position: relative;
    z-index: 1;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 14.5rem;
      background-color: $white;
      z-index: -1;

      @media (max-width: 1199px) {
        height: 11.5rem;
      }

      @media (max-width: 991px) {
        height: 15.5rem;
      }
    }

    &__topfollow {
      position: relative;
      width: 100%;
      max-width: 95.9rem;
      min-height: 23.7rem;
      margin: auto;
      text-align: center;
      border: 3px solid;
      padding: 1rem;
      z-index: 1;

      @media (max-width: 991px) {
        max-width: 97%;
      }

      // @media (max-width: 767px) {
      //   height: 30rem;
      // }

      // @media (max-width: 479px) {
      //   height: 35rem;
      // }

      // @media (max-width: 379px) {
      //   height: 38rem;
      // }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 98%;
        min-height: 94%;
        background: $white;
        z-index: -1;
        margin: 1rem;

        @media (max-width: 991px) {
          padding: 2rem;
          width: 100%;
          margin: 1rem;
          max-width: 98%;
        }

        @media (max-width: 767px) {
          max-width: 97%;
        }

        @media (max-width: 575px) {
          max-width: 96%;
        }

        @media (max-width: 479px) {
          max-width: 92%;
          left: 5px;
          top: 3px;
        }
      }

      .userIcon {
        position: absolute;
        z-index: 9;
        top: -70px;
        left: 50%;
        transform: translateX(-50%);
        width: 12.5rem;
        height: 12.5rem;
        border-radius: 50%;
        object-position: center;
      }

      h5 {
        margin-top: 5rem;
        font-size: 2rem;
        margin-bottom: 1.4rem;
        line-height: 2.6rem;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
      }

      p {
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        padding: 0 1.5rem 3.5rem;
      }

      .followBtn {
        position: absolute;
        z-index: 9;
        bottom: -23px;
        left: 50%;
        transform: translateX(-50%);
        width: 17.5rem;
        height: 4.7rem;
        border-radius: 3rem;
        border: none;
        font-size: 2rem;
        background: $black;
        color: $yellow;
        font-weight: bold;
        font-family: "Roboto", sans-serif;
        transition: 0.3s all ease-in-out;

        &:hover {
          background-color: $yellow;
          color: $black;
          border-color: $yellow;
          box-shadow: 4px 5px 14px rgba(0, 0, 0, 0.3);

          >span {
            svg {

              path,
              polygon {
                fill: $black;
              }
            }
          }
        }
      }
    }

    .browsetitle {
      font-size: 6.7rem;
      font-family: "Roboto", sans-serif;
      line-height: 7.5rem;
      font-weight: bold;
      margin: 8.8rem auto 3.8rem;
      text-align: center;

      @media (max-width: 1679px) {
        font-size: 5.7rem;
        line-height: 6.5rem;
      }

      @media (max-width: 1439px) {
        font-size: 4.7rem;
        line-height: 5.5rem;
      }

      @media (max-width: 1199px) {
        font-size: 3.7rem;
        line-height: 4.5rem;
      }

      @media (max-width: 991px) {
        font-size: 3rem;
        line-height: 3.8rem;
      }

      @media (max-width: 575px) {
        font-size: 2.2rem;
        line-height: 3rem;
      }
    }

    .brwose_card {
      width: 100%;

      .cardhover {
        transition: 0.3s all ease-in-out;

        &:hover {
          transition: 0.3s all ease-in-out;
          box-shadow: 0px 0px 13px #000000e6;
          transform: scale(1.06);
        }
      }

      :global {
        .no_found_data {
          .slick-list {
            .slick-track {
              .slick-slide {
                width: 100% !important;
              }
            }
          }
        }

        .video_hover_sec {
          &:hover {
            transition: 0.3s all ease-in-out;
            box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
            transform: scale(1.06);
          }

          .CommonCard {
            &:hover {
              transition: unset;
              box-shadow: none;
              transform: none;
            }
          }
        }

        .slick-slider {
          .slick-prev {
            left: unset;
            top: 0 !important;
            right: 7rem !important;

            &::before {
              color: #ffffff00 !important;
            }
          }

          .slick-next {
            left: unset;
            top: 0 !important;
            right: 1rem !important;
            transform: none;

            &::before {
              color: #ffffff00 !important;
            }
          }
        }
      }
    }

    .browseviewBtn {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 15rem;
      height: 4.5rem;
      border-radius: 3rem;
      font-size: 1.6rem;
      font-weight: bold;
      margin: 6rem auto;

      @media (max-width: 1679px) {
        margin: 3rem auto 6rem;
      }

      @media (max-width: 1199px) {
        margin: 0 auto 6rem;
      }

      @media (max-width: 991px) {
        margin: 2rem auto 3rem;
      }
    }

    :global {
      // @media (max-width:991px) {
      //     .slick-slider {
      //         padding-bottom: 5rem;
      //     }
      // }

      .slick-slider {
        padding-bottom: 0 !important;

        .slick-list {
          padding: 5rem 0;
        }
      }
    }
  }
}