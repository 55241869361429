@import '../../../../Assets/_var.scss';

.offer_tab {
    :global {
        .tabTop {
            display: flex;
            align-items: center;

            .tablists {
                width: 100%;
            }

            .search_input {
                width: 100%;
                max-width: 66.5rem;
                padding-left: 2.9rem;
                top: 1.2rem;

                &_inner {
                    height: 4rem;
                    border-radius: 2.9rem;
                    background: $white;
                    padding: 0 2rem;
                    border: 1px solid $gray707070;

                    .search_input_icon {
                        width: 1.8rem;
                        height: 1.8rem;
                        padding: 0;
                        left: 1.7rem;

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    input {
                        font-size: 1.4rem;
                        font-weight: bold;
                        color: $black;
                        padding-left: 2.8rem;

                        &::placeholder {
                            color: $gray707070;
                        }
                    }
                }
            }
        }
    }
}