@import '../../../../Assets/_var.scss';

.CommonHero {
    position: relative;
    height: 25rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width:1679px) {
        height: 23rem;
    }

    @media (max-width:991px) {
        height: 21rem;
    }

    @media (max-width:767px) {
        height: 20rem;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
        object-position: center;
    }

    .imageUperror {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: auto 1.5rem 1.5rem auto;

        .error_msg {
            color: $red;
            font-size: 1.4rem;
            font-weight: 500;
        }
    }

    .imageUpbtn {
        position: relative;
        margin-top: auto;
        margin-left: auto;
        margin-bottom: 0.5rem;

        :global {
            .form-control {
                position: absolute;
                height: 100%;
                width: 100%;
                opacity: 0;
                top: 0;
                left: 0;
                z-index: 1;
                cursor: pointer;
            }
        }

        .editBtn {
            border-radius: 1rem;
            padding: 0.8rem 1rem;
            border: 0.2rem solid $white;
            background: $black;
        }
    }
}

.uploadImg {
    position: relative;
    height: 56.4rem;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    justify-self: flex-end;

    @media (max-width:1679px) {
        height: 43rem;
    }

    @media (max-width:1439px) {
        height: 38rem;
    }

    @media (max-width:991px) {
        height: 30rem;
    }

    @media (max-width:767px) {
        height: 20rem;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

}