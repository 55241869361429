@import '../../../../../Assets/_var.scss';

.earnings_table {
    .list_earns {
        display: flex;
        align-items: center;
        margin-top: -0.4rem;

        @media (max-width:767px) {
            margin-top: 0;
        }

        @media (max-width:575px) {
            flex-wrap: wrap;
        }

        li {
            border-right: 1px solid $gray707070;
            padding: 2.4rem 2rem 1rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width:1399px) {
                padding: 1.8rem 1.5rem 1rem;
            }

            @media (max-width:575px) {
                padding: 1.2rem 1rem 1rem;
                flex: 0 0 50%;
            }

            &:first-child {
                @media (max-width:575px) {
                    border-bottom: 1px solid $gray707070;
                }
            }

            &:nth-child(2) {
                @media (max-width:575px) {
                    border-bottom: 1px solid $gray707070;
                    border-right: 0;
                }
            }

            &:last-child {
                @media (max-width:575px) {
                    border-bottom: 0;
                    border-right: 0;
                }
            }

            label {
                font-size: 1.4rem;
                font-weight: bold;
                line-height: 1;
                margin-bottom: 1rem;

                @media (max-width:1199px) {
                    font-size: 1.2rem;
                    margin-bottom: 0.5rem;
                }
            }

            h2 {
                font-weight: bold;
                line-height: 1;
                display: flex;
                align-items: flex-start;

                span {
                    font-size: 1.8rem;
                    margin-top: 0.2rem;
                    margin-right: 0.3rem;

                    @media (max-width:991px) {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    .earnings_table {
        margin: 3rem 0;
        width: 100%;

        .btneditsize {
            width: 100%;
            max-width: 17rem;
            height: 4.7rem;
            border-radius: 2.4rem;
            font-size: 2rem;
            font-weight: bold;
            font-family: "Roboto", sans-serif;
        }

        :global {
            table {
                border-spacing: 0;
                border-collapse: collapse;
                border: 1px solid $black;

                thead {
                    tr {
                        th {
                            background-color: $black;
                            color: $white;
                            padding: 2rem 3rem;
                            font-weight: bold;
                            font-size: 1.6rem;

                            &:last-child {
                                text-align: left;
                            }

                            &.shortDrop {
                                min-width: 16rem;
                                max-width: 16rem;
                            }

                            .dropBtn {
                                margin-left: auto;
                                font-size: 1.6rem;
                                font-weight: bold;
                                font-family: $baseFont2;
                                padding: 0 1rem;
                                background: transparent;
                                border: none;
                                color: $white;
                                height: 2.3rem;
                                width: 100%;
                                max-width: 10rem;

                                &:focus,
                                &.active {
                                    color: $white  !important;
                                    border: none !important;
                                }

                                &.show {
                                    border: none;
                                    color: $white;

                                    &:focus,
                                    &.active {
                                        color: $white  !important;
                                        border: none !important;
                                    }
                                }

                                @media (max-width: 1439px) {
                                    font-size: 1.4rem;
                                }

                                @media (max-width: 991x) {
                                    font-size: 1.2rem;
                                }

                                svg {
                                    height: 1rem;
                                    width: 1rem;

                                    path {
                                        fill: $white;
                                    }
                                }
                            }

                            .dropdown-menu {
                                min-width: 10rem;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            border-top: 1px solid $black;
                            border-bottom: none;
                            padding: 1.5rem 3rem;
                            font-size: 1.6rem;

                            &:last-child {
                                text-align: left;
                            }

                            &.space_sizing {
                                min-width: 25rem;
                                max-width: max-content;
                                width: max-content;
                            }

                            .field_table {
                                display: flex;
                                align-items: center;

                                img {
                                    margin-right: 2rem;
                                    width: 7rem;
                                    height: 7rem;
                                    object-fit: cover;

                                    @media (max-width: 991px) {
                                        margin-right: 1.5rem;
                                        width: 4rem;
                                        height: 4rem;
                                    }
                                }

                                .text_field {
                                    h5 {
                                        font-size: 1.4rem;
                                        line-height: 1.2;
                                        font-weight: bold;
                                        font-family: $baseFont;
                                        color: $black;
                                        margin-bottom: 0.7rem;
                                        white-space: normal;
                                        word-break: break-word;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -moz-box-orient: vertical;
                                        -ms-box-orient: vertical;
                                        box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                        -moz-line-clamp: 2;
                                        -ms-line-clamp: 2;
                                        line-clamp: 2;
                                        overflow: hidden;

                                        @media (max-width: 991px) {
                                            font-size: 1.2rem;
                                            margin-bottom: 0;
                                        }
                                    }

                                    p {
                                        font-size: 1.2rem;
                                        font-weight: 400;
                                        font-family: $baseFont;
                                        color: $black4;
                                    }
                                }
                            }
                        }

                        &:first-child {
                            td {
                                border-top: none;
                            }
                        }
                    }
                }
            }
        }

        .art_purchased {
            img {
                border-radius: 50%;
            }
        }

        .art_details,
        .art_purchased {
            display: flex;
            align-items: center;

            img {
                width: 4.6rem;
                height: 4.6rem;
                object-fit: cover;
                object-position: center;
                border-radius: 50%;
            }

            .text_field {
                margin-left: 1rem;

                p {
                    font-size: 1.2rem;
                    font-weight: 400;
                    color: $black;
                    cursor: pointer;
                }

                label {
                    color: $black;
                    font-size: 1.4rem;
                    font-weight: 400;
                }
            }
        }
    }
}