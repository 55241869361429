@import '../../../Assets/_var.scss';
.customFilter {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-bottom: .5rem;
    border-bottom: 1px solid $black;
    ul {
        display: flex;
        li {
            padding-right: 2rem;
            button {
                background-color: transparent;
                padding: 1.3rem 3.2rem;
                border-radius: 3rem;
                font-weight: bold;
                transition: .3s all ease-in-out;
                border: 0;
                &.active {
                    color: $white;
                    background-color: $yellow;
                }
            }
            @media (max-width:1679px) {
                padding-right: 1rem;
                button {
                    padding: 1rem 2.5rem;
                }
            }
            @media (max-width:1439px) {
                padding-right: .5rem;
                button {
                    white-space: nowrap;
                    padding: .5rem 1.5rem;
                    font-size: 1.4rem;
                }
            }
            @media (max-width:991px) {
                padding-right: .3rem;
                button {
                    white-space: nowrap;
                    padding: .5rem .8rem;
                    font-size: 1.4rem;
                }
            }
        }
    }
    .common_select {
        &_inner {
            min-width: 15rem;
            @media (max-width:1439px) {
                min-width: 11rem;
            }
        }
    }
    .react-select {
        &__placeholder,
        &__single-value {
            font-weight: bold;
            color: $black; 
            @media (max-width:1439px) {                
                font-size: 1.4rem;
            }
        }
        &__indicator {
            svg {
                path {
                    fill: $black;
                }
            }
        }
        &__menu-list {
            padding: 0;
            >div {
                margin: 0 !important;
                &:first-child {
                    border-top-left-radius: .8rem;
                    border-top-right-radius: .8rem;
                }
                &:last-child {
                    border-bottom-left-radius: .8rem;
                    border-bottom-right-radius: .8rem;
                }
            }
        }
    }
}