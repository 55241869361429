@import '../../../../Assets/_var.scss';

.NewsSec {
    .topHeadings {
        text-align: center;
        margin-bottom: 8.1rem;

        @media (max-width: 1679px) {
            margin-bottom: 6rem;
        }

        @media (max-width: 1399px) {
            margin-bottom: 4rem;
        }

        @media (max-width: 991px) {
            margin-bottom: 2.5rem;
        }

        @media (max-width: 575px) {
            margin-bottom: 1.6rem;
        }
    }

    :global {
        .CommonCard {
            .CommonCardContentHandleTop {
                border: none;
                padding: 0;

                .CommonCardContentHandleTopLeft {
                    max-width: 75%;

                    @media (max-width: 575px) {
                        max-width: 100%;
                    }
                }
            }

            .textHandle {
                margin-bottom: 0;
            }

            // @media (max-width:991px) {
            //     h3 {
            //         white-space: nowrap;
            //         overflow: hidden;
            //         text-overflow: ellipsis;
            //     }
            // }
        }

        .slick-slider {
            padding-bottom: 3rem !important;

            // .slick-list {
            //    padding: 5rem 0;
            // }

            .slick-prev {
                top: unset;
                left: unset;
                bottom: -4rem !important;
                right: 21rem !important;

                &::before {
                    color: #ffffff00 !important;
                }

                @media (max-width: 1679px) {
                    bottom: -3.9rem !important;
                }

                @media (max-width: 1399px) {
                    bottom: -3.4rem !important;
                }

                @media (max-width:991px) {
                    left: 50%;
                }

                @media (max-width:575px) {
                    right: unset !important;
                    left: 6rem;
                }
            }

            .slick-next {
                top: unset;
                left: unset;
                bottom: -4rem !important;
                right: 16.5rem !important;

                &::before {
                    color: #ffffff00 !important;
                }

                @media (max-width: 1679px) {
                    bottom: -3.9rem !important;
                }

                @media (max-width: 1399px) {
                    bottom: -3.4rem !important;
                }

                @media (max-width:991px) {
                    left: 50%;
                }

                @media (max-width:575px) {
                    right: unset !important;
                    left: 6rem;
                    margin-left: 0 !important;
                }

            }
        }
    }

    .viewbtn_market {
        // height: 4.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 13.1rem;
        border-radius: 3rem;
        font-size: 1.8rem;
        font-weight: 700;
        margin-left: auto;
        padding: 0.3rem 1rem;
    }
}