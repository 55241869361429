@import '../../../../Assets/_var.scss';

.gst_details {
    padding: 3rem 0;

    .table {
        :global {
            .table-responsive {
                border-radius: 0.8rem;
                border: 0.2rem solid $borderClr4;
            }

            table {
                border-spacing: 0;
                border-collapse: collapse;
                border: none;

                thead {
                    tr {
                        th {
                            background-color: $black;
                            color: $white;
                            padding: 1.5rem 2.5rem;
                            font-size: 1.2rem;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            border-top: 0.2rem solid $borderClr2;
                            border-bottom: 0.2rem solid $borderClr2;
                            border-left: 0;
                            border-right: 0;
                            padding: 1rem 2.5rem;
                            font-size: 1.2rem;
                            font-weight: bold;
                            color: #AAAAAA;

                            h4 {
                                font-size: 1.4rem;
                                font-weight: bold;
                            }

                            .without_hover {
                                border: none;

                                &:hover {
                                    background-color: $white;
                                    color: $black;
                                    box-shadow: none;
                                }
                            }

                            .btnSize {
                                font-size: 1.2rem;
                                padding: 0.7rem 1.9rem;
                                height: 3rem;
                                line-height: 1;
                            }
                        }

                        &:first-child {
                            td {
                                border-top: none;
                            }
                        }

                        &:last-child {
                            td {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            // table {
            //     border: none;
            //     border-spacing: 0;
            //     border-collapse: collapse;

            //     thead {

            //         tr {
            //             th {
            //                 background-color: $black;

            //             }
            //         }
            //     }

            //     tbody {
            //         tr {
            //             td {
            //                 // border-bottom: 2px solid $light_gray4;

            //                 // &:last-child {
            //                 //     border-right: 2px solid $light_gray4;
            //                 // }

            //                 // &:first-child {
            //                 //     border-left: 2px solid $light_gray4;
            //                 // }
            //             }
            //         }
            //     }
            // }
        }
    }

    .add_btn {
        margin-top: 3rem;
        height: 4rem;
        padding: 1rem 2rem;
        font-size: 1.6rem;
        font-weight: bold;
        font-family: $baseFont;
    }

    .form {
        margin-top: 3rem;
        width: 100%;
        max-width: 62rem;

        label {
            color: $black3;
            font-size: 1.4rem !important;
            margin-bottom: 1rem;
            font-weight: bold;
            line-height: 1.2;
        }

        :global {
            .common_input {
                margin-bottom: 1.5rem;

                &_inner {
                    .form-control {
                        border: 1px solid $black;
                        border-radius: 0.5rem;
                        padding: 0.5rem 1rem;
                        height: 3.5rem;
                        background: transparent;
                        font-size: 1.2rem;
                    }
                }

            }

            .input_number {
                .form-label {
                    color: $black3;
                    font-size: 1.4rem !important;
                    margin-bottom: 1rem;
                    font-weight: bold;
                    line-height: 1.2;
                }

                .phone_border {
                    border: 1px solid $black  !important;
                    border-radius: 0.5rem !important;
                    padding: 0.5rem 1rem !important;
                    height: 3.5rem !important;
                    background: transparent;
                    font-size: 1.2rem !important;

                    .PhoneInputInput {
                        font-size: 1.2rem !important;
                    }
                }
            }

            .common_select_inner {
                height: 3.5rem;
            }
        }

        .select {
            @media (max-width: 575px) {
                margin-bottom: 2rem;
            }

            >div {
                :global {
                    .react-select {
                        .react-select__control {
                            border-radius: 0.5rem;
                            border: 1px solid $black ;

                            .react-select__value-container {

                                .react-select__single-value,
                                .react-select__placeholder,
                                .react-select__input-container {
                                    font-size: 1.2rem;
                                }
                            }

                            .react-select__indicators {
                                .react-select__indicator {
                                    svg {
                                        width: 2rem;
                                        height: 2rem;

                                        path {
                                            fill: $light_gray4;
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }


    .btnSave {
        height: 4rem;
        padding: 1rem 2rem;
        font-size: 1.6rem;
        font-weight: bold;
        font-family: $baseFont;
    }
}