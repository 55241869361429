@import '../../../Assets/_var.scss';

.whatIsDfa {
    .dfaLast {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}