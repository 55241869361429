@import '../../../Assets/_var.scss';

.FlauntBanner {
    padding: 12.8rem 0;
    background-color: $yellow;
    text-align: center;

    @media (max-width: 1679px) {
        padding: 10rem 0;
    }

    @media (max-width: 1439px) {
        padding: 8rem 0;
    }

    @media (max-width: 1199px) {
        padding: 6rem 0;
    }

    @media (max-width: 991px) {
        padding: 4rem 0;
    }

    .flauntHead {
        font-size: 3rem;

        @media (max-width: 1679px) {
            font-size: 2.4rem;
        }

        @media (max-width: 1399px) {
            font-size: 2rem;
        }

        @media (max-width: 1199px) {
            font-size: 1.8rem;
        }

        @media (max-width: 991px) {
            font-size: 1.6rem;
        }
    }

    .FlauntBannerText {
        width: 100%;
        max-width: 100rem;
        margin: 1rem auto 0;

        p {
            margin-bottom: 1.6rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.GetAppSec {
    padding: 12.8rem 0;
    background-color: $black;
    color: $white;

    .getAppText {
        &Headlogo {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            h2 {
                font-size: 8rem;
                line-height: 9.4rem;
            }

            img {
                width: 45rem;
            }
        }

        &Maintext {
            margin: 4rem 0;

            p {
                display: flex;
                flex-direction: column;

                span {
                    margin-top: 2rem;
                }
            }
        }
    }

    .btnAppins {
        margin-top: 4rem;

        .btnApp {
            border: none;
            background: transparent;
            padding: 0;
        }
    }

    @media (max-width: 1679px) {
        padding: 10rem 0;
    }

    @media (max-width: 1439px) {
        padding: 8rem 0;

        h2 {
            margin-bottom: 3rem;
        }
    }

    @media (max-width: 1199px) {
        padding: 6rem 0;
    }

    @media (max-width: 991px) {
        padding: 4rem 0;
    }

    @media (max-width: 575px) {
        text-align: center;
    }
}

.DigitalSec {
    padding: 12.8rem 0;


    h2 {
        margin-bottom: 4rem;
    }

    &Innerpart {
        text-align: center;
        width: 100%;
        max-width: 100rem;
        margin: auto;

        h3 {
            font-size: 3rem;
            line-height: 1.3;
            margin-bottom: 3rem;
        }

        &TextSec {
            margin: 3rem 0;

            p {
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &Screens {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media (max-width: 1679px) {
        padding: 10rem 0;
    }

    @media (max-width: 1439px) {
        padding: 8rem 0;

        &Screens {
            img {
                max-width: 16rem;

                &.tab {
                    max-width: 10rem;
                }
            }
        }

        h2 {
            margin-bottom: 3rem;
            line-height: 1.2;
        }
    }

    @media (max-width: 1199px) {
        padding: 6rem 0;


        h2 {
            margin-bottom: 2rem;
        }
    }

    @media (max-width: 991px) {
        padding: 4rem 0;

        &Screens {
            img {
                max-width: 10rem;

                &.tab {
                    max-width: 6rem;
                }
            }
        }

        h2 {
            margin-bottom: 1rem;
        }
    }

    @media (max-width: 767px) {
        text-align: center;

        h2 {
            margin-top: 2rem;

            br {
                display: none;
            }
        }
    }

    @media (max-width: 479px) {
        &Screens {
            img {
                max-width: 7rem;

                &.tab {
                    max-width: 4rem;
                }
            }
        }
    }

}