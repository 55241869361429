@import '../../../../Assets/_var.scss';

.coupons_tab {
    padding: 0 0 3.2rem;

    .coupons_table {
        width: 100%;
        // max-width: 84rem;

        :global {
            table {
                border-spacing: 0;
                border-collapse: collapse;
                border: 1px solid $black;

                thead {
                    tr {
                        th {
                            background-color: $black;
                            color: $white;
                            padding: 2rem 3rem;
                            font-weight: bold;
                            font-size: 1.6rem;

                            .dropBtn {
                                margin-left: auto;
                                font-size: 1.6rem;
                                font-weight: bold;
                                font-family: $baseFont2;
                                padding: 0 1rem;
                                background: transparent;
                                border: none;
                                color: $white;
                                height: 2.3rem;
                                width: 100%;
                                max-width: 10rem;

                                &.show {
                                    border: none;
                                    color: $white;

                                    &:focus,
                                    &.active {
                                        color: $white;
                                        border: none;
                                    }
                                }

                                @media (max-width: 1439px) {
                                    font-size: 1.4rem;
                                }

                                @media (max-width: 991x) {
                                    font-size: 1.2rem;
                                }

                                svg {
                                    height: 1.5rem;
                                    width: 1.5rem;

                                    path {
                                        fill: $white;
                                    }
                                }
                            }

                            .dropdown-menu {
                                min-width: 10rem;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            border-top: 1px solid $black;
                            border-bottom: 1px solid $black;
                            padding: 1.5rem 3rem;
                            font-size: 1.5rem;

                            &.copoenData {
                                width: 30rem;
                                min-width: 30rem;
                                max-width: 30rem;
                            }

                            .field_table {
                                display: flex;
                                align-items: center;

                                img {
                                    margin-right: 2rem;
                                    width: 7rem;
                                    height: 7rem;
                                    object-fit: cover;

                                    @media (max-width: 991px) {
                                        margin-right: 1.5rem;
                                        width: 4rem;
                                        height: 4rem;
                                    }
                                }

                                .text_field {
                                    h5 {
                                        font-size: 1.4rem;
                                        line-height: 1.2;
                                        font-weight: bold;
                                        font-family: $baseFont;
                                        color: $black;
                                        margin-bottom: 0.7rem;
                                        white-space: normal;
                                        word-break: break-word;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -moz-box-orient: vertical;
                                        -ms-box-orient: vertical;
                                        box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                        -moz-line-clamp: 2;
                                        -ms-line-clamp: 2;
                                        line-clamp: 2;
                                        overflow: hidden;

                                        @media (max-width: 991px) {
                                            font-size: 1.2rem;
                                            margin-bottom: 0;
                                        }
                                    }

                                    p {
                                        font-size: 1.2rem;
                                        font-weight: 400;
                                        font-family: $baseFont;
                                        color: $black4;
                                    }
                                }
                            }

                            .btn_section {
                                .btneditsize {
                                    height: 3rem;
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                    padding: 0.5rem 2rem;
                                    width: 100%;
                                    max-width: max-content;
                                    margin-left: 1rem;

                                    &:first-child {
                                        margin-left: 0;
                                    }
                                }
                            }
                        }

                        &:first-child {
                            td {
                                border-top: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.redeemData {
    :global {
        form {
            display: flex;
            align-items: flex-start;

            .fund_inputs {
                width: 100%;

                .common_input {
                    margin: 0;

                    input {
                        height: 3.5rem;
                        border-width: 0.1rem;
                        font-size: 1.2rem;
                        padding: 1rem;
                    }

                    .error_message {
                        font-size: 1rem;
                        font-weight: 400;
                        margin-top: 0.5rem;
                    }
                }
            }

            .btnsubmit {
                font-size: 1.4rem;
                font-weight: bold;
                font-family: $baseFont2;
                height: 3.5rem;
                margin-left: 1.7rem;
                width: 100%;
                max-width: 9.6rem;
                padding: 0.5rem 1rem;
            }
        }
    }
}