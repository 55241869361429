@import '../../../../Assets/_var.scss';

.custom_pagination {
    width: fit-content;
}

.pagination {
    margin-top: 2rem;

    .page-item {
        padding: 0 4px;

        .page-link {
            border-color: $black;
            font-size: 1.2rem;
            box-shadow: none;
            color: $black;
            width: 3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;

            &:focus {
                background-color: transparent;
            }

            &:hover {
                background-color: $gray;
                color: $black;
            }

            @media (max-width: 991px) {
                width: 3.4rem;
                height: 3.4rem;
                font-size: 1.4rem;
            }

            @media (max-width: 575px) {
                width: 3rem;
                height: 3rem;
                font-size: 1.2rem;
            }
        }

        &.active {
            .page-link {
                background-color: $black;
                color: $white;
            }
        }

        &:first-child,
        &:last-child {
            .page-link {
                border-color: rgba($black, 0.2);

                svg {
                    max-width: 1rem;

                    polygon {
                        transition: 0.3s ease-in-out;
                    }

                    @media (max-width: 767px) {
                        max-width: 1.4rem;
                    }
                }

                &:hover {
                    svg {

                        polygon {
                            fill: $white;
                        }
                    }
                }
            }

        }

        &:first-child {
            svg {
                transform: rotate(-180deg);
            }
        }
    }
}