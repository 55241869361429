@import '../../../../Assets/_var.scss';

.topDfa {
    background: url(../../../../Assets/images/whatdfatopBg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 8rem);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 0 3.9rem;

    @media (max-width:1679px) {
        min-height: calc(100vh - 6.5rem);
    }

    @media (max-width:991px) {
        min-height: calc(100vh - 5.5rem);
    }

    @media (max-height:799px) {
        padding: 3.9rem 0;
    }

    &_innerPart {
        text-align: center;
        width: 100%;
        height: 100%;

        h1 {
            margin-bottom: 1.5rem;
            font-family: $baseFont2;
            line-height: 1.2;
            color: $white;

            @media (max-height:799px) {
                margin-bottom: 1rem;
                line-height: 1;
            }
        }

        h4 {
            font-family: $baseFont2;
            line-height: 1.2;
            color: $green;
        }

        .imgHead {
            margin: 4rem auto 3rem;
            width: 13.6vw;

            @media (max-height:899px) {
                width: 11vw;
                margin: 2.5rem auto;
            }

            @media (max-height:799px) {
                width: 10vw;
                margin: 2rem auto;
            }

            @media (max-width:991px) {
                min-width: 20rem;
            }
        }

        .btnSize {
            @media (max-height:799px) {
                height: 4rem;
                font-size: 1.6rem;
            }
        }

        .learnBtnInfo {
            .btnLearn {
                background: transparent;
                border: none;
                padding: 0 0 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: $white;
                margin: 4rem auto 0;
                height: 100%;
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 1.2;

                @media (max-height:899px) {
                    margin: 2.8rem auto 0;
                }

                @media (max-height:799px) {
                    margin: 2rem auto 0;
                    font-size: 1.6rem;

                    :global {
                        .scroll-arrow {
                            width: 0.8rem;
                            height: 0.8rem;
                        }
                    }
                }

                span {
                    margin-bottom: 0.4rem;
                }
            }
        }
    }
}