// fonts
$baseFont: 'Roboto', sans-serif;
$baseFont2: 'Raleway', sans-serif;
// colors
$white: #ffffff;
$black: #000000;
$black2: #161616;
$black3: #020302;
$black4: #161615;
$black5: #252525;
$black020202: #020202;
$black313131: #313131;
$black333333: #333333;
$black700: #1b1b1b;
$blacklight: #191919;
$green: #51bc89;
$green2: #50BA88;
$yellow: #edc45d;
$yellow2: #ebbc57;
$borderGray: #bababa;
$borderGraydark: #3c3d3c;
$light_gray: #dbdbdb;
$light_gray2: #eeeeee;
$light_gray3: #c4c4c4;
$light_gray4: #d7d7d7;
$gray: #d3d3d3;
$gray2: #7e817d;
$gray3: #989898;
$gray4: #a2a2a2;
$gray5: #8e8c8c;
$gray6: #959494;
$red: #f00;
$yellow100: #f1f1db;
$borderClr: #e3e3e3;
$borderClr2: #a3a3a3;
$borderClr3: #e7e7e7;
$borderClr4: #d4d4d4;
$borderClr5: #ececec;
$borderClr6: #d9d9d9;
$white_light: #f0f0f0;
$white_light2: #eaeaea;
$white_light3: #f6f6f6;
$white_light4: #e4e4e4;
$bgwhite: #f8f8f8;
$boxshadow: #000000bf;
$dark_gray: #3b3b3b;
$dark_gray2: #717171;
$gray626262: #626262;
$gray707070: #707070;
$gray656565: #656565;
$grayC1C1C1: #C1C1C1;
$gray969696: #969696;
$grayC7C7C7: #C7C7C7;
$grayB5B5B5: #B5B5B5;
$gray747474: #747474;
$gray6E6E6E: #6E6E6E;
$blue007eff: #007eff;