@import '../../../../../Assets/_var.scss';


.search_input {
    position: relative;

    .search_input_inner {
        position: relative;
        height: 44px;

        @media (max-width: 991px) {
            height: 35px;
        }
    }

    &_icon {
        background-color: transparent;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        width: 38px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 991px) {
            svg {
                width: 14px;
            }
        }
    }

    .rightIconSearch {
        input {
            padding-right: 40px;
        }


        .rightIcon {
            position: absolute;
            right: 0;
            width: 38px;
            background-color: inherit;
            height: 100%;
            top: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%);
        }

    }

    input {
        background-color: $white;
        border: 2px solid gray;
        padding: 4px 16px 4px 42px;
        color: $black;
        height: 100%;
        border-radius: 10px;
        font-size: 14px;

        &:focus {
            border: 2px solid #000;
            box-shadow: none;
        }

        @media (max-width: 991px) {
            padding-left: 34px;
            height: 100%;
            font-size: 12px;
        }
    }

    &.search_input-lg {
        input {
            font-size: 16px;

            @media (max-width: 991px) {
                font-size: 14px;
            }
        }
    }

    &.dark {
        .form-label {
            color: $white;
        }

        svg {
            path {
                fill: $white;
            }
        }

        .form-control {
            background-color: #2c2e38;
            color: $white;
            border: none;
        }
    }
}