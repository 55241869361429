@import '../../../../Assets/_var.scss';

.searchModal {
    :global {
        .modal-dialog {
            max-width: 142.8rem;

            .modal-content {
                background-color: $white;
                border-radius: 2rem;
                border: none;

                .modal-header {
                    display: none;
                }

                .modal-body {
                    padding: 0;
                    position: relative;

                    .loader_style {
                        width: 100%;
                        height: calc(100% - 5rem);
                        position: absolute;
                        bottom: 0;
                        top: unset;
                    }

                    .not_recard {
                        height: 10rem;
                    }
                }
            }
        }
    }

    .SearchInput {
        margin-bottom: 0;

        input {
            background-color: $black;
            border: 0.1rem solid $white;
            border-radius: 1rem 1rem 0 0;
            padding-left: 6rem;
            padding-right: 6rem;
            color: $white;

            &::placeholder {
                color: $white;
            }

            @media (max-width: 767px) {
                padding-left: 4rem;
                padding-right: 4rem;
            }
        }

        span {
            max-width: 2rem;

            @media (max-width: 767px) {
                img {
                    max-width: 1.2rem;
                }
            }
        }
    }

    .closeBtn {
        position: absolute;
        top: 8px;
        right: 0;
        width: fit-content;
        height: fit-content;
        border: none;
        background-color: transparent;

        img {
            filter: brightness(10);
        }

        @media (max-width: 1279px) {
            top: 4px;
        }

        @media (max-width: 767px) {
            top: 1.5rem;
            padding: 0;
            right: 2rem;

            img {
                max-width: 1.2rem;
            }
        }

        &:hover {
            border: none;
        }
    }

    .categories {
        padding: 3.8rem 3rem 2.5rem;
        height: 100%;
        max-height: 41.3rem;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-track-piece:end {
            background: transparent;
            margin-bottom: 2rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $green;
        }

        @media (max-width: 1679px) {
            padding: 3.5rem 2.5rem 2rem;
        }

        @media (max-width: 1399px) {
            padding: 3rem 2rem 1.5rem;
        }

        @media (max-width: 1199px) {
            padding: 2rem 1.5rem 1rem;
        }

        @media (max-width: 991px) {
            padding: 1.5rem 1rem 1rem;
        }

        @media (max-width: 767px) {
            padding: 1rem;
        }

        h5 {
            margin-bottom: 2.5rem;

            @media (max-width: 1679px) {
                margin-bottom: 2rem;
            }

            @media (max-width: 1399px) {
                margin-bottom: 1.5rem;
            }

            @media (max-width: 1199px) {
                margin-bottom: 1rem;
            }
        }

        .categoryBox {
            .tagSec {
                height: 4rem;

                @media (max-width: 1679px) {
                    height: 3.8rem;
                }

                @media (max-width: 1399px) {
                    height: 3.3rem;
                }

                @media (max-width: 1199px) {
                    height: 3rem;
                }

                @media (max-width: 991px) {
                    height: 2.6rem;
                }
            }

            >div {
                margin-bottom: 2.5rem;

                @media (max-width: 991px) {
                    margin-bottom: 1.6rem;
                }

                @media (min-width: 1200px) {

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &.loggedout_searchmodal {
        :global {
            .modal-dialog {
                max-width: 70rem !important;
            }
        }

        .categories {
            :global {
                .row {
                    justify-content: flex-start !important;

                    >div {
                        width: calc(100% / 2);

                        @media (max-width:575px) {
                            width: calc(100% / 1);
                        }
                    }
                }
            }
        }
    }
}