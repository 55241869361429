@import '../../../../Assets/_var.scss';

.fade.modal-backdrop.show {
    background-color: #000 !important;
    opacity: 0.85;

    &.fade_clrbg {
        background-color: #edc367 !important;
        opacity: 0.85;
    }
}

.commonModal {
    padding: 0 !important;

    p,
    span,
    a {
        color: $white;
    }

    .modal {
        &-dialog {
            padding: 3rem 1.5rem;
            max-width: 67.5rem;

            @media (max-width:1679px) {
                max-width: 56rem;
            }

            @media (max-width:1439px) {
                max-width: 46rem;
            }

            @media (max-width: 575px) {
                margin-left: auto;
                margin-right: auto;
            }

            &:not(.modal-dialog-centered) {

                margin: 14rem auto 0;

                @media (max-width:1679px) {
                    margin: 11rem auto 0;

                }

                @media (max-width:991px) {
                    margin: 7rem auto 0;

                }
            }
        }

        &-content {
            background-color: $black;
        }

        &-header {
            border: 0;
            padding-top: 5rem;

            .modal-title {
                color: $white;
                font-weight: bold;
                font-size: 5rem;
                font-family: $baseFont2;
                line-height: 1;
            }

            .btn-close {
                position: absolute;
                right: 2rem;
                top: 2rem;
                background-image: url(../../../../Assets/icons/closeIcon-green.png);
                background-repeat: no-repeat;
                background-position: center;
                opacity: 1;
                z-index: 99;

                &:hover {
                    background-image: url(../../../../Assets/icons/closeIcon-yellow.png);
                }

                &:focus {
                    box-shadow: none;
                }
            }

            @media (max-width:1679px) {
                padding-top: 3.5rem;

                .modal-title {
                    font-size: 4rem;
                }
            }

            @media (max-width:1439px) {
                .modal-title {
                    font-size: 3.4rem;
                }
            }

            @media (max-width:1279px) {
                .modal-title {
                    font-size: 2.8rem;
                }
            }
        }

        &-header,
        &-body {
            padding-left: 6rem;
            padding-right: 6rem;

            @media (max-width:1679px) {
                padding-left: 4rem;
                padding-right: 4rem;
            }

            @media (max-width:1439px) {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }

        &-body {
            padding-bottom: 5rem;

            @media (max-width:1679px) {
                padding-bottom: 3rem;
            }
        }
    }

    &-subtitle {
        color: $yellow;
        margin-bottom: 2.5rem;
    }

    .stayTuned {
        margin-top: 4rem;
        padding-bottom: 3rem;
        border-bottom: 1px solid rgba($white, .3);
    }

    &.stripModal {
        .modal-dialog {
            .modal-content {
                background: $yellow2;
            }
        }
    }
}

.landscapeModal {
    .modal-dialog {
        max-width: 118.3rem;
    }

    .landscapeLeft {
        width: 100%;
        max-width: calc(100% - 46rem);
        flex: 0 0 calc(100% - 46rem);

        @media (max-width: 1679px) {
            max-width: calc(100% - 42rem);
            flex: 0 0 calc(100% - 42rem);
        }

        @media (max-width: 1399px) {
            max-width: calc(100% - 36rem);
            flex: 0 0 calc(100% - 36rem);
        }

        @media (max-width: 991px) {
            max-width: calc(100% - 32rem);
            flex: 0 0 calc(100% - 32rem);
        }

        @media (max-width: 767px) {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .landscapeImg {
            height: 100%;
        }
    }

    .landscapeRight {
        width: 100%;
        max-width: 46rem;
        flex: 0 0 46rem;

        @media (max-width: 1679px) {
            max-width: 42rem;
            flex: 0 0 42rem;
        }

        @media (max-width: 1399px) {
            max-width: 36rem;
            flex: 0 0 36rem;
        }

        @media (max-width: 991px) {
            max-width: 32rem;
            flex: 0 0 32rem;
        }

        @media (max-width: 767px) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

.portraitModal {
    .modal-dialog {
        max-width: 80.6rem;
    }
}