@import '../../../../Assets/_var.scss';

.whoCanApply {
    position: relative;
    overflow: hidden;
    margin: 13.6rem 0 15rem;


    &Right {

        h2 {
            font-size: 8rem;
            font-weight: bold;
            font-family: $baseFont2;
            color: $black;
            line-height: 1.2;
            margin-bottom: 3rem;
        }

        p {
            margin-bottom: 2rem;
            color: $black;

            span {
                font-weight: bold;
            }

            @media (min-width: 1680px) {
                padding-right: 7rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            @media (max-width: 991px) {
                margin-bottom: 1rem;
            }
        }
    }

    &LeftSlider,
    &RightSlider {
        &Slide {
            height: auto;
            width: 100%;
            margin: 1rem 0;

            img {
                width: 100%;
                max-width: 100%;
            }

            &:hover,
            &:focus {
                outline: none;
            }
        }
    }

    &RightSlider {
        transform: rotate(180deg);

        &Slide {
            transform: rotate(-180deg);
        }

    }

    :global {
        .slick-list {
            padding: 20% 0 20% 0 !important;

        }
    }

    @media (max-width:1679px) {
        margin: 10rem 0;

        h1 {
            font-size: 9.4rem;
        }

    }

    @media (max-width:1439px) {
        margin: 7rem 0;

        h1 {
            font-size: 7rem;
        }
    }

    @media (max-width:1199px) {
        margin: 6rem 0;

        h1 {
            font-size: 5rem;
            padding-top: 3rem;
            line-height: 1.2;
        }
    }

    @media (max-width:991px) {

        h1 {
            font-size: 4rem;
        }
    }

    @media (max-width:767px) {
        text-align: center;
        margin-bottom: 4rem;

        br {
            display: none;
        }

        h1 {
            font-size: 3.4rem;
            margin-bottom: 1rem;
        }

    }
}