@import '../../../../../../../Assets/_var.scss';

.tabTable {
    .tableSec {
        margin-top: 2rem;

        .bidder_table {
            :global {
                .table-responsive {
                    border: 2px solid $borderClr4;
                    border-radius: 0.6rem;
                }

                table {
                    border-spacing: 0;
                    border-collapse: collapse;

                    thead {
                        tr {
                            th {
                                background-color: $black;
                                color: $white;
                                padding: 1rem 2rem;
                                font-weight: bold;
                                font-size: 1.2rem;
                                font-family: $baseFont;
                                text-align: left;

                                &:first-child {
                                    border-radius: 5px 0px 0px 0px;
                                    min-width: 20rem;
                                    width: 20rem;
                                    max-width: 20rem;
                                }
                                &:nth-child(2) {
                                    min-width: 30rem;
                                    width: 30rem;
                                    max-width: 30rem;
                                }

                                &:last-child {
                                    border-radius: 0px 5px 0px 0px;
                                    min-width: 18rem;
                                    width: 18rem;
                                    max-width: 18rem;
                                }

                                .btnGlobal {
                                    height: 3rem;
                                    font-size: 1.2rem;
                                    padding: 0.3rem 2rem;
                                    width: 100%;
                                    max-width: 19rem;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            border-bottom: 2px solid $borderClr5;

                            td {
                                border: none;
                                padding: 1rem 2rem;
                                font-size: 1.2rem;
                                font-weight: bold;
                                font-family: $baseFont;
                                text-align: left;
                                color: $black;
                                background: $white;

                                &.currentPrice {
                                    color: $black;
                                }

                                &:nth-child(2) {
                                    text-align: center;
                                    // width: 20rem;
                                    // max-width: 20rem;
                                    // min-width: 20rem;
                                  }
                                .ownedBy {
                                    display: flex;
                                    align-items: center;

                                    img {
                                        width: 3rem;
                                        height: 3rem;
                                        border-radius: 50%;
                                    }

                                    h6 {
                                        font-size: 1.2rem;
                                        margin-left: 0.6rem;
                                    }
                                }

                                .btnSection {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    margin-inline: -1rem;

                                    .btnMake,
                                    .btnBuy {
                                        height: 3rem;
                                        width: 100%;
                                        max-width: 9rem;
                                        font-size: 1.2rem;
                                        padding: 0.7rem 1.4rem;
                                        margin-inline: 1rem;
                                    }
                                }
                            }

                            &:last-child {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}