@import "../../../Assets/_var.scss";

.marketPlace {
  position: relative;
  padding-top: 13.4rem;
  overflow-x: hidden;

  @media (max-width: 1679px) {
    padding-top: 10rem;
  }

  @media (max-width: 1399px) {
    padding-top: 8rem;
  }

  @media (max-width: 1199px) {
    padding-top: 6rem;
  }

  @media (max-width: 991px) {
    padding-top: 4rem;
  }

  @media (max-width: 575px) {
    padding-top: 3rem;
  }

  &_innerPart {
    .topHead {
      display: flex;
      align-items: center;

      @media (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .headings {
        width: 100%;
        max-width: 32.2rem;

        @media (max-width: 1679px) {
          max-width: 28rem;
        }

        @media (max-width: 991px) {
          max-width: max-content;
        }
      }

      .rightText {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: calc(100% - 32.2rem);
        padding-left: 2.4rem;

        @media (max-width: 1679px) {
          max-width: calc(100% - 28rem);
        }

        @media (max-width: 991px) {
          max-width: 100%;
          padding-left: 1rem;
        }

        @media (max-width: 575px) {
          padding-left: 0;
          margin-top: 1rem;
        }

        @media (max-width: 479px) {
          flex-direction: column;
          align-items: flex-start;
        }

        h2 {
          color: $gray;
          line-height: 3rem;
          width: 100%;
          max-width: max-content;
          padding-right: 2rem;
        }

        .filterSection {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;

          .arttoggleBtn {
            display: none;

            .btnFilter {
              padding-inline: 0.6rem;
            }
          }

          @media (max-width: 991px) {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .arttoggleBtn {
              display: block;
              padding-right: 1rem;

              button {
                background: transparent;
                border: none;
              }
            }
          }

          @media (max-width: 479px) {
            margin-top: 1rem;
            justify-content: space-between;
          }

          .dropFilter {
            max-width: 20rem;

            :global {
              .dropdown-menu {
                min-width: 20rem;
              }
            }
          }
        }
      }
    }

    .mainContaint {
      display: flex;
      // align-items: center;
      border-top: 1px solid $gray;
      margin-top: 2rem;
      position: relative;

      :global {
        .hide {
          @media (max-width: 991px) {
            transform: translateX(100%);
            transition: all 0.3s ease-in-out;
            right: -2rem !important;
          }
        }

        .show {
          transform: translate(0);
          transition: all 0.3s ease-in-out;
          right: 0 !important;
        }

        .sidefilter {
          @media (max-width: 991px) {
            position: absolute;
            right: 0;
            background-color: $white;
            z-index: 99;
            width: 100%;
            max-width: 25rem;
            height: 100%;
            overflow-x: hidden;
          }
        }
      }

      .leftFilter {
        width: 100%;
        max-width: 32.2rem;
        color: $black;
        border-right: 1px solid $borderClr;

        @media (max-width: 1679px) {
          max-width: 28rem;
        }

        @media (max-width: 991px) {
          max-width: 31rem;
          padding-inline: 1.5rem;
          border-right: 0;
        }

        @media (max-width: 479px) {
          max-width: 26rem;
        }

        h4 {
          margin-bottom: 2.4rem;
        }

        :global {
          .form-check-label {
            color: $black;
          }
        }

        ul {
          // padding-top: 2rem;

          li {
            padding: 2rem 0;
            border-top: 1px solid $borderClr;

            @media (max-width: 1679px) {
              padding: 1.6rem 0;
            }

            @media (max-width: 991px) {
              padding: 1rem 0;
            }

            &:first-child {
              border-top: none;
              padding-top: 3rem;

              // background-color: red;
              :global {
                .dropdown {
                  button {
                    border-top: 1px solid $borderGray;
                  }
                }
              }
            }

            // &:last-child {
            //     border-bottom: 1px solid $borderClr;
            // }

            &:nth-child(2) {
              :global {
                .dropdown {
                  button {
                    @media (max-width: 767px) {
                      border-top: 1px solid $borderGray;
                    }
                  }
                }
              }
            }

            :global {
              .dropdown {
                button {
                  background-color: transparent;
                  border: 0;
                  font-size: 2rem;
                  font-weight: bold;
                  color: $black;
                  border-bottom: 1px solid $borderGray;
                  border-radius: 0;
                  width: 100%;
                  padding: 2rem 0;
                  text-align: left;
                  padding-right: 5rem;
                  position: relative;

                  &:hover,
                  &:focus,
                  &:active {
                    background-color: transparent !important;
                    color: $black;
                    border-color: $borderGray;
                  }

                  &:after {
                    content: "";
                    border: 0;
                    position: absolute;
                    transform: translateY(-50%) rotate(90deg);
                    top: 50%;
                    height: 2.4rem;
                    width: 2.4rem;
                    background-image: url(../../../Assets/icons/rightArrow.svg);
                    background-repeat: no-repeat;
                    background-position: calc(100% + 0.35rem) center;
                    right: 0;
                    transition: 0.3s all ease-in-out;
                  }
                }

                > div {
                  border-radius: 0;
                  border: 0;
                  position: static !important;
                  display: block;
                  height: 0px;
                  max-height: 0px;
                  opacity: 0;
                  overflow: hidden;
                  transition: all 0.3s;
                  padding: 0;
                  transform: none !important;

                  > div {
                    margin: 0.5rem 0 0 0;
                  }

                  .form-check {
                    input {
                      height: 2.5rem;
                      width: 2.5rem;
                      border: 2px solid $black;
                      border-radius: 3px;
                      top: 0;

                      &:focus {
                        box-shadow: none;
                      }
                    }

                    label {
                      padding-left: 1rem;
                      font-size: 2rem;
                      color: $black;
                    }
                  }
                }
              }

              .dropdown.show {
                button {
                  &:after {
                    transform: translateY(-50%) rotate(-95deg);
                  }
                }

                > div {
                  padding-top: 2rem;
                  opacity: 1;
                  height: auto;
                  max-height: 100rem;
                }
              }
            }

            .vaule_selected {
              .serch_head {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                position: relative;
                height: auto;
                margin-bottom: 2.4rem;

                @media (max-width: 1679px) {
                  margin-bottom: 2rem;
                }

                @media (max-width: 1199px) {
                  margin-bottom: 1.6rem;
                }

                @media (max-width: 991px) {
                  margin-bottom: 1.2rem;
                }

                h5 {
                  font-size: 2rem;
                  font-weight: bold;
                  margin-top: 0.5rem;
                  margin-left: 0.2rem;
                  line-height: 2.6rem;
                  text-transform: uppercase;

                  @media (max-width: 1679px) {
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                  }

                  @media (max-width: 1199px) {
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                  }

                  @media (max-width: 991px) {
                    font-size: 1.4rem;
                    line-height: 2rem;
                  }
                }

                .serch_value {
                  position: absolute;
                  top: 0.5rem;
                  right: 1.5rem;
                  width: 100%;
                  z-index: 1;

                  @media (max-width: 991px) {
                    top: 0.2rem;
                  }

                  .clickable {
                    pointer-events: all !important;
                  }

                  :global {
                    .common_input_inner {
                      position: relative;
                      z-index: 0;

                      .form-control {
                        cursor: pointer;
                        padding: 0;
                        border-radius: 4rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 2.4rem;
                        margin-left: auto;
                        height: 2.4rem;
                        background-color: #e6e6e6;
                        border: 1px solid #e6e6e6;
                        transition: 0.3s all ease-in-out;
                        color: transparent;

                        &::placeholder {
                          opacity: 0;
                        }

                        &:hover {
                          color: transparent;
                        }

                        &:focus {
                          width: 100% !important;
                          padding: 1.1rem 1.5rem;
                          border-radius: 2rem;
                          background-color: $bgwhite;
                          transition: 0.3s all ease-in-out;
                          color: $black;
                          padding-right: 3.9rem;

                          &::placeholder {
                            opacity: 1;
                          }

                          &[value=""] {
                            color: $black;
                          }

                          + .rightIcon {
                            pointer-events: all !important;
                          }
                        }

                        &[value=""] {
                          color: transparent;
                        }
                      }

                      .rightIcon {
                        position: absolute;
                        right: 0.5rem;
                        top: 50%;
                        background-color: transparent;
                        width: 1.5rem;
                        height: 1.5rem;
                        z-index: 1;
                        pointer-events: none;
                        text-align: center;
                        transform: translateY(-50%);

                        svg {
                          g {
                            stroke: $black;
                            opacity: 1;
                          }

                          path {
                            fill: $black;
                          }
                        }
                      }
                    }
                  }
                }
              }

              :global {
                .common_input {
                  margin-bottom: 1rem;
                  justify-content: flex-start !important;

                  .form-check {
                    display: inline-flex;
                    align-items: center;

                    input {
                      height: 2rem;
                      width: 2rem;
                      border: 2px solid $black;
                      border-radius: 3px;

                      // top: 0;
                      @media (max-width: 1199px) {
                        height: 1.6rem;
                        width: 1.6rem;
                      }

                      &:focus {
                        box-shadow: none;
                      }
                    }

                    label {
                      padding-left: 1rem;
                      font-size: 1.6rem;
                      color: $black;
                      font-family: $baseFont;
                      line-height: 1.2;
                      font-weight: 400;
                      white-space: unset;
                      word-break: break-all;
                      width: 100%;
                      min-width: 25rem;
                      max-width: 26rem;

                      @media (max-width: 1679px) {
                        padding-left: 0.8rem;
                        font-size: 1.4rem;
                        max-width: 22rem;
                        min-width: 20rem;
                      }

                      @media (max-width: 991px) {
                        padding-left: 0.6rem;
                        font-size: 1.2rem;
                      }
                    }
                  }
                }
              }

              .morebtn {
                text-decoration: none;
                padding: 0.7rem 1rem;
                height: 3rem;
                margin-left: 2rem;

                @media (max-width: 1679px) {
                  font-size: 1.4rem;
                  padding: 0.5rem 1rem;
                  margin-left: 1.5rem;
                }

                @media (max-width: 991px) {
                  font-size: 1.2rem;
                  margin-left: 1rem;
                }

                span {
                  margin-left: 0;
                  margin-right: 0.5rem;
                  font-size: 2rem;
                  font-weight: 500;
                }
              }
            }

            .min_max_value {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              @media (min-width: 1200px) and (max-width: 1679px) {
                flex-wrap: wrap;
              }

              :global {
                .common_input {
                  margin-bottom: 0;
                  margin-right: 1rem;

                  &_inner {
                    .form-control {
                      height: 3rem;
                      border-radius: 0.3rem;
                      border: 1px solid;
                      padding: 0.5rem 1rem;
                      font-size: 1.4rem;
                      max-width: 6rem;
                    }
                  }

                  // @media (min-width: 1200px) and (max-width: 1679px) {
                  //     flex: 0 0 47%;
                  //     margin-right: 0;
                  // }
                }
              }

              .btngo {
                border-radius: 0.3rem;
                height: 3rem;
                font-size: 1.4rem;
                font-weight: 400;
                max-width: 6rem;

                // @media (min-width: 1200px) and (max-width: 1679px) {
                //     margin-top: 1rem;
                // }
              }
            }
          }
        }

        @media (max-width: 1679px) {
          ul {
            li {
              :global {
                .dropdown {
                  button {
                    font-size: 1.8rem;
                  }

                  > div {
                    .form-check {
                      label {
                        font-size: 1.8rem;
                      }

                      input {
                        height: 2rem;
                        width: 2rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        @media (max-width: 1439px) {
          ul {
            li {
              :global {
                .dropdown {
                  button {
                    font-size: 1.6rem;

                    &:after {
                      background-size: 2rem;
                      height: 2rem;
                      width: 2rem;
                    }
                  }

                  > div {
                    .form-check {
                      label {
                        font-size: 1.6rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .rightCards {
        width: 100%;
        max-width: calc(100% - 32.2rem);
        padding-left: 2.4rem;
        margin-top: 3rem;
        padding-bottom: 3rem;

        @media (max-width: 1679px) {
          max-width: calc(100% - 28rem);
        }

        @media (max-width: 991px) {
          padding-left: 0;
          max-width: 100%;
        }

        .cardRow {
          display: flex;
          align-items: center;
          margin: -1.5rem -1.2rem;
          flex-wrap: wrap;

          .cardBox {
            width: calc(100% / 5);
            padding: 1.5rem 1.2rem;

            @media (max-width: 1679px) {
              width: calc(100% / 4);
            }

            @media (max-width: 1399px) {
              width: calc(100% / 3);
            }

            @media (max-width: 1199px) {
              width: calc(100% / 2);
            }

            // @media (max-width: 767px) {
            //     width: calc(100% / 2);
            // }

            @media (max-width: 575px) {
              width: 100%;
            }

            :global {
              .video_hover_sec {
                height: auto;
                position: relative;
                // margin-top: -0.8rem;

                > div {
                  position: relative !important;

                  .CommonCard {
                    &::before {
                      background: transparent;
                    }

                    .image_section_hover {
                      transition: 0.2s all ease-in-out;

                      &:hover {
                        .thumnail_img {
                          transition: 0.2s all ease-in-out;
                        }

                        .featureimg {
                          display: none;
                        }
                      }
                    }
                  }
                }

                &:hover {
                  transition: 0.2s all ease-in-out;

                  > div {
                    z-index: 11 !important;
                    transition: 0.2s all ease-in-out;
                  }

                  > video {
                    transform: scale(1.06);
                    z-index: 9;
                  }
                }

                > video {
                  order: 1;
                  position: absolute;
                  top: 0px;
                  left: 0;
                  transition: 0.2s all ease-in-out;
                }
              }

              .CommonCard {
                position: relative;
                height: auto;

                &:before {
                  background-color: hsla(0, 0%, 100%, 0.6);
                  border-radius: 1.4rem;
                  content: "";
                  display: block;
                  padding-top: 122%;
                  width: 100%;
                }

                &ContentHandle {
                  padding: 2rem 1.4rem;

                  &BottomLeft {
                    p {
                      font-size: 1rem;
                    }

                    :global {
                      .labelData {
                        padding-bottom: 0.5rem;
                      }
                    }
                  }
                }

                .textHandle {
                  h4 {
                    font-size: 1.4rem;
                    height: auto;
                    line-height: 1.2;
                    word-break: break-word;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -moz-box-orient: vertical;
                    -ms-box-orient: vertical;
                    box-orient: vertical;
                    -webkit-line-clamp: 2;
                    -moz-line-clamp: 2;
                    -ms-line-clamp: 2;
                    line-clamp: 2;
                    overflow: hidden;
                  }

                  p {
                    font-size: 1rem;
                  }
                }

                &:hover {
                  transition: 0.3s all ease-in-out;
                  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
                  transform: scale(1.06);
                }

                .image_section_hover {
                  bottom: 0;
                  display: block;
                  height: 100%;
                  left: 0;
                  margin-left: auto;
                  margin-right: auto;
                  overflow: hidden;
                  position: absolute;
                  right: 0;
                  top: 0;
                }
              }
            }
          }
        }

        .marketNotdata {
          min-height: 34rem;
        }
      }
    }
  }
}
