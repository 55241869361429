@import '../../../../Assets/_var.scss';

.earnings {
    .tablist {
        position: relative;

        @media (max-width:991px) {
            margin-top: 3rem;
        }

        @media (max-width:767px) {
            margin-top: 0;
        }

        .earnings_table {
            margin: 3rem 0;
            width: 100%;

            .btneditsize {
                width: 100%;
                max-width: 9rem;
                height: 3rem;
                border-radius: 1.4rem;
                font-size: 1.2rem;
                font-weight: 400;
                font-family: 'Roboto';
            }
        }

        .calander_field {
            width: 100%;
            max-width: 33rem;
            margin-left: 1.2rem;
            position: relative;
            top: 1.5rem;

            @media (max-width:1199px) {
                max-width: 28rem;
                margin-left: 0.5rem;
            }

            @media (max-width:767px) {
                max-width: 30rem;
                top: 0;
                margin-bottom: 1rem;
                margin-left: 0;
            }

            @media (max-width:449px) {
                max-width: 100%;
            }

            :global {
                .common_date {
                    margin-bottom: 0;

                    .form-control {
                        font-size: 1.4rem;
                        text-align: center;
                        border: 1px solid $gray707070;
                        width: 100%;
                        height: 4rem;

                        &::placeholder {
                            color: $gray707070;
                        }
                    }
                }
            }

            form {
                display: flex;
                align-items: center;

                .createInputBox {
                    margin-left: 1.7rem;
                    width: 100%;
                    max-width: 15.9rem;
                    font-size: 1.4rem;
                    text-align: center;
                    border: 1px solid $gray707070;
                    width: 100%;
                    height: 4rem;
                    padding: 0.5rem 1rem;
                    border-radius: 2rem;

                    @media (max-width:1199px) {
                        margin-left: 1rem;
                        font-size: 1.2rem;
                    }

                    @media (max-width:767px) {
                        &:first-child {
                            margin-left: 0;
                        }
                    }

                    &::placeholder {
                        color: $gray707070;
                    }
                }
            }
        }
    }

    .tabTop {
        display: flex;
        align-items: center;

        @media (max-width:767px) {
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        :global {
            .tablists {
                width: 100%;
            }
        }
    }
}