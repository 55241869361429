@import '../../../../Assets/_var.scss';

@keyframes marquee {
    0% {
        background-position: 0;
    }

    100% {
        background-position: -300rem;
    }
}

.nifty_banner {
    position: relative;
    text-align: center;
    padding-top: 15rem;
    padding-bottom: 4rem;


    &::after {
        content: '';
        width: 100%;
        height: 26rem;
        position: absolute;
        top: 21rem;
        left: 0;
        background-image: url(../../../../Assets/images/createSecBg.png);
        background-position: center;
        background-repeat: repeat-x;
        z-index: -1;
        animation: marquee 110s infinite linear;
    }

    &:before {
        content: "";
        position: absolute;
        height: 26rem;
        width: 100%;
        max-width: 100%;
        left: 0;
        bottom: 0;
        background-image: url(../../../../Assets/images/createSecBg.png);
        background-position: center;
        background-repeat: repeat-x;
        z-index: -1;
        animation: marquee 110s infinite linear;
    }

    .niftyBannerCenter {
        position: relative;

        .bannerTopHead {
            background: $white;
            position: absolute;
            z-index: 1;
            height: 11rem;
            top: -5.5rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 37rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width:1469px) {
                max-width: 25rem;
                top: -4rem;
                height: 8rem;
            }

            @media (max-width:575px) {
                max-width: 25rem;
                top: -2.5rem;
                height: 5rem;
            }
        }
    }


    &_content {
        width: 100%;
        max-width: 120rem;
        margin: 0 auto;
        padding: 7rem 10rem 6rem;
        border: 3px solid $black;
        background-color: $white;

        h4 {
            margin-bottom: 2rem;
            font-family: $baseFont;
        }

        p {
            margin-bottom: 1.6rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media (max-width:1679px) {
        padding-top: 15rem;
        padding-bottom: 14rem;

        img {
            max-width: 106rem;
        }

        &_content {
            padding: 14rem 5rem 5rem;
        }

        &:before {
            height: 30.2rem;
        }
    }

    @media (max-width:1439px) {
        padding-top: 12rem;
        padding-bottom: 10rem;

        &::after {
            height: 8rem;
        }

        img {
            max-width: 80rem;
            margin: 0 auto -8rem;
        }

        &_content {
            padding: 7rem 4rem 4rem;
        }

        &:before {
            height: 26.2rem;
        }
    }

    @media (max-width: 1199px) {
        padding-bottom: 7rem;

        img {
            max-width: 60rem;
            margin: 0 auto -6rem;
        }

        &_content {
            padding: 8rem 3rem 3rem;

            h4 {
                margin-bottom: 1rem;
            }
        }

        &:before {
            height: 24.2rem;
        }
    }

    @media (max-width:991px) {
        padding-bottom: 6rem;

        img {
            margin: 0 auto -4rem;
            max-width: 40rem;
            padding: 0 2rem;
        }

        &_content {
            padding: 6rem 2rem 2rem;

            p {
                margin-bottom: 1rem;
            }
        }

        &:before {
            height: 23.2rem;
        }
    }

    @media (max-width: 575px) {
        img {
            max-width: 25rem;
            margin: 0 auto -3rem;
        }

        &_content {
            padding: 6rem 1.5rem 1.5rem;

            h5 {
                margin-top: 4rem;
            }

            p {
                font-size: 1.4rem;
            }
        }
    }
}