@import "../../../../Assets/_var.scss";

.active_digitalart {
  @media (max-width:991px) {
    margin-top: 1rem;
  }

  :global {
    .row {
      margin: -1.5rem;

      >div {
        padding: 1.5rem;
      }
    }
  }

  .no_record {
    p {
      font-size: 4rem;
      border: 2px solid $borderGray;
      color: $borderGray;
    }
  }

  .card_colloection {
    // :global {
    //   .video_hover_sec {
    //     height: auto;
    //     position: relative;
    //     // margin-top: -0.8rem;

    //     >div {
    //       position: relative !important;

    //       .CommonCard {
    //         &::before {
    //           background: transparent;
    //         }

    //         .image_section_hover {
    //           transition: 0.2s all ease-in-out;


    //           &:hover {
    //             .thumnail_img {
    //               transition: 0.2s all ease-in-out;
    //             }

    //             .featureimg {
    //               display: none;
    //             }
    //           }
    //         }
    //       }
    //     }

    //     >div:nth-of-type(2) {
    //       // display: none;
    //       position: absolute !important;
    //     }

    //     &:hover {
    //       >video {
    //         transform: scale(1.06);
    //         z-index: 9;
    //       }
    //     }

    //     >video {
    //       order: 1;
    //       position: absolute;
    //       top: 0px;
    //       left: 0;
    //       transition: 0.2s all ease-in-out;
    //     }
    //   }

    //   .CommonCard {
    //     position: relative;
    //     height: auto;

    //     &:before {
    //       background-color: hsla(0, 0%, 100%, .6);
    //       border-radius: 1.4rem;
    //       content: "";
    //       display: block;
    //       padding-top: 122%;
    //       width: 100%;
    //     }

    //     &ContentHandle {
    //       padding: 2rem 1.4rem;

    //       &BottomLeft {
    //         p {
    //           font-size: 1rem;
    //         }
    //       }

    //     }

    //     .textHandle {
    //       h4 {
    //         font-size: 1.4rem;
    //       }

    //       p {
    //         font-size: 1rem;
    //       }
    //     }

    //     @media (max-width:991px) {
    //       h4 {
    //         white-space: nowrap;
    //         overflow: hidden;
    //         text-overflow: ellipsis;
    //       }
    //     }

    //     &:hover {
    //       transition: 0.3s all ease-in-out;
    //       box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
    //       transform: scale(1.06);
    //     }

    //     .image_section_hover {
    //       bottom: 0;
    //       display: block;
    //       height: 100%;
    //       left: 0;
    //       margin-left: auto;
    //       margin-right: auto;
    //       overflow: hidden;
    //       position: absolute;
    //       right: 0;
    //       top: 0;
    //     }
    //   }
    // }

    .cardRow {
      display: flex;
      align-items: center;
      margin: -1.5rem -1.2rem;
      flex-wrap: wrap;

      .cardBox {
        width: calc(100% / 5);
        padding: 1.5rem 1.2rem;

        @media (max-width: 1679px) {
          width: calc(100% / 4);
        }

        @media (max-width: 1399px) {
          width: calc(100% / 3);
        }

        @media (max-width: 1199px) {
          width: calc(100% / 2);
        }

        @media (max-width: 575px) {
          width: 100%;
        }

        :global {
          .video_hover_sec {
            position: relative;
            height: auto;

            &:before {
              background-color: hsla(0, 0%, 100%, 0.6);
              border-radius: 1.4rem;
              content: "";
              display: block;
              padding-top: 122%;
              width: 100%;
            }

            video {
              position: absolute;
              left: 0;
              top: 0;
            }
          }

          .CommonCard {
            position: relative;
            height: auto;

            &:before {
              background-color: hsla(0, 0%, 100%, 0.6);
              border-radius: 1.4rem;
              content: "";
              display: block;
              padding-top: 122%;
              width: 100%;
            }

            &ContentHandle {
              padding: 2rem 1.4rem;

              &BottomLeft {
                p {
                  font-size: 1rem;
                }

                :global {
                  .labelData {
                    padding-bottom: 0.5rem;
                  }
                }
              }

              &Top {
                padding-bottom: 0.4rem;
              }

              &Bottom {
                min-height: auto;
              }
            }

            .textHandle {
              h4 {
                font-size: 1.4rem;
                height: auto;
                line-height: 1.2;
                word-break: break-word;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                -ms-box-orient: vertical;
                box-orient: vertical;
                -webkit-line-clamp: 2;
                -moz-line-clamp: 2;
                -ms-line-clamp: 2;
                line-clamp: 2;
                overflow: hidden;
              }

              p {
                font-size: 1rem;
              }
            }

            @media (max-width: 991px) {
              h3 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &:hover {
              transition: 0.3s all ease-in-out;
              box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
              transform: scale(1.06);
            }

            .image_section_hover {
              bottom: 0;
              display: block;
              height: 100%;
              left: 0;
              margin-left: auto;
              margin-right: auto;
              overflow: hidden;
              position: absolute;
              right: 0;
              top: 0;
            }
          }

          .video_hover_sec {
            &:hover {
              transition: 0.3s all ease-in-out;
              box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
              transform: scale(1.06);

              video {
                z-index: 9;
              }
            }

            .CommonCard {
              &:hover {
                transition: unset;
                box-shadow: none;
                transform: none;

                &::before {
                  background: transparent;
                }
              }
            }
          }

          .video_hover_sec {
            &:hover {
              transition: 0.3s all ease-in-out;
              box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.9019607843);
              transform: scale(1.06);

              >div {
                &:first-child {
                  z-index: 11 !important;
                  transition: 0.2s all ease-in-out;
                }
              }

              video {
                z-index: 9;
              }
            }

            .CommonCard {
              &:hover {
                transition: unset;
                box-shadow: none;
                transform: none;

                &::before {
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }

    &Other {
      .cardRow {
        display: flex;
        align-items: center;
        margin: -1.5rem -1.2rem;
        flex-wrap: wrap;

        .cardBox {
          width: calc(100% / 6);
          padding: 1.5rem 1.2rem;

          @media (max-width: 1679px) {
            width: calc(100% / 5);
          }

          @media (max-width: 1399px) {
            width: calc(100% / 4);
          }

          @media (max-width: 1199px) {
            width: calc(100% / 3);
          }

          @media (max-width: 991px) {
            width: calc(100% / 2);
          }

          @media (max-width: 575px) {
            width: 100%;
          }
        }
      }
    }
  }

  .tablist {
    margin-top: 5rem;

    .card_colloection {
      padding: 6rem 0;

    }
  }

  .searchbar {
    margin-bottom: 5rem;

    :global {
      form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 575px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .search_input {
          width: 100%;

          .search_input_inner {
            height: 5.8rem;
            border-radius: 2.9rem;
            background: $white_light3;
            padding: 0 2.8rem;

            @media (max-width: 991px) {
              height: 5rem;
            }

            @media (max-width: 767px) {
              height: 4.5rem;
            }

            .search_input_icon {
              border: none;
              left: 2rem;

              svg {
                path {
                  fill: $gray5;
                }
              }
            }

            .form-control {
              border: none;
              background: transparent;
              font-size: 2rem;

              @media (max-width: 991px) {
                font-size: 1.8rem;
              }

              @media (max-width: 767px) {
                font-size: 1.6rem;
              }

              &::placeholder {
                color: $gray6;
              }
            }
          }
        }
      }
    }

    .btnimport {
      width: 100%;
      max-width: 22rem;
      height: 4.7rem;
      margin-left: 3.3rem;
      padding: 0.8rem 1rem;
      font-size: 2rem;
      line-height: 2.2rem;

      @media (max-width: 991px) {
        font-size: 1.8rem;
      }

      @media (max-width: 767px) {
        font-size: 1.6rem;
      }

      @media (max-width: 575px) {
        margin: 1.5rem 0 0;
      }
    }
  }
}