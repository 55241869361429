@import '../../../Assets/_var.scss';

.user_profile {
    .tablist {
        margin: 5rem 0;

        @media (max-width:1679px) {
            margin: 4rem 0;
        }

        @media (max-width:1399px) {
            margin: 3.5rem 0;
        }

        @media (max-width:991px) {
            margin: 3rem 0;
        }

        @media (max-width:767px) {
            margin: 2rem 0;
        }

        .navtablist {
            display: flex;
            align-items: flex-start;

            @media (max-width:991px) {
                flex-direction: column;
            }

            :global {
                .nav_tabslist {
                    border-bottom: 1px solid $gray707070;
                    border-right: 0;
                    min-height: unset;
                    flex-direction: unset;
                    width: 100%;
                    max-width: calc(100% - 51rem);
                    flex: 0 0 calc(100% - 51rem);
                    margin-right: 2rem;
                    overflow-x: auto;
                    flex-wrap: unset;

                    &.tabFull {
                        max-width: 100%;
                        flex: 0 0 100%;
                    }

                    // @media (max-width:1679px) {
                    //     max-width: calc(100% - 70rem);
                    //     flex: 0 0 calc(100% - 70rem);
                    // }

                    @media (max-width:1399px) {
                        max-width: calc(100% - 43.5rem);
                        flex: 0 0 calc(100% - 43.5rem);
                        margin-right: 1.5rem;
                    }

                    @media (max-width:1199px) {
                        max-width: calc(100% - 34rem);
                        flex: 0 0 calc(100% - 34rem);
                        margin-right: 1rem;
                    }

                    @media (max-width:991px) {
                        max-width: 100%;
                        flex: 0 0 100%;
                        margin-bottom: 1rem;
                        margin-right: 0;
                    }

                    &::before {
                        content: unset;
                    }

                    .nav-item {
                        padding-inline: 1.5rem;

                        @media (max-width:1679px) {
                            padding-inline: 1rem;
                        }

                        @media (max-width:1399px) {
                            padding-inline: 0.7rem;
                        }

                        @media (max-width:1199px) {
                            padding-inline: 0.5rem;
                        }

                        @media (max-width:991px) {
                            padding-inline: 0.8rem;
                            max-width: max-content;
                        }

                        @media (max-width:767px) {
                            padding-inline: 0.4rem;
                        }

                        &:first-child {
                            padding-left: 0;
                        }

                        &:last-child {
                            padding-right: 0;
                        }

                        .nav-link {
                            padding: 0.4rem 1rem;
                            font-size: 1.6rem;
                            font-weight: 400;
                            color: $gray969696;
                            border: none;
                            line-height: 1.2;

                            @media (max-width:1679px) {
                                padding: 0.4rem 0.8rem;
                                font-size: 1.4rem;
                            }

                            @media (max-width:1199px) {
                                padding: 0.4rem 0.5rem;
                                font-size: 1.2rem;
                            }

                            @media (max-width:991px) {
                                padding: 0.6rem 0.8rem;
                            }

                            @media (max-width:767px) {
                                padding: 0.6rem 0.4rem;
                            }

                            &.active {
                                color: $black313131;
                                border-bottom: 3px solid $black313131;
                                font-weight: bold;
                            }

                        }
                    }
                }

            }

            .rightSearch {
                width: 100%;
                max-width: 49rem;
                flex: 0 0 49rem;

                // @media (max-width:1679px) {
                //     max-width: 68rem;
                //     flex: 0 0 68rem;
                // }

                @media (max-width:1399px) {
                    max-width: 42rem;
                    flex: 0 0 42rem;
                }

                @media (max-width:1199px) {
                    max-width: 33rem;
                    flex: 0 0 33rem;
                }

                @media (max-width:991px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                :global {
                    form {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        // @media (max-width: 479px) {
                        //     flex-direction: column;
                        //     align-items: flex-start;
                        // }

                        .search_input {
                            width: 100%;

                            .search_input_inner {
                                height: 4rem;
                                border-radius: 2.9rem;
                                background: transparent;
                                padding: 0 2rem;
                                border: 0.1rem solid $gray707070;

                                @media (max-width: 991px) {
                                    height: 3.5rem;
                                }

                                @media (max-width: 767px) {
                                    height: 3rem;
                                    padding: 0 1rem;
                                }

                                .search_input_icon {
                                    border: none;
                                    left: 2rem;
                                    padding: 0;
                                    width: 1.6rem;
                                    height: 1.6rem;

                                    @media (max-width: 767px) {
                                        left: 1rem;
                                    }

                                    svg {
                                        width: 100%;
                                        height: 100%;

                                        path {
                                            fill: $gray5;
                                        }
                                    }
                                }

                                .form-control {
                                    border: none;
                                    background: transparent;
                                    padding-left: 3rem;
                                    font-size: 1.4rem;
                                    font-weight: bold;

                                    @media (max-width: 767px) {
                                        padding-left: 2rem;
                                    }

                                    &::placeholder {
                                        color: $gray707070;
                                    }
                                }
                            }
                        }
                    }
                }

                .btnimport {
                    width: 100%;
                    max-width: 12.2rem;
                    height: 4rem;
                    margin-left: 2.2rem;
                    padding: 0.8rem 1rem;
                    font-size: 1.6rem;
                    line-height: 1.2;
                    border-radius: 2rem;
                    border-width: 1px;

                    @media (max-width:1679px) {
                        margin-left: 1.2rem;
                        font-size: 1.4rem;
                    }

                    @media (max-width:1199px) {
                        margin-left: 1rem;
                        max-width: 9rem;
                    }

                    @media (max-width:991px) {
                        height: 3.5rem;
                    }

                    @media (max-width:767px) {
                        height: 3rem;
                    }
                }
            }
        }

    }

    :global {
        .tab-content {
            padding: 3.2rem 0;
        }
    }
}