@import '../../../../../Assets/_var.scss';

.artImages {
    .landscapeImg {
        .imageSec {
            position: relative;
            width: 100%;
            height: 35.6rem;

            &::before {
                content: "";
                position: absolute;
                top: auto;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, #0000008a 0%, #00000000 100%);
            }

            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .expandBtn {
                position: absolute;
                top: 1.8rem;
                right: 2rem;
                text-decoration: none;
                color: $white;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1;
                height: auto;
            }
        }
    }

    .portraitImg {
        width: 100%;
        max-width: 35.6rem;
        margin-inline: auto;

        .imageSec {
            position: relative;
            width: 100%;
            height: 63.3rem;

            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .expandBtn {
                position: absolute;
                top: 1.8rem;
                right: 2rem;
                text-decoration: none;
                color: $white;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1;
                height: auto;
            }
        }
    }
}