@import '../../../../../Assets/_var.scss';

.password_sec {
    padding: 3rem 0;

    .password_heading {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 2.8rem;
        margin-bottom: 2rem;
    }

    .btnsize {
        margin-top: 3rem;
        min-width: 10rem;
        height: 4rem;
        padding: 0.6rem 1rem;
        font-size: 1.6rem;
        font-weight: bold;
        font-family: $baseFont;

        @media (max-width:1679px) {
            font-size: 1.4rem;
        }

        @media (max-width:991px) {
            min-width: 14rem;
            margin-top: 1rem;
            font-size: 1.2rem;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .listingpass {
        padding-left: 3rem;

        @media (max-width:991px) {
            padding-left: 1rem;
        }

        @media (max-width:575px) {
            padding-left: 0;
        }

        li {
            position: relative;
            font-size: 1.4rem;
            font-weight: 500;
            font-family: $baseFont;
            line-height: 3.4rem;
            padding-left: 2rem;

            &::before {
                position: absolute;
                content: '';
                left: 0;
                top: 1.26rem;
                border-radius: 50%;
                width: 1.1rem;
                height: 1.1rem;
                background-color: $gray;
            }

            &.active {
                &::before {
                    background-color: $green2;
                }
            }
        }
    }

    :global {
        form {
            width: 100%;
            max-width: 80rem;

            .password_input {
                margin-bottom: 3rem;

                .rightIcon {
                    width: 2.5rem;
                    height: 100%;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .heading_text {
        margin-bottom: 2.5rem;

        h3 {
            font-size: 2.4rem;
            line-height: 2.4rem;
            font-weight: bold;
            font-family: 'Roboto';
            color: $black;
            margin-bottom: 0.8rem;
        }

        p {
            font-size: 1.6rem;
            font-weight: 400;
            font-family: 'Roboto';
            color: $black;
        }
    }
}