@import "../../../../Assets/_var.scss";

.wallet_tab {
  .balance_fields {
    padding: 3.2rem 0;

    @media (max-width: 1679px) {
      padding: 3rem 0;
    }

    @media (max-width: 1399px) {
      padding: 2.4rem 0;
    }

    @media (max-width: 991px) {
      padding: 1.8rem 0;
    }

    .values_balance {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 479px) {
        flex-direction: column;
      }

      .text_fields {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: 3rem;

        @media (max-width: 1679px) {
          margin-right: 2.6rem;
        }

        @media (max-width: 1399px) {
          margin-right: 2rem;
        }

        @media (max-width: 479px) {
          margin: 0 0 1.5rem;
        }

        label {
          font-size: 1.2rem;
          font-weight: 400;
          font-family: $baseFont;
          margin-right: 0.7rem;
          color: $gray969696;
          line-height: 1.2;
        }

        h2 {
          line-height: 1.2;
          font-weight: bold;
          font-family: $baseFont;
          margin: 0;
          color: $black3;
          display: flex;
          align-items: flex-start;

          span {
            font-size: 1.4rem;
            font-weight: bold;
            margin-right: 0.6rem;
            line-height: 1.2;
            color: $black3;
          }
        }
      }

      .btnfunds {
        .addfundBtn,
        .borderBtn {
          margin-right: 1rem;
          font-size: 1.2rem;
          height: 3rem;
          padding: 0.8rem 2rem;

          @media (max-width: 1199px) {
            margin-right: 0.8rem;
          }
        }

        .borderBtn {
          margin-right: 0 !important;
        }
      }
    }
  }
}

.ledger_tab {
  .table_section {
    padding: 4rem 0;

    .artowned_table {
      :global {
        table {
          border-collapse: collapse;
          border-spacing: 0;
          border: 1px solid $gray707070;

          thead {
            tr {
              border: 1px solid $gray707070;

              th {
                border: none;
                font-size: 1.6rem;
                font-weight: bold;
                color: $white;
                font-family: "Roboto";
                padding: 2rem 1.8rem;
                background: $black;

                @media (max-width: 1679px) {
                  padding: 1.6rem;
                }

                @media (max-width: 1399px) {
                  padding: 1.4rem;
                  font-size: 1.4rem;
                }

                @media (max-width: 991px) {
                  padding: 1rem;
                  font-size: 1.2rem;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                border: 1px solid $gray707070;
                font-size: 1.6rem;
                font-weight: 400;
                color: $black;
                font-family: "Roboto";
                padding: 2.2rem 1.8rem;

                @media (max-width: 1679px) {
                  padding: 2rem 1.6rem;
                }

                @media (max-width: 1399px) {
                  padding: 1.8rem 1.4rem;
                  font-size: 1.4rem;
                }

                @media (max-width: 991px) {
                  padding: 1.4rem 1rem;
                  font-size: 1.2rem;
                }

                &.narrationData {
                  width: 100%;
                  max-width: 46.1rem;
                  white-space: normal;
                  word-break: break-word;
                  min-width: 46.1rem;
                }
                h6 {
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

.funds_add {
  .fund_inputs {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;

    :global {
      .fund_input {
        width: 100%;
        margin-bottom: 0 !important;

        .leftIconInput {
          .leftIcon {
            color: $black;
            font-size: 1.8rem;
            font-weight: 500;
          }

          .form-control {
            padding-left: 4rem;
          }
        }
      }

      .form-control {
        height: 4.5rem;
        font-size: 1.8rem;
      }

      .common_select {
        width: 100%;

        .common_select_inner {
          .react-select {
            .react-select__control {
              border-radius: 1rem;

              .react-select__placeholder {
                color: rgba($black, 0.8);
              }
            }
          }
        }
      }
    }
  }

  .addbtns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;

    .borderBtn {
      color: $white;
    }
  }
}
