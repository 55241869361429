@import '../../../Assets/_var.scss';

.user_dashboard {
    .bodernone {
        border: none;
    }

    &_section {
        padding-bottom: 3rem;

        .tablist {
            border-top: 1px solid $gray707070;

            :global {
                .nav_tabslist {
                    .nav-item {
                        @media (max-width:991px) {
                            max-width: 16rem;
                        }

                        @media (max-width:767px) {
                            max-width: max-content;
                            min-width: 11rem;

                            &:first-child {
                                max-width: 16rem;
                            }

                            &:last-child {
                                max-width: 14rem;
                            }
                        }
                    }
                }

                .tabSpace {
                    padding-top: 3.3rem;

                    @media (max-width:991px) {
                        padding-top: 2rem;
                    }
                }
            }
        }
    }
}