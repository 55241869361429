@import '../../../../../Assets/_var.scss';

.password_input {
    .common_input_inner {
        .rightIcon {
            svg {
                path {
                    fill: $white;
                }
            }
        }
    }

    &.black-eye-icon {

        .common_input_inner {
            .rightIcon {
                svg {
                    path {
                        fill: $black;
                    }
                }
            }
        }
    }
}