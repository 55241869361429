@import '../../../../Assets/_var.scss';

.commonCardSecond {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  cursor: pointer;
  height: 100%;

  &_innersec {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    @media (max-width: 479px) {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  }

  img {
    width: 10rem;
    height: 10rem;
    flex-shrink: 0;
    object-fit: cover;
    object-position: center;

    @media (max-width: 1679px) {
      width: 9rem;
      height: 9rem;
    }

    @media (max-width: 1399px) {
      width: 8rem;
      height: 8rem;
    }

    @media (max-width: 1199px) {
      width: 7rem;
      height: 7rem;
    }

    @media (max-width: 991px) {
      width: 6rem;
      height: 6rem;
    }

    @media (max-width: 767px) {
      width: 5rem;
      height: 5rem;
    }
  }

  .card_textfields {
    padding-left: 2.4rem;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 991px) {
      padding-left: 1.5rem;
    }

    @media (max-width: 575px) {
      padding-left: 1rem;
    }

    @media (max-width: 479px) {
      padding-left: 0;
      padding-top: 1rem;
    }

    h6 {
      font-weight: bold;
      color: $black;
      word-break: break-word;
      font-family: $baseFont;
      margin-bottom: 1rem;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // width: 100%;
      // overflow: hidden;
      // max-width: 40rem;

      @media (max-width: 991px) {
        margin-bottom: 0.5rem;
      }
    }

    p {
      color: $black;
      font-size: 1.6rem;
      font-weight: 400;
      font-family: $baseFont;
      margin-bottom: 0;
      line-height: 2.2rem;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // overflow: hidden;
      // width: 100%;
      // max-width: 14rem;
      margin-bottom: 1rem;

      @media (max-width: 991px) {
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
      }

      @media (max-width: 767px) {
        font-size: 1.2rem;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 2px solid #e4e4e4;
      margin-bottom: 4.8rem;

      @media (max-width: 1199px) {
        margin-bottom: 2rem;
      }

      li {
        padding-top: 2rem;
        border-right: 2px solid #e4e4e4;
        flex: 0 0 33%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        &:nth-child(2) {
          align-items: center;
        }

        &:last-child {
          border: none;
          align-items: flex-end;
        }

        label {
          font-size: 1.4rem;
          font-weight: bold;
          color: #020302;
          line-height: 1.6rem;
          margin-bottom: 0.7rem;
          font-family: $baseFont;
          min-width: 5rem;
        }

        p {
          margin: 0;
          font-size: 1.8rem;
          font-weight: bold;
          color: #000;
          font-family: $baseFont;
          min-width: 5rem;
        }
      }
    }

    .pricesslist {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.6rem;

      h6 {
        font-size: 1.4rem;
        font-weight: bold;
        color: #020302;
        font-family: $baseFont;
      }
    }


    :global {
      .btnpositionchange {
        flex-direction: row-reverse;
        justify-content: flex-end;

        .black-btn {
          margin-right: 3.8rem !important;

          @media (max-width: 1439px) {
            margin-right: 1rem !important;
            max-width: 12rem !important;
          }
        }

        .bordred-black {
          margin-right: 0;
          max-width: 18rem;
        }
      }
    }
  }

  .btnsselandview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    .btneditsize {
      width: 100%;
      height: 3rem;
      max-width: 9rem;
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      font-weight: bold;
      font-family: $baseFont;

      @media (max-width: 1399px) {
        padding: 0.5rem 1.6rem;
      }

      @media (max-width: 991px) {
        padding: 0.5rem 1rem;
        height: 2.6rem;
      }
    }
  }
}


.commonCard_second {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10rem;
  cursor: pointer;

  @media (max-width: 1439px) {
    margin-bottom: 8rem;
  }

  @media (max-width: 1199px) {
    margin-bottom: 6rem;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    flex-direction: row;
  }

  @media (max-width: 991px) {
    margin-bottom: 4rem;
  }

  @media (min-width: 480px) and (max-width: 575px) {
    flex-direction: column;
  }

  img {
    width: 20rem;
    height: 20rem;
    flex-shrink: 0;
    object-fit: cover;

    @media (max-width: 1679px) {
      width: 18rem;
      height: 18rem;
    }

    @media (max-width: 1399px) {
      width: 16rem;
      height: 16rem;
    }

    @media (max-width: 1199px) {
      width: 12rem;
      height: 12rem;
    }

    @media (max-width: 991px) {
      width: 10rem;
      height: 10rem;
    }

    @media (max-width: 767px) {
      width: 8rem;
      height: 8rem;
    }
  }

  .card_textfields {
    padding-left: 2rem;
    cursor: pointer;

    @media (max-width: 1679px) {
      padding-left: 1.5rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding-left: 1rem;
    }

    @media (min-width: 480px) and (max-width: 575px) {
      padding-left: 0;
      margin-top: 1.5rem;
    }

    h6 {
      font-size: 1.4rem;
      font-weight: bold;
      color: $black;
      word-break: break-word;
      font-family: $baseFont;
      margin-bottom: 1rem;
      width: 100%;
      max-width: 20rem;
    }

    p {
      font-size: 1.2rem;
      // font-weight: bold;
      color: #969696;
      font-family: $baseFont;
      margin-bottom: 2rem;

      @media (max-width:1399px) {
        margin-bottom: 1.5rem;
      }

      @media (max-width:991px) {
        margin-bottom: 1rem;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #707070;
      margin-bottom: 3.3rem;
      width: 100%;
      max-width: 14rem;

      @media (max-width: 1679px) {
        margin-bottom: 2.8rem;
      }

      @media (max-width: 1399px) {
        margin-bottom: 2rem;
      }

      @media (max-width: 1199px) {
        margin-bottom: 1.6rem;
      }

      @media (max-width: 991px) {
        margin-bottom: 1rem;
      }

      li {
        padding-top: 0.5rem;
        border-right: 1px solid #707070;
        flex: 0 0 50%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        &:nth-child(2) {
          align-items: center;
        }

        &:last-child {
          border: none;
          align-items: flex-end;
        }

        label {
          font-size: 1rem;
          font-weight: 400;
          color: $black;
          line-height: 1rem;
          margin-bottom: 0.5rem;
          font-family: $baseFont;
          min-width: 5rem;
        }

        p {
          margin: 0;
          font-size: 1.6rem;
          line-height: 1rem;
          font-weight: bold;
          color: $black;
          font-family: $baseFont;
          min-width: 5rem;
        }
      }
    }

    .pricesslist {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.8rem;

      h6 {
        font-size: 1rem;
        line-height: 1rem;
        font-weight: bold;
        color: $black;
        font-family: $baseFont;
        margin-bottom: 0;

        span {
          font-weight: 400;
        }
      }
    }

    .btnsselandview {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: auto;

      .btneditsize {
        width: 100%;
        max-width: 12rem;
        padding: 0.5rem 1.4rem;
        margin-inline: 1rem;
        margin-top: 0;
        font-size: 1.2rem;
        font-weight: bold;
        height: 3rem;
        font-family: $baseFont;

        @media (max-width: 1679px) {
          max-width: 12rem;
          padding: 0.8rem 1rem;
        }

        @media (max-width: 1399px) {
          max-width: 9rem;
          padding: 0.4rem 0.5rem;
          margin-inline: 0.5rem;

          &:last-child {
            max-width: 7rem;
          }
        }

        @media (max-width: 767px) {
          font-size: 1rem;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    :global {
      .btnpositionchange {
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }
  }

  &.commonCard_other {
    align-items: stretch;

    .card_textfields {
      flex-direction: column;
      display: flex;
      // justify-content: space-between;
    }
  }
}