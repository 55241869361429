@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "./Assets/_var.scss";

$animation-delay: .1s;
$arrow-size: 1rem;

html {
  font-size: 62.5%;

  body {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    font-family: $baseFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      background-color: $grayC1C1C1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $green;
    }
  }
}

a {
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

img,
svg {
  max-width: 100%;
}

.container {
  max-width: 1750px;
  padding: 0 15px;

  // @media (max-width: 1679px) {
  //   max-width: 1366px;
  // }

  // @media (max-width: 1439px) {
  //   max-width: 1280px;
  // }
}

.row {
  margin: 0 -15px;

  >div {
    padding: 0 15px;
  }
}

ul {
  padding-left: 0;
  list-style: none;
}

// iframe {
//   display: none;
// }

ul,
ol {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  font-family: $baseFont;
}

h1 {
  font-size: 5rem;

  @media (max-width: 1679px) {
    font-size: 4rem;
  }

  @media (max-width: 1399px) {
    font-size: 3.6rem;
  }

  @media (max-width: 991px) {
    font-size: 3rem;
  }

  @media (max-width: 767px) {
    font-size: 2.6rem;
  }

  &.big {
    font-size: 8.6rem;
    line-height: 1;

    @media (max-width: 1679px) {
      font-size: 7rem;
    }

    @media (max-width: 1399px) {
      font-size: 6rem;
    }

    @media (max-width: 991px) {
      font-size: 4rem;
    }

    @media (max-width: 767px) {
      font-size: 3.4rem;
    }
  }

  &.smallText {
    font-size: 3rem;

    @media (max-width: 1679px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1439px) {
      font-size: 2.4rem;
    }

    @media (max-width: 991px) {
      font-size: 2.2rem;
    }

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }
}

h2 {
  font-size: 3rem;

  @media (max-width: 1679px) {
    font-size: 2.8rem;
  }

  @media (max-width: 1439px) {
    font-size: 2.4rem;
  }

  @media (max-width: 991px) {
    font-size: 2.2rem;
  }

  @media (max-width: 767px) {
    font-size: 2rem;
  }

  &.big {
    font-size: 6rem;

    @media (max-width: 1679px) {
      font-size: 5rem;
    }

    @media (max-width: 1399px) {
      font-size: 4rem;
    }

    @media (max-width: 991px) {
      font-size: 3rem;
    }

    @media (max-width: 767px) {
      font-size: 2.6rem;
    }
  }
}

h3 {
  font-size: 2.4rem;
  line-height: 1.2;

  @media (max-width: 1679px) {
    font-size: 2.2rem;
  }

  @media (max-width: 1439px) {
    font-size: 2rem;
  }

  @media (max-width: 991px) {
    font-size: 1.8rem;
  }

  @media (max-width: 767px) {
    font-size: 1.6rem;
  }

  &.big {
    font-size: 4rem;

    @media (max-width: 1679px) {
      font-size: 3rem;
    }

    @media (max-width: 1399px) {
      font-size: 2.6rem;
    }

    @media (max-width: 991px) {
      font-size: 2.2rem;
    }

    @media (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
}

h4 {
  font-size: 2rem;
  line-height: 1.2;

  @media (max-width: 1439px) {
    font-size: 1.8rem;
  }

  @media (max-width: 991px) {
    font-size: 1.6rem;
  }

  @media (max-width: 767px) {
    font-size: 1.4rem;
  }

  &.smallText {
    font-size: 1.8rem;

    @media (max-width: 1439px) {
      font-size: 1.6rem;
    }

    @media (max-width: 991px) {
      font-size: 1.4rem;
    }

    @media (max-width: 767px) {
      font-size: 1.2rem;
    }

  }
}

h5 {
  font-size: 1.8rem;
  line-height: 1.2;

  @media (max-width: 1439px) {
    font-size: 1.6rem;
  }

  @media (max-width: 991px) {
    font-size: 1.4rem;
  }

  @media (max-width: 767px) {
    font-size: 1.2rem;
  }
}

h6 {
  font-size: 1.6rem;
  line-height: 1.2;

  @media (max-width: 1439px) {
    font-size: 1.4rem;
  }

  @media (max-width: 767px) {
    font-size: 1.2rem;
  }
}

p {
  font-size: 1.4rem;
  line-height: 1.2;
}

.dropdown {
  &-menu {
    padding: 1.8rem 2rem;
    min-width: 24rem;
    border: 2px solid $black;
    border-radius: 0.7rem;

    a {
      padding: 1rem 0;
      border-bottom: 2px solid $borderGray;

      &:first-child {
        margin: 0 0 1rem 0;
      }

      &:last-child {
        border-bottom: 0;
        margin: 1rem 0 0 0;
      }

      &:hover {
        background-color: transparent;
      }
    }

    // @media (min-width:1280px) {
    //    display: block !important;
    //   transition: .3s all ease-in-out;
    //   visibility: hidden;
    //   pointer-events: none;
    //   opacity: 0;
    //   transform: translateY(1rem);
    // }
  }

  // @media (min-width:1280px) {
  //   &:hover{
  //     .dropdown-menu{
  //       visibility: visible;
  //       pointer-events: all;
  //       opacity: 1;
  //       transform: translateY(0);
  //     }
  //    }
  // }
}

.slick {

  // &-slider {
  // }
  &-slide {
    margin: 0;
    border: 0 !important;
  }

  &-slider {
    &.arrowsDown {
      padding-bottom: 10rem;

      .slick-arrow {
        top: inherit;
        bottom: 3rem;
      }
    }

    @media (max-width: 1679px) {
      &.arrowsDown {
        padding-bottom: 8rem;

        .slick-arrow {
          bottom: 1rem;
        }
      }
    }
  }

  &-track {
    margin-left: inherit;
    min-width: 100%;
  }
}

.wight_bold {
  font-weight: bold;
}

.modal-backdrop {
  z-index: 9991 !important;
}

.modal {
  z-index: 9992 !important;
}

.signUp {
  li {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
}

.bell {
  height: 4.4rem;
  width: 4.4rem;
  border: 2px solid $yellow;
  border-radius: 50%;
  background-color: $black;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-image: url(./Assets/icons/bell.png);
  background-size: 1.8rem;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1679px) {
    height: 3.4rem;
    width: 3.4rem;
    background-size: 1.4rem;
  }

  @media (max-width: 1439px) {
    height: 3rem;
    width: 3rem;
    background-size: 1.1rem;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

button,
a {
  cursor: pointer !important;
}

:disabled,
.disabled {
  cursor: not-allowed !important;
}

.form-check {
  cursor: pointer;

  input {
    cursor: pointer;
  }

  &-input {
    &:focus {
      border-color: transparent;
    }

    &:checked {
      background-color: $yellow;
      border-color: $yellow;
    }
  }
}

.form-label {
  display: none;
}

// COGO TOAST
#ct-container {
  // position: relative;
  z-index: 9993 !important;

  .ct-row {
    .ct-group {
      position: relative;
      z-index: 99991 !important;

      .ct-toast {
        margin-top: 7.5rem !important;

        // width: 30rem;
        // height: 10rem;
        svg {
          width: 3rem;
          height: 3rem;
        }

        .ct-text-group {
          .ct-text {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}

.hovBtn {
  &:hover {
    background-color: $white  !important;
    color: $black  !important;
    border-color: $white  !important;
  }
}

.tabCursor {
  .nav-item {
    .nav-link {
      cursor: default !important;
    }
  }
}

.tablists {
  border-bottom: 2px solid $borderClr;

  .nav-item {
    border: none;
    background: transparent;

    .nav-link {
      border: none;
      background-color: transparent;
      border-bottom: none;
      color: $gray;
      font-size: 1.6rem;
      font-weight: bold;
      font-family: "Roboto", sans-serif;
      padding: 0.7rem 1.5rem;
      box-shadow: none;

      @media (max-width: 1439px) {
        font-size: 1.4rem;
        padding: 0.7rem 1rem;
      }

      @media (max-width: 479px) {
        font-size: 1.4rem;
        padding: 0.7rem;
      }

      &.active {
        color: $black313131;
        border-bottom: 3px solid $black313131;
      }
    }
  }
}

// .tabvertical {
.nav_tabslist {
  flex-direction: column;
  border: none;
  border-right: 0.1rem solid $gray707070;
  min-height: 25rem;
  height: 100%;

  @media (max-width: 991px) {
    flex-direction: row;
    width: 100%;
    min-height: auto;
    border-right: none;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.2rem;
      background-color: $borderClr;
      z-index: -1;
    }
  }

  .nav-item {
    @media (max-width: 991px) {
      width: 100%;
      max-width: 9rem;
      text-align: center;
    }


    .nav-link {
      font-size: 1.6rem;
      font-weight: 400;
      color: $gray969696;
      line-height: 1.1;
      padding: 2rem 0;
      border-bottom: 0.1rem solid $gray707070;
      box-shadow: none;
      border-radius: 0;
      background: transparent;

      @media (max-width:1199px) {
        font-size: 1.4rem;
        padding: 1.2rem 0;
      }

      @media (max-width: 991px) {
        border-bottom: none;
        white-space: nowrap;
      }

      @media (max-width: 767px) {
        border-bottom: none;
        font-size: 1.6rem;
      }

      &.active {
        font-weight: bold;
        color: $black313131;
      }
    }
  }
}

.btn_resentotp {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 100%;
    max-width: max-content;
    border: none;
    background: no-repeat;
    font-size: 1.6rem;
    color: $green;
    font-weight: 500;

    @media (max-width: 479px) {
      font-size: 1.4rem;
    }
  }

  span {
    color: $green;

    @media (max-width: 479px) {
      font-size: 1.4rem;
    }
  }

  &.afterOtp {
    justify-content: flex-end;

    button {
      max-width: max-content;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.search_input {
  width: 100%;

  .search_input_inner {
    height: 5.8rem;
    border-radius: 2.9rem;
    background: $white_light3;
    padding: 0 2.8rem;

    @media (max-width: 991px) {
      height: 5rem;
    }

    @media (max-width: 767px) {
      height: 4.5rem;
    }

    .search_input_icon {
      border: none;
      left: 2rem;

      svg {
        path {
          fill: $gray5;
        }
      }
    }

    .form-control {
      border: none;
      background: transparent;
      font-size: 2rem;

      @media (max-width: 991px) {
        font-size: 1.8rem;
      }

      @media (max-width: 767px) {
        font-size: 1.6rem;
      }

      &::placeholder {
        color: $gray6;
      }
    }
  }
}

.textyellow {
  color: $yellow2  !important;
}

.size_tag {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: $baseFont;
}

.text-center {
  text-align: center;
}

.no_recordtable {
  text-align: center !important;
  padding: 2.5rem 0;
}

.error_space {
  margin-bottom: 3.5rem !important;
}

.clickable {
  cursor: pointer;
}

.radius_50 {
  img {
    border-radius: 50%;
  }
}

.text-white {
  h6 {
    color: $white  !important;
  }
}

.notificationList {
  overflow-x: hidden;
  overflow-y: auto;
  height: 44rem;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $black;
  }
}

.payBtn {
  &:disabled {
    opacity: 1 !important;

    &:hover {
      background-color: $white  !important;
      color: $black  !important;
      border-color: $black  !important;
    }
  }
}

.resize-none {
  resize: none !important;
}

.mt-20 {
  margin-top: 2rem !important;

  @media (max-width: 575px) {
    margin-top: 1rem !important;
  }
}

.forgetOtp {
  margin-bottom: 2.5rem;
}

.authTwomodal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        @media (max-width: 479px) {
          padding-inline: 1.8rem;
        }
      }
    }
  }
}

.quill {
  border-radius: 1rem;
  border: 2px solid $light_gray4;

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 2px solid $light_gray4;
  }

  .ql-container.ql-snow {
    border: none;
  }
}

.mb-25 {
  margin-bottom: 2.5rem;
}

.mb-40 {
  margin-bottom: 4rem;
}

.textSection {
  color: $black;

  h1,
  h2,
  h3,
  p {
    word-break: break-word;
  }
}

.text-white {
  color: $white  !important;
}

.text-black {
  color: $black;
}

.filterPagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;

  @media (max-width: 479px) {
    flex-direction: column;
    align-items: flex-end;
  }

  .selectShow {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    @media (max-width: 479px) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    label {
      font-size: 1.2rem;
      margin-right: 1rem;
      font-weight: 400;
    }

    &_inner {
      min-width: 6rem;
      height: 3rem;

      .react-select {
        .react-select__control {
          border-radius: 1rem;

          .react-select__value-container {
            .react-select__input-container {
              font-size: 1.2rem;
            }
          }

          .react-select__indicators {
            .react-select__indicator {
              svg {
                fill: #B4B4B4;
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }

  .custom_pagination {
    @media (max-width: 479px) {
      width: 100%;
    }

    .pagination {
      margin-top: 0;

      @media (max-width: 479px) {
        margin-inline: -0.4rem;
        justify-content: flex-end;
      }
    }
  }
}

.scroll-arrow {
  width: $arrow-size;
  height: $arrow-size;
  transform: rotate(45deg);
  border-right: 0.2rem solid $white;
  border-bottom: 0.2rem solid $white;
  animation: arrow-wave 1s infinite;
  animation-direction: alternate;

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation-delay: $animation-delay*$i;
    }
  }
}

@keyframes arrow-wave {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.1;
  }

  20% {
    opacity: 0.2;
  }

  30% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.6;
  }

  70% {
    opacity: 0.7;
  }

  80% {
    opacity: 0.8;
  }

  90% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}