@import "../../../Assets/_var.scss";

.terms_and_use {
    padding: 10rem 0rem;


    :global {
        .container {
            max-width: 132rem;
        }

        .termsLink {
            color: #212529;
        }
    }

    h2 {
        font-size: 4rem;
        font-weight: 700;
        margin-bottom: 2rem;
    }

    h4 {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: normal;
        margin: 2rem 0rem .6rem 0rem;
    }


    p {
        font-size: 1.6rem;
        font-weight: normal;
        line-height: normal;
    }

    h6 {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: normal;
        margin-top: 1rem;
    }

    ul {
        padding-left: 1.5rem;

        li {
            list-style: disc;
            font-size: 1.6rem;
            font-weight: normal;
            line-height: normal;
        }
    }

    .terms_and_sales {
        margin-top: 2rem;

        p {
            margin: 0rem 0rem 2rem 0rem;
        }
    }

}