@import "../../../../../Assets/_var.scss";

.table_sectionoffier {
  border: 1px solid $black;

  // height: 53rem;
  // overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $black;
  }
}

.main_containt {
  padding-top: 1.4rem;

  .offier_recive_row {
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .colSpaceLeft {
      @media (min-width:1200px) {
        width: 100%;
        max-width: 28rem;
      }
    }

    .colSpaceRight {
      @media (min-width:1200px) {
        width: 100%;
        max-width: calc(100% - 28rem);
      }
    }
  }

  .artimgselect {
    @media (min-width: 480px) and (max-width: 991px) {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2rem;
    }

    img {
      height: 29.7rem;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .text_fields {
      margin-top: 2rem;

      @media (min-width: 480px) and (max-width: 991px) {
        margin-left: 2rem;
        width: 100%;
        max-width: 25rem;
        margin-top: 0;
      }

      @media (max-width: 479px) {
        margin-bottom: 2rem;
      }

      h5 {
        font-size: 1.4rem;
        font-weight: 400;
        color: $black3;
        margin-bottom: 0.5rem;
        line-height: 1.2;
      }

      p {
        font-size: 1.2rem;
        font-weight: 400;
        color: $black3;
        margin-bottom: 1rem;
        line-height: 1.2;
      }

      .listart {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        border-top: 1px solid $gray707070;
        border-bottom: 1px solid $gray707070;
        padding: 1rem 0;

        li {
          display: flex;
          align-items: center;
          width: 100%;
          padding-bottom: 0.8rem;

          &:last-child {
            padding-bottom: 0;
          }

          label {
            color: $black3;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1;
            margin-right: 1rem;
          }

          h6 {
            color: $black3;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1;
          }
        }
      }

      .price_list {
        border-bottom: 1px solid $gray707070;
        padding: 1rem 0;
        margin-bottom: 2rem;

        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 0.8rem;

          &:last-child {
            margin: 0;
          }

          label {
            color: $black3;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1;
            margin-right: 1rem;
          }

          h6 {
            color: $black3;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1;
          }
        }
      }

      .btneditsize {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.2;
        height: 4rem;
        padding: 0.8rem;
        width: 100%;
        max-width: 25rem;
        font-family: $baseFont2;

        @media (max-width: 1439px) {
          font-size: 1.4rem;
        }

        @media (max-width: 767px) {
          font-size: 1.2rem;
        }
      }
    }
  }

  :global {
    .table-responsive {
      height: 52.7rem;
    }

    table {
      border-spacing: 0;
      border-collapse: collapse;

      thead {
        tr {
          th {
            background-color: $black;
            border-bottom: 1px solid $black;
            color: $white;
            padding: 2rem 2.4rem;
            font-weight: bold;
            font-size: 1.6rem;

            @media (max-width: 1439px) {
              font-size: 1.4rem;
              padding: 1.6rem 2rem;
            }

            @media (max-width: 991px) {
              font-size: 1.2rem;
              padding: 1rem 1.6rem;
            }

            .dropBtn {
              margin-left: auto;
              font-size: 1.6rem;
              font-weight: bold;
              font-family: $baseFont2;
              padding: 0 1rem;
              background: transparent;
              border: none;
              color: $white;
              height: 2.3rem;
              width: 100%;
              max-width: 10rem;

              &:focus,
              &.active {
                color: $white  !important;
                border: none !important;
              }

              &.show {
                border: none;
                color: $white;

                &:focus,
                &.active {
                  color: $white  !important;
                  border: none !important;
                }
              }

              @media (max-width: 1439px) {
                font-size: 1.4rem;
              }

              @media (max-width: 991x) {
                font-size: 1.2rem;
              }

              svg {
                height: 1.5rem;
                width: 1.5rem;

                path {
                  fill: $white;
                }
              }
            }

            .dropdown-menu {
              min-width: 10rem;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            border-top: 1px solid $black;
            border-bottom: 1px solid $black;
            padding: 2rem 2.4rem;
            font-weight: bold;
            border-left: none !important;
            border-left: none !important;
            font-size: 1.6rem;

            @media (max-width: 1439px) {
              font-size: 1.4rem;
              padding: 1.6rem 2rem;
            }

            @media (max-width: 991px) {
              font-size: 1.2rem;
              padding: 1rem 1.6rem;
            }

            .offer_price {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              flex-direction: column;

              h6 {
                font-weight: bold;
                border-left: none;
                margin-bottom: 0.6rem;
              }

              button {
                background: transparent;
                border: none;
                text-decoration: underline;
                font-size: 1.2rem;
                font-weight: 300;
                color: $black2;
                line-height: 1;
                padding: 0;
              }
            }

            &:last-child {
              text-align: right;
            }

            .btn_section {
              .btneditsize {
                padding: 0.8rem 1.6rem;
                height: 3rem;
                font-size: 1.2rem;
                min-width: 8rem;
                margin-left: 2rem;

                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }

          &:first-child {
            td {
              border-top: none;
            }
          }
        }
      }
    }
  }
}