@import '../../../../../Assets/_var.scss';

.common_select {
    &_inner {
        height: 4.86rem;
        min-width: 17.6rem;

        @media (max-width: 991px) {
            height: 4.4rem;

            input {
                font-size: 1.4rem;
            }
        }

        @media (max-width: 991px) {
            min-width: 10rem;
        }

        .react-select {
            height: 100%;

            .react-select__control {
                background-color: $white;
                border-radius: 4rem;
                cursor: pointer;
                border: 2px solid $black;
                height: 100%;

                &.react-select__control--is-focused {
                    border: 2px solid $green;
                    box-shadow: none;
                }

                .react-select__value-container {

                    .react-select__placeholder,
                    .react-select__input-container,
                    .react-select__single-value {
                        font-size: 16px;

                        @media (max-width: 991px) {
                            font-size: 14px;
                        }

                        @media (max-width: 575px) {
                            font-size: 12px;
                        }
                    }
                }

                .react-select__indicators {
                    .react-select__indicator-separator {
                        display: none;
                    }

                    .react-select__dropdown-indicator {
                        svg {
                            fill: $black;
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }

            .react-select__menu {
                border-radius: 10px;
                border: none;
                box-shadow: 1px 0px 16px rgb(0 0 0 / 10%);
                background-color: $black;

                .react-select__menu-list {
                    .react-select__option {
                        cursor: pointer;
                        background-color: transparent;
                        margin-bottom: 1px;
                        font-size: 16px;
                        color: $white;

                        @media (max-width: 991px) {
                            font-size: 14px;
                        }

                        @media (max-width: 575px) {
                            font-size: 12px;
                        }

                        &:hover,
                        &.react-select__option--is-selected {
                            background-color: $yellow;
                            color: $white;
                        }
                    }
                    .react-select__option--is-disabled {
                        color: rgb($white, 0.5);
                    }
                    .react-select__menu-notice {
                        font-weight: 500;
                        font-size: 16px;

                        @media (max-width: 1199px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    &.borderless {
        .common_select_inner {
            .react-select {
                .react-select__control {
                    border: none;
                }
            }
        }
    }

    &.border_input {
        border: 2px solid #d7d7d7;
        border-radius: 0.8rem;
        margin: 0 0 1.9rem;
        padding: 1rem 2.4rem;
        height: 6rem;

        .error_message {
            margin-top: 10px;
        }
        .form-label {
            color: #d3d3d3;
            margin-bottom: 0;
            line-height: 1.4rem;
        }

        .common_select_inner {
            height: unset;
            min-height: 2.5rem;

            .react-select {
                &__control {
                    align-items: flex-start;
                    border: none;
                    min-height: 2.5rem;
                    height: unset;

                    .react-select__value-container {
                        padding: 0;

                        .react-select__single-value {
                            padding: 0 !important;
                            margin: 0;
                        }

                        .react-select__input-container,
                        .react-select__placeholder {
                            padding: 0 !important;
                            margin-left: 0;
                        }
                    }

                    .react-select__indicators {
                        height: 2rem;

                        .react-select__indicator {
                            padding: 0 !important;
                        }
                    }
                }
            }
        }
    }
    &.SelectKyc{
        .common_select_inner {
            .react-select {
                .react-select__control {
                    border: 2px solid #d7d7d7;
                    border-radius: 0.8rem;
                }
            }
        }
    }
}