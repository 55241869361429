@import '../../../../Assets/_var.scss';

.devicesadd {
    padding-top: 1rem;

    .bitlimitset {
        padding: 3.2rem 0;

        @media (max-width:1679px) {
            padding: 3rem 0;
        }

        @media (max-width:1399px) {
            padding: 2.4rem 0;
        }

        @media (max-width:991px) {
            padding: 1.8rem 0;
        }

        label {
            font-size: 1.4rem;
            font-weight: bold;
            font-family: $baseFont;
            color: $black;
            line-height: 1.8rem;
            margin-bottom: 1rem;

            @media (max-width:767px) {
                font-size: 1.2rem;
            }
        }

        form {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;

            .btn_aactive {
                min-width: 13.9rem;
                padding: 1rem 2rem;
                margin-top: 5rem;
                height: 4rem;
                font-size: 1.6rem;
                max-width: 16rem;
                width: 100%;

                @media (max-width:1679px) {
                    margin-top: 4rem;
                    padding-inline: 1.8rem;
                }

                @media (max-width:1399px) {
                    margin-top: 3rem;
                    padding-inline: 1.5rem;
                }

                @media (max-width:1199px) {
                    margin-top: 2.5rem;
                    padding: 0.8rem 1.2rem;
                }

                @media (max-width:991px) {
                    margin-top: 2rem;
                    font-size: 1.4rem;
                }
            }

            :global {
                .device_add {
                    margin-bottom: 0;
                    margin-right: 0;
                    width: 100%;

                    .common_input_inner {
                        .form-control {
                            height: 3rem;
                            max-width: 30rem;
                            border: 0.1rem solid $black;
                            padding: 0.8rem 1rem;
                        }
                    }

                    .error_message {
                        margin-top: 1rem;
                        font-size: 1rem;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

:global {
    .tablists {
        border-bottom: 1px solid $gray707070;

        .nav-item {
            .nav-link {
                line-height: 1;

                &.active {
                    color: $black313131;
                    border-bottom: 3px solid $black313131;
                    border-radius: 0;
                }
            }
        }
    }

    .activate_devicemodal {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    padding-left: 5.1rem;
                    padding-right: 5.1rem;

                    .modal-title {
                        font-size: 3rem;
                        font-family: "Roboto", sans-serif;
                        line-height: 1.3;
                    }
                }

                .modal-body {
                    padding-left: 5.1rem;
                    padding-right: 5.1rem;

                    .main_content {
                        p {
                            font-size: 1.6rem;
                            font-weight: 400;
                            margin-bottom: 1.4rem;
                            line-height: 1;
                        }

                        .device_enterform {
                            margin-top: 3.2rem;

                            .commonBtn {
                                border-color: $white;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}