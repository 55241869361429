@import '../../../Assets/_var.scss';

.footer {
    background-color: $black;
    padding-top: 10rem;
    position: relative;

    @media (max-width:1679px) {
        padding-top: 8rem;
    }

    @media (max-width:1439px) {
        padding-top: 4rem;
    }

    h4 {
        font-size: 2rem;
        color: $white;
        font-family: $baseFont2;
        font-weight: bold;
        margin-bottom: 3rem;

        @media (max-width:1679px) {
            font-size: 1.8rem;
        }

        @media (max-width:1199px) {
            font-size: 1.6rem;
            margin-bottom: 2rem;
        }

        @media (min-width:576px) {
            text-align: right;
        }
    }

    ul {
        li {
            @media (min-width:576px) {
                text-align: right;
            }

            a {
                color: $white;
                font-family: $baseFont;
                margin-bottom: 1rem;
                display: inline-block;

                @media (max-width:1679px) {
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }

                @media (max-width:1399px) {
                    margin-bottom: 0.7rem;
                }
            }
        }
    }

    &_top {
        .footercontent {
            @media (max-width: 1199px) {
                max-width: 34rem;
            }

            .footer_Logo {
                width: 25.5rem;
            }
        }

        .linkList {
            max-width: 11%;
            padding: 0 0.75rem;

            @media (max-width: 991px) {
                max-width: unset;

                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:last-child {
                    max-width: unset !important;
                }
            }

            @media (max-width: 575px) {
                max-width: 100%;
                flex: 0 0 100%;

                &:nth-child(3),
                &:nth-child(4),
                &:last-child {
                    max-width: 100% !important;
                    flex: 0 0 100%;
                }
            }

            &:nth-child(3) {
                max-width: 100%;
            }

            &:nth-child(4) {
                max-width: 14%;

                @media (max-width: 1199px) {
                    max-width: 17%;
                }
            }

            &:nth-child(5) {
                max-width: 100%;
            }
        }

        .listHide {
            h4 {
                @media (max-width: 575px) {
                    margin-bottom: 0;
                }
            }

            ul {
                padding-top: 2.5rem;

                @media (max-width: 1679px) {
                    padding-top: 2.2rem;
                }

                @media (max-width: 1199px) {
                    padding-top: 1.9rem;
                }

                @media (max-width: 991px) {
                    padding-top: 1.8rem;
                }

                @media (max-width: 575px) {
                    padding-top: 0;
                }
            }
        }

        @media (max-width: 991px) {
            .footercontent {
                max-width: 100%;
            }

            margin: -1.5rem;

            >div {
                &:not(:first-child) {
                    flex: 1 0 50%;
                    padding: 2rem;

                    h4 {
                        text-align: left;
                    }

                    ul {
                        li {
                            text-align: left;
                        }
                    }
                }
            }
        }


    }

    &_copyright {
        margin-top: 2rem;
        padding-bottom: 4rem;

        p {
            border-top: 2px solid $green;
            padding-top: 2rem;
            color: $white;

        }
    }

    &_Logo {
        @media (max-width:1679px) {
            max-width: 23rem;
            width: 100%;

        }

        @media (max-width:991px) {
            max-width: 16rem
        }
    }

    .infologs {
        margin-top: 6rem;

        @media (max-width:1679px) {
            margin-top: 4rem;
        }

        @media (max-width:1199px) {
            margin-top: 3rem;
        }

        @media (max-width: 991px) {
            margin-top: 2rem;
        }

        p {
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 2.4rem;
            color: $white;

            @media (max-width:1679px) {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }

            @media (max-width:1199px) {
                font-size: 1.4rem;
                line-height: 2rem;
            }
        }
    }
}

.footerSec {
    background-color: $black;
    padding: 5.5rem 0 4.3rem;
    position: relative;

    @media (max-width: 1679px) {
        padding: 4rem 0 3rem;
    }

    @media (max-width: 1199px) {
        padding: 3rem 0 2.5rem;
    }

    @media (max-width: 767px) {
        padding: 2rem 0;
    }

    &_topHead {
        display: flex;
        align-items: flex-end;

        @media (max-width: 1399px) {
            align-items: flex-start;
        }

        @media (max-width: 1199px) {
            flex-wrap: wrap;
        }

        @media (max-width: 767px) {
            flex-wrap: unset;
            flex-direction: column;
        }

        .footerLogo {
            height: 5rem;
            width: 100%;
            max-width: 25.5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 1679px) {
                height: 4rem;
                max-width: 20rem;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

        .details {
            color: $white;
            line-height: 2.2rem;
            width: 100%;
            max-width: 78rem;
            margin: 0 3.8rem;

            @media (max-width: 1679px) {
                margin: 0 2.8rem;
            }

            @media (max-width: 1199px) {
                order: 1;
                margin: 1.5rem 0 0;
                max-width: 100%;
            }

            @media (max-width: 991px) {
                order: unset;
                margin-top: 1rem;
            }
        }

        .searchInput {
            width: 100%;
            max-width: 50.5rem;
            margin-left: auto;

            @media (max-width: 991px) {
                margin-left: 0;
                margin-top: 1rem;
            }

            :global {
                .form-label {
                    color: $white;
                    line-height: 3rem;
                    font-weight: 400;

                    @media (max-width: 1679px) {
                        font-size: 1.4rem;
                        line-height: 2rem;
                    }
                }
            }
        }
    }

    &_bottomHead {
        border-top: 2px solid $white;
        margin-top: 4.7rem;
        padding-top: 1.6rem;

        @media (max-width: 1679px) {
            margin-top: 4rem;
        }

        @media (max-width: 1199px) {
            margin-top: 3rem;
        }

        @media (max-width: 767px) {
            margin-top: 2.5rem;
            padding-top: 1.3rem;
        }

        .rowBottom {
            margin: 0 -1.5rem;
            align-items: center;

            :global {
                .orderSide {
                    @media (max-width:767px) {
                        order: 1;
                    }
                }
            }

            .elephInfo {
                color: $white;
                font-weight: 400;

                @media (max-width: 575px) {
                    text-align: center;
                }
            }

            .menuListing {
                display: flex;
                align-items: center;
                margin: 0 -1rem;

                @media (max-width: 575px) {
                    justify-content: center;
                }

                li {
                    padding: 0 1rem;

                    .navLink {
                        font-size: 1.6rem;
                        line-height: 2.2rem;
                        color: $white;
                        font-weight: 400;

                        @media (max-width: 1679px) {
                            font-size: 1.4rem;
                            line-height: 2rem;
                        }
                    }
                }
            }

            .flex_changes {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-left: auto;

                @media (max-width: 575px) {
                    justify-content: center;
                    margin: 1.3rem 0;
                }

                li {
                    a {
                        svg {
                            width: 4rem;
                            height: 4rem;

                            @media (max-width: 1199px) {
                                width: 3.5rem;
                                height: 3.5rem;
                            }

                            @media (max-width: 767px) {
                                width: 3rem;
                                height: 3rem;
                            }

                            rect,
                            circle {
                                fill: $green;
                            }

                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}