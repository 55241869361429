@import '../../../Assets/_var.scss';


.maintenancePage {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black;

    &-inner {
        text-align: center;
        color: $white;
        max-width: 90rem;
        width: 100%;
        margin: auto;

        img {
            width: 34.8rem;
            height: 7rem;
      
            @media (max-width: 1679px) {
              width: 20rem;
              height: 4.5rem;
            }
      
            @media (max-width: 991px) {
              width: 16rem;
              height: 3.5rem;
            }
      
            @media (max-width: 359px) {
              max-width: 12rem;
              height: 3rem;
            }
          }
        p {
            margin-top: 2rem;
            font-size: 3rem;
        }
        @media (max-width: 1199px) {
            p{
                font-size: 2rem;
            }
        }
    }
}