@import '../../../Assets/_var.scss';

.previousDrops {
    padding: 2rem 0;

    .top_head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width:991px) {
            flex-wrap: wrap;
        }

        h2 {
            font-size: 6rem;
            color: $black;
            font-weight: bold;
            line-height: 6.5rem;

            @media (max-width:1679px) {
                font-size: 5.7rem;
                line-height: 6.5rem;
            }

            @media (max-width:1439px) {
                font-size: 5rem;
                line-height: 5.5rem;
            }

            @media (max-width:1199px) {
                font-size: 4rem;
                line-height: 4.5rem;
            }

            @media (max-width:991px) {
                font-size: 3.5rem;
                line-height: 3.8rem;
            }

            @media (max-width:575px) {
                font-size: 2.8rem;
                line-height: 3rem;
            }
        }

        .filterlist {
            display: flex;
            align-items: center;


            @media (max-width:991px) {
                flex: 1 1 100%;
                margin-top: 1.5rem;
            }

            @media (max-width:767px) {
                flex-wrap: wrap;
            }

            .searchBar {
                margin-bottom: 0;

                @media (max-width:991px) {
                    width: 100%;
                    max-width: 50%;
                }

                @media (max-width:767px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 1.5rem;
                }


                input {
                    border: 2px solid #e7e7e7;
                    border-radius: 2.4rem;
                    padding: 1.4rem;
                }
            }

            .timeperiodlist {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 4rem;

                @media (max-width:1279px) {
                    margin: 0 2.5rem;
                }

                @media (max-width:767px) {
                    margin-left: 0;
                }

                @media (max-width:420px) {
                    width: 100%;
                    margin: 0;
                }


                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    max-width: 6.7rem;
                    height: 4.7rem;
                    border: 2px solid #000;
                    padding: 0 2rem;
                    margin-right: 1rem;
                    border-radius: 2.4rem;
                    font-size: 1.4rem;
                    font-weight: 400;
                    font-family: "Roboto", sans-serif;
                    cursor: pointer;

                    @media (max-width:1279px) {
                        max-width: 5.7rem;
                        height: 3.7rem;
                        padding: 0 1.5rem;
                        margin-right: 0.8rem;
                        border-radius: 2rem;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    &.active,
                    &:hover {
                        color: $white;
                        background-color: #000;
                    }
                }
            }

            .filtersel {
                @media (max-width:991px) {
                    width: 100%;
                    max-width: 14rem;
                }

                @media (max-width:420px) {
                    margin-top: 1.5rem;
                }


                :global {
                    .common_select_inner {
                        @media (max-width:1279px) {
                            height: 4rem;
                        }
                    }

                    .react-select {
                        @media (max-width:1279px) {
                            height: 4rem;
                        }

                        .react-select__control {
                            background-color: #edc45d;
                            color: $white;
                            cursor: pointer;
                            border: 2px solid #edc45d;
                            height: 100%;

                            &.react-select__control--is-focused {
                                border: 2px solid #edc45d;
                                box-shadow: none;
                            }

                            .react-select__value-container {

                                .react-select__placeholder,
                                .react-select__input-container,
                                .react-select__single-value {
                                    font-size: 2rem;
                                    color: $white;
                                    font-weight: bold;
                                    font-family: "Roboto", sans-serif;

                                    @media (max-width: 1279px) {
                                        font-size: 1.6rem;
                                    }

                                    @media (max-width: 991px) {
                                        font-size: 1.4rem;
                                    }

                                    @media (max-width: 575px) {
                                        font-size: 1.2rem;
                                    }
                                }
                            }

                            .react-select__indicators {
                                .react-select__indicator-separator {
                                    display: none;
                                }

                                .react-select__indicator {
                                    svg {
                                        path {
                                            fill: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .statsTable {
        margin: 2.7rem 0;

        .drop_table {
            :global {
                table {
                    border-spacing: 0;
                    border-collapse: collapse;
                    border: 2px solid $borderClr2;

                    thead {
                        tr {
                            th {
                                background-color: $black;
                                color: $white;
                                padding: 2.2rem 4rem;
                                font-weight: 400;
                                font-size: 2rem;
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                border-top: 2px solid $borderClr2;
                                border-bottom: 2px solid $borderClr2;
                                padding: 1.5rem 4rem;

                            }

                            &:first-child {
                                td {
                                    border-top: none;
                                }
                            }
                        }
                    }
                }
            }

            .notifiedBtn {
                font-size: 2rem;
                font-weight: bold;
                color: $yellow;
                padding: 1rem;
                vertical-align: middle;
                background: $black;
                border: none;
                border-radius: 30px;
                width: 100%;
                max-width: 25rem;
                min-width: 11rem;

                @media (max-width:1199px) {
                    font-size: 1.8rem;
                }

                @media (max-width:991px) {
                    font-size: 1.6rem;
                }

                @media (max-width:575px) {
                    font-size: 1.4rem;
                }
            }
        }

        .showvalues {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 4rem;

            form {
                .showicons {
                    display: flex;
                    align-items: center;
                    margin-right: 1.5rem;

                    label {
                        font-size: 1.8rem !important;
                        font-weight: bold;
                        color: #000;
                        margin-bottom: 0;
                        margin-right: 1.6rem;
                    }

                }

                :global {
                    .common_select_inner {
                        min-width: 10rem;
                    }
                }
            }

            .btnArrow {
                display: flex;
                align-items: center;
                justify-content: space-between;

                li {
                    padding-right: 1rem;

                    &:last-child {
                        padding-right: 0;
                    }

                    button {
                        background: transparent;
                        border: 2px solid $black;
                        border-radius: 50%;
                        height: 3.1rem;
                        width: 3.1rem;
                        padding: 0;

                        &:hover {
                            background: $yellow;
                        }

                        &.leftbtn {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

}