@import "../../../Assets/_var.scss";

.dropaction_ended_leftsec {
  width: 100%;
  height: 100%;
}
:global {
  // .image_section_hover {
  //   position: relative;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-direction: column;
  //   width: 100%;
  //   height: 100%;

  //   .thumnail_img {
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     z-index: 0;
  //     display: block;
  //   }

  //   .featureimg {
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     z-index: 1;
  //     display: none;
  //   }

  //   &:hover {
  //     .thumnail_img {
  //       display: none;
  //     }

  //     .featureimg {
  //       display: block;
  //     }
  //   }

  //   &::after {
  //     content: "";
  //     height: 100%;
  //     z-index: 0;
  //     width: 100%;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     background: -moz-linear-gradient(top,
  //         rgba(0, 0, 0, 0.1) 30%,
  //         rgba(0, 0, 0, 0.8) 100%);
  //     background: -webkit-linear-gradient(top,
  //         rgba(0, 0, 0, 0.1) 30%,
  //         rgba(0, 0, 0, 0.8) 100%);
  //     background: linear-gradient(to bottom,
  //         rgba(0, 0, 0, 0.1) 30%,
  //         rgba(0, 0, 0, 0.8) 100%);
  //     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
  //     // z-index: -1;
  //     // transition: .5s all ease-in-out;
  //   }
  // }

  .ffliveBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width:479px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .bordred-black {
        margin-top: 1.5rem;
      }
    }
  }
  .video_hover_sec_second {
    width: 100%;
    height: 45rem;

    >video {
      display: block;
      width: 100%;
      object-fit: contain !important;
      height: 100%;
      background: $black;
    }
  }
}