@import "../../../../Assets/_var.scss";

@keyframes marquee {
  0% {
    background-position: 0;
  }

  100% {
    background-position: 300rem;
  }
}

.howitsWorks {
  position: relative;
  z-index: 1;
  padding-top: 16rem;

  @media (max-width: 1679px) {
    padding-top: 12rem;
  }

  @media (max-width: 1399px) {
    padding-top: 9rem;
    margin-bottom: 7rem;
  }

  @media (max-width: 991px) {
    padding-top: 7rem;
  }

  &:before {
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 26.7rem;
    position: absolute;
    background-image: url(../../../../Assets/images/howitBg.png);
    background-size: cover;
    background-position: center;
    background-repeat: repeat-x;
    animation: marquee 110s infinite linear;
    z-index: -1;
  }

  &_lisitng {
    border: 0.2rem solid #071a2f;
    background: $white;
    padding: 5.4rem 4rem 2.2rem;
    position: relative;

    @media (max-width: 1679px) {
      padding: 5rem 3.5rem 2rem;
    }

    @media (max-width: 1399px) {
      padding: 4.5rem 3rem 1rem;
    }

    @media (max-width:991px) {
      padding-top: 4rem;
    }

    @media (max-width: 991px) {
      padding-inline: 2rem;
    }

    h2 {
      position: absolute;
      top: -2.3rem;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 37rem;
      height: 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      background: $white;
      padding: 0.2rem 1rem;

      @media (max-width: 1679px) {
        max-width: 30rem;
      }

      @media (max-width: 1399px) {
        max-width: 26rem;
      }

      @media (max-width: 991px) {
        max-width: 20rem;
      }
    }

    .sliderWork {
      padding-bottom: 0;
      user-select: auto;
      -webkit-user-select: auto;

      .worksCard {
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        .worksImg {
          // display: flex;
          // align-items: center;
          // justify-content: center;
          margin-bottom: 1rem;
          text-align: center;

          img {
            width: 18.6rem;
            height: 15.6rem;
            object-fit: contain;

            &.imgSpace {
              width: 30.1rem;
            }

            &.imgNexIcon {
              width: 8.7rem;
              height: 10.4rem;
              margin-top: 4rem;
            }
          }

          h4 {
            font-weight: bold;
            margin-top: 2rem;
            font-family: $baseFont;
          }
        }

        .worksText {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          max-width: 20rem;
          min-height: 4.3rem;

          &.maxSpace {
            max-width: 14rem;
          }

          h6 {
            font-weight: 400;
            text-align: center;
            font-family: $baseFont;
          }

          .btn_create {
            padding-inline: 2rem;
          }
        }

        .arrowSec {
          position: absolute;
          right: -4rem;
          bottom: 0;
          z-index: 1;
          width: 5rem;
          height: 3.6rem;

          @media (max-width: 767px) {
            right: -1rem;
            width: 4rem;
            height: 3rem;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      :global {

        .slick-list {
          padding-bottom: 2rem;

          .slick-track {
            display: flex;

            .slick-slide {
              display: flex;
              height: auto;

              &>div {
                width: 100%;
              }
            }
          }
        }

        .slick-slide>div {
          @media (max-width: 991px) {
            margin: 0 1rem;
          }
        }

        .slick-arrow {
          bottom: -6rem !important;
          top: unset;
          right: 0;

          &.slick-prev {
            right: 2.6rem;

            @media (max-width: 1199px) {
              right: 2rem !important;
            }

            @media (max-width: 991px) {
              right: 3rem !important;
            }
          }

          &.slick-next {
            right: -1.5rem;

            @media (max-width: 1199px) {
              right: -2rem !important;
            }

            @media (max-width: 991px) {
              right: -1rem !important;
              left: unset;
            }
          }
        }
      }
    }
  }
}