@import '../../../Assets/_var.scss';

.niftyAppBanner {
    padding: 13rem 0 19rem;
    background-color: $yellow;
    margin-bottom: 10rem;

    @media (max-width: 1679px) {
        padding: 10rem 0;
    }

    @media (max-width: 1439px) {
        padding: 8rem 0;
    }

    @media (max-width: 1199px) {
        padding: 6rem 0;
    }

    @media (max-width: 991px) {
        padding: 4rem 0;
        margin-bottom: 2rem;
    }

    &Inner {
        max-width: 100rem;
        margin: 0 auto;

        .newartAppTop {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 4rem;

            img {
                margin-top: 0.6rem;
                width: 42.5rem;
                height: 8.2rem;
            }

            h1 {
                font-size: 3rem;

                @media (max-width:1679px) {
                    font-size: 2.8rem;
                }

                @media (max-width:1399px) {
                    font-size: 2.4rem;
                }

                @media (max-width:1199px) {
                    font-size: 2.2rem;
                }

                @media (max-width:991px) {
                    font-size: 2rem;
                }

                @media (max-width:575px) {
                    font-size: 1.8rem;
                }
            }
        }
    }

    // h2 {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     margin-bottom: 2rem;
    //     line-height: 1;

    //     img {
    //         max-width: 49rem;
    //         margin-right: 2rem;
    //         transform: translateY(5px);

    //         @media (max-width: 1679px) {
    //             max-width: 24.5rem;
    //             transform: translateY(2px);
    //         }

    //         @media (max-width: 1439px) {
    //             max-width: 21rem;
    //             transform: translateY(2px);
    //         }

    //         @media (max-width: 1279px) {
    //             max-width: 16rem;
    //             transform: none;
    //             margin-right: 1.4rem;
    //         }

    //     }
    // }

    &Content {
        margin-bottom: 9rem;

        @media (max-width: 1439px) {
            margin-bottom: 6rem;
        }

        @media (max-width: 1199px) {
            margin-bottom: 4rem;
        }

        @media (max-width: 991px) {
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .btnAppins {
            .btnApp {
                border: none;
                background: transparent;
                padding: 0;
            }
        }
    }

    &Imgs {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1199px) {
            img {
                max-width: 30rem;
            }
        }

        @media (max-width: 991px) {
            justify-content: center;
        }
    }
}


.BenefitsSec {
    padding: 10rem 0;

    :global {
        .row {
            position: relative;
        }
    }

    .imagesSlider {
        .BenefitsSecImg {
            iframe {
                height: 685px;

                @media (max-width: 1679px) {
                    height: 60rem;
                }

                @media (max-width: 1199px) {
                    height: 40rem;
                }
            }
        }

        @media (min-width: 1400px) {
            :global {
                .slick-arrow {
                    &.slick-arrow {
                        bottom: -2rem;
                    }
                }
            }
        }

        @media (min-width: 1200px) {

            :global {
                padding-bottom: 0;
                position: static;

                .slick-arrow {
                    left: 6rem;
                    right: unset;
                    top: unset;
                    bottom: 8px;

                    &.slick-prev {
                        left: 1.5rem;
                    }
                }
            }
        }

        @media (max-width: 1199px) {
            :global {
                position: relative;

                .slick-arrow {
                    bottom: 0;
                    top: unset;
                    right: 50%;

                    &.slick-prev {
                        transform: translateX(-2rem) rotate(-180deg);
                    }

                    &.slick-next {
                        transform: translateX(2rem);
                    }
                }
            }
        }
    }

    h2 {
        color: $yellow;
    }

    @media (max-width:1679px) {
        padding: 8rem 0;

    }

    @media (max-width:1439px) {
        padding: 7rem 0;

    }

    @media (min-width: 768px) and (max-width: 1279px) {
        h2 {
            font-size: 6rem;
        }
    }

    @media (max-width: 991px) {
        paddign: 6rem 0;
    }

    &Content {
        margin-top: 2rem;

        @media (min-width: 1680px) {
            padding-right: 14rem;
        }

        @media (max-width: 767px) {
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }

            @media (max-width: 991px) {
                margin-bottom: 1rem;
            }
        }
    }
}