@import "../../../../Assets/_var.scss";

.commonTable {
  .table-responsive {
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black;
    }
  }

  table {
    width: 100%;
    max-width: 100%;
    border-collapse: separate;
    border-spacing: 0 1em;

    tr {
      // padding: 1rem 0;
      transition: 0.3s all ease-in-out;

      .thumb-outer {
        justify-content: space-between;

        img {

          &.thumb,
          &.thumb-rounded {
            width: 5rem;
            height: 5rem;
            flex: 0 0 5rem;
            border-radius: 0.5rem;
            object-fit: cover;
            max-width: 5rem;

            @media (max-width: 991px) {
              width: 4rem;
              height: 4rem;
              max-width: 4rem;
            }
          }

          &.thumb-rounded {
            border-radius: 50%;
          }
        }

        >*:not(.thumb):not(.thumb-rounded) {
          flex: 0 0 calc(100% - 5rem);
          padding-left: 1.4rem;
          max-width: calc(100% - 5rem);

          @media (max-width: 991px) {
            padding-left: 1rem;
            flex: 0 0 calc(100% - 4rem);
            max-width: calc(100% - 4rem);
          }
        }
      }

      td,
      th {
        white-space: nowrap;

        >* {
          vertical-align: middle;
        }

        // border-radius: .5rem;
        border-top: 1px solid $borderClr2;
        border-bottom: 1px solid $borderClr2;
        font-size: 2rem;
        font-weight: bold;
        color: $black;
        padding: 1rem;
        vertical-align: middle;

        span {
          font-weight: normal;
          color: $borderClr2;
          font-size: 1.6rem;
        }

        &:first-child {
          border-left: 1px solid $borderClr2;
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }

        &:last-child {
          border-right: 1px solid $borderClr2;
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          text-align: right;
        }

        @media (max-width: 1679px) {
          span {
            font-size: 1.4rem;
          }

          font-size: 1.8rem;

          h4 {
            font-size: 1.8rem;
          }
        }

        @media (max-width: 1439px) {
          font-size: 1.6rem;

          h4 {
            font-size: 1.6rem;
          }
        }

        @media (max-width: 1439px) {
          font-size: 1.4rem;

          h4 {
            font-size: 1.4rem;
          }
        }
      }

      th {
        border: 0;

        &:first-child {
          border-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &:last-child {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      td {
        &.no_data_tablebid {
          height: 33rem;
        }

        &.borderNot {
          border: none;
        }
      }

      &.cursor-pointer {
        &:hover {
          background-color: rgba($gray, 0.3);
        }
      }
    }
  }
}