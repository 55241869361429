@import "../../../../Assets/_var.scss";

.common_dropdown {
  width: 100%;
  max-width: 31rem;
  position: relative;

  @media (max-width: 1199px) {
    max-width: 28rem;
  }

  .dropBtn {
    width: 100%;
    padding: 0.7rem 1.3rem;
    line-height: 2.4rem;
    border: 1px solid $gray;
    box-shadow: none;
    border-radius: 0;
    height: 4rem;
    font-size: 1.6rem;
    font-weight: 400;
    background: $white;
    color: $black;
    font-family: $baseFont;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s all ease-in-out;

    @media (max-width: 1199px) {
      font-size: 1.4rem;
      padding: 0.5rem 1rem;
      height: 3.5rem;
      line-height: 2rem;
    }

    &:focus {
      box-shadow: none;
      background-color: transparent;
      color: $black;
      border: 1px solid $gray;
    }

    &:hover {
      transition: 0.3s all ease-in-out;

      svg {
        transition: 0.3s all ease-in-out;
        transform: rotate(270deg);
      }
    }

    &::after {
      display: none;
    }

    svg {
      margin-left: 0.6rem;
      transition: 0.3s all ease-in-out;
      width: 1.2rem;
      height: 0.7rem;

      path {
        fill: $black;
      }
    }
  }

  &.border-drop {
    .dropBtn {
      background-color: transparent;
      color: $black;
      border-color: $black;

      svg {
        margin-left: 2.2rem;
        transform: unset;
        transition: 0.3s all ease-in-out;

        path {
          fill: $black;
        }
      }

      &:hover {
        background-color: $black;
        color: $white;
        border-color: $black;

        svg {
          path {
            fill: $white;
          }
        }
      }
    }

    &.show {
      .dropBtn {
        &.show {
          background-color: $black;
          color: $yellow;
          border-color: $black;

          svg {
            transform: rotate(180deg);
            transition: 0.3s all ease-in-out;

            path {
              fill: $yellow;
            }
          }
        }
      }
    }

    .dropdown-menu {
      border: 2px solid $black;
      padding: 1rem;
      background: $white;

      .dropdown-item {
        margin: 0;
        color: $black;
        padding: 0;
        border-bottom: 2px solid;
        padding: 1rem;

        &:last-child {
          border: none;
        }

        &.active,
        &:hover {
          color: $yellow;
          border-color: $yellow;
        }
      }
    }
  }

  &.without-border {
    .dropBtn {
      background-color: transparent;
      color: $black2;
      border: none;

      svg {
        margin-left: 0;
        transform: unset;
        transition: unset;

        &:last-child {
          display: none;
        }
      }

      &:hover {
        background-color: transparent;
        color: $black2;
        border: none;
      }
    }

    &.show {
      .dropBtn {
        &.show {
          background-color: transparent;
          color: $black;
          border: none;

          svg {
            transform: unset;
            transition: unset;

            path {
              fill: $yellow;
            }
          }
        }
      }
    }

    .dropdown-menu {
      border: none;
      padding: 3rem;
      background: #333333;
      border-radius: 0.5rem;
      min-width: 27rem;

      .dropdown-item {
        border: 1px solid #d7d7d7;
        border-radius: 0.9rem;
        padding: 1rem;
        padding-left: 3rem;
        margin-bottom: 3rem;
        font-size: 1.6rem;
        font-weight: 400;
        color: $white;
        font-family: "Roboto";
        background-color: #050505;

        svg {
          width: 2.6rem !important;
          height: 2.6rem !important;
          margin-right: 1.5rem;
        }

        &:last-child {
          border: 1px solid #d7d7d7;
          margin-bottom: 0;
        }

        &:hover {
          svg {

            path,
            polygon {
              fill: $yellow;
            }
          }
        }
      }
    }
  }

  .dropdown-menu {
    padding: 0;
    background: $white;
    width: 100%;
    border-radius: 0;
    border: 1px solid #d3d3d3;

    .dropdown-item {
      margin: 0;
      color: $black2;
      font-size: 1.2rem;
      font-weight: 400;
      font-family: "Roboto";
      border: 0;
      border-top: 1px solid #d3d3d3;
      padding: 1.4rem 1.3rem;

      &:first-child {
        border-top: none;
      }

      &.active,
      &:hover {
        color: $white;
        background: $yellow;
      }
    }
  }
}

.dropdown {
  &.show {
    .dropdown-toggle {
      box-shadow: none;
      background-color: transparent;
      color: $black;
      border: 1px solid $gray;

      &.show {

        &:focus,
        &:active {
          box-shadow: none;
          background-color: transparent;
          color: $black;
          border: 1px solid $gray;
        }

        svg {
          transform: rotate(270deg);
          transition: 0.3s all ease-in-out;
        }
      }
    }
  }
}