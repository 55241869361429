@import '../../../Assets/_var.scss';

@keyframes marquee {
    0% {
        background-position: 0;
    }

    100% {
        background-position: 300rem;
    }
}

.login {
    width: 100%;
    background-color: $green2;
    // background-image: url(../../../Assets/images/Signup-bg.jpg);
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: top center;
    // background-attachment: fixed;
    padding: 12rem 2rem 17rem;
    position: relative;
    min-height: calc(100vh - 36.6rem);
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        position: absolute;
        background-image: url(../../../Assets/images/loginNewBg.svg);
        background-size: initial;
        background-position: center;
        background-repeat: repeat-x;
        animation: marquee 110s infinite linear;
        z-index: 0;
    }

    @media (min-height: 1079px) {
        min-height: calc(100vh - 30.6rem);
    }

    @media (max-width: 1439px) {
        padding: 9rem 2rem 13rem;
    }

    @media (max-width: 991px) {
        padding: 6rem 0;
    }

    &__Section {
        background-color: $black;
        padding: 4.5rem 4.5rem 4.9rem 5.5rem;
        border: 0.2rem solid $green;
        width: 100%;
        max-width: 59rem;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        box-shadow: 15px 24px 15px rgba($black, 0.26);
        min-height: 48.1rem;

        @media (max-width: 1439px) {
            padding: 4rem;
        }

        @media (max-width: 991px) {
            padding: 3rem 2rem;
            min-height: 35.6rem;
            max-width: 48rem;
        }

        .Heading {
            h2 {
                color: $white;
                font-weight: bold;
                font-family: $baseFont2;
                line-height: 1;
                margin-bottom: 1rem;

                @media (max-width: 1199px) {
                    margin-bottom: 0.8rem;
                }
            }

            p {
                font-size: 1.8rem;
                line-height: 2rem;
                font-weight: bold;
                font-family: $baseFont;
                margin-bottom: 4.2rem;
                color: $white;

                @media (max-width: 1679px) {
                    margin-bottom: 3.6rem;
                }

                @media (max-width: 1199px) {
                    margin-bottom: 3rem;
                    font-size: 1.6rem;
                    line-height: 1.8rem;
                }

                @media (max-width: 991px) {
                    margin-bottom: 2rem;
                    font-size: 1.4rem;
                }
            }
        }

        :global {
            .common_input {
                width: 100%;
                max-width: 45rem;

                &.emailField {
                    margin-bottom: 2.8rem;
                }

                &.password_input {
                    margin-bottom: 0.6rem;
                }
            }
        }

        .loginBtns {
            display: flex;
            align-items: center;
            margin-top: 4rem;

            @media (max-width: 1679px) {
                margin-top: 3rem;
            }

            @media (max-width: 1199px) {
                margin-top: 2.4rem;
            }

            @media (max-width: 991px) {
                margin-top: 2rem;
            }

            @media (max-width: 479px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}


.LoginForm {
    position: relative;

    &AsStrong {
        color: $green2;
        font-weight: 400;
        font-size: 1.2rem;
        margin-top: 0.4rem;
    }


    &SignUp {
        color: $white;
        padding-left: 3.3rem;
        font-size: 1.6rem;

        @media (max-width: 1199px) {
            padding-left: 2.3rem;
        }

        @media (max-width: 767px) {
            padding-left: 1.3rem;
            font-size: 1.4rem;
        }

        @media (max-width: 479px) {
            padding-left: 0;
            padding-top: 1.3rem;
        }

        a {
            color: $green2;
            font-weight: bold;

            &:hover {
                color: $white;

            }
        }
    }

    &btnHanlde {
        min-width: 19rem;

        @media (max-width: 767px) {
            min-width: 14rem;
        }

        &:hover {
            background-color: $white  !important;
            color: $black  !important;
            border-color: $white  !important;
        }
    }

}