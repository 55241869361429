@import "../../../../Assets/_var.scss";

.view_modal {
    :global {
        .modal-dialog {
            width: 100%;
            max-width: 92rem;
            padding: 0 1.5rem;
        }

        .input_phone {
            background: $white  !important;
        }
    }

    label {
        color: $white  !important;
        font-size: 1.6rem !important;
        font-family: "Roboto";
        margin-bottom: 1.2rem;
        font-weight: 400;
    }

    .select {

        @media (max-width: 575px) {
            margin-bottom: 2rem;
        }

        >div {
            :global {
                .react-select {
                    .react-select__control {
                        border-radius: 1rem;
                        border-color: $light_gray4;

                        .react-select__indicators {
                            .react-select__indicator {
                                svg {
                                    path {
                                        fill: $light_gray4;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}