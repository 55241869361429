@import '../../../../Assets/_var.scss';


.articlesSec {
    padding: 9.5rem 0;

    &Slider {
        margin: 0;

        :global {

            .slick-slide {
                >div {
                    margin: 0;
                }
            }

            .slick-arrow {
                top: -7.5rem;
                @media (max-width:991px) {
                    &.slick-prev {
                        right: unset;
                        left: 50%;
                        margin-left: -2rem !important;
                    }
                }
            }
        }
    }

    .articlTophead {
        text-align: center;
        margin-bottom: 3rem;

        h3 {
            margin-bottom: 1rem;
        }
    }

    h5 {
        border-top: 2px solid $borderGray;
        border-bottom: 2px solid $borderGray;
        padding: 1.5rem 0;
        font-size: 1.8rem;
        font-weight: bold;
        font-family: $baseFont;
    }

    &Content {
        padding: 4rem 4rem 0 0;

        @media (min-width: 1680px) {
            max-width: 45.5rem;
        }

        @media (max-width: 1679px) {
            padding: 4rem 1.5rem 0 0;
        }

        p {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: 991px) {
        &Content {
            padding: 3rem 2rem 0 0;
        }
    }

    @media (max-width: 575px) {

        h5,
        &Content {
            text-align: center;
        }

        &Content {
            padding: 2rem 0 0 0;
        }
    }
}