@import "../../../../Assets/_var.scss";

.commonBtn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-family: $baseFont;
  border: 1px solid $green;
  border-radius: 3rem;
  padding: 0.8rem 3rem;
  font-weight: bold;
  background-color: $green;
  transition: 0.3s all ease-in-out;
  color: $white;
  text-align: center;
  height: 5rem;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  >span {
    margin-left: 2rem;

    svg {

      path,
      polygon {
        transition: 0.3s all ease-in-out;
        fill: $white;
      }

      @media (max-width: 1679px) {
        width: 2rem;
      }
    }
  }

  &:hover {
    background-color: $yellow;
    color: $black;
    border-color: $yellow;
    box-shadow: 4px 5px 14px rgba(0, 0, 0, 0.3);

    >span {
      svg {

        path,
        polygon {
          fill: $black;
        }
      }
    }
  }

  &.black-btn {
    background-color: $black;
    color: $white;
    border-color: $black;

    &:hover {
      background-color: $yellow;
      color: $black;
      border-color: $yellow;
      box-shadow: 4px 5px 14px rgba(0, 0, 0, 0.3);

      >span {
        svg {

          path,
          polygon {
            fill: $black;
          }
        }
      }
    }
  }

  &.black-btn-yellow {
    background-color: $black;
    color: $yellow;
    border-color: $black;

    &:hover {
      background-color: $yellow;
      color: $black;
      border-color: $yellow;
      box-shadow: 4px 5px 14px rgba(0, 0, 0, 0.3);

      >span {
        svg {

          path,
          polygon {
            fill: $black;
          }
        }
      }
    }
  }

  &.bordred-gray {
    background-color: transparent;
    color: $dark_gray2;
    border-color: $light_gray2;

    &:hover {
      background-color: $yellow;
      color: $black;
      border-color: $yellow;
      box-shadow: 4px 5px 14px rgba(0, 0, 0, 0.3);

      >span {
        svg {

          path,
          polygon {
            fill: $black;
          }
        }
      }
    }
  }

  &.bordred-black {
    background-color: $white;
    color: $black;
    border-color: $black;

    >span {
      svg {

        path,
        polygon {
          fill: $black;
        }
      }
    }

    &:hover {
      color: $white;
      background-color: $black;
      border-color: $black;

      >span {
        svg {

          path,
          polygon {
            fill: $white;
          }
        }
      }
    }
  }

  &.bordred-white {
    background-color: transparent;
    color: $white;
    border-color: $white;

    >span {
      svg {

        path,
        polygon {
          fill: $white;
        }
      }
    }

    &:hover {
      color: $black;
      background-color: $white;
      border-color: $white;

      >span {
        svg {

          path,
          polygon {
            fill: $black;
          }
        }
      }
    }
  }

  &.bordred-yellow {
    background-color: $white;
    color: $black;
    border-color: $yellow;

    >span {
      svg {

        path,
        polygon {
          fill: $black;
        }
      }
    }

    &:hover {
      color: $white;
      background-color: $yellow;
      border-color: $yellow;

      >span {
        svg {

          path,
          polygon {
            fill: $white;
          }
        }
      }
    }
  }

  &.btn-yellow {
    background-color: $yellow;
    color: $white;
    border-color: $yellow;

    >span {
      svg {

        path,
        polygon {
          fill: $white;
        }
      }
    }

    &:hover {
      color: $white;
      background-color: $black;
      border-color: $black;

      >span {
        svg {

          path,
          polygon {
            fill: $white;
          }
        }
      }
    }
  }

  &.btn-yellow-black {
    background-color: $yellow;
    color: $black;
    border-color: $yellow;

    >span {
      svg {

        path,
        polygon {
          fill: $white;
        }
      }
    }

    &:hover {
      color: $black;
      background-color: $white;
      border-color: $black;

      >span {
        svg {

          path,
          polygon {
            fill: $white;
          }
        }
      }
    }
  }

  &.makeoffer {
    margin-top: 3.5rem;

    @media (max-width: 991px) {
      margin-top: 2rem;
    }
  }

  &.btn-yellow-textblack {
    background-color: $yellow;
    color: $black;
    border-color: $yellow;

    >span {
      svg {

        path,
        polygon {
          fill: $white;
        }
      }
    }

    &:hover {
      color: $white;
      background-color: $black;
      border-color: $black;

      >span {
        svg {

          path,
          polygon {
            fill: $white;
          }
        }
      }
    }
  }

  &.bordred-green {
    background-color: transparent;
    color: $green;
    border-color: $green;

    >span {
      svg {

        path,
        polygon {
          fill: $green;
        }
      }
    }

    &:hover {
      color: $white;
      background-color: $green;
      border-color: $green;

      >span {
        svg {

          path,
          polygon {
            fill: $white;
          }
        }
      }
    }
  }

  @media (max-width: 1679px) {
    font-size: 1.6rem;
    height: 4.5rem;
  }

  @media (max-width: 1399px) {
    height: 4rem;
    padding: 0.6rem 2rem;
  }

  @media (max-width: 991px) {
    font-size: 1.4rem;
    padding: 0.6rem 1.5rem;
  }

  &.bg-border-without {
    background: transparent;
    border: none;
    height: 2.6rem;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: underline;
    color: $black;
    padding: 0;

    &:hover {
      box-shadow: none;
    }
  }
}