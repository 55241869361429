@import '../../../Assets/_var.scss';

.howtoflaunt {
    &_innerPart {
        padding: 14rem 0;

        @media (max-width:1679px) {
            padding: 10rem 0;
        }

        @media (max-width:1399px) {
            padding: 8rem 0;
        }

        @media (max-width:1199px) {
            padding: 6rem 0;
        }

        @media (max-width:991px) {
            padding: 4.5rem 0;
        }

        @media (max-width:767px) {
            padding: 3.5rem 0;
        }

        .getApp {
            display: flex;
            align-items: flex-start;

            @media (max-width:991px) {
                flex-direction: column;
            }

            &LeftSide {
                width: 100%;
                max-width: 74rem;
                flex: 0 0 74rem;

                @media (max-width:1679px) {
                    max-width: 68rem;
                    flex: 0 0 68rem;
                }

                @media (max-width:1399px) {
                    max-width: 60rem;
                    flex: 0 0 60rem;
                }

                @media (max-width:1199px) {
                    max-width: 48rem;
                    flex: 0 0 48rem;
                }

                @media (max-width:991px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    text-align: center;
                }

                h2 {
                    display: flex;
                    align-items: center;
                    line-height: 1.1;
                    margin-bottom: 5.5rem;

                    @media (max-width:1679px) {
                        margin-bottom: 4rem;
                    }

                    @media (max-width:1399px) {
                        margin-bottom: 2.5rem;
                    }

                    @media (max-width:1199px) {
                        margin-bottom: 2rem;
                    }

                    @media (max-width:991px) {
                        margin-bottom: 1.5rem;
                        justify-content: center;
                    }

                    img {
                        width: 25.6rem;
                        height: 6.2rem;
                        margin-inline: 1.5rem;

                        @media (max-width:1679px) {
                            width: 21.6rem;
                            height: 5.5rem;
                        }

                        @media (max-width:1399px) {
                            width: 19rem;
                            height: 4.7rem;
                        }

                        @media (max-width:991px) {
                            width: 14rem;
                            height: 3.5rem;
                            margin-inline: 1rem;
                        }

                        @media (max-width:767px) {
                            width: 13rem;
                            height: 3rem;
                        }
                    }
                }

                h5 {
                    margin-bottom: 3rem;
                    font-weight: 400;
                    line-height: 2.7rem;

                    @media (max-width:1679px) {
                        margin-bottom: 2.5rem;
                        line-height: 2.2rem;
                    }

                    @media (max-width:1399px) {
                        margin-bottom: 1.8rem;
                    }

                    @media (max-width:1199px) {
                        margin-bottom: 1.2rem;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &RightSide {
                width: 100%;
                max-width: calc(100% - 74rem);
                flex: 0 0 calc(100% - 74rem);
                padding-left: 9.8rem;

                @media (max-width:1679px) {
                    max-width: calc(100% - 68rem);
                    flex: 0 0 calc(100% - 68rem);
                    padding-left: 6.2rem;
                }

                @media (max-width:1399px) {
                    max-width: calc(100% - 60rem);
                    flex: 0 0 calc(100% - 60rem);
                    padding-left: 4rem;
                }

                @media (max-width:1199px) {
                    max-width: calc(100% - 48rem);
                    flex: 0 0 calc(100% - 48rem);
                    padding-left: 2.5rem;
                }

                @media (max-width:991px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding-left: 0;
                    margin: 3rem auto 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                .tapImg {
                    width: 100%;
                    height: auto;
                    max-width: 88.1rem;
                    margin-bottom: 2.3rem;

                    @media (max-width:1199px) {
                        margin-bottom: 2rem;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .btnSec {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .btnApp {
                        margin-inline: 1rem;
                        height: 100%;
                        width: 100%;
                        max-width: max-content;

                        span {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .digitalDisplay {
            display: flex;
            align-items: flex-start;
            padding-top: 15.5rem;

            @media (max-width:1679px) {
                padding-top: 12rem;
            }

            @media (max-width:1399px) {
                padding-top: 9rem;
            }

            @media (max-width:1199px) {
                padding-top: 7rem;
            }

            @media (max-width:991px) {
                padding-top: 5rem;
                flex-direction: column;
                text-align: center;
            }

            @media (max-width:767px) {
                padding-top: 4rem;
            }

            &LeftSide {
                width: 100%;
                max-width: calc(100% - 80rem);
                flex: 0 0 calc(100% - 80rem);
                padding-right: 3.9rem;

                @media (max-width:1679px) {
                    max-width: calc(100% - 70rem);
                    flex: 0 0 calc(100% - 70rem);
                    padding-right: 3rem;
                }

                @media (max-width:1399px) {
                    max-width: calc(100% - 60rem);
                    flex: 0 0 calc(100% - 60rem);
                    padding-right: 2rem;
                }

                @media (max-width:1199px) {
                    max-width: calc(100% - 48rem);
                    flex: 0 0 calc(100% - 48rem);
                    padding-right: 1rem;
                }

                @media (max-width:991px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding-right: 0;
                    margin: 0 auto 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                .tapImg {
                    width: 100%;
                    height: auto;
                    max-width: 88.1rem;
                    margin-bottom: 2.3rem;

                    @media (max-width:1199px) {
                        margin-bottom: 2rem;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            &RightSide {
                width: 100%;
                max-width: 79.1rem;
                flex: 0 0 79.1rem;

                @media (max-width:1679px) {
                    max-width: 70rem;
                    flex: 0 0 70rem;
                }

                @media (max-width:1399px) {
                    max-width: 60rem;
                    flex: 0 0 60rem;
                }

                @media (max-width:1199px) {
                    max-width: 48rem;
                    flex: 0 0 48rem;
                }

                @media (max-width:991px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }


                h2 {
                    display: flex;
                    align-items: center;
                    line-height: 1.1;
                    margin-bottom: 5.5rem;

                    @media (max-width:1679px) {
                        margin-bottom: 4rem;
                    }

                    @media (max-width:1399px) {
                        margin-bottom: 2.5rem;
                    }

                    @media (max-width:1199px) {
                        margin-bottom: 2rem;
                    }

                    @media (max-width:991px) {
                        margin-bottom: 1.5rem;
                        justify-content: center;
                    }
                }

                h5 {
                    margin-bottom: 3rem;
                    font-weight: 400;
                    line-height: 2.7rem;

                    @media (max-width:1679px) {
                        margin-bottom: 2.5rem;
                        line-height: 2.2rem;
                    }

                    @media (max-width:1399px) {
                        margin-bottom: 1.8rem;
                    }

                    @media (max-width:1199px) {
                        margin-bottom: 1.2rem;
                    }
                }
            }
        }
    }
}