.mainLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  .mainWrappe {
    padding-top: 8rem;

    @media (max-width: 1679px) {
      padding-top: 6.5rem;
    }

    @media (max-width: 991px) {
      padding-top: 5.5rem;
    }

    @media (max-width: 425px) {
      padding-top: 5.4rem;
    }
  }
}