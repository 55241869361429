@import "../../../../Assets/_var.scss";

.art_owned {
  .searchbar {
    margin-bottom: 5rem;

    :global {
      form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 575px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .search_input {
          width: 100%;

          .search_input_inner {
            height: 5.8rem;
            border-radius: 2.9rem;
            background: $white_light3;
            padding: 0 2.8rem;

            @media (max-width: 991px) {
              height: 5rem;
            }

            @media (max-width: 767px) {
              height: 4.5rem;
            }

            .search_input_icon {
              border: none;
              left: 2rem;

              svg {
                path {
                  fill: $gray5;
                }
              }
            }

            .form-control {
              border: none;
              background: transparent;
              font-size: 2rem;

              @media (max-width: 991px) {
                font-size: 1.8rem;
              }

              @media (max-width: 767px) {
                font-size: 1.6rem;
              }

              &::placeholder {
                color: $gray6;
              }
            }
          }
        }
      }
    }

    .btnimport {
      width: 100%;
      max-width: 22rem;
      height: 4.7rem;
      margin-left: 3.3rem;
      padding: 0.8rem 1rem;
      font-size: 2rem;
      line-height: 2.2rem;

      @media (max-width: 991px) {
        font-size: 1.8rem;
      }

      @media (max-width: 767px) {
        font-size: 1.6rem;
      }

      @media (max-width: 575px) {
        margin: 1.5rem 0 0;
      }
    }
  }

  .textfeilds {
    width: 100%;
    max-width: 40rem;
  }

  .table_section {
    .artowned_table {
      :global {
        table {
          border-spacing: 0;
          border-collapse: collapse;

          thead {
            tr {
              th {
                background-color: $black;
                color: $white;
                padding: 1.5rem 2.5rem;
                font-weight: 500;
                font-size: 1.6rem;
                font-family: $baseFont;

                &:first-child {
                  min-width: 20rem;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                border-top: 2px solid $borderClr2;
                border-bottom: 2px solid $borderClr2;
                padding: 1.5rem 2.5rem;
                font-size: 1.4rem;
                font-weight: bold;
                font-family: $baseFont;

                &:first-child {
                  width: 70rem;
                  min-width: 70rem;
                  max-width: 15rem;

                  @media (max-width: 1679px) {
                    width: 60rem;
                    min-width: 60rem;
                  }

                  @media (max-width: 1399px) {
                    width: 50rem;
                    min-width: 50rem;
                  }

                  @media (max-width: 1199px) {
                    width: 40rem;
                    min-width: 40rem;
                  }

                  @media (max-width: 991px) {
                    width: 30rem;
                    min-width: 30rem;
                  }
                }

                .thumb-outer {
                  img {
                    width: 100%;
                    height: 7rem;
                    max-width: 7rem;
                    flex: unset;
                  }
                }

                h4 {
                  font-size: 1.4rem;
                  font-weight: bold;
                  font-family: $baseFont;
                  word-break: break-word;
                  white-space: normal;
                }
              }

              &:first-child {
                td {
                  border-top: none;
                }
              }
            }
          }
        }
      }

      .notifiedBtn {
        font-size: 1.6rem;
        padding: 0.5rem 1rem;
        vertical-align: middle;
        width: 100%;
        max-width: 13rem;
        height: 3.8rem;

        @media (max-width: 991px) {
          font-size: 1.4rem;
        }
      }

      .borderBtn {
        width: 100%;
        max-width: 21rem;
        height: 3.8rem;
        padding: 0.5rem 1rem;
        margin-left: 2rem;
        font-size: 1.6rem;

        @media (max-width: 991px) {
          font-size: 1.4rem;
        }
      }
    }
  }
}