@import '../../../../../../../Assets/_var.scss';

.auctionForm {
    display: flex;
    align-items: center;
    width: 100%;

    form {
        width: 100%;

        .auctionTophead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;

            @media (max-width: 479px) {
                flex-direction: column;
                align-items: flex-start;
            }

            .bidrank {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                label {
                    font-size: 1.8rem;
                    font-weight: 400;
                    color: $black;
                    margin-right: 0.6rem;

                    @media (max-width: 1439px) {
                        font-size: 1.6rem;
                    }

                    @media (max-width: 991px) {
                        font-size: 1.4rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.2rem;
                    }
                }
            }

            .timerSet {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                label {
                    font-size: 1.8rem;
                    font-weight: 400;
                    color: $black;
                    margin-right: 0.6rem;

                    @media (max-width: 1439px) {
                        font-size: 1.6rem;
                    }

                    @media (max-width: 991px) {
                        font-size: 1.4rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.2rem;
                    }
                }
            }
        }


        .innerForm {
            display: flex;
            align-items: center;
            border: 0.1rem solid $gray707070;
            width: 100%;
            padding: 2.7rem 3rem;
            border-radius: 0.5rem;

            @media (max-width:1679px) {
                padding: 2rem 2.4rem;
            }

            @media (max-width:1399px) {
                padding: 1.5rem 2rem;
            }

            @media (min-width:992px) and (max-width:1199px) {
                flex-direction: column;
            }

            @media (max-width:991px) {
                padding: 1rem 1.5rem;
            }

            @media (max-width:575px) {
                flex-direction: column;
            }

            &Leftside {
                display: flex;
                align-items: center;
                width: 100%;
                max-width: max-content;
                min-width: 15rem;

                @media (max-width:1399px) {
                    min-width: 14rem;
                }

                @media (min-width:992px) and (max-width:1199px) {
                    min-width: 100%;
                }

                @media (max-width:575px) {
                    min-width: 100%;
                }

                label {
                    font-size: 1.8rem;
                    font-weight: 400;
                    color: $black;
                    margin-right: 0.4rem;

                    @media (max-width: 1439px) {
                        font-size: 1.6rem;
                    }

                    @media (max-width: 991px) {
                        font-size: 1.4rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.2rem;
                    }
                }
            }

            &Rightside {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                max-width: calc(100% - 15rem);

                @media (max-width:1399px) {
                    max-width: calc(100% - 14rem);
                }

                @media (min-width:992px) and (max-width:1199px) {
                    max-width: 100%;
                    justify-content: flex-start;
                    margin-top: 1rem;
                }

                @media (max-width:575px) {
                    max-width: 100%;
                    justify-content: flex-start;
                    margin-top: 1rem;
                }

                @media (max-width:479px) {
                    flex-direction: column;
                    align-items: flex-start;
                }


                .addqantity {
                    display: flex;
                    align-items: center;

                    @media (min-width:992px) and (max-width:1199px) {
                        width: 100%;
                    }

                    @media (max-width:575px) {
                        width: 100%;
                    }

                    @media (max-width:479px) {
                        margin-bottom: 1rem;
                    }

                    @media (max-width:379px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    :global {
                        .common_input {
                            display: flex;
                            align-items: center;
                            margin-bottom: 0;
                            margin-right: 2rem;

                            @media (max-width:1679px) {
                                margin-right: 1.5rem;
                            }

                            @media (max-width:1399px) {
                                margin-right: 1rem;
                            }

                            &.checkMax {
                                width: 100%;
                                max-width: 12rem;

                                @media (max-width:1399px) {
                                    max-width: 11rem;
                                }
                            }


                            &.priceadd {
                                flex-direction: column;
                                width: 100%;
                                max-width: 19rem;
                                text-align: center;

                                @media (max-width:1679px) {
                                    max-width: 16rem;
                                }

                                @media (max-width:1399px) {
                                    max-width: 14rem;
                                }


                                @media (min-width:992px) and (max-width:1199px) {
                                    max-width: 100%;

                                    .common_input_inner {
                                        width: 100%;
                                    }
                                }

                                @media (max-width:991px) {
                                    .error_message {
                                        font-size: 1.2rem;
                                    }
                                }

                                @media (max-width:575px) {
                                    max-width: 100%;

                                    .common_input_inner {
                                        width: 100%;
                                    }
                                }

                                @media (max-width:379px) {
                                    margin-top: 1rem;
                                }

                            }

                            .form-label {
                                font-size: 1.4rem;
                                color: $gray656565;
                                font-weight: 400;
                                margin-bottom: 0;
                                margin-right: 1.4rem;
                                max-width: max-content;
                            }

                            &_inner {
                                .form-control {
                                    height: 4rem;
                                    width: 100%;
                                    max-width: 100%;
                                    padding: 0 2.3rem;
                                    border: 0.2rem solid $grayC1C1C1;
                                    border-radius: 1.8rem;

                                    @media (max-width:1679px) {
                                        padding-inline: 1.8rem;
                                    }

                                    @media (max-width:1399px) {
                                        padding-inline: 1.2rem;
                                        font-size: 1.4rem;
                                    }

                                    @media (max-width:1199px) {
                                        height: 3.5rem;
                                    }
                                }
                            }

                            &.checkMax {
                                .form-check {
                                    .form-check-label {
                                        color: $gray656565;
                                        font-size: 1.4rem;

                                        @media (max-width:1399px) {
                                            font-size: 1.2rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .btnSize {
                    height: 4rem;
                    max-width: 12rem;
                    font-size: 1.6rem;
                    padding: 1rem 2rem;
                    width: 100%;

                    @media (max-width:1399px) {
                        max-width: 10rem;
                        font-size: 1.4rem;
                        padding: 0.7rem 1rem;
                    }

                    @media (max-width:1199px) {
                        height: 3.5rem;
                    }
                }
            }
        }
    }
}